import React, { CSSProperties, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  useMediaQuery,
  MenuItem
} from '@mui/material';
import {
  actionCreators as addressesActions,
  selectCountryItems,
  selectStateItems
} from '../../actions/address';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { useTheme, styled } from '@mui/material/styles';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { CompaniesFilterDto } from '../../entities/Dto/CompaniesFilterDto';
import { IndustryCategory } from '../../entities/IndustryCategory';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './CompaniesStyle.css';

interface FiltersFormProps
  extends Pick<
    UseFormReturn<CompaniesFilterDto>,
    'control' | 'watch' | 'handleSubmit' | 'formState' | 'setValue'
  > {
  submitHandler: SubmitHandler<CompaniesFilterDto>;
  industryCategories?: IndustryCategory[];
  bottomActions: React.ReactNode;
}

const FiltersCompanies = ({
  control,
  watch,
  handleSubmit,
  submitHandler,
  setValue,
  industryCategories = [],
  formState,
  bottomActions
}: FiltersFormProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const watchCountry = watch('Country');

  const isLoadingCountries = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCountries
  );
  const isLoadingStates = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingStates
  );

  const countryItems = useSelector(selectCountryItems);
  const stateItems = useSelector(selectStateItems);

  useEffect(() => {
    if (watchCountry)
      dispatch(addressesActions.getStatesFromCountries(watchCountry));
  }, [watchCountry]);

  const industriesOptions = useMemo(() => {
    if (!!industryCategories && !!industryCategories.length) {
      return industryCategories.map(industryCategory => ({
        label: industryCategory.name,
        value: industryCategory.name
      }));
    }
    return [];
  }, [industryCategories]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12} md={4} lg={4} className="muiGrid-item">
          <FormLabel color="#FFF">Country</FormLabel>
          <Controller
            name="Country"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <Autocomplete
                disablePortal
                options={countryItems ?? []}
                loading={isLoadingCountries}
                value={value}
                style={autocompleteStyle}
                onChange={(_, newValue) => {
                  setValue('State', '');
                  formOnChange(newValue);
                }}
                placeholder="Country"
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingCountries && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="muiGrid-item">
          <FormLabel color="#FFF">State</FormLabel>
          <Controller
            name="State"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <Autocomplete
                disablePortal
                options={stateItems ?? []}
                loading={isLoadingStates}
                value={value}
                onChange={(_, newValue) => {
                  formOnChange(newValue);
                }}
                style={autocompleteStyle}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingStates && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4} className="muiGrid-item">
          <FormLabel color="#FFF">Industries</FormLabel>
          <Controller
            name="Industry"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                onChange={onChange}
                value={value ?? ''}
                displayEmpty
                labelId="Industry"
                defaultValue={''}
                sx={{
                  height: '45px',
                  backgroundColor: '#FFFFFF !important'
                }}
              >
                <MenuItem disabled value={''}>
                  - select -
                </MenuItem>
                {industriesOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
          className="muiGrid-item"
        >
          {bottomActions}
        </Grid>
      </Grid>
    </form>
  );
};

const autocompleteStyle: CSSProperties = {
  backgroundColor: '#FFFFFF',
  borderRadius: '4px'
};

export default FiltersCompanies;
