import React, { useState, useEffect, forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker'
import enUS from 'date-fns/locale/en-US';
import { useTheme, styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

export interface DatePickerProps {
    onChange?: (date: Date) => void,
    disablePastDays?: boolean;
    value?: Date | null | undefined;
}

const ReactDatePickerCustomInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(( props, ref) => <input ref={ref} {...props} className="custom-datepicker-input"/>)

const DatePickerInput : React.FC<DatePickerProps> = ({
    onChange,
    disablePastDays = false,
    value
}) => {
    registerLocale('en-US', enUS);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const theme = useTheme();

    useEffect(() => {
        if (value)
            setSelectedDate(moment(value).toDate());
    }, [value])

    const dateText = (() => {            
        if (selectedDate)
            return moment(selectedDate).format('MM.DD.YY');
        return '';
    })();

    const filterDate = (date: Date) => {
        if (!disablePastDays)
            return true;

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        return date >= currentDate;
    }

    const handleOnChange = (date: Date) => {
        setSelectedDate(date);

        if (onChange)
            onChange(date);
    }

    return(<div className="custom-datepicker">
            <DatePicker 
                locale="en-US"
                selected={selectedDate}
                onChange={handleOnChange}
                filterDate={filterDate}
                customInput={<ReactDatePickerCustomInput />}
            />
        <label className="custom-datepicker-label" htmlFor="customDatePicker">
            <FontAwesomeIcon icon={faCalendarMinus} color={theme.palette.grey[100]}/><span className="ps-2" >{dateText}</span> 
        </label>
    </div>);
}

export default DatePickerInput;