import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { useTheme } from '@mui/material/styles';
import zIndex from '@mui/material/styles/zIndex';

export interface DropDownlistProps extends StateManagerProps {
    color?: 'primary' | 'secundary' | undefined
}

const DropDownlist : React.FC<DropDownlistProps> = ({color, ...props}) => {
    const theme = useTheme();

    const primaryStyle : StylesConfig = {
        control : (styles) => ({ 
            ...styles, 
            backgroundColor: 'white',
            color: 'black',
            border: 0,
            boxShadow: 'none'
        }),
        option: (styles, state) => ({
              ...styles,
              background: state.data === state.selectProps.value ? theme.palette.warning.light : 'none',
              '&:hover': {
                background: state.data === state.selectProps.value ? theme.palette.warning.light  :  'rgba(0, 0, 0, 0.04)',
              },  
          }),
        input : (styles) => ({
            ...styles
        }),
        menu: (styles) => ({
            ...styles,
            color: theme.palette.secondary.main,
            zIndex: '99',
        }),
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '25px',
            padding: '5px 10px 0'
        }),
    }

    const secondaryStyle : StylesConfig = {
        control : (styles) => ({ 
            ...styles, 
            backgroundColor: '#282828',
            borderColor: '#000000',
            boxShadow: 'none',
            color: 'white',
            
        }),
        singleValue : (styles) => ({
            ...styles,
            color: 'white'
        }) 
    }

    return (<Select
                {...props} 
                styles={ !color || color === 'primary' ? primaryStyle : secondaryStyle} 
            />)
}

export default DropDownlist;