import React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../actions/lessons';
import { actionCreators as courseActions } from '../../actions/courses';
import { actionCreators as appActions } from '../../actions/appState';
import { Lesson } from '../../entities/Lesson';
import LessonCard from '../../components/LessonCard/LessonCard';
import { useHistory } from 'react-router-dom';
import ModalPage from '../../components/core/ModalPage/ModalPage';
import CreateLesson from '../lessons/CreateLesson';
import ModalAssessment from '../../components/ModalAssessment/ModalAssessment';
import EditLesson from '../lessons/EditLesson';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions, LessonQuiz } from '../../core/constants';
import AssignLesson from './AssignLesson';
import { buttonStyle, cardButtons, fontButton } from '../../utils/buttonStyles';
import { Typography } from '@mui/material';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import config from '../../config';
import { ServerEventsNames } from '../../core/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { LessonFormModel } from '../../entities/LessonFormModel';
import { useLesson } from './useLesson';

export interface LessonProps {}

interface actionsAreaProps {
  id: string;
  onEdit: (id: string) => void;
  onPreview: (lessonId: string) => void;
  onAssign: (id: string) => void;
}

const ActionsArea: React.FC<actionsAreaProps> = ({
  id,
  onEdit,
  onPreview,
  onAssign
}) => {
  const { t } = useTranslation(['common']);

  return (
    <div style={divButtonStyle}>
      <div>
        <BasicButton
          style={cardButtons}
          color="primary"
          onClick={() => onPreview(id)}
        >
          {t('preview', { ns: 'common' })}
        </BasicButton>
        <ProtectedComponent action={appPermissions.ADD_LESSON}>
          <BasicButton
            style={{ ...cardButtons, ...localStyle }}
            color="secondary"
            onClick={() => onAssign(id)}
          >
            {t('assign', { ns: 'common' })}
          </BasicButton>
        </ProtectedComponent>
      </div>
      <div>
        <ProtectedComponent action={appPermissions.EDIT_LESSON}>
          <Button onClick={() => onEdit(id)} style={editStyle}>
            {t('edit', { ns: 'common' })}{' '}
            <FontAwesomeIcon icon={faPen} style={styleIcon} />
          </Button>
        </ProtectedComponent>
      </div>
    </div>
  );
};

export const Lessons: React.FC<LessonProps> = ({}) => {
  const dispatch = useDispatch();
  const companyLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allLessons
  );
  const history = useHistory();
  const [lessons, setLessons] = React.useState<Lesson[]>([]);
  const [searchCriteria, setSearchCriteria] = React.useState<string>('');

  const [connection, setConnection] = React.useState<null | HubConnection>(
    null
  );

  const {
    api: {
      handleSaveLesson,
      handleAssessmentGoBack,
      handleEditLesson,
      handleCloseEditModal,
      handleFinishEditLesson,
      handleCloseAssessmentModal,
      setShowAddLessonModal,
      handleOpenAssignLessonModal,
      closeLessonModal,
      setShowEditLessonModal,
      handleCloseEditModalOnLessonDeletion,
      handleCloseAssignLessonModal,
      handleAssignLessonGoBack,
      setIsEdit
    },
    state: {
      isEdit,
      uploadVideoProgress,
      showAddLessonModal,
      showAssessmentModal,
      showAssignLessonModal,
      showEditLessonModal,
      createError,
      refresh
    }
  } = useLesson(false);

  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons!.currentLesson
  );
  const { t } = useTranslation(['common', 'lessons', 'quizzes']);

  React.useEffect(() => {
    loadLessons();
  }, [dispatch, refresh]);

  React.useEffect(() => {
    setLessons(companyLessons!);
  }, [companyLessons]);

  React.useEffect(() => {
    if (searchCriteria) {
      const filtered = filterLessons(searchCriteria, companyLessons!);
      setLessons(filtered);
    } else {
      setLessons(companyLessons!);
    }
  }, [searchCriteria]);

  React.useEffect(() => {
    dispatch(courseActions.setCurrentCourse(null));

    const connect = new HubConnectionBuilder()
      .withUrl(`${config.COURSES_BASE_URL}/notifications`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  React.useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on(
            ServerEventsNames.LessonVideoEncodingComplete,
            (message: any) => {
              loadLessons();
            }
          );
          connection.on(
            ServerEventsNames.LessonVideoEncodingFailed,
            (error: any) => {
              alert(`${t('videoEncodingError', { ns: 'common' })}: ${error}`);
            }
          );
        })
        .catch((error: any) => console.log(error));
    }
  }, [connection]);

  const filterLessons = (criteria: string, lessons: Lesson[]) => {
    let filtered: Lesson[] = [];
    lessons.forEach(lesson => {
      const tags = lesson.tags.slice();
      if (
        lesson.title.toLowerCase().includes(criteria.toLowerCase()) ||
        lesson.description.toLowerCase().includes(criteria.toLowerCase()) ||
        tags.filter(tag =>
          tag.name.toLowerCase().includes(criteria.toLowerCase())
        ).length > 0
      ) {
        filtered.push(lesson);
      }
    });
    return filtered;
  };

  const loadLessons = async () => {
    dispatch(appActions.setIsLoading(true));
    await dispatch(actionCreators.requestCompanyLessons());
    dispatch(appActions.setIsLoading(false));
  };

  const handleLessonPreview = (lesson: Lesson) => {
    if (lesson && lesson.isEncodingFailed) {
      alert(t('videoLessonEcodingFailed', { ns: 'lessons' }));
    } else {
      dispatch(actionCreators.setCurrentLessons([lesson]));
      history.push({ pathname: `/lessons/preview`, state: { lesson } });
    }
  };

  const onBeforeSaveAssessments =
    currentLesson?.lessonType === LessonQuiz
      ? (lesson: LessonFormModel) => handleSaveLesson(lesson, true)
      : undefined;

  return (
    <>
      <PageContainer>
        <Row>
          <h3>{t('lessons', { ns: 'lessons' })}</h3>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div style={divStyle}>
              <SearchBox
                id="searchLesson"
                name="searchLesson"
                value=""
                onChangeValue={e => {
                  setSearchCriteria(e);
                }}
                placeholderText={t('search', { ns: 'common' })}
                width="350px"
                height="35px"
                onSearchClick={e => {}}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div style={buttonsPanelStyle}>
              <ProtectedComponent action={appPermissions.ADD_LESSON}>
                <BasicButton
                  color="primary"
                  onClick={() => {
                    setShowAddLessonModal(true);
                    setIsEdit(false);
                  }}
                  style={buttonStyle}
                >
                  <Typography sx={fontButton}>
                    {t('addLesson', { ns: 'lessons' })}
                  </Typography>
                </BasicButton>
              </ProtectedComponent>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Row>
                {!!lessons.length ? (
                  lessons.map(lesson => (
                    <Col
                      key={lesson.id}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={4}
                      style={cardItemStyle}
                    >
                      <div className="pt-3">
                        <LessonCard lesson={lesson}>
                          {
                            <ActionsArea
                              id={lesson.id}
                              onEdit={handleEditLesson}
                              onPreview={() => handleLessonPreview(lesson)}
                              onAssign={handleOpenAssignLessonModal}
                            />
                          }
                        </LessonCard>
                      </div>
                    </Col>
                  ))
                ) : (
                  <h3>{t('noLessonsAvailable', { ns: 'lessons' })}</h3>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </PageContainer>
      <ModalPage
        title={t('createLesson', { ns: 'lessons' })}
        onSave={() => {
          setShowAddLessonModal(false);
        }}
        onCancel={() => {
          setShowAddLessonModal(false);
        }}
        show={showAddLessonModal}
        showSave={false}
        showCancel={false}
        saveText={t('save', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        variant="lg"
        modalClassName="lesson-modal"
        contentClassName="modal-body-auto-height"
      >
        {showAddLessonModal && (
          <CreateLesson
            closeModal={closeLessonModal}
            createError={createError}
            handleSaveLesson={handleSaveLesson}
            uploadVideoProgress={uploadVideoProgress}
          />
        )}
      </ModalPage>
      <ModalPage
        title={t('editLesson', { ns: 'lessons' })}
        onSave={() => {
          setShowEditLessonModal(false);
        }}
        onCancel={handleCloseEditModal}
        show={showEditLessonModal}
        showSave={false}
        showCancel={false}
        saveText={t('save', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        variant="lg"
        modalClassName="lesson-modal"
        contentClassName="modal-body-auto-height"
      >
        {showEditLessonModal && (
          <EditLesson
            closeModal={handleFinishEditLesson}
            closeDeleteModal={() => handleCloseEditModalOnLessonDeletion()}
            openedFrom="Lessons"
          />
        )}
      </ModalPage>
      {showAssessmentModal && (
        <ModalAssessment
          title={
            currentLesson?.lessonType === LessonQuiz
              ? t('quiz', { ns: 'lessons' })
              : t('assessment', { ns: 'assessments' })
          }
          variant="lg"
          onGoBack={handleAssessmentGoBack}
          onClose={handleCloseAssessmentModal}
          isEdit={isEdit}
          onBeforeSave={onBeforeSaveAssessments}
        />
      )}
      {showAssignLessonModal && (
        <AssignLesson
          onGoBack={handleAssignLessonGoBack}
          onClose={handleCloseAssignLessonModal}
        />
      )}
    </>
  );
};

const divStyle = {
  textAlign: 'left' as 'left',
  width: '100%',
  paddingTop: '20px',
  paddingBottom: '20px'
};

const buttonsPanelStyle = {
  textAlign: 'right' as 'right',
  width: '100%',
  paddingTop: '20px',
  paddingBottom: '20px'
};

const cardItemStyle = {
  paddingTop: '10px'
};

const editStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  textDecoration: 'underline',
  width: '95px',
  height: '35px'
};

const styleIcon = {
  marginLeft: '5px',
  fontSize: '15px'
};

const localStyle = { marginLeft: '10px' };

const divButtonStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px'
};

export default Lessons;
