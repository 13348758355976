import * as React from 'react';
import * as firebase from './firebase';

import { actionCreators as notificationsActions } from './actions/notifications';
import { actionCreators as appActions } from './actions/appState';
import { actionCreators as companyActions } from './actions/companies';
import { actionCreators as jobsActions } from './actions/jobs';
import { actionCreators as addressActions } from './actions/address';

import './site.css';
import './custom-site.css';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';

import LessonPreview from './pages/lessons/LessonPreview';
import Lessons from './pages/lessons/Lessons';
import Settings from './pages/settings/Settings';
import LearningPlans from './pages/learningPlans/LearningPlans';
import LearningPlanDetails from './pages/learningPlans/LearningPlanDetails';
import AssignLearningPlan from './pages/learningPlans/AssignLearningPlan';
import Employees from './pages/employees/Employees';
import Assignments from './pages/assignments/Assignments';
import LearningPlanPreview from './pages/learningPlans/LearningPlanPreview';
import TakeQuiz from './pages/Quizzes/TakeQuiz';
import Dashboard from './pages/dashboard/Dashboard';
import { Mobile } from './pages/Mobile';

import CoursesRoutes from './routes/CoursesRoutes';
import { Layout } from './components/layouts/Layout';
import { HomePage } from './pages/Home';
import { IdentificationSectionCreate } from './pages/EditDirtWorldProfile/Identification/IdentificationSectionCreate';
import { CreateCompanyProfile } from './pages/CompanyDW/CreateCompanyProfile';
import DirtWorldCompanyInfo from './pages/CompanyDW/DirtWorldCompanyInfo';
import { EditDirtWorldProfile } from './pages/EditDirtWorldProfile';
import JobPostConfirmation from './pages/PostJobs/JobPostConfirmation';
import JobPreview from './pages/PostJobs/JobPreview';
import CreateJob from './pages/PostJobs/CreateJob';
import { WorkExperienceSectionUpdate } from './pages/EditDirtWorldProfile/WorkExperience/WorkExperienceSectionUpdate';
import { EducationSectionUpdate } from './pages/EditDirtWorldProfile/Education/EducationSectionUpdate';
import { CertificationSectionUpdate } from './pages/EditDirtWorldProfile/Certification/CertificationSectionUpdate';
import { WorkExperienceSectionDetails } from './pages/EditDirtWorldProfile/WorkExperience/WorkExperienceSectionDetails';
import { WorkExperienceSectionCreate } from './pages/EditDirtWorldProfile/WorkExperience/WorkExperienceSectionCreate';
import { EducationSectionDetails } from './pages/EditDirtWorldProfile/Education/EducationSectionDetails';
import { EducationSectionCreate } from './pages/EditDirtWorldProfile/Education/EducationSectionCreate';
import { CertificationSectionDetails } from './pages/EditDirtWorldProfile/Certification/CertificationSectionDetails';
import { CertificationSectionCreate } from './pages/EditDirtWorldProfile/Certification/CertificationSectionCreate';
import { IdentificationSectionEdit } from './pages/EditDirtWorldProfile/Identification/IdentificationSectionEdit';
import { EditCompanyProfile } from './pages/CompanyDW/EditCompanyProfile';
import JobDetail from './pages/PostJobs/JobDetail';
import DuplicatePostJob from './pages/PostJobs/DuplicatePostJob';
import ConfirmationPage from './pages/PostJobs/ConfirmationPage';
import DeleteJobPost from './pages/PostJobs/DeleteJobPost';
import EditJobPost from './pages/PostJobs/EditJobPost';
import { ScrollToTop } from './components/ScrollToTop';
import PublicJobsPost from './pages/publicJobsPosts/PublicJobsPost';
import CompaniesList from './pages/publicCompaniesList/CompaniesList';

const App = () => {
  firebase.analytics.app.automaticDataCollectionEnabled = true;

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(notificationsActions.requestNotifications());
    dispatch(appActions.getIndustryCategories());
    dispatch(companyActions.getCompanies());
    dispatch(jobsActions.getJobsCategories());
    dispatch(addressActions.getCountries());
    dispatch(jobsActions.getJobs());
    dispatch(jobsActions.getJobTypes());
  }, []);

  return (
    <Layout>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route
          exact
          path="/user/profile/create"
          component={IdentificationSectionCreate}
        />

        <Route
          exact
          path="/user/profile/:userProfileId"
          component={EditDirtWorldProfile}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/identification/edit"
          component={IdentificationSectionEdit}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/work-experience"
          component={WorkExperienceSectionDetails}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/work-experience/create"
          component={WorkExperienceSectionCreate}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/work-experience/:workExperienceId/edit"
          component={WorkExperienceSectionUpdate}
        />

        <Route
          exact
          path="/user/profile/:userProfileId/education"
          component={EducationSectionDetails}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/education/create"
          component={EducationSectionCreate}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/education/:educationId/edit"
          component={EducationSectionUpdate}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/certification"
          component={CertificationSectionDetails}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/certification/create"
          component={CertificationSectionCreate}
        />
        <Route
          exact
          path="/user/profile/:userProfileId/certification/:certificationId/edit"
          component={CertificationSectionUpdate}
        />
        {/*
        Commenting temporarily for MVP.
      <Route
        exact
        path="/profile/user/:id/additionalInformation"
        component={AdditionalInformationSectionEdit}
      />
      <Route
        exact
        path="/profile/user/:id/jobApplication"
        component={JobApplicationSectionEdit}
      /> */}
        <Route
          exact
          path="/company/profile/create"
          component={CreateCompanyProfile}
        />

        <Route
          exact
          path="/company/profile/:companyProfileId"
          component={DirtWorldCompanyInfo}
        />

        <Route
          exact
          path="/company/profile/:companyProfileId/edit"
          component={EditCompanyProfile}
        />

        <Route
          exact
          path="/company/profile/jobs/post-job-create"
          component={CreateJob}
        />
        <Route
          exact
          path="/company/profile/jobs/post-job-preview"
          component={JobPreview}
        />
        <Route
          exact
          path="/company/profile/jobs/post-job-confirmation"
          component={JobPostConfirmation}
        />
        <Route
          exact
          path="/company/profile/jobs/post-job-detail/:jobID"
          component={JobDetail}
        />
        <Route
          exact
          path="/company/profile/jobs/duplicate-post-job"
          component={DuplicatePostJob}
        />
        <Route
          exact
          path="/company/profile/jobs/delete-post-job/:jobID"
          component={DeleteJobPost}
        />
        <Route
          exact
          path="/company/profile/jobs/confirmation"
          component={ConfirmationPage}
        />
        <Route
          exact
          path="/company/profile/jobs/:jobID/edit"
          component={EditJobPost}
        />
        <Route
          exact
          path="/jobs"
          component={PublicJobsPost}
        />
        <Route
          exact
          path="/companies"
          component={CompaniesList}
        />
        <Route path="/courses">
          <CoursesRoutes />
        </Route>
        <Route exact path="/lessons/:id/preview" component={LessonPreview} />
        <Route exact path="/lessons/play" component={LessonPreview} />
        <Route exact path="/lessons/preview" component={LessonPreview} />
        <Route exact path="/lessons" component={Lessons} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/assignments" component={Assignments} />
        <Route exact path="/learning-plans" component={LearningPlans} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route
          exact
          path="/learning-plans-details"
          component={LearningPlanDetails}
        />
        <Route
          exact
          path="/assign-learning-plans"
          component={AssignLearningPlan}
        />
        <Route exact path="/employees" component={Employees} />
        <Route
          exact
          path="/learning-plans/preview/:id"
          component={LearningPlanPreview}
        />
        <Route exact path="/lesson/quiz" component={TakeQuiz} />
        <Route exact path="/lesson/quiz/results" component={TakeQuiz} />
        <Route exact path="/Mobile" component={Mobile} />
      </Switch>
    </Layout>
  );
};

export default App;
