import React, { CSSProperties } from 'react';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import { Row, Col } from 'react-bootstrap';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import ModalPage from '../../components/core/ModalPage/ModalPage';
import SelectLessons from '../lessons/SelectLessons';
import CreateLesson from '../lessons/CreateLesson';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../actions/courses';
import { actionCreators as courseActions } from '../../actions/courses';
import { actionCreators as appActions } from '../../actions/appState';
import { actionCreators as actionTags } from '../../actions/tags';

import {
  CourseLessonName,
  ImportLessonDto,
  LessonOrder
} from '../../entities/Course';

import EditLesson from '../lessons/EditLesson';
import EditCourse from './EditCourse';
import { Course } from '../../entities/Course';
import DragDropList, {
  DraggableListItem
} from '../../components/DragDropList/DragDropList';
import { EntityTypeEnum } from '../../core/enums';
import ModalAssessment from '../../components/ModalAssessment/ModalAssessment';
import AssignLesson from '../lessons/AssignLesson';
import ConfirmationModal from '../../components/core/ConfirmationModal/ConfirmationModal';
import { Typography } from '@mui/material';
import {
  buttonStyle,
  fontButton,
  fontDeleteButton
} from '../../utils/buttonStyles';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import config from '../../config';
import {
  LessonQuiz,
  ServerEventsNames,
  LessonAssessment
} from '../../core/constants';
import { useTranslation } from 'react-i18next';
import { useLesson } from '../lessons/useLesson';
import { LessonFormModel } from '../../entities/LessonFormModel';

export interface CourseDetailsProps {}

const emptyCourse: Course = {
  id: '',
  title: '',
  description: '',
  thumbnailUrl: '',
  companyId: '',
  isDraft: true,
  tags: [],
  videosCount: 0,
  flashCardsCount: 0,
  quizesCount: 0,
  revisionNumber: 0
};

const CourseDetails: React.FC<CourseDetailsProps> = () => {
  const [showEditCourseModal, setShowEditCourseModal] = React.useState<boolean>(
    false
  );
  const [courseData, setCourseData] = React.useState<Course>(emptyCourse);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<
    boolean
  >(false);
  const [
    isDeleteLessonConfirmationModalOpen,
    setIsDeleteLessonConfirmationModalOpen
  ] = React.useState<boolean>(false);

  const [showSelectLessons, setShowSelectLessons] = React.useState<boolean>(
    false
  );
  const courseLessonNames = useSelector(
    (state: ApplicationState) => state.courses?.courseLessonNames
  );

  const {
    api: {
      handleSaveLesson,
      handleAssessmentGoBack,
      handleEditLesson,
      handleCloseEditModal,
      handleFinishEditLesson,
      handleCloseAssessmentModal,
      setShowAddLessonModal,
      closeLessonModal,
      setShowEditLessonModal,
      handleCloseAssignLessonModal,
      handleAssignLessonGoBack,
      setIsEdit
    },
    state: {
      isEdit,
      uploadVideoProgress,
      showAddLessonModal,
      showAssessmentModal,
      showAssignLessonModal,
      showEditLessonModal,
      createError
    }
  } = useLesson(true);

  const params: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = React.useState<DraggableListItem[]>([]);
  const [displaySaveOrderButton, setDisplaySaveOrderButton] = React.useState<
    boolean
  >(false);

  const currentCourse = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );

  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons!!.currentLesson
  );
  const [connection, setConnection] = React.useState<null | HubConnection>(
    null
  );
  const [selectedLessonEntryId, setSelectedLessonEntryId] = React.useState<
    string
  >('');
  const { t } = useTranslation(['common', 'lessons', 'courses']);

  React.useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${config.COURSES_BASE_URL}/notifications`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  React.useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on(
            ServerEventsNames.LessonVideoEncodingComplete,
            (message: any) => {
              dispatch(actionCreators.requestCourseLessonNames(params.id));
            }
          );
          connection.on(
            ServerEventsNames.LessonVideoEncodingFailed,
            (error: any) => {
              alert(`${t('videoEncodingError', { ns: 'common' })}: ${error}`);
            }
          );
        })
        .catch((error: any) => console.log(error));
    }

    return () => {
      if (connection) connection.stop();
    };
  }, [connection]);

  const updateLessonsOrder = async () => {
    let lessonsOrder: LessonOrder[] = [];
    dispatch(appActions.setIsLoading(true));
    list.map((item: any, index: number) => {
      lessonsOrder.push({ id: item.id, order: index + 1 });
    });
    await dispatch(
      actionCreators.updateLessonsOrder(currentCourse?.id!, lessonsOrder)
    );
    await dispatch(actionCreators.requestCourseLessonNames(params.id));
    dispatch(appActions.setIsLoading(false));
    setDisplaySaveOrderButton(false);
  };

  const handlePublishCourse = async () => {
    if (currentCourse) {
      const courseDto = { ...currentCourse, isDraft: false };
      await dispatch(actionCreators.editCourse(courseDto, ''));
      history.push('/courses');
    }
  };

  const mapLessonNames = (lessonNames: CourseLessonName[]) => {
    let res: DraggableListItem[] = [];
    lessonNames.forEach(lesson =>
      res.push({
        id: lesson.id,
        title: lesson.title,
        type: lesson.lessonType,
        isEditable: !lesson.isFromLibrary
      })
    );
    return res;
  };

  const deleteCourseAndAllLessonsButton = () => {
    return (
      <BasicButton
        onClick={handleDeleteCourseAndAllLessons}
        color="primary"
        disable={false}
      >
        {t('deleteAll', { ns: 'common' })}
      </BasicButton>
    );
  };

  const getItemsFromDDList = (items: DraggableListItem[]) => {
    if (courseLessonNames) {
      let isDifferent: boolean = false;
      for (let i = 0; i < courseLessonNames.length; i++) {
        const indexItem = items.findIndex(
          item => item.id === courseLessonNames[i].id
        );
        if (i !== indexItem) isDifferent = true;
      }
      setList(items);
      setDisplaySaveOrderButton(isDifferent);
    }
  };

  const handleSaveImportLessons = async (
    selectedLessons: ImportLessonDto[]
  ) => {
    if (selectedLessons) {
      await dispatch(
        actionCreators.importLessonsBatch(params.id, selectedLessons)
      );
      await dispatch(actionCreators.requestCourseLessonNames(params.id));
      setShowSelectLessons(false);
    }
  };

  React.useEffect(() => {
    if (params && params.id) {
      dispatch(courseActions.resetCourseTransactionStatus());
      dispatch(actionCreators.requestCourseLessonNames(params.id));
      dispatch(actionTags.requestTags());

      if (!currentCourse) {
        dispatch(actionCreators.requestCourseById(params.id));
      }
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (courseLessonNames) {
      setList(mapLessonNames(courseLessonNames));
    }
  }, [courseLessonNames]);

  React.useEffect(() => {
    if (currentCourse) {
      setCourseData(currentCourse);
    }
  }, [currentCourse]);

  const closeModalSelectLessons = () => {
    setShowSelectLessons(false);
  };

  const handleDeleteLessonFromCourse = async () => {
    try {
      const lessonId = courseLessonNames?.find(
        c => c.id === selectedLessonEntryId
      )?.lessonId;
      await dispatch(
        actionCreators.deleteLessonFromCourse(params.id, lessonId!)
      );
      await dispatch(actionCreators.requestCourseLessonNames(params.id));
    } catch (e) {
      alert(t('deleteLessonException', { ns: 'lessons' }));
    }
    setIsDeleteLessonConfirmationModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await dispatch(actionCreators.deleteCourse(courseData.id));
      dispatch(courseActions.setCurrentCourse(null));
      setIsConfirmationModalOpen(false);
      history.push('/courses');
    } catch (e) {
      //TODO
    }
  };

  const handleDeleteCourseAndAllLessons = async () => {
    try {
      await dispatch(actionCreators.deleteFullCourse(courseData.id));
      dispatch(courseActions.setCurrentCourse(null));
      setIsConfirmationModalOpen(false);
      history.push('/courses');
    } catch (e) {
      //TODO
    }
  };

  const handleFinishEditCourse = async () => {
    setShowEditCourseModal(false);
  };

  const openDeleteConfirmationModal = (lessonEntryId: string) => {
    setSelectedLessonEntryId(lessonEntryId);
    setIsDeleteLessonConfirmationModalOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setSelectedLessonEntryId('');
    setIsDeleteLessonConfirmationModalOpen(false);
  };

  const onBeforeSaveAssessments =
    currentLesson?.lessonType === LessonQuiz
      ? (lesson: LessonFormModel) => handleSaveLesson(lesson, true)
      : undefined;

  return (
    <>
      <PageContainer>
        <Row>
          <Col xs={10} sm={4} md={4} lg={4}>
            <h3>{t('courseDetails', { ns: 'courses' })}</h3>
          </Col>
          <Col xs={12} sm={8} md={8} lg={8}>
            <ConfirmationModal
              show={isConfirmationModalOpen}
              showSave={true}
              showCancel={true}
              maxWidth="sm"
              saveText={t('deleteCourse', { ns: 'courses' })}
              cancelText={t('cancel', { ns: 'common' })}
              title={t('courseDeletion', { ns: 'courses' })}
              onSave={handleDelete}
              onCancel={() => setIsConfirmationModalOpen(false)}
              extraOptions={[deleteCourseAndAllLessonsButton()]}
            >
              <p>
                {' '}
                {t('removeCourseConfirmation', { ns: 'courses' })}: "
                {courseData?.title}"?
              </p>
            </ConfirmationModal>
            <ConfirmationModal
              show={isDeleteLessonConfirmationModalOpen}
              showSave={true}
              showCancel={true}
              maxWidth="sm"
              saveText={t('deleteLesson', { ns: 'lessons' })}
              cancelText={t('cancel', { ns: 'common' })}
              title={t('deleteLessonFromCourse', { ns: 'lessons' })}
              onSave={handleDeleteLessonFromCourse}
              onCancel={closeDeleteConfirmationModal}
            >
              <p>
                {' '}
                {t('deleteLessonConfirmation', { ns: 'lessons' })}: "
                {selectedLessonEntryId.length
                  ? courseLessonNames?.find(c => c.id === selectedLessonEntryId)
                      ?.title
                  : ''}{' '}
                {t('fromThisCourse', { ns: 'course' })}"?
              </p>
            </ConfirmationModal>

            <div style={topPanelStyle}>
              <BasicButton
                onClick={() => setIsConfirmationModalOpen(true)}
                style={buttonStyle}
                color="delete"
                disable={false}
              >
                <Typography sx={fontDeleteButton}>
                  {t('deleteCourse', { ns: 'courses' })}
                </Typography>
              </BasicButton>

              <div style={divWidth}></div>

              {currentCourse?.isDraft && (
                <>
                  <div style={divButtonStyle}></div>
                  <BasicButton
                    onClick={async () => handlePublishCourse()}
                    color="primary"
                    style={buttonStyle}
                  >
                    <Typography sx={fontButton}>
                      {t('publish', { ns: 'common' })}
                    </Typography>
                  </BasicButton>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row style={addLessonModalStyle}>
          <Col xs={12} sm={12} md={9} lg={9}>
            <DragDropList
              itemType={EntityTypeEnum.Lesson}
              items={list}
              editItem={handleEditLesson}
              deleteItem={openDeleteConfirmationModal}
              getItems={getItemsFromDDList}
            >
              <div style={buttonsContainerStyle}>
                <BasicButton
                  onClick={() => {
                    setShowSelectLessons(true);
                  }}
                  color="primary"
                  style={{ ...buttonStyle, ...btnCreateLessonStyle }}
                >
                  <Typography sx={fontButton}>
                    {t('addLesson', { ns: 'lessons' })}
                  </Typography>
                </BasicButton>
                <BasicButton
                  onClick={() => {
                    setShowAddLessonModal(true);
                    setIsEdit(false);
                  }}
                  color="secondary"
                  style={{ ...buttonStyle, ...btnCreateLessonStyle }}
                >
                  <Typography sx={fontButton}>
                    {t('createLesson', { ns: 'lessons' })}
                  </Typography>
                </BasicButton>
                {displaySaveOrderButton && (
                  <BasicButton
                    onClick={updateLessonsOrder}
                    color="secondary"
                    width="175px"
                    height="45px"
                    style={btnCreateLessonStyle}
                  >
                    {t('saveLessonOrder', { ns: 'lessons' })}
                  </BasicButton>
                )}
              </div>
            </DragDropList>
          </Col>
        </Row>
        <Row>
          <Col className="pt-3">
            <BasicButton
              onClick={() => setShowEditCourseModal(true)}
              color="secondary"
              width="200px"
              height="45px"
            >
              {t('editCourseDetails', { ns: 'courses' })}
            </BasicButton>
          </Col>
        </Row>
      </PageContainer>
      <ModalPage
        title={t('createLesson', { ns: 'lessons' })}
        onSave={() => {
          setShowAddLessonModal(false);
        }}
        onCancel={() => {
          setShowAddLessonModal(false);
        }}
        show={showAddLessonModal}
        showSave={false}
        showCancel={false}
        saveText={t('save', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        variant="lg"
        modalClassName="lesson-modal course-details-modal"
      >
        {showAddLessonModal && (
          <CreateLesson
            closeModal={closeLessonModal}
            createError={createError}
            handleSaveLesson={handleSaveLesson}
            uploadVideoProgress={uploadVideoProgress}
          />
        )}
      </ModalPage>
      <ModalPage
        title={t('editLesson', { ns: 'lessons' })}
        onSave={() => {
          setShowEditLessonModal(false);
        }}
        onCancel={handleCloseEditModal}
        show={showEditLessonModal}
        showSave={false}
        showCancel={false}
        saveText={t('save', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        variant="lg"
        modalClassName="lesson-modal course-details-modal"
      >
        {showEditLessonModal && (
          <EditLesson closeModal={handleFinishEditLesson}></EditLesson>
        )}
      </ModalPage>
      <ModalPage
        title={t('editCourse', { ns: 'courses' })}
        onSave={() => {}}
        onCancel={handleFinishEditCourse}
        show={showEditCourseModal}
        showSave={false}
        showCancel={false}
        saveText={t('save', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        variant="lg"
        contentClassName="modal-body-auto-height"
        backdrop="static"
        keyboard={false}
      >
        {showEditCourseModal && (
          <EditCourse closeModal={handleFinishEditCourse}></EditCourse>
        )}
      </ModalPage>
      {showSelectLessons && (
        <SelectLessons
          closeModal={closeModalSelectLessons}
          showModal={showSelectLessons}
          currentLessons={courseLessonNames!}
          onSave={handleSaveImportLessons}
        />
      )}
      {showAssessmentModal && (
        <ModalAssessment
          title={
            currentLesson?.lessonType === LessonQuiz
              ? LessonQuiz
              : LessonAssessment
          }
          variant="lg"
          onGoBack={handleAssessmentGoBack}
          onClose={handleCloseAssessmentModal}
          isEdit={isEdit}
          onBeforeSave={onBeforeSaveAssessments}
        />
      )}
      {showAssignLessonModal && (
        <AssignLesson
          onGoBack={handleAssignLessonGoBack}
          onClose={handleCloseAssignLessonModal}
        />
      )}
    </>
  );
};

const topPanelStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'right',
  justifyContent: 'end'
};

const buttonsContainerStyle = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  minHeight: '150px',
  paddingTop: '50px'
};

const addLessonModalStyle = { marginTop: '20px' };

const btnCreateLessonStyle = { marginLeft: '15px' };

const divWidth = { width: '30px' };

const divButtonStyle = { width: '30px' };

export default CourseDetails;
