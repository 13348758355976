import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionCreators } from '../../../actions/userProfile';
import { EducationDto } from '../../../entities/UserProfile';

import { UserProfileDetailParams } from '../../../entities/Common';
import FallbackLoader from '../../../components/FallbackLoader/FallbackLoader';
import { ApplicationState } from '../../../store';
import { SimpleFormPage } from '../../../components/Pages';
import { FormDualButtons } from '../FormDualButtons';
import { EducationForm } from './EducationForm';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { EducationValidationSchema } from './EducationValidationSchema';

export const EducationSectionCreate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, formState } = useForm<EducationDto>({
    resolver: yupResolver(EducationValidationSchema)
  });
  const { userProfileId } = useParams<UserProfileDetailParams>();

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const onSubmit: SubmitHandler<EducationDto> = data => {
    dispatch(actionCreators.createEducation(data, userProfileId, history));
  };

  const goToProfile = () => {
    history.push(`/user/profile/${userProfileId}`);
  };

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <SimpleFormPage title={'Complete your Profile'}>
      <EducationForm
        title={'Education'}
        submitHandler={onSubmit}
        formState={formState}
        handleSubmit={handleSubmit}
        control={control}
        bottomActions={
          <FormDualButtons
            cancelOnClick={goToProfile}
            submitButtonText={'Save Changes'}
          />
        }
      />
    </SimpleFormPage>
  );
};
