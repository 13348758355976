import React, { useState, useEffect, CSSProperties } from 'react';
import { SxProps } from '@mui/system';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { actionCreators as employeeActions } from '../../actions/employees';
import {
  Employee,
  EmployeeGroup,
  GroupWorkloadModel,
  GroupStartTimeModel
} from '../../entities/Employee';
import { ApplicationState } from '../../store';
import { DropdownItem } from '../../entities/Common';
import {
  appPermissions,
  DefaultStartTimeShiftInMinutesUtc
} from '../../core/constants';
import { TransactionStatusEnum } from '../../core/enums';
import ModalComponent from '../../components/core/ModalPage/ModalComponent';
import GroupWorkload from './GroupWorkload';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { buttonStyle } from '../../utils/buttonStyles';
import EmployeeTable from './EmployeeTable';
import GroupStartTime from './GroupStartTime';

export interface EmployeeTabProps {}

const EmployeeTab: React.FC<EmployeeTabProps> = () => {
  const dispatch = useDispatch();
  let searchTimeout: any = null;

  const { t } = useTranslation(['common', 'employees']);
  const [dataSource, setDataSource] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<DropdownItem[]>([]);
  const [employeeGroups, setEmployeeGroups] = useState<EmployeeGroup[]>([]);
  const [showAssignGroupModal, setShowAssignGroupModal] =
    useState<boolean>(false);
  const [showGroupStartTimeModal, setShowGroupStartTimeModal] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [groupWorkload, setGroupWorkload] = useState<GroupWorkloadModel>({
    groupId: '',
    applyToAll: false,
    lessonsPerDay: 2
  });
  const [groupStartTime, setGroupStartTime] = useState<GroupStartTimeModel>({
    groupId: '',
    applyToAll: false,
    startTimeMinutes: DefaultStartTimeShiftInMinutesUtc
  });
  const employeesLearningProfile = useSelector(
    (state: ApplicationState) => state?.employees?.employeesLearningProfile
  );
  const transactionStatus = useSelector(
    (state: ApplicationState) => state?.employees?.transactionStatus
  );
  const defaultGroupfilterOption: DropdownItem = {
    label: t('filterGroup', { ns: 'common' }),
    value: ''
  };

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, []);

  useEffect(() => {
    if (employeesLearningProfile) {
      setDataSource(employeesLearningProfile);
    }
  }, [employeesLearningProfile]);

  useEffect(() => {
    if (employeesLearningProfile && employeesLearningProfile.length > 0) {
      const groups: EmployeeGroup[] = [];
      employeesLearningProfile.forEach(employee => {
        employee.groups.forEach(group => {
          if (groups.findIndex(g => g.id === group.id) < 0) {
            groups.push(group);
          }
        });
      });

      setEmployeeGroups(groups);
      const items: DropdownItem[] = groups.map(group => {
        return { label: group.name, value: group.id };
      });
      items.unshift(defaultGroupfilterOption);
      setGroups(items);
    }
  }, [employeesLearningProfile]);

  useEffect(() => {
    if (transactionStatus === TransactionStatusEnum.Successfull) {
      if (showAssignGroupModal) setShowAssignGroupModal(false);
      if (showGroupStartTimeModal) setShowGroupStartTimeModal(false);
    } else if (transactionStatus === TransactionStatusEnum.Failed) {
      setErrorMessage(t('anExceptionOccurred', { ns: 'common' }));
    }

    setSaveInProgress(false);
    return () => {
      dispatch(employeeActions.resetTransactionStatus());
    };
  }, [transactionStatus]);

  const handleRefresh = () => {
    setIsLoading(true);
    loadData();
  };

  const loadData = async () => {
    await dispatch(employeeActions.requestEmployeesLearningProfile());
    setIsLoading(false);
  };

  const handleChangeStartTime = (employeeId: string, minutes: string) => {
    const utcMinutes = getUTCMinutes(Number(minutes));
    dispatch(employeeActions.setEmployeeStartTime(employeeId, utcMinutes));
  };

  const getUTCMinutes = (minutes: number) => {
    const localDateTime = moment().startOf('day').add(minutes, 'm');
    const utcDateTime = moment.utc(localDateTime);
    return utcDateTime.hours() * 60 + utcDateTime.minutes();
  };

  const handleSearch = (searchFilter: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      const filtered = employeesLearningProfile?.filter(
        (employee: Employee) =>
          `${employee.firstName} ${employee.lastName}`
            .toLowerCase()
            .includes(searchFilter) ||
          (employee.email ?? '').toLowerCase().includes(searchFilter) ||
          (employee.phoneNumber ?? '').toLowerCase().includes(searchFilter) ||
          employee.groups?.some((group: EmployeeGroup) =>
            group.name.toLowerCase().includes(searchFilter)
          )
      );

      setDataSource(filtered ?? []);
    }, 1000);
  };

  const handleCloseWorkloadModal = () => {
    setShowAssignGroupModal(false);
    setErrorMessage('');
  };

  const handleEditEmployeeWorkload = (
    employeeId: string,
    lessonsPerDay: number
  ) => {
    dispatch(employeeActions.setEmployeeWorkload(employeeId, lessonsPerDay));
  };

  const handleSaveGroupWorkload = () => {
    const { groupId, applyToAll, lessonsPerDay } = groupWorkload;

    if (!groupId.length && !applyToAll) {
      setErrorMessage(t('mustSelectAGroup', { ns: 'common' }));
      return;
    }

    let employeesGroup: Employee[] = employeesLearningProfile ?? [];
    if (!applyToAll) {
      employeesGroup =
        employeesLearningProfile?.filter(employee =>
          employee.groups.some(group => group.id === groupId)
        ) || [];
    }

    const employeeIds = employeesGroup?.map(item => item.id) || [];
    dispatch(employeeActions.setGroupWorkload(employeeIds, lessonsPerDay));
    setSaveInProgress(true);
    setErrorMessage('');
  };

  const handleCloseStartTimeModal = () => {
    setShowGroupStartTimeModal(false);
    setErrorMessage('');
  };

  const handleSaveGroupStartTime = () => {
    const { groupId, applyToAll, startTimeMinutes } = groupStartTime;

    if (!groupId.length && !applyToAll) {
      setErrorMessage(t('mustSelectAGroup', { ns: 'common' }));
      return;
    }

    let employeesGroup: Employee[] = employeesLearningProfile ?? [];
    if (!applyToAll) {
      employeesGroup =
        employeesLearningProfile?.filter(employee =>
          employee.groups.some(group => group.id === groupId)
        ) || [];
    }

    const employeeIds = employeesGroup?.map(item => item.id) || [];
    dispatch(
      employeeActions.setGroupStartTime(
        employeeIds,
        getUTCMinutes(startTimeMinutes)
      )
    );
    setSaveInProgress(true);
    setErrorMessage('');
  };

  return (
    <>
      <Grid
        container
        sx={containerStyle}
        columns={{ xs: 1, md: 3, lg: 12, xl: 12 }}
      >
        <Grid item xs={1} md={1} lg={3} xl={3} sx={gridItemStyle}>
          <SearchBox
            id="searchLesson"
            name="searchLesson"
            value=""
            onChangeValue={value => handleSearch(value)}
            placeholderText={t('search', { ns: 'common' })}
            width="100%"
            height="45px"
          />
        </Grid>
        <Grid item xs={1} md={1} lg={3} xl={2} sx={gridItemStyle}>
          <BasicButton
            onClick={() => setShowAssignGroupModal(true)}
            color="secondary"
            style={customButtonStyle}
          >
            {t('manageGroupWorkload', { ns: 'employees' })}
          </BasicButton>
        </Grid>
        <Grid item xs={1} md={1} lg={3} xl={2} sx={gridItemStyle}>
          <BasicButton
            onClick={() => setShowGroupStartTimeModal(true)}
            color="secondary"
            style={customButtonStyle}
          >
            {t('manageStartTime', { ns: 'employees' })}
          </BasicButton>
        </Grid>
        <Grid item xs={1} md={1} lg={3} xl={5} sx={gridButtonStyle}>
          <ProtectedComponent action={appPermissions.CREATE_GROUP}>
            <BasicButton
              color="secondary"
              style={buttonStyle}
              onClick={() => handleRefresh()}
            >
              <Typography sx={fontButton}>
                {t('refreshData', { ns: 'common' })}
              </Typography>
            </BasicButton>
          </ProtectedComponent>
        </Grid>
      </Grid>
      <Grid item>
        <EmployeeTable
          employeesLearningProfile={dataSource}
          employeeGroups={groups}
          isLoading={isLoading}
          onChangeEmployeeWorkload={handleEditEmployeeWorkload}
          onChangeStartTime={handleChangeStartTime}
        />
      </Grid>
      {showAssignGroupModal && (
        <ModalComponent
          showModal={showAssignGroupModal}
          title={t('groupWorkload', { ns: 'employees' })}
          savingInProgress={saveInProgress}
          maxWidth="sm"
          saveButtonText={t('save', { ns: 'common' })}
          onClose={() => handleCloseWorkloadModal()}
          onSave={() => handleSaveGroupWorkload()}
        >
          <GroupWorkload
            employeeGroups={employeeGroups}
            groupWorkLoad={groupWorkload}
            errorMessage={errorMessage}
            setGroupWorkload={setGroupWorkload}
          />
        </ModalComponent>
      )}
      {showGroupStartTimeModal && (
        <ModalComponent
          showModal={showGroupStartTimeModal}
          title={t('manageStartTime', { ns: 'employees' })}
          savingInProgress={saveInProgress}
          maxWidth="sm"
          saveButtonText={t('save', { ns: 'common' })}
          onClose={() => handleCloseStartTimeModal()}
          onSave={() => handleSaveGroupStartTime()}
        >
          <GroupStartTime
            employeeGroups={employeeGroups}
            groupStartTime={groupStartTime}
            errorMessage={errorMessage}
            setGroupStartTime={setGroupStartTime}
          />
        </ModalComponent>
      )}
    </>
  );
};

const containerStyle: SxProps = {
  paddingBottom: '0.5rem'
};

const gridItemStyle: SxProps = {
  paddingBottom: {
    xs: '0.5rem'
  },
  paddingRight: {
    md: '0.5rem'
  }
};

const customButtonStyle: CSSProperties = {
  width: '100%',
  height: '45px'
};

const gridButtonStyle: SxProps = {
  textAlign: {
    xs: 'start',
    lg: 'end'
  }
};

const fontButton: CSSProperties = {
  fontSize: '15px',
  color: '#0E0D0D'
};

export default EmployeeTab;
