import React, { useState, useEffect, useMemo } from 'react';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress
} from '@mui/material';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import TextBox from '../../components/core/TextBox/TextBox';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerInput from '../../components/core/DatePickerInput/DatePickerInput';
import {
  actionCreators as addressesActions,
  selectCityItems,
  selectCountryItems,
  selectStateItems
} from '../../actions/address';
import { ApplicationState } from '../../store';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { CreatePostJobDto } from '../../entities/Dto/createJobPostDto';
import NewBasicSelect from '../../components/core/BasicSelect/NewBasicSelect';
import {
  JobsTypeOptions,
  payRateOptions,
  applicantsOptions,
  JobLocationOptions
} from '../../core/constants';
import { DropdownItemNumber } from '../../entities/Common';
import RichTextInput from '../../components/RichTextInput/RichTextInput';

interface JobPostFormProps
  extends Pick<
    UseFormReturn<CreatePostJobDto>,
    'control' | 'watch' | 'handleSubmit' | 'formState' | 'setValue'
  > {
  submitHandler: SubmitHandler<CreatePostJobDto>;
  bottomActions: React.ReactNode;
  editMode: boolean;
}

const JobPostForm = ({
  control,
  watch,
  handleSubmit,
  submitHandler,
  setValue,
  formState,
  bottomActions,
  editMode
}: JobPostFormProps) => {
  const [jobLocationOptions] =
    useState<DropdownItemNumber[]>(JobLocationOptions);
  const dispatch = useDispatch();
  const watchCountry = watch('postingAddress.country');
  const watchState = watch('postingAddress.state');

  const jobsCategories = useSelector(
    (state: ApplicationState) => state.jobs?.jobsCategories
  );

  const jobsTypes = useSelector(
    (state: ApplicationState) => state.jobs?.jobTypes
  );

  const isLoadingCountries = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCountries
  );
  const isLoadingStates = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingStates
  );
  const isLoadingCities = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCities
  );

  const countryItems = useSelector(selectCountryItems);

  const stateItems = useSelector(selectStateItems);

  const cityItems = useSelector(selectCityItems);

  const [applyMethod, setApplyMethod] = useState('');

  const handleChangeApplicantOptions = (values: string[]) => {
    const type = values[0] as string;
    setApplyMethod(type);
  };

  const jobCategoriesOptions = useMemo(() => {
    if (!!jobsCategories && !!jobsCategories.length) {
      return jobsCategories.map(jobCategory => ({
        label: jobCategory.name,
        value: jobCategory.name
      }));
    }
    return [];
  }, [jobsCategories]);

  const jobTypesOptions = useMemo(() => {
    if (!!jobsTypes && !!jobsTypes.length) {
      return jobsTypes.map(jobsType => ({
        label: jobsType.name,
        value: jobsType.key
      }));
    }
    return [];
  }, [jobsCategories]);


  useEffect(() => {
    if (watchCountry)
      dispatch(addressesActions.getStatesFromCountries(watchCountry));
  }, [watchCountry]);

  useEffect(() => {
    if (watchState && watchCountry)
      dispatch(addressesActions.getCitiesFromStates(watchCountry, watchState));
  }, [watchState]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.jobTitle}>
            <FormLabel>
              Job Title
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="jobTitle"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value ?? ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.jobTitle?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.jobCategory}>
            <FormLabel>
              Job Category
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="jobCategory"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  onChange={onChange}
                  value={value ?? ''}
                  displayEmpty
                  defaultValue={''}
                  labelId="jobCategory"
                >
                  <MenuItem disabled value={''}>
                    - select -
                  </MenuItem>
                  {jobCategoriesOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.jobCategory?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.jobType}>
            <FormLabel>
              Job Type
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="jobType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  displayEmpty
                  defaultValue={-1}
                  labelId="jobType"
                >
                  <MenuItem disabled value={-1}>
                    - select -
                  </MenuItem>
                  {jobTypesOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{formState.errors.jobType?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.compensation}>
            <FormLabel>
              Pay Rate
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="compensation"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value ?? ''}
                  displayEmpty
                  defaultValue={''}
                  labelId="compensation"
                >
                  <MenuItem disabled value={''}>
                    - select -
                  </MenuItem>
                  {payRateOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.compensation?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.jobLocationType}>
            <FormLabel>
              Job Location
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="jobLocationType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value ?? ''}
                  displayEmpty
                  defaultValue={''}
                >
                  <MenuItem disabled value={''}>
                    - select -
                  </MenuItem>
                  {jobLocationOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.jobLocationType?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl
            fullWidth
            error={!!formState.errors.postingAddress?.country}
          >
            <FormLabel>
              Country
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="postingAddress.country"
              control={control}
              defaultValue=""
              render={({ field: { value, onChange: formOnChange } }) => (
                <>
                  <Autocomplete
                    disablePortal
                    options={countryItems ?? []}
                    loading={isLoadingCountries}
                    value={value}
                    onChange={(_, newValue) => {
                      setValue('postingAddress.state', '');
                      setValue('postingAddress.city', '');
                      formOnChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoadingCountries && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </>
              )}
            />
            <FormHelperText>
              {formState.errors.postingAddress?.country?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl
            fullWidth
            error={!!formState.errors.postingAddress?.state}
          >
            <FormLabel>
              State
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="postingAddress.state"
              control={control}
              defaultValue=""
              render={({ field: { value, onChange: formOnChange } }) => (
                <>
                  <Autocomplete
                    disablePortal
                    options={stateItems ?? []}
                    loading={isLoadingStates}
                    value={value}
                    onChange={(_, newValue) => {
                      setValue('postingAddress.city', '');
                      formOnChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoadingStates && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </>
              )}
            />
            <FormHelperText>
              {formState.errors.postingAddress?.state?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormLabel>City</FormLabel>
          <Controller
            name="postingAddress.city"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <>
                <Autocomplete
                  disablePortal
                  options={cityItems ?? []}
                  loading={isLoadingCities}
                  value={value}
                  onChange={(_, newValue) => {
                    formOnChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingCities && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            error={!!formState.errors.postingAddress?.postalCode}
          >
            <FormLabel>
              ZIP Code
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="postingAddress.postalCode"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value ?? ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.postingAddress?.postalCode?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.description}>
            <FormLabel>
              Job Description
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <RichTextInput
                  id={name}
                  name={name}
                  value={value ?? ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.description?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.expiresAtUtc}>
            <FormLabel>
              Length of Job Post
              <span className="text-danger">*</span>
            </FormLabel>
            <Controller
              name="expiresAtUtc"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <DatePickerInput
                  disablePastDays={true}
                  onChange={onChange}
                  value={value ?? ''}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.expiresAtUtc?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        {editMode ? (
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <FormLabel>
                Apply Method Registered
                <Typography variant="redCaption">*</Typography>
              </FormLabel>
              <Controller
                name="howToApply"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextBox
                    id={name}
                    name={name}
                    value={value ?? ''}
                    onChangeValue={onChange}
                  />
                )}
              />
            </FormControl>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel>
                  How do you want applicants to apply?
                  <Typography variant="redCaption">*</Typography>
                </FormLabel>
                <NewBasicSelect
                  labelId="applyMethod"
                  id="applyMethod"
                  options={applicantsOptions}
                  defaultValue=""
                  value={[applyMethod]}
                  handleOnChange={handleChangeApplicantOptions}
                  multiple={false}
                  sx={{ backgroundColor: 'transparent' }}
                />
              </FormControl>
            </Grid>

            {applyMethod === 'email' && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Email
                    <Typography variant="redCaption">*</Typography>
                  </FormLabel>
                  <Controller
                    name="howToApply"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <TextBox
                        id={name}
                        name={name}
                        value={value ?? ''}
                        onChangeValue={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}

            {applyMethod === 'website' && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel>
                    Website
                    <Typography variant="redCaption">*</Typography>
                  </FormLabel>
                  <Controller
                    name="howToApply"
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <TextBox
                        id={name}
                        name={name}
                        value={value ?? ''}
                        onChangeValue={onChange}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>
              Contact Email Address
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="contactEmailAddress"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value ?? ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              Don't worry, this will not be displayed. We will use this to send
              you a confirmation email for your job posting, as well as to send
              you notifications when your job posting is set to expire.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {bottomActions}
        </Grid>
      </Grid>
    </form>
  );
};

export default JobPostForm;
