import { AppThunkAction } from '../store';
import {
  COMPANY_SET_COMPANIES,
  COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE,
  COMPANIES_GET_ADMINS,
  COMPANY_REMOVE_ADMIN,
  COMPANIES_SET_ADMIN,
  COMPANY_GET_EMPLOYEE_BY_QUERY,
  COMPANIES_IS_LOADING
} from './actionTypes';
import axios from 'axios';
import config from '../config';
import { HttpStatusEnum } from '../core/enums';
import { ICompany } from '../entities/Company';
import { CompanyProfileDTO } from '../entities/Dto/createCompanyDto';
import { uploadCompanyLogoToBlob } from '../services/blobStorage-service';
import { History } from 'history';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { UserProfileDto } from '../entities/UserProfile';
import { enqueueSnackbar } from 'notistack';
export interface RequestCompaniesAction {
  type: 'COMPANY_SET_COMPANIES';
  companies: ICompany[];
}

export interface SetCompanyIsLoadingAction {
  type: 'COMPANY_IS_LOADING';
  isLoading: boolean;
}

export interface SetCompaniesIsLoadingAction {
  type: 'COMPANIES_IS_LOADING';
  isLoading: boolean;
}

export interface RequestCompanyAction {
  type: 'COMPANY_SET_COMPANY';
  company: ICompany;
}

export interface SetCompanyProfileAction {
  type: 'COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE';
  companyProfile: CompanyProfileDTO;
}

export interface SetAdminsByCompaniesAction {
  type: 'COMPANIES_GET_ADMINS';
  admins: UserProfileDto[];
}

export interface SetAdminToCompanyAction {
  type: 'COMPANIES_SET_ADMIN';
  admin: UserProfileDto;
}

export interface RemoveAdminAction {
  type: 'COMPANY_REMOVE_ADMIN';
  adminId: string;
}

export interface GetEmployeeByQuery {
  type: 'COMPANY_GET_EMPLOYEE_BY_QUERY';
  employees: UserProfileDto[];
}

export const actionCreators = {
  setCompaniesIsLoading: (isLoading: boolean) =>
    ({
      type: COMPANIES_IS_LOADING,
      isLoading: isLoading
    } as SetCompaniesIsLoadingAction),

  getCompanies:
    (filter?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        dispatch(actionCreators.setCompaniesIsLoading(true));

        let companies: ICompany[] = [];
        try {
          const res = await axios.get(`${config.USERPROFILE_API_URL!}company?${filter}`);

          if (res.status === HttpStatusEnum.OK) companies = res.data;
        } catch (e) {
        } finally {
          dispatch({
            type: COMPANY_SET_COMPANIES,
            companies
          });

          dispatch(appActions.setIsLoading(false));
          dispatch(actionCreators.setCompaniesIsLoading(false));
        }
      }
    },
  getCompanyById:
    (companyProfileId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}company/${companyProfileId}`
          );
          dispatch({
            type: COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE,
            companyProfile: { ...res.data }
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  createCompany:
    (companyName: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          await axios.post(`${config.USERPROFILE_API_URL!}company`, {
            name: companyName
          });
        } catch (e) {
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  createDWCompany:
    (
      companyProfileData: CompanyProfileDTO,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        let companyCreated: CompanyProfileDTO;
        try {
          if (companyProfileData.logoFile) {
            const thumbUrl = await uploadCompanyLogoToBlob(
              companyProfileData.logoFile
            );
            companyProfileData.logoUrl = thumbUrl;
          }
          var { logoFile, isCompanyVerify, ...companyProfileDataRequest } =
            companyProfileData;
          const res = await axios.post(
            `${config.USERPROFILE_API_URL!}company`,
            companyProfileDataRequest
          );

          companyCreated =
            res.status === HttpStatusEnum.Created ? res.data : null;
          dispatch({
            type: COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE,
            companyProfile: companyCreated
          });
          history.push(`/company/profile/${companyCreated.id}`);
          const message = 'Company Profile was created';
          enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
          const message = 'Error while creating company profile';
          enqueueSnackbar(message, { variant: 'error' });
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  updateDWCompany:
    (
      companyProfileId: string,
      companyProfileData: CompanyProfileDTO,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          if (companyProfileData.logoFile) {
            const thumbUrl = await uploadCompanyLogoToBlob(
              companyProfileData.logoFile
            );
            companyProfileData.logoUrl = thumbUrl;
          }
          var { logoFile, isCompanyVerify, ...companyProfileDataRequest } =
            companyProfileData;
          await axios.put(
            `${config.USERPROFILE_API_URL!}company/${companyProfileId}`,
            companyProfileDataRequest
          );

          history.push(`/company/profile/${companyProfileId}`);
          const message = 'Company profile was updated';
          enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
          const message = 'Error while updating company profile';
          enqueueSnackbar(message, { variant: 'error' });
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setCompanyProfile:
    (companyProfile: CompanyProfileDTO): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({
        type: COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE,
        companyProfile: companyProfile
      });
    },
  getAdminsByCompanies:
    (companyProfileId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}company/${companyProfileId}/admin`
          );
          dispatch({
            type: COMPANIES_GET_ADMINS,
            admins: res.data
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setAdminToCompany:
    (companyProfileId: string, userId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.post(
            `${config.USERPROFILE_API_URL!}company/${companyProfileId}/admin`,
            userId,
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json' // set the content type to JSON
              }
            }
          );

          dispatch({
            type: COMPANIES_SET_ADMIN,
            admin: res.data
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  removeAdmin:
    (companyProfileId: string, userId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.delete(
            `${config.USERPROFILE_API_URL!}company/${companyProfileId}/admin`,
            {
              data: userId,
              headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json' // set the content type to JSON
              }
            }
          );
          dispatch({
            type: COMPANY_REMOVE_ADMIN,
            adminId: userId
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  getEmployeeByQuery:
    (query: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}userProfile/search/${query}`
          );
          dispatch({
            type: COMPANY_GET_EMPLOYEE_BY_QUERY,
            employees: res.data
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    }
};

export type KnownAction =
  | SetLoadingAction
  | RequestCompaniesAction
  | SetCompanyIsLoadingAction
  | RequestCompanyAction
  | SetCompanyProfileAction
  | SetAdminsByCompaniesAction
  | SetAdminToCompanyAction
  | RemoveAdminAction
  | GetEmployeeByQuery
  | SetCompaniesIsLoadingAction;
