import React, { CSSProperties, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressLine from '../../components/ProgressLine/ProgressLine';
import { useSelector, useDispatch } from 'react-redux';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BackwardIcon from '../../assets/backward.svg';
import { ApplicationState } from '../../store';
import Video from '../../assets/Video.png';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import CourseCard from '../../components/CourseCard/CourseCard';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { actionCreators as studentActions } from '../../actions/student';
import ProgressIcon from '../../components/core/ProgressIcon/ProgressIcon';
import { useTranslation } from 'react-i18next';

export interface LearningPlanPreviewProps {}

const LearningPlanPreview: React.FC<LearningPlanPreviewProps> = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const params = useParams<{ id: string; assignmentId: string }>();
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentLearningPlan = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlan
  );
  const learningPlanCourses = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlanCourses
  );
  const completedCourses = useSelector(
    (state: ApplicationState) => state.student?.completedCourses
  );
  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );
  const appIsLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const [dueDate, setDueDate] = useState<string>();
  const { t } = useTranslation(['common', 'lessons', 'courses', 'assignments']);

  useEffect(() => {
    if (currentLearningPlan && currentLearningPlan.entries) {
      if (location.state) {
        dispatch(
          learningPlanActions.getCoursesByLearningPlanAssignment(
            location.state.assignmentId
          )
        );
      } else {
        const entryCourses = currentLearningPlan.entries.filter(
          entry => !!entry.courseId.length
        );
        const entries = entryCourses.map(entry => entry.courseId);
        const courseIds = entries.join(',');

        dispatch(learningPlanActions.getLearningPlanCourses(courseIds));
      }
      dispatch(
        studentActions.getCompletedCoursesByLearningPlanId(
          currentLearningPlan.id,
          currentLearningPlan.revisionNumber
        )
      );
    }
  }, [currentLearningPlan]);

  useEffect(() => {
    if (!currentLearningPlan && params && params.id) {
      dispatch(learningPlanActions.requestPlan(params.id));
    }

    if (location.state) {
      setDueDate(location.state.dueDate);
    }

    return () => {
      dispatch(studentActions.setCompletedCourses([]));
    };
  }, []);

  const backToPreviousPage = () => {
    history.goBack();
  };

  const handleStartCourse = (courseId: string, revisionnumber: number) => {
    history.push(`/courses/${courseId}/revision/${revisionnumber}`);
  };

  const coursesCountStyle: CSSProperties = {
    fontSize: '11px',
    paddingLeft: '0.3rem',
    color: theme.palette.grey[500]
  };

  const descriptionStyle: CSSProperties = {
    color: theme.palette.grey[500],
    paddingBottom: '2rem'
  };

  const dueDateStyle: CSSProperties = {
    color: theme.palette.primary.main,
    paddingLeft: '0.3rem'
  };

  const actionButtonsContainerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: dueDate ? 'space-between' : 'flex-end',
    alignItems: 'center'
  };

  const containerStyle: CSSProperties = {
    color: '#999',
    backgroundColor: '#000',
    backgroundImage: `linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%), linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5) 2%, rgba(0, 0, 0, 0)), linear-gradient(-90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5) 2%, rgba(0, 0, 0, 0)), url('${currentLearningPlan?.thumbnailUrl}')`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'luminosity',
    backgroundSize: 'contain'
  };

  return (
    <PageContainer style={containerStyle}>
      <div className="d-flex flex-row">
        <div className="pt-2">
          <i
            className="fas fa-sm fa-fw mr-2 text-gray-400"
            style={BackwardIconStyle}
            onClick={backToPreviousPage}
          />
        </div>
        <div className="ps-4 w-100">
          <Grid container>
            <Grid item xs={12} lg={8} className="pb-2">
              <h3 className="mb-0">{currentLearningPlan?.name}</h3>
            </Grid>
            <Grid item xs={12} lg={8} className="pb-4">
              <img src={Video} />
              <span style={coursesCountStyle}>
                {` ${currentLearningPlan?.courseCount ?? ''} ${t('courses', {
                  ns: 'courses'
                })}`}
              </span>
            </Grid>
            <Grid item xs={12} lg={8} sx={descriptionStyle}>
              {currentLearningPlan?.description}
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '0.5rem',
                color: theme.palette.grey[500]
              }}
            >
              <span>{t('progress', { ns: 'common' })}</span>
              <span>{`${!studentIsLoading ? completedCourses?.length : '?'} / ${
                learningPlanCourses?.length ?? '0'
              } ${t('lessons', { ns: 'lessons' })}`}</span>
            </Grid>
            <Grid item xs={12} lg={8}>
              {(appIsLoading || studentIsLoading) && <LinearProgress />}
              {!appIsLoading &&
                !studentIsLoading &&
                learningPlanCourses &&
                completedCourses && (
                  <ProgressLine
                    color={'#44d47a'}
                    value={
                      (completedCourses.length / learningPlanCourses.length) *
                      100
                    }
                    width="100%"
                  ></ProgressLine>
                )}
            </Grid>
            <Grid item xs={12} className="pt-5 pb-2">
              <h4>{t('courses', { ns: 'courses' })}</h4>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={4}
                columns={{ xs: 1, md: 12, lg: 12 }}
                sx={coursesContainerStyle}
              >
                {learningPlanCourses && !appIsLoading && !studentIsLoading ? (
                  learningPlanCourses.map(course => {
                    const { id, companyId, revisionNumber } = course;
                    const isComplete = !!completedCourses?.find(
                      cc => cc.courseId === id
                    );

                    return (
                      <Grid item xs={1} md={6} lg={4} key={id}>
                        <CourseCard
                          course={course}
                          showEdit={false}
                          isComplete={isComplete}
                          topTitle={
                            companyId
                              ? t('course', { ns: 'courses' }).toUpperCase()
                              : t('bWCourse', { ns: 'courses' }).toUpperCase()
                          }
                          width="100%"
                          height="295.5px"
                        >
                          <div style={actionButtonsContainerStyle}>
                            {dueDate && (
                              <span>
                                {t('dueDate', { ns: 'assignments' })}:
                                <span style={dueDateStyle}>{dueDate}</span>
                              </span>
                            )}
                            <BasicButton
                              onClick={() =>
                                handleStartCourse(id, revisionNumber)
                              }
                              color={isComplete ? 'revisit' : 'primary'}
                              style={startButtonStyle}
                            >
                              {isComplete
                                ? t('revisit', { ns: 'common' })
                                : t('start', { ns: 'common' })}
                            </BasicButton>
                          </div>
                        </CourseCard>
                      </Grid>
                    );
                  })
                ) : (
                  <ProgressIcon />
                )}
                {!appIsLoading &&
                  !studentIsLoading &&
                  learningPlanCourses &&
                  !learningPlanCourses.length && (
                    <Grid item>
                      <span>{t('noCourses', { ns: 'courses' })}</span>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </PageContainer>
  );
};

const BackwardIconStyle: CSSProperties = {
  display: 'inline-block',
  width: 10,
  height: 18,
  cursor: 'pointer',
  backgroundImage: `url(${BackwardIcon})`
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

const coursesContainerStyle: SxProps = {
  paddingTop: '1rem'
};

export default LearningPlanPreview;
