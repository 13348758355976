import React, { CSSProperties } from 'react';
export interface PageContainerProps {
  style?: CSSProperties;
}
const PageContainer: React.FC<PageContainerProps> = ({
  children,
  style = {}
}) => {
  const finalStyle = { ...pageContainerStyle, ...style };
  return (
    <div className="page-container" style={finalStyle}>
      {children}
    </div>
  );
};
const pageContainerStyle = {
  height: '100%',
  padding: '45px',
  backgroundColor: '#000',
  minWidth: '360px'
};
export default PageContainer;
