import React, {CSSProperties} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLink } from '@fortawesome/free-solid-svg-icons';
export interface FileInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialText: string;
  id: string;
  name: string;
  type: string;
}

const FileInput: React.FC<FileInputProps> = ({
  onChange,
  id,
  name,
  initialText,
  type
}) => {
  const [text, setText] = React.useState<string>(initialText);
  const handleFileChange = (e: any) => {
    let parts = e.target.value.split('\\');
    const fileName = parts[parts.length - 1];
    const cutFileName = fileName.length > 30 ? fileName.substring(0, 31) + '...' : fileName;
    setText(cutFileName);
    onChange(e);
  };
  const validTypes =
    type === 'video'
      ? 'video/*'
      : type === 'pdf'
      ? '.pdf'
      : type === 'vtt'
      ? '.vtt'
      : 'image/png, image/jpeg, image/svg+xml, image/png';
  return (
    <div className="custom-file mb-1">
      <input
        type="file"
        accept={validTypes}
        className="custom-file-input"
        id={id}
        name={name}
        style={inputStyle}
        onChange={handleFileChange}
        required
      />
      <label className="custom-file-label" htmlFor="customFile">
        <FontAwesomeIcon icon={faLink} color="black" /> {text}
      </label>
    </div>
  );
};

const inputStyle: CSSProperties = {
  cursor: 'pointer'
};

export default FileInput;
