import axios from 'axios';
import config from '../config';
import { HttpStatusEnum } from '../core/enums';
import { IndustryCategory } from '../entities/IndustryCategory';
import { AppThunkAction } from '../store';
import { APP_SET_INDUSTRY_CATEGORIES } from './actionTypes';

export const APP_STATE_LOADING = 'APP_STATE_LOADING';

export interface SetLoadingAction {
  type: 'APP_STATE_LOADING';
  isLoading: boolean;
}

export interface SetIndustryCategoriesAction {
  type: 'APP_SET_INDUSTRY_CATEGORIES';
  industryCategories: IndustryCategory[];
}

export const actionCreators = {
  setIsLoading: (isLoading: boolean) =>
    ({ type: APP_STATE_LOADING, isLoading: isLoading } as SetLoadingAction),

  getIndustryCategories:
    (companyProfileId?: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        let industryCategories: IndustryCategory[] = [];
        try {
          dispatch(actionCreators.setIsLoading(true));
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}company/industries`,
            {
              params: { companyProfileId }
            }
          );
          if (res.status === HttpStatusEnum.OK) industryCategories = res.data;
          dispatch({
            type: APP_SET_INDUSTRY_CATEGORIES,
            industryCategories: industryCategories
          });
        } catch (e) {
        } finally {
          dispatch(actionCreators.setIsLoading(false));
        }
      }
    }
};
export type KnownAction = SetLoadingAction | SetIndustryCategoriesAction;
