import { Button, Grid, Stack } from '@mui/material';

import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { WorkExperienceSectionDetailsItem } from './WorkExperienceSectionDetailsItem';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useHistory } from 'react-router';
import { FormTitle, SimpleFormPage } from '../../../components/Pages';
import React from 'react';

export const WorkExperienceSectionDetails = () => {
  const history = useHistory();
  const currentUserProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );
  const createItem = () => {
    history.push(
      `/user/profile/${currentUserProfile?.id}/work-experience/create`
    );
  };

  return (
    <SimpleFormPage title={'Complete your profile'}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormTitle title={'Work Experience'} />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={4}>
            {currentUserProfile?.workExperiences?.map(workExperience => (
              <WorkExperienceSectionDetailsItem
                key={workExperience.id}
                workExperience={workExperience}
                userProfileId={currentUserProfile.id}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="text"
            startIcon={<AddOutlinedIcon />}
            onClick={createItem}
          >
            Add work experience
          </Button>
        </Grid>
      </Grid>
    </SimpleFormPage>
  );
};
