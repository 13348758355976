import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { DropdownItem } from '../../../entities/Common';
import { useTranslation } from 'react-i18next';

export interface BasicSelectProps extends SelectProps {
  options: DropdownItem[];
  value?: string[];
  handleOnChange: (items: string[]) => void;
  theme?: string;
}

const basicSelectTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
          '&.Mui-focused': {
            border: '2px solid none',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          },
          backgroundColor: 'white',
          height: '38px',
          fontFamily: 'inherit',
          fontSize: '14px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: '14px'
        }
      }
    }
  }
});

const darkSelectTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
          '&.Mui-focused': {
            border: '2px solid white',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'white'
            },
            '& .MuiSvgIcon-root': {
              color: 'white'
            }
          },
          backgroundColor: '#111012',
          border: '2px solid #3d3d3d',
          color: 'white',
          height: '38px',
          fontFamily: 'inherit',
          fontSize: '14px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '#3d3d3d'
          },
          '& .MuiSvgIcon-root': {
            color: '#878787'
          },
          '&.Mui-disabled': {
            color: '#999'
          }
        },
        input: {
          '&.Mui-disabled': {
            ['-webkit-text-fill-color']: '#999'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: '14px'
        }
      }
    }
  }
});

const BasicSelect: React.FC<BasicSelectProps> = ({
  labelId,
  id,
  options,
  defaultValue,
  handleOnChange,
  placeholder = 'Select',
  multiple = true,
  value,
  theme = '',
  ...props
}) => {
  const [values, setValues] = useState<string[]>(value ?? []);
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (value) setValues(value);
  }, [value]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    const selected = typeof value === 'string' ? value.split(',') : value;

    setValues(selected);
    handleOnChange(selected);
  };

  const getGroupNames = (groupIds: string[]) => {
    if (!groupIds.length)
      return placeholder === 'Select'
        ? t('select', { ns: 'common' })
        : placeholder;

    const selectedOptions = options.filter(option =>
      groupIds.includes(option.value)
    );
    return selectedOptions.map(option => option.label).join(', ');
  };

  let menuHeight = options.length * 33 + 16;
  menuHeight = menuHeight > 180 ? 180 : menuHeight;

  const getTheme = (theme: string) => {
    switch (theme) {
      case 'dark':
        return darkSelectTheme;
      default:
        return basicSelectTheme;
    }
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: menuHeight,
        width: 100
      }
    },
    MenuListProps: {
      style: {
        backgroundColor: 'white',
        fontFamily: 'inherit'
      }
    }
  };

  return (
    <div>
      <ThemeProvider theme={getTheme(theme)}>
        <Select
          {...props}
          labelId={labelId}
          id={id}
          multiple={multiple}
          displayEmpty
          value={values}
          onChange={handleChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          placeholder={
            placeholder === 'Select'
              ? t('select', { ns: 'common' })
              : placeholder
          }
          renderValue={selected => <span>{getGroupNames(selected)}</span>}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </div>
  );
};

export default BasicSelect;
