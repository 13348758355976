import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';

export interface LessonCardLoaderProps {
  variant: 'circular' | 'linear';
}

const LessonCardLoader: React.FC<LessonCardLoaderProps> = ({ variant }) => {
  const loaderStyle = {
    backgroundColor: '#000'
  };
  return variant == 'circular' ? (
    <CircularProgress style={loaderStyle} />
  ) : (
    <LinearProgress style={loaderStyle} />
  );
};
export default LessonCardLoader;
