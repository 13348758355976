import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../actions/employees';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  LearningPlan,
  LearningPlanAssignment
} from '../../entities/LearningPlan';
import { newLearningPlanAssignment } from '../../reducers/LearningPlansReducer';
import { LearningPlanAssignByOptions } from '../../core/constants';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { TransactionStatusEnum } from '../../core/enums';
import AssignModal from '../../components/AssignModal/AssignModal';
import { useTranslation } from 'react-i18next';

export interface AssignLearningPlanProps {
  show: boolean;
  learningPlan: LearningPlan;
  onClose: () => void;
}

const AssignLearningPlan: React.FC<AssignLearningPlanProps> = ({
  show,
  learningPlan,
  onClose
}) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [learningPlanAssignment, setLearningPlanAssignment] = useState<
    LearningPlanAssignment
  >({ ...newLearningPlanAssignment });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const transactionSatus = useSelector(
    (state: ApplicationState) => state.learningPlans?.transactionStatus
  );
  const { t } = useTranslation(['common']);

  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;

  useEffect(() => {
    dispatch(actionCreators.requestEmployeeGroups(companyId));
    dispatch(actionCreators.requestEmployees(companyId));
  }, []);

  useEffect(() => {
    if (learningPlan) {
      setLearningPlanAssignment({
        ...newLearningPlanAssignment,
        learningPlanId: learningPlan.id!,
        learningPlanCompanyId: learningPlan.companyId,
        assignBy: LearningPlanAssignByOptions.EmployeesWorkloadBudget
      });
    }
  }, [learningPlan]);

  useEffect(() => {
    if (transactionSatus === TransactionStatusEnum.Successfull) {
      alert(t('savedSucessfully', {ns: 'common'}));
      onClose();
    } else if (transactionSatus === TransactionStatusEnum.Failed) {
      alert(t('anExceptionOccurred', {ns: 'common'}));
      setSaveInProgress(false);
    }

    if (transactionSatus !== TransactionStatusEnum.None)
      dispatch(learningPlanActions.resetLearningPlanTransactionStatus());

    return () => {
      dispatch(learningPlanActions.resetLearningPlanTransactionStatus());
    };
  }, [transactionSatus]);

  const handleAssign = () => {
    let message: string = '';

    if (!learningPlanAssignment.employeeIds.length)
      message = t('selectEmployee', {ns: 'common'});
    else if (
      !learningPlanAssignment.assignBy ||
      (learningPlanAssignment.assignBy ===
        LearningPlanAssignByOptions.DueDate &&
        !learningPlanAssignment.dueDate)
    )
      message = t('requiredFieldsMustBeFilled', {ns: 'common'});

    if (message.length) {
      alert(message);
      setErrorMessage(message);
      return;
    }

    dispatch(
      learningPlanActions.assignLearningPlanToEmployees(learningPlanAssignment)
    );
    setSaveInProgress(true);
    setErrorMessage('');
  };

  return (
    <AssignModal
      showModal={show}
      assignToEntity={learningPlan}
      errorMessage={errorMessage}
      savingInProgress={saveInProgress}
      onAssign={handleAssign}
      onClose={onClose}
      employees={employees || []}
      employeeGroups={employeeGroups || []}
      assignment={learningPlanAssignment}
      setAssignment={setLearningPlanAssignment}
    />
  );
};

export default AssignLearningPlan;
