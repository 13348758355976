import React, { CSSProperties } from 'react';
import { Spinner } from 'react-bootstrap';
import { Typography } from '@mui/material';

const FallbackLoader = () => {
  return (
    <div style={divStyle}>
      <Spinner animation="border" style={{borderColor: '#CCFF00'}}/>
      <Typography style={spanStyle}>Loading elements...</Typography>
    </div>
  );
};

const divStyle: CSSProperties = {
  marginInline: 'auto',
  textAlign: 'center',
  marginTop: window.screen.height / 4
};

const spanStyle: CSSProperties = {
  color: '#232323',
  fontSize: '22px'
};

export default FallbackLoader;
