import * as React from 'react';
import { TopMenu } from '../TopMenu/TopMenu';
import AppLoader from '../core/AppLoader/AppLoader';
import { Box, Stack } from '@mui/material';
import { Footer } from '../Footer/Footer';

export const Layout = (props: { children?: React.ReactNode }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Stack
      flexDirection={'column'}
      minHeight={'100%'}
      justifyContent={'space-between'}
      sx={{
        background:
          'linear-gradient(to bottom,#4D4E49 0%, #4D4E49 35vh,#F5F5F5 35vh, #F5F5F5 100%)'
      }}
    >
      <TopMenu isMobile={!mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <AppLoader variant="linear" />
      <Box
        sx={theme => ({
          minHeight: '50vh',
          color: 'white',
          padding: '60px 0',
          [theme.breakpoints.down('sm')]: {
            padding: 0,
            flexGrow: 1,
            display: 'flex',
            alignItems: 'stretch'
          }
        })}
      >
        {props.children}
      </Box>
      <Footer />
    </Stack>
  );
};
