import { DropdownItem, DropdownItemNumber } from '../entities/Common';

export const TemporalNewId: string = '00000000-0000-0000-0000-000000000000';
export const UserClaims = {
  CompanyName: 'BuildWitt.Company.CompanyName',
  CompanyId: 'BuildWitt.Company.CompanyId',
  EmployeeId: 'BuildWitt.Company.EmployeeId',
  UserRole: 'BuildWitt.User.Role',
  UserId: 'BuildWitt.User.Guid',
  TrainingEmployeeSubscription: 'BuildWitt.Employee.Subscription.Training',
  CompanyLogoUrl: 'BuildWitt.Company.CompanyLogoUrl',
  BWLibraryCompanyId: 'BuildWitt.Library.CompanyId',
  UserEmail: 'BuildWitt.User.Email'
};

export const UserRoles = {
  CompanyAdmin: 'CompanyAdmin',
  Employee: 'Employee'
};

export const appPermissions = {
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  VIEW_ASSIGNMENTS: 'VIEW_ASSIGMENTS',
  VIEW_COURSES: 'VIEW_COURSES',
  VIEW_COURSE: 'VIEW_COURSE',
  VIEW_PROGRESS: 'VIEW_PROGRESS',
  VIEW_MESSAGES: 'VIEW_MESSAGES',
  VIEW_LEARNING_PLANS: 'VIEW_LEARNING_PLANS',
  VIEW_LESSONS: 'VIEW_LESSONS',
  VIEW_EMPLOYEES: 'VIEW_EMPLOYEES',
  VIEW_ANALYTICS: 'VIEW_ANALYTICS',
  VIEW_SETTINGS: 'VIEW_SETTINGS',
  CREATE_COURSE: 'CREATE_COURSE',
  PREVIEW_COURSE: 'PREVIEW_COURSE',
  PLAY_COURSE: 'PLAY_COURSE',
  EDIT_COURSE: 'EDIT_COURSE',
  VIEW_COURSE_DETAILS: 'VIEW_COURSE_DETAILS',
  IMPORT_COURSE: 'IMPORT_COURSE',
  EDIT_LESSON: 'EDIT_LESSON',
  ADD_LESSON: 'ADD_LESSON',
  ADD_NEW_EMPLOYEE: 'ADD_NEW_EMPLOYEE',
  CREATE_GROUP: 'CREATE_GROUP',
  VIEW_EMPLOYEE_ASSIGNMENTS: 'VIEW_EMPLOYEE_ASSIGNMENTS',
  VIEW_DRAFT_COURSE: 'VIEW_DRAFT_COURSE',
  ASSIGN_COURSE: 'ASSIGN_COURSE',
  ASSIGN_LEARNING_PLANS: 'ASSIGN_LEARNING_PLANS',
  MANAGE_COMPANY: 'MANAGE_COMPANY'
};

export const LearningPlanAssignByOptions = {
  DueDate: 'DueDate',
  EmployeesWorkloadBudget: 'WorkloadBudget'
};

export const QuestionTypes = {
  TrueFalse: 'TrueFalse',
  MultipleChoiceText: 'MultipleChoiceText',
  MultipleChoiceImage: 'MultipleChoiceImage',
  Number: 'Number'
};

export const LabelDropdown = {
  TrueFalse: 'True or False',
  MultipleChoiceText: 'Multiple choice',
  MultipleChoiceImage: 'Choose an Image',
  Number: 'Text number response'
};

export const Buildwitt_Value = 'buildwitt';
export const Company_Value = 'company';
export const HttpStatusOK = 200;

export const ServerEventsNames = {
  LessonVideoEncodingComplete: 'VideoEncodingFinished',
  LessonVideoEncodingFailed: 'VideoEncodingFailed'
};

export const LessonsPerDay = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' }
];

export const BuildWittName = 'BuildWitt';

export const LessonVideo = 'Video';
export const LessonQuiz = 'Quiz';
export const LessonAssessment = 'Assessment';

export const LanguagesSubtitles = [
  { label: 'English', value: 'English', srcLang: 'EN' },
  { label: 'Spanish', value: 'Spanish', srcLang: 'ES' }
];

export const NotificationType = {
  NewAssignment: 'NewAssignment',
  PendingWorkload: 'PendingWorkload',
  OverdueAssignments: 'OverdueAssignments'
};

export const NotificationStatus = {
  New: 'New',
  Viewed: 'Viewed',
  Dismissed: 'Dismissed'
};

export const DefaultStartTimeShiftInMinutesUtc = 570;
export const StartTimes = [
  { label: '12:00am', value: '0' },
  { label: '12:30am', value: '30' },
  { label: '1:00am', value: '60' },
  { label: '1:30am', value: '90' },
  { label: '2:00am', value: '120' },
  { label: '2:30am', value: '150' },
  { label: '3:00am', value: '180' },
  { label: '3:30am', value: '210' },
  { label: '4:00am', value: '240' },
  { label: '4:30am', value: '270' },
  { label: '5:00am', value: '300' },
  { label: '5:30am', value: '330' },
  { label: '6:00am', value: '360' },
  { label: '6:30am', value: '390' },
  { label: '7:00am', value: '420' },
  { label: '7:30am', value: '450' },
  { label: '8:00am', value: '480' },
  { label: '8:30am', value: '510' },
  { label: '9:00am', value: '540' },
  { label: '9:30am', value: '570' },
  { label: '10:00am', value: '600' },
  { label: '10:30am', value: '630' },
  { label: '11:00am', value: '660' },
  { label: '11:30am', value: '690' },
  { label: '12:00pm', value: '720' },
  { label: '12:30pm', value: '750' },
  { label: '1:00pm', value: '780' },
  { label: '1:30pm', value: '810' },
  { label: '2:00pm', value: '840' },
  { label: '2:30pm', value: '870' },
  { label: '3:00pm', value: '900' },
  { label: '3:30pm', value: '930' },
  { label: '4:00pm', value: '960' },
  { label: '4:30pm', value: '990' },
  { label: '5:00pm', value: '1020' },
  { label: '5:30pm', value: '1050' },
  { label: '6:00pm', value: '1080' },
  { label: '6:30pm', value: '1110' },
  { label: '7:00pm', value: '1140' },
  { label: '7:30pm', value: '1170' },
  { label: '8:00pm', value: '1200' },
  { label: '8:30pm', value: '1230' },
  { label: '9:00pm', value: '1260' },
  { label: '9:30pm', value: '1290' },
  { label: '10:00pm', value: '1320' },
  { label: '10:30pm', value: '1350' },
  { label: '11:00pm', value: '1380' },
  { label: '11:30pm', value: '1410' }
];

export const AssignmentType = {
  lesson: 'lessons',
  course: 'courses',
  learningPlan: 'learningPlans'
};

export const WeeklyStatisticsDefault = [
  {
    dayOfWeek: 'Sunday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Monday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Tuesday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Wednesday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Thursday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Friday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Saturday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  }
];

export const EmployeeTypes = [
  {
    value: '1',
    label: 'Full-Time'
  },
  {
    value: '2',
    label: 'Part-Time'
  }
];

export const JobsTypeOptions: DropdownItemNumber[] = [
  {
    label: 'Full Time',
    value: 0
  },
  {
    label: 'Part Time',
    value: 1
  },
  {
    label: 'Internship',
    value: 2
  }
];

export const payRateOptions = [
  {
    value: 'hourly',
    label: 'Hourly'
  },
  {
    value: 'salary',
    label: 'Salary'
  },
  {
    value: 'contract',
    label: 'Contract'
  },
  {
    value: 'fulltime',
    label: 'Full Time'
  }
];

export const applicantsOptions = [
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'website',
    label: 'Website'
  }
];

export const IAmOptions: DropdownItemNumber[] = [
  { label: 'Looking to join the industry', value: 1 },
  { label: 'Business Leader', value: 2 },
  { label: 'Working in the industry', value: 3 }
];

export const CompanySizeOptions: DropdownItem[] = [
  { label: 'Unknown', value: '0' },
  { label: '1-25', value: '1' },
  { label: '26-100', value: '2' },
  { label: '101-300', value: '3' },
  { label: '301-750', value: '4' },
  { label: '751-1999', value: '5' },
  { label: '2000+', value: '6' }
];

export const CompanyBusinessTypeOptions: DropdownItem[] = [
  { label: 'We are a company in the Dirt World', value: '0' },
  { label: 'We are a vendor that serves the Dirt World', value: '1' }
];

export const JobLocationOptions: DropdownItemNumber[] = [
  { label: 'Office', value: 0 },
  { label: 'Remote', value: 1 },
  { label: 'Field', value: 2 },
  { label: 'Hybrid', value: 3 }
];

export const countriesToHighlight = ['Canada', 'United States'];

export const TitilliumWebNormal =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf';
export const TitilliumWebSemiBold =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffBzCKIx5YrSYqWM.ttf';
export const TitilliumWebBold =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffHjDKIx5YrSYqWM.ttf';
