import { CSSProperties } from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useHistory, useParams } from 'react-router';
import { UserProfileDetailParams } from '../../../entities/Common';
import { ApplicationState } from '../../../store';
import { useSelector } from 'react-redux';
import { Card } from '../../../components/Card';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFTemplate from '../PDFTemplate';
import config from '../../../config';

export const IdentificationSection = () => {
  const history = useHistory();
  const { userProfileId } = useParams<UserProfileDetailParams>();
  const currentUserProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );
  const editProfile = () => {
    history.push(`/user/profile/${userProfileId}/identification/edit`);
  };

  const viewPDF = () => {
    history.push(`/user/viewpdf`);
  };

  if (currentUserProfile) {
    return (
      <Card mobileCenterAlign gridProps={{ spacing: 2 }}>
        <Grid item>
          <Avatar
            sx={{ width: 165.61, height: 165.02 }}
            src={currentUserProfile.photoURL}
          >
            AV
          </Avatar>
        </Grid>
        <Grid item xs={12} sm container marginTop={1}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom style={userProfileNameStyle}>
                {currentUserProfile.name} {currentUserProfile.lastName}
              </Typography>
              <Typography style={jobTitleStyle}>
                {currentUserProfile.jobTitle},{' '}
                <span style={employerStyle}>{currentUserProfile.employer}</span>
              </Typography>
              <Typography variant={'body1'}>
                {currentUserProfile.address?.city},{' '}
                {currentUserProfile.address?.state}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div style={buttonsStyle}>
            <BasicButton
              onClick={editProfile}
              color="secondary"
              style={{ height: '34px', color: '#EDECE8', marginRight: '5px' }}
              endIcon={
                <EditIcon
                  style={{
                    color: '#000',
                    fontSize: '16px'
                  }}
                />
              }
            >
              <Typography variant="blackStrongCaption">Edit</Typography>
            </BasicButton>
            {config.FF_PDF_RESUME && (
              <BasicButton
                color="secondary"
                style={{ height: '34px', color: '#EDECE8', marginRight: '5px' }}
                endIcon={
                  <PictureAsPdfIcon
                    style={{
                      color: '#000',
                      fontSize: '16px'
                    }}
                  />
                }
              >
                <PDFDownloadLink
                  document={<PDFTemplate userProfile={currentUserProfile} />}
                  fileName={`${currentUserProfile.name}_${currentUserProfile.lastName}.pdf`}
                >
                  <Typography variant="blackStrongCaption">
                    Generate Resume
                  </Typography>
                </PDFDownloadLink>
              </BasicButton>
            )}
          </div>
        </Grid>
      </Card>
    );
  }
  return null;
};

const userProfileNameStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '30px',
  color: '#1B1C1C'
};

const jobTitleStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '28px',
  color: '#4E4E4C'
};

const employerStyle: CSSProperties = {
  color: '#8B9162',
  fontWeight: 700
};

const buttonsStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
};
