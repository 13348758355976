import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { CertificationDto } from '../../../entities/UserProfile';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';

export const CertificationSectionDetailsItem = ({
  certification,
  userProfileId
}: {
  certification: CertificationDto;
  userProfileId: string;
}) => {
  const history = useHistory();

  const editCertificationDetails = () => {
    history.push(
      `/user/profile/${userProfileId}/certification/${certification.id}/edit`
    );
  };

  return (
    <div>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'flex-start'}
        spacing={2}
        elevation={0}
        component={Paper}
        sx={{
          backgroundColor: 'rgba(237, 236, 232, 0.5)',
          padding: 2,
          border: '1px solid #ccc'
        }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent={'space-between'}
          flexWrap={'nowrap'}
        >
          <Typography variant="blackTitleMedium">
            {certification.skillName}
          </Typography>
          <Button
            variant="edit"
            endIcon={<EditIcon />}
            onClick={editCertificationDetails}
          >
            Edit
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="blackSubTitle">
              {certification.grantingInstitution}
            </Typography>
            <Typography variant="blackCaption">{`${
              certification.dateAwarded
            } to ${
              certification.expirationDate &&
              certification.expirationDate !== ''
                ? certification.expirationDate
                : 'Forever'
            }`}</Typography>
            <Typography variant="blackCaption">
              {certification.details}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
