import React, { useState, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/system';
import { Guid } from 'guid-typescript';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import ModalPage from '../../components/core/ModalPage/ModalPage';
import CreateLearningPlan from './CreateLearningPlan';
import LearningPlanCard from '../../components/LearningPlanCard/LearningPlanCard';
import { LearningPlan } from '../../entities/LearningPlan';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../actions/learningPlans';
import {
  UserClaims,
  BuildWittName,
  appPermissions
} from '../../core/constants';
import { FormModeEnum } from '../../core/enums';
import { buttonStyle } from '../../utils/buttonStyles';
import AssignLearningPlan from './AssignLearningPlan';
import BasicToggleButton from '../../components/core/BasicToggleButton/BasicToggleButton';
import useCurrentUser from '../../hooks/useCurrentUser';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { actionCreators as studentActions } from '../../actions/student';
import ProgressIcon from '../../components/core/ProgressIcon/ProgressIcon';
import { useTranslation } from 'react-i18next';

const assignButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px',
  marginRight: '1rem'
};

const startButtonStyle: CSSProperties = {
  ...assignButtonStyle,
  marginRight: '1rem'
};

const empLabel = {
  fontWeight: 'bold'
};

const buttonSection: CSSProperties = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '0.3rem'
};

const titleStyle: CSSProperties = {
  paddingBottom: '1rem'
};

const publishedTitleStyle: CSSProperties = {
  paddingTop: '1.5rem'
};

const planCardStyle: CSSProperties = {
  marginBottom: '2rem'
};

const mainGridContainerStyle: SxProps<Theme> = {
  paddingBottom: '2rem'
};

const mainButtonsContainerStyle: SxProps<Theme> = {
  display: 'flex',
  gap: '0.3rem',
  justifyContent: {
    xs: 'flex-start',
    md: 'flex-end'
  },
  flexWrap: 'wrap'
};

const toggleContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: {
    xs: 'flex-start',
    md: 'flex-end'
  },
  marginTop: '20px',
  paddingBottom: '1.5rem'
};

export interface LearningPlansProps {}

const LearningPlans: React.FC<LearningPlansProps> = () => {
  const user = useCurrentUser();
  const companyName = user?.profile[UserClaims.CompanyName] as string;
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const userCompanyId = user?.profile[UserClaims.CompanyId] as string;
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId] as string;
  const [learningPlanSource, setLearningPlanSource] = useState<string>(
    companyId
  );
  const [openCreateEditModal, setOpenCreateEditModal] = useState<boolean>(
    false
  );
  const [openAssignModal, setOpenAssignModal] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<LearningPlan>();
  const [modalMode, setModalMode] = useState<FormModeEnum>(FormModeEnum.Create);
  const dispatch = useDispatch();
  const learningPlans = useSelector(
    (state: ApplicationState) => state.learningPlans?.learningPlans
  );
  const completedLearningPlans = useSelector(
    (state: ApplicationState) => state.student?.completedLearningPlans
  );
  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );
  const appIsLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const [drafts, setDrafts] = useState<LearningPlan[]>([]);
  const [published, setPublished] = useState<LearningPlan[]>([]);
  const [filteredPublished, setFilteredPublished] = useState<LearningPlan[]>(
    []
  );
  const history = useHistory();
  const { t } = useTranslation(['common', 'learningPlans']);

  const lPSources = [
    {
      text: companyName,
      value: companyId
    },
    {
      text: BuildWittName,
      value: bWCompanyId
    }
  ];

  const handleCreateNewPlan = () => {
    setModalMode(FormModeEnum.Create);
    dispatch(actionCreators.newLearningPlan());
    handleOpenCreateEditModal(true);
  };

  const handleEditLearningPlan = (learningPlan: LearningPlan) => {
    dispatch(actionCreators.requestPlan(learningPlan.id));
    history.push('/learning-plans-details');
  };

  const handleOpenCreateEditModal = (open: boolean) => {
    setOpenCreateEditModal(open);

    if (!open) {
      dispatch(actionCreators.setCurrentLearningPlan(null));
      dispatch(actionCreators.setLearningPlansAssigment(null));
    }
  };

  React.useEffect(() => {
    if (companyId) setLearningPlanSource(companyId);
  }, [companyId]);

  React.useEffect(() => {
    dispatch(actionCreators.setCurrentLearningPlan(null));
    dispatch(actionCreators.setLearningPlansAssigment(null));
    dispatch(actionCreators.setLearningPlanCourses([]));
    dispatch(studentActions.setCompletedCourses([]));

    return () => {
      dispatch(actionCreators.updatePlans([]));
    };
  }, []);

  React.useEffect(() => {
    dispatch(actionCreators.requestPlans());
    dispatch(studentActions.getCompletedLearningPlans());
  }, [dispatch]);

  React.useEffect(() => {
    if (learningPlans) {
      const pubPlans = learningPlans.filter(plan => !plan.isDraft).slice();
      const draftPlans = learningPlans.filter(plan => plan.isDraft).slice();
      const filteredPub = pubPlans.filter(lp =>
        learningPlanSource === bWCompanyId
          ? lp.companyId === learningPlanSource || lp.companyId === Guid.EMPTY
          : lp.companyId === learningPlanSource
      );
      setFilteredPublished(filteredPub);
      setPublished(pubPlans);
      setDrafts(draftPlans);
    }
  }, [learningPlans]);

  React.useEffect(() => {
    const filtered = published.filter(lp =>
      learningPlanSource === bWCompanyId
        ? lp.companyId === learningPlanSource || lp.companyId === Guid.EMPTY
        : lp.companyId === learningPlanSource
    );
    setFilteredPublished(filtered);
  }, [learningPlanSource]);

  const handleAssign = (learningPlan: LearningPlan) => {
    setSelectedPlan(learningPlan);
    setOpenAssignModal(true);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignModal(false);
    dispatch(actionCreators.setCurrentLearningPlan(null));
    setSelectedPlan(undefined);
  };

  const handleStartLearningPlan = (learningPlan: LearningPlan) => {
    dispatch(actionCreators.setCurrentLearningPlan(learningPlan));
    history.push(`/learning-plans/preview/${learningPlan.id}`);
  };

  return (
    <PageContainer>
      <Grid container sx={mainGridContainerStyle} columns={{ xs: 1, md: 12 }}>
        <Grid item xs={1} md={4}>
          <h3 style={titleStyle}>{t('learningPlans', {ns: 'learningPlans'})}</h3>
        </Grid>
        <ProtectedComponent action={appPermissions.ASSIGN_LEARNING_PLANS}>
          <Grid item xs={1} md={8}>
            <Box sx={mainButtonsContainerStyle}>
              <BasicButton
                color="primary"
                onClick={handleCreateNewPlan}
                style={buttonStyle}
              >
                {t('createNewPlan', {ns: 'learningPlans'})}
              </BasicButton>
            </Box>
          </Grid>
        </ProtectedComponent>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={10} xl={8}>
          <ProtectedComponent action={appPermissions.ASSIGN_LEARNING_PLANS}>
            <h3 style={titleStyle}>{t('drafts', {ns: 'common'})}</h3>
            {!appIsLoading && !studentIsLoading && !drafts.length && (
              <span>{t('noDraftLPs', {ns: 'learningPlans'})}</span>
            )}
            {!appIsLoading && !studentIsLoading ? (
              drafts.map(plan => {
                const { id } = plan;
                const isCompleted = !!completedLearningPlans?.find(
                  lp => lp.learningPlanId === id
                );

                return (
                  <LearningPlanCard
                    key={id}
                    plan={plan}
                    isComplete={isCompleted}
                    onEdit={() => handleEditLearningPlan(plan)}
                    style={planCardStyle}
                  >
                    <BasicButton
                      onClick={() => handleStartLearningPlan(plan)}
                      color="primary"
                      style={startButtonStyle}
                    >
                      {t('start', {ns: 'common'})}
                    </BasicButton>
                  </LearningPlanCard>
                );
              })
            ) : (
              <ProgressIcon />
            )}
          </ProtectedComponent>
          <Grid container columns={{ xs: 1, md: 12 }}>
            <Grid item xs={1} md={6}>
              <h3 style={publishedTitleStyle}>{t('publishedLPs', {ns: 'learningPlans'})}</h3>
            </Grid>
            {!appIsLoading && !studentIsLoading && !published.length ? (
              <span>{t('noPublishedLPs', {ns: 'learningPlans'})}</span>
            ) : (
              <>
                <Grid
                  item
                  className="toggle-button"
                  sx={toggleContainerStyle}
                  xs={1}
                  md={6}
                >
                  {!appIsLoading &&
                    !studentIsLoading &&
                    published.length &&
                    lPSources.map(source => (
                      <BasicToggleButton
                        key={`switch-${source.value}`}
                        id={`switch-${source.value}`}
                        type="radio"
                        name="LPSource"
                        value={source.value}
                        checked={learningPlanSource === source.value}
                        color={
                          learningPlanSource === source.value
                            ? 'primary'
                            : 'secondary'
                        }
                        onChange={e =>
                          setLearningPlanSource(e.currentTarget.value)
                        }
                      >
                        {source.text}
                      </BasicToggleButton>
                    ))}
                </Grid>
                {!appIsLoading && !studentIsLoading ? (
                  filteredPublished.map(plan => {
                    const { id } = plan;
                    const isCompleted = !!completedLearningPlans?.find(
                      lp => lp.learningPlanId === id
                    );

                    return (
                      <LearningPlanCard
                        key={id}
                        plan={plan}
                        isComplete={isCompleted}
                        onEdit={() => handleEditLearningPlan(plan)}
                        style={planCardStyle}
                        showEdit={plan.companyId === userCompanyId}
                      >
                        <div style={buttonSection}>
                          <BasicButton
                            onClick={() => handleStartLearningPlan(plan)}
                            color="primary"
                            style={startButtonStyle}
                          >
                            {t('start', {ns: 'common'})}
                          </BasicButton>
                          <ProtectedComponent
                            action={appPermissions.ASSIGN_LEARNING_PLANS}
                          >
                            <BasicButton
                              onClick={() => {
                                handleAssign(plan);
                              }}
                              color="secondary"
                              style={assignButtonStyle}
                            >
                              {t('assign', {ns: 'common'})}
                            </BasicButton>
                          </ProtectedComponent>
                        </div>
                      </LearningPlanCard>
                    );
                  })
                ) : (
                  <ProgressIcon />
                )}
              </>
            )}
          </Grid>
        </Grid>
        <ModalPage
          title={`${
            modalMode === FormModeEnum.Create ? t('create', {ns: 'common'}) : t('edit', {ns: 'common'})
          } ${t('aLearningPlan', {ns: 'learningPlans'})}`}
          onSave={() => {}}
          onCancel={() => handleOpenCreateEditModal(false)}
          show={openCreateEditModal}
          showSave={false}
          showCancel={false}
          saveText={t('save', {ns: 'common'})}
          cancelText={t('cancel', {ns: 'common'})}
          variant="lg"
          contentClassName="modal-body-auto-height"
          backdrop="static"
          keyboard={false}
        >
          <CreateLearningPlan formMode={modalMode} />
        </ModalPage>
        {openAssignModal && selectedPlan && (
          <AssignLearningPlan
            show={openAssignModal}
            learningPlan={selectedPlan}
            onClose={handleCloseAssignModal}
          />
        )}
      </Grid>
    </PageContainer>
  );
};

export default LearningPlans;
