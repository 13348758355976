import React, { useEffect, useState, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../components/core/Panel/Panel';
import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators as studentActions } from '../../actions/student';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import DashboardWidget from './DashboardWidget';

const PersonalizedInput = styled(InputBase)(({ theme }) => ({
  '.MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #999999',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    color: '#ffffff',
    fontWeight: 400,
    // Use the system font instead of the default Roboto font.
    fontFamily: [theme.typography.fontFamily].join(',')
  },
  '.MuiNativeSelect-icon ': {
    color: '#FFFFFF8F'
  }
}));

const PersonalizedButtonGroup = styled(ButtonGroup)(() => ({
  '.MuiButtonGroup-grouped:not(:last-of-type)': {
    borderRight: '0px',
    borderColor: 'none'
  }
}));

const WeeklyStatistics = () => {
  const { t } = useTranslation(['dashboard']);
  const [option, setOption] = useState<string>('lessonsCompleted');
  const dispatch = useDispatch();
  const handleChange = (event: { target: { value: string } }) => {
    setOption(event.target.value);
  };
  const [startDate, setStartDate] = useState<string>(
    moment().startOf('week').format()
  );
  const [endDate, setEndDate] = useState<string>(
    moment().endOf('week').format()
  );
  useEffect(() => {
    dispatch(studentActions.getWeeklyStatistics(startDate, endDate));
  }, [startDate, endDate]);

  const weeklyStatistics = useSelector(
    (state: ApplicationState) => state.student?.weeklyStatistics
  );

  const previousWeek = () => {
    setStartDate(
      moment(startDate).startOf('week').subtract(1, 'week').format()
    );
    setEndDate(moment(endDate).endOf('week').subtract(1, 'week').format());
  };
  const nextWeek = () => {
    setStartDate(moment(startDate).startOf('week').add(1, 'week').format());
    setEndDate(moment(endDate).endOf('week').add(1, 'week').format());
  };
  const uiStartDate = moment(startDate).format('MMM Do');
  const uiEndDate = moment(endDate).format('MMM Do');
  return (
    <DashboardWidget title={t('weeklyStatistics', { ns: 'dashboard' })}>
      <Panel width="100%">
        <Grid container>
          <Grid item xs={8}>
            <FormControl sx={{ mt: 2 }} variant="standard">
              <NativeSelect
                id="native-select-option"
                value={option}
                onChange={handleChange}
                input={<PersonalizedInput />}
              >
                <option value={'lessonsCompleted'}>
                  Total Lessons Completed
                </option>
                <option value={'coursesCompleted'}>
                  Total Courses Completed
                </option>
                <option value={'learningPlansCompleted'}>
                  Total Learning Plans Completed
                </option>
              </NativeSelect>
            </FormControl>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Typography sx={{ mt: 3 }}>
              {uiStartDate} - {uiEndDate}
            </Typography>
            <PersonalizedButtonGroup variant="text" sx={{ mt: 1 }}>
              <Button onClick={previousWeek}>
                <FontAwesomeIcon icon={faAngleLeft} style={styleIcon} />
              </Button>
              <Button onClick={nextWeek}>
                <FontAwesomeIcon icon={faAngleRight} style={styleIcon} />
              </Button>
            </PersonalizedButtonGroup>
          </Grid>
        </Grid>
        {weeklyStatistics ? (
          <VictoryChart width={1000}>
            <VictoryAxis
              style={{
                axis: { strokeWidth: 0 },
                tickLabels: { fontSize: 15, padding: 5, fill: '#fff' }
              }}
            />
            <VictoryBar
              barWidth={50}
              cornerRadius={3}
              style={{
                data: {
                  fill: '#FF9E00'
                },
                labels: {
                  fontSize: 25,
                  fill: '#FFFFFF'
                }
              }}
              data={weeklyStatistics}
              x="dayOfWeek"
              y={option}
              labels={weeklyStatistics.map(ws => {
                switch (option) {
                  case 'coursesCompleted':
                    return ws.coursesCompleted 
                  case 'learningPlansCompleted':
                    return ws.learningPlansCompleted 
                  default:
                    return ws.lessonsCompleted 
                }
              })}
            />
          </VictoryChart>
        ) : (
          <div style={noDataDiv}>
            <Typography style={noDataText}>Loading...</Typography>
          </div>
        )}
      </Panel>
    </DashboardWidget>
  );
};

const styleIcon: CSSProperties = {
  fontSize: '20px',
  color: 'rgba(255, 255, 255, 0.56)',
  marginTop: '2px'
};

const noDataDiv: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100px'
};

const noDataText: CSSProperties = {
  fontSize: '24px'
};

export default WeeklyStatistics;
