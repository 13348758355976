import React, { CSSProperties, useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridRowHeightParams
} from '@mui/x-data-grid';
import { Employee, EmployeeGroup } from '../../entities/Employee';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { DropdownItem } from '../../entities/Common';
import {
  LessonsPerDay,
  StartTimes,
  DefaultStartTimeShiftInMinutesUtc
} from '../../core/constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from 'moment';

export interface EmployeeTableProps {
  employeesLearningProfile: Employee[];
  employeeGroups: DropdownItem[];
  isLoading: boolean;
  onChangeEmployeeWorkload: (employeeId: string, lessonsPerDay: number) => void;
  onChangeStartTime: (employeeId: string, minutes: string) => void;
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({
  employeesLearningProfile,
  employeeGroups,
  isLoading,
  onChangeEmployeeWorkload,
  onChangeStartTime
}) => {
  const theme = useTheme();
  let rowCounter = 0;

  const [dataSource, setDataSource] = useState<Employee[]>([]);
  const [groupFilter, setGroupFilter] = useState('');
  const { t } = useTranslation(['common', 'employees']);
  const defaultGroupfilterOption: DropdownItem = {
    label: t('filterGroup', { ns: 'common' }),
    value: ''
  };

  useEffect(() => {
    setGroupFilter(defaultGroupfilterOption.value);
    setDataSource(employeesLearningProfile);
  }, [employeesLearningProfile]);

  const getLocalStartTime = (minutes?: number) => {
    let startTimeMinutes = minutes
      ? minutes
      : DefaultStartTimeShiftInMinutesUtc;
    let utcDateTime = moment.utc().startOf('day').add(startTimeMinutes, 'm');
    utcDateTime = utcDateTime.local();

    return (utcDateTime.hours() * 60 + utcDateTime.minutes()).toString();
  };

  const handleFilterGroup = (groupId: string) => {
    let filtered = employeesLearningProfile;
    if (groupId.length > 0) {
      filtered = employeesLearningProfile?.filter((profile: Employee) =>
        profile.groups?.some((group: EmployeeGroup) => group.id === groupId)
      );
    }

    setDataSource(filtered);
    setGroupFilter(groupId);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('employee', { ns: 'employees' }),
      flex: 1,
      minWidth: 350,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className="d-flex ps-1 pt-2 pb-2">
          <img
            className={`img-profile rounded-circle`}
            width="36"
            height="36"
            alt={`${params.row.firstName} ${params.row.lastName}`}
            src={
              params.row.photoUrl && params.row.photoUrl.length
                ? params.row.photoUrl
                : require('../../assets/user.png').default
            }
          />
          <div className="d-flex flex-column px-3">
            <span
              style={{ fontSize: 14 }}
            >{`${params.row.firstName} ${params.row.lastName}`}</span>
            <span style={{ fontSize: 12, color: theme.palette.grey[100] }}>
              {params.row.title}
            </span>
          </div>
        </div>
      )
    },
    {
      field: 'isCompanyAdmin',
      headerName: t('admin', { ns: 'common' }),
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<boolean>) => (
        <span style={dataGridColumnStyle}>
          {params.value ? t('yes', { ns: 'common' }) : 'No'}
        </span>
      )
    },
    {
      field: 'groups',
      minWidth: 350,
      sortable: false,
      renderCell: (params: GridRenderCellParams<EmployeeGroup[]>) => (
        <span>{params.value?.map(group => group.name).join(', ')}</span>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          <span style={{ paddingRight: '4rem' }}>
            {t('groups', { ns: 'employees' })}
          </span>
          <BasicSelect
            labelId="groups"
            id="groups"
            options={employeeGroups}
            defaultValue=""
            value={[groupFilter]}
            handleOnChange={(e: string[]) => handleFilterGroup(e[0])}
            style={filterSelectStyle}
            theme={'dark'}
            multiple={false}
            sx={{ backgroundColor: 'transparent' }}
          />
        </>
      )
    },
    {
      field: 'lessonsPerDay',
      headerName: t('dailyWorkload', { ns: 'employees' }),
      minWidth: 140,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => (
        <BasicSelect
          labelId="dailyWorkload"
          id="dailyWorkload"
          options={LessonsPerDay}
          defaultValue=""
          value={[params.row.lessonsPerDay.toString()]}
          handleOnChange={(e: string[]) =>
            onChangeEmployeeWorkload(params.row.id, Number(e[0]))
          }
          style={selectStyle}
          theme={'dark'}
          multiple={false}
          sx={{ backgroundColor: 'transparent' }}
        />
      )
    },
    {
      field: 'dailyStartTimeMinutes',
      headerName: t('startTime', { ns: 'employees' }),
      minWidth: 140,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => (
        <BasicSelect
          labelId="startTime"
          id="startTime"
          options={StartTimes}
          defaultValue=""
          value={[getLocalStartTime(params.value)]}
          handleOnChange={(e: string[]) =>
            onChangeStartTime(params.row.id, e[0])
          }
          style={selectStyle}
          theme={'dark'}
          multiple={false}
          sx={{ backgroundColor: 'transparent' }}
        />
      )
    }
  ];

  return (
    <Box
      sx={{
        height: '50vh',
        width: '100%',
        border: `1px solid ${theme.palette.grey[100]}`,
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '8px',
        '& .row-odd-style': {
          bgcolor: theme.palette.grey.A200
        },
        '& .row-peer-style': {
          bgcolor: theme.palette.common.black
        }
      }}
    >
      {!isLoading && dataSource.length > 0 && (
        <CustomDataGrid
          getRowId={row => row.id}
          rows={dataSource}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          sortingOrder={['desc', 'asc']}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'firstName',
                  sort: 'asc'
                }
              ]
            }
          }}
          getRowClassName={() => {
            rowCounter++;
            return rowCounter % 2 !== 0 ? 'row-odd-style' : 'row-peer-style';
          }}
          disableSelectionOnClick
          disableColumnMenu
          getRowHeight={(params: GridRowHeightParams) => {
            if (params.model.groups.length > 1) {
              return 'auto';
            }
            return null;
          }}
          components={{
            ColumnSortedDescendingIcon: () => {
              return <ArrowDropDownIcon height="5px" width="10px" />;
            },
            ColumnSortedAscendingIcon: () => {
              return <ArrowDropUpIcon height="5px" width="10px" />;
            }
          }}
        />
      )}
      {!isLoading && dataSource.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <span>{t('noRecords', { ns: 'common' })}</span>
        </Box>
      )}
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `none`,
  backgroundColor: theme.palette.common.black,
  borderRadius: '8px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiIconButton-root': {
    color: theme.palette.common.white
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-iconSeparator': {
    color: theme.palette.grey.A100
  },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '1rem'
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.common.black
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#333333'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    background: '#3e3e3e'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px #3e3e3e',
    borderRadius: '10px'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    background: theme.palette.common.white,
    width: '10px',
    borderRadius: '7px'
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  }
}));

const dataGridColumnStyle: CSSProperties = {
  fontSize: '17px'
};

const filterSelectStyle: CSSProperties = {
  width: '170px',
  height: '35px'
};

const selectStyle: CSSProperties = {
  width: '120px',
  height: '35px'
};

export default EmployeeTable;
