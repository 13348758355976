import React, { useEffect, CSSProperties, useRef } from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { Lesson } from '../../entities/Lesson';
import { useDispatch, useSelector } from 'react-redux';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import LessonCard from '../../components/LessonCard/LessonCard';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as actionAssesments } from '../../actions/assessments';
import { DailyLesson } from '../../entities/LearningPlaylist';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../store';
import moment from 'moment';
import { Box, useTheme } from '@mui/material';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';

export interface DailyLessonsProps {
  dailyLessons: DailyLesson[];
  isHorizontalView?: boolean;
}

const DailyLessons: React.FC<DailyLessonsProps> = ({
  dailyLessons,
  isHorizontalView = false
}) => {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const { t } = useTranslation(['common', 'assignments']);

  const listFailedQuiz = useSelector(
    (state: ApplicationState) => state.assessments?.failedQuizzes
  );

  const handleStartLesson = (lesson: Lesson) => {
    dispatch(lessonActions.setCurrentLessons([lesson]));
    logEvent.logPlayLessonEvent(lesson.id);

    history.push({
      pathname: `/lessons/play`,
      state: { lesson }
    });
  };

  let quizAssesmentId: string[] = [];
  {
    dailyLessons?.map(lesson => {
      if (lesson.lesson.lessonType === 'Quiz') {
        quizAssesmentId.push(lesson.lesson.assessmentId!);
      }
    });
  }

  useEffect(() => {
    dispatch(actionAssesments.failedQuizzesResult(quizAssesmentId));
  }, [dailyLessons]);

  const dueDateStyle: CSSProperties = {
    color: theme.palette.primary.main,
    paddingLeft: '0.3rem'
  };

  const overdueDateStyle: CSSProperties = {
    color: theme.palette.error.main,
    paddingLeft: '0.3rem'
  };

  const Container = isHorizontalView ? HorizontalContainer : VerticalContainer;

  const today = moment();
  return (
    <>
      <Container>
        {!!dailyLessons.length ? (
          dailyLessons.map(item => {
            const { lesson, isComplete, workloadAssignmentDate } = item;
            const { id } = lesson;
            const workloadAssignmentDateLocalized = absUtcDateToLocalMoment(
              workloadAssignmentDate
            );

            const itemProps: GridProps = isHorizontalView
              ? {}
              : {
                  xs: 1,
                  md: 6,
                  lg: 4
                };

            return (
              <Grid item key={id} {...itemProps}>
                <LessonCard
                  lesson={lesson}
                  isComplete={isComplete ?? false}
                  listFailedQuiz={listFailedQuiz!}
                  dueDate={workloadAssignmentDateLocalized.toDate()}
                >
                  <div style={actionButtonsContainerStyle}>
                    {isComplete === true ? (
                      <span />
                    ) : (
                      <div>
                        <span>
                          {t('assignedDate', { ns: 'assignments' })}:
                          <span
                            style={
                              !isComplete &&
                              workloadAssignmentDateLocalized.isBefore(
                                today,
                                'day'
                              )
                                ? overdueDateStyle
                                : dueDateStyle
                            }
                          >
                            {workloadAssignmentDateLocalized.format('MM.DD.YY')}
                          </span>
                        </span>
                      </div>
                    )}
                    <BasicButton
                      onClick={() => handleStartLesson(lesson)}
                      color={isComplete ? 'revisit' : 'primary'}
                      style={startButtonStyle}
                    >
                      {isComplete
                        ? t('revisit', { ns: 'common' })
                        : t('start', { ns: 'common' })}
                    </BasicButton>
                  </div>
                </LessonCard>
              </Grid>
            );
          })
        ) : (
          <Grid item>
            <h5>{t('noDailyLessons', { ns: 'assignments' })}</h5>
          </Grid>
        )}
      </Container>
    </>
  );
};

const HorizontalContainer: React.FC = ({ children }) => (
  <Box
    className="horizontalContainer"
    sx={{
      display: 'grid',
      gridTemplateRows: 'minmax(150px, 1fr)',
      gridAutoFlow: 'column',
      gridAutoColumns: '370px',
      overflowX: 'auto',
      paddingBottom: '20px !important',
      gap: '30px',
    }}
  >
    {children}
  </Box>
);

const VerticalContainer: React.FC = ({ children }) => (
  <Grid
    container
    sx={containerStyle}
    spacing={4}
    columns={{ xs: 1, md: 12, lg: 12 }}
  >
    {children}
  </Grid>
);

const containerStyle: SxProps = {
  paddingTop: '1rem'
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '109px',
  fontSize: '15px'
};
const actionButtonsContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};
export default DailyLessons;
