import React, { useEffect, useState } from 'react';
import NumberQuestion2 from './NumberQuestion2';
import { AnswerStatus, Question2ContainerProps } from './Types';

const NumberQuestion2Container: React.FC<Question2ContainerProps> = ({
  question,
  savedAnswer,
  setAnswer,
  isShowingResultsView,
  shouldShowCorrectAnswer
}) => {
  const [answerStatus, setAnswerStatus] = useState(
    isShowingResultsView ? AnswerStatus.AnswerSubmitted : AnswerStatus.NoAnswer
  );

  useEffect(() => {
    if (isShowingResultsView) {
      setAnswerStatus(AnswerStatus.AnswerSubmitted);
    }
  }, [isShowingResultsView]);

  const onSelectAnswer = (answer?: number) => {
    if (!isShowingResultsView) {
      setAnswerStatus(AnswerStatus.AnsweredSelected);
      setAnswer({
        questionId: question.id,
        type: question.type!,
        isCorrect: answer === parseInt(question.numberCorrectAnswer),
        order: question.order,
        answerNumber: answer === undefined ? undefined : answer.toString()
      });
    }
  };

  let receivedAnswer: undefined | number = undefined;
  if (savedAnswer && savedAnswer!.answerNumber !== undefined) {
    receivedAnswer = parseInt(savedAnswer!.answerNumber);
  }

  return (
    <NumberQuestion2
      questionText={question.questionText}
      showFeedback={isShowingResultsView && shouldShowCorrectAnswer}
      correctAnswer={parseInt(question.numberCorrectAnswer)}
      answerStatus={answerStatus}
      onAnswerReceived={onSelectAnswer}
      receivedAnswer={receivedAnswer}
    />
  );
};
export default NumberQuestion2Container;
