import { useEffect, useMemo, useReducer, useState } from 'react';
import {
  DialogTitle,
  ThemeProvider,
  DialogContent,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Typography
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  AwaitableDialog,
  defaultTheme,
  DialogHeader
} from '@buildwitt/component-library';
import { actions, defaultState, reducer } from './state';
import {
  getAssignmentFetcher,
  getDueDateColumns,
  getUnassignmentCall,
  getWorkloadColumns
} from './utils';
import { EmployeeAssignmentsStatus } from '../../entities/Employee';
import { Alert } from '@mui/lab';
import { Assignment, DueDateModal } from './DueDateModal';
import { styled } from '@mui/material/styles';

interface Props {
  assignmentType: string;
  employeeRow?: EmployeeAssignmentsStatus;
  clearSelectedEmployeeId: () => void;
}

const ManageEmployeeAssignmentsModal = ({
  assignmentType,
  employeeRow,
  clearSelectedEmployeeId
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState<Assignment>();
  const toggleIsOpen = () => {
    clearSelectedEmployeeId();
    setIsOpen(x => !x);
  };
  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    if (employeeRow) {
      dispatch({ type: actions.GET_ASSINGMENTS });
      getAssignmentFetcher(assignmentType, employeeRow.employeeId)
        .then((response: any) => {
          dispatch({ type: actions.SET_ASSIGNMENTS, payload: response.data });
        })
        .catch(error => {
          dispatch({ type: actions.SET_ASSIGNMENTS, payload: [] });
          dispatch({
            type: actions.SET_ERROR,
            payload: 'Something went wrong. Try again.'
          });
        });
    }
  }, [employeeRow?.employeeId, assignmentType]);

  useEffect(() => {
    if (employeeRow && !isOpen) setIsOpen(true);
  }, [employeeRow?.employeeId]);

  const handleUnassign = (assignmentType: string, assignmentId: string) => {
    dispatch({ type: actions.GET_ASSINGMENTS });
    getUnassignmentCall(assignmentType, assignmentId)
      .then(() => {
        dispatch({ type: actions.UNASSIGN_ASSIGNMENT, payload: assignmentId });
      })
      .catch(() => {
        dispatch({
          type: actions.SET_ERROR,
          payload: 'Could not unassign assignment. Try again.'
        });
      });
  };

  const handleAssignmentSelect = (assignment: Assignment) => {
    setCurrentAssignment(assignment);
  };
  const removeCurrentAssingment = () => setCurrentAssignment(undefined);

  const columns: GridColDef[] = useMemo(() => {
    if (state.dueType === 'date')
      return getDueDateColumns(
        assignmentType,
        handleUnassign,
        handleAssignmentSelect
      );
    else return getWorkloadColumns(assignmentType, handleUnassign);
  }, [assignmentType, state.dueType]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <AwaitableDialog
        isLoading={state.isLoading}
        open={isOpen}
        sx={{ height: 'auto' }}
        maxWidth={'lg'}
      >
        <DialogHeader onClose={toggleIsOpen}>
          <DialogTitle>Manage Assignments</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant={'h6'}>
              {employeeRow?.firstName} {employeeRow?.lastName}
            </Typography>
            <ToggleButtonGroup
              exclusive
              value={state.dueType}
              onChange={(_, newValue) =>
                dispatch({ type: actions.SET_DUE_TYPE, payload: newValue })
              }
            >
              <ToggleButton value={'date'}>Due Date</ToggleButton>
              <ToggleButton value={'workload'}>Workload</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </DialogContent>
        <Box
          sx={{
            height: '500px',
            width: '100%'
          }}
        >
          {state.error && <Alert severity={'error'}>{state.error}</Alert>}
          <CustomDataGrid
            rows={state.filteredAssignments}
            columns={columns}
            autoPageSize
            pagination
            disableSelectionOnClick
            getRowClassName={params => {
              return params.indexRelativeToCurrentPage % 2 !== 0
                ? 'row-odd-style'
                : 'row-peer-style';
            }}
          />
        </Box>
      </AwaitableDialog>
      <DueDateModal
        assignment={currentAssignment}
        removeCurrentAssingment={removeCurrentAssingment}
        assignmentType={assignmentType}
        dispatch={dispatch}
      />
    </ThemeProvider>
  );
};

export default ManageEmployeeAssignmentsModal;

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `none`,
  borderTop: `1px solid ${theme.palette.grey['100']}4d`,
  borderRadius: '0px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.grey['100']}4d`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.grey['100']}4d`
  },
  '& .MuiDataGrid-cell:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.grey['100']}4d`
  },
  '& .MuiIconButton-root': {
    color: theme.palette.common.white
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey['100']}4d`
  },
  '& .MuiDataGrid-iconSeparator': {
    color: `${theme.palette.grey['100']}4d`
  },
  '& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none'
  },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '1rem'
  },
  '& .MuiDataGrid-row.row-peer-style': {
    backgroundColor: `${theme.palette.grey[400]}B8`
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#333333'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    background: '#3e3e3e'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px #3e3e3e',
    borderRadius: '10px'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    background: theme.palette.common.white,
    width: '10px',
    borderRadius: '7px'
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  }
}));
