import { Grid, Stack, Typography } from '@mui/material';
import { CertificationDto } from '../../../entities/UserProfile';

export const CertificationSectionItem = ({
  certification
}: {
  certification: CertificationDto;
}) => {
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}
      sx={{ padding: '15px' }}
    >
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="blackTitleMedium">
            {certification.skillName}
          </Typography>
          <Typography variant="blackSubTitle">
            {certification.grantingInstitution}
          </Typography>
          <Typography variant="blackCaption">{`${
            certification.dateAwarded
          } to ${
            certification.expirationDate && certification.expirationDate !== ''
              ? certification.expirationDate
              : 'Forever'
          }`}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
