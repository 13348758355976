import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../actions/lessons';
import { actionCreators as courseActions } from '../../actions/courses';
import { actionCreators as tagActions } from '../../actions/tags';
import { Lesson } from '../../entities/Lesson';
import { useParams } from 'react-router-dom';
import { ApplicationState } from '../../store';
import LessonForm from '../../components/LessonForm/LessonForm';
import { LessonFormModel, Subtitles } from '../../entities/LessonFormModel';
import { checkUploadSubtitle, uploadLargeFileToBlob } from '../../services/blobStorage-service';
import { TransferProgressEvent } from '@azure/core-http';
import { getCall } from '../../services/rest-service';
import config from '../../config';
import { Guid } from 'guid-typescript';
import { AssetInfo } from '../../entities/assetInfo';
import { UpdateLessonDto } from '../../entities/Dto/UpdateLessonDto';
import ConfirmationModal from '../../components/core/ConfirmationModal/ConfirmationModal';
import { Grid, Typography } from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { SxProps } from '@mui/system';
import { SubtitlesDTO } from '../../entities/Dto/createLessonDto';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';

const emptyLesson: Lesson = {
  id: '',
  title: '',
  description: '',
  order: 0,
  companyId: '',
  courseId: '',
  contentMedium: 0,
  videoUrl: '',
  videoId: '',
  thumbnailUrl: '',
  learningMaterialUrl: '',
  externalVideoUrl: '',
  lessonType: '',
  tags: [],
  isVideoEncoding: false,
  isEncodingFailed: false,
  subtitles: []
};
export interface EditLessonProps {
  closeModal: () => void;
  closeDeleteModal?: () => void;
  openedFrom?: 'Lessons' | 'Courses' | undefined;
}

const gridItemStyles: SxProps = {
  textAlign: 'center'
};

const EditLesson: React.FC<EditLessonProps> = ({
  closeModal,
  closeDeleteModal,
  openedFrom = 'Courses'
}) => {
  const dispatch = useDispatch();
  const params: any = useParams();
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons!!.currentLesson
  );
  const [lesson, setLesson] = React.useState<any>(emptyLesson);
  const [uploadVideoProgress, setUploadVideoProgress] = React.useState<number>(
    0
  );

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<
    boolean
  >(false);
  const companyTags = useSelector(
    (state: ApplicationState) => state.tags?.tags
  );
  const { t } = useTranslation(['common', 'lessons']);

  React.useEffect(() => {
    if (!companyTags?.length) dispatch(tagActions.requestTags());
  }, []);

  React.useEffect(() => {
    if (currentLesson) {
      setLesson(currentLesson);
    }
  }, [currentLesson]);

  const handleUploadProgress = (
    progress: TransferProgressEvent,
    file: File
  ) => {
    const fileTotalBytes = file?.size;
    //multiply for 40% the progress cause the remaining 60% is the backend encoding time
    const valueProgress = Math.floor(
      (progress.loadedBytes / fileTotalBytes!) * 100
    );
    setUploadVideoProgress(valueProgress);
  };

  const handleSaveLesson = async (lessonData: LessonFormModel, isEdit: boolean = true) => {
    if (lessonData) {
      let upVideoUrl = '';
      let assetName = '';
      setUploadVideoProgress(1);
      if (!lessonData.externalVideoUrl && lessonData.video) {
        const assetUrl = await getCall(
          `${config.COURSES_API_URL!}lessons/asset?name=${
            'vid_' + Guid.create()
          }`
        );
        const assetInfo: AssetInfo = assetUrl.data;
        assetName = assetInfo.assetName;
        upVideoUrl = await uploadLargeFileToBlob(
          lessonData.video,
          assetInfo.containerName,
          (progress: TransferProgressEvent) =>
            handleUploadProgress(progress, lessonData.video!)
        ); //upload video file
      }

      const lessonDto: UpdateLessonDto = {
        id: lessonData.id,
        title: lessonData.title,
        description: lessonData.description,
        thumbnailUrl: lessonData.thumbnailUrl,
        externalVideoUrl: lessonData.externalVideoUrl,
        tags: lessonData.tags,
        order: 0,
        companyId: '',
        contentMedium: 0,
        documentUrl: '',
        lessonFileName: '',
        lessonFileContentType: '',
        assetName: assetName,
        assessmentId: lessonData.assessmentId,
        subtitles: [],
      };

      if(lessonData.subtitles) {
        const checkupload = await checkUploadSubtitle(lessonData.subtitles);
        lessonDto.subtitles = checkupload;
      }
      await dispatch(
        actionCreators.updateLesson(
          lessonDto,
          lessonData.thumbnailFile!,
          lessonData.pdfFile!
        )
      );
      await dispatch(tagActions.requestTags());

      if (openedFrom === 'Courses')
        await dispatch(courseActions.requestCourseLessonNames(params.id));
    }
  };

  const handleDelete = async () => {
    try {
      //setProgressInterval(true);
      await dispatch(actionCreators.deleteLesson(lesson.id));
      //setProgressInterval(false);
      if (openedFrom === 'Courses')
        await dispatch(courseActions.requestCourseLessonNames(params.id));

      setIsConfirmationModalOpen(false);
      if (closeDeleteModal) {
        closeDeleteModal();
      }
    } catch (e) {
      throw e;
    }
  };
  return (
    <>
      <LessonForm
        lesson={lesson}
        companyTags={companyTags || []}
        handleSaveLesson={handleSaveLesson}
        onSaveComplete={() => closeModal()}
        isEdit={true}
        showProgress={true}
        progressValue={uploadVideoProgress}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      ></LessonForm>
      <ConfirmationModal
        show={isConfirmationModalOpen}
        showSave={false}
        showCancel={false}
        maxWidth="sm"
        onSave={handleDelete}
        onCancel={() => setIsConfirmationModalOpen(false)}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sx={gridItemStyles}>
            <Typography variant="h6">
              {t('areYouSureToDelete', {ns: 'common'})} "{lesson.title}"?
            </Typography>
          </Grid>
          <Grid item xs={6} sx={gridItemStyles}>
            <BasicButton
              onClick={() => setIsConfirmationModalOpen(false)}
              color="secondary"
              width={'200px'}
              height={'50px'}
            >
              {t('cancel', {ns: 'common'})}
            </BasicButton>
          </Grid>
          <Grid item xs={6} sx={gridItemStyles}>
            <BasicButton
              onClick={handleDelete}
              color="confirm"
              width={'240px'}
              height={'50px'}
            >
              {t('deleteLesson', {ns: 'lessons'})}
            </BasicButton>
          </Grid>
        </Grid>
      </ConfirmationModal>
    </>
  );
};

export default EditLesson;
