import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  actionCreators,
  selectEducationById
} from '../../../actions/userProfile';
import { EducationDto } from '../../../entities/UserProfile';

import { ApplicationState } from '../../../store';
import FallbackLoader from '../../../components/FallbackLoader/FallbackLoader';
import { FormDualButtons } from '../FormDualButtons';
import { EducationForm } from './EducationForm';
import { SimpleFormPage } from '../../../components/Pages';
import { yupResolver } from '@hookform/resolvers/yup';
import { EducationValidationSchema } from './EducationValidationSchema';

export const EducationSectionUpdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{
    userProfileId: string;
    educationId: string;
  }>();

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const education = useSelector((state: ApplicationState) =>
    selectEducationById(state, params.educationId)
  );

  const { control, handleSubmit, reset, formState } = useForm<EducationDto>({
    resolver: yupResolver(EducationValidationSchema)
  });

  const goToProfile = () => {
    history.push(`/user/profile/${params.userProfileId}`);
  };

  const onSubmit: SubmitHandler<EducationDto> = data => {
    dispatch(
      actionCreators.updateEducation(
        data,
        params.userProfileId,
        params.educationId,
        history
      )
    );
  };

  React.useEffect(() => {
    if (education) {
      reset({
        degreeType: education.degreeType,
        details: education.details,
        fieldOfStudy: education.fieldOfStudy,
        graduationDate: education.graduationDate,
        school: education.school
      });
    }
  }, [education]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <SimpleFormPage title={'Complete your Profile'}>
      <EducationForm
        title={'Education'}
        submitHandler={onSubmit}
        formState={formState}
        handleSubmit={handleSubmit}
        control={control}
        bottomActions={
          <FormDualButtons
            cancelOnClick={goToProfile}
            submitButtonText={'Save Changes'}
          />
        }
      />
    </SimpleFormPage>
  );
};
