import React from 'react';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled, useTheme } from '@mui/material/styles';

export interface ProgressBarProps {
  progress: number;
  text?: string;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 9,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 9,
    backgroundColor: theme.palette.success.main
  }
}));
{
  /*<LinearProgress style={barStyle} variant="determinate" value={progress} />*/
}
export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  text = ''
}) => {
  const theme = useTheme();
  const barStyle = {};
  const spanStyle = {
    color: theme.palette.common.white
  };
  return (
    <>
      <BorderLinearProgress variant="determinate" value={progress} />
      <span style={spanStyle}>{`${text} ${progress}%`}</span>
    </>
  );
};
export default ProgressBar;
