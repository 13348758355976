import { Button, Grid, Stack } from '@mui/material';

import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { EducationSectionDetailsItem } from './EducationSectionDetailsItem';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useHistory } from 'react-router';
import { FormTitle, SimpleFormPage } from '../../../components/Pages';
import React from 'react';

export const EducationSectionDetails = () => {
  const history = useHistory();
  const currentUserProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );

  const createItem = () => {
    history.push(`/user/profile/${currentUserProfile?.id}/education/create`);
  };

  return (
    <SimpleFormPage title={'Complete your profile'}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormTitle title={'Education'} />
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={4}>
            {currentUserProfile?.educations?.map(education => (
              <EducationSectionDetailsItem
                key={education.id}
                education={education}
                userProfileId={currentUserProfile.id}
              />
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="text"
            startIcon={<AddOutlinedIcon />}
            onClick={createItem}
          >
            Add education
          </Button>
        </Grid>
      </Grid>
    </SimpleFormPage>
  );
};
