import React from 'react';
import { PropsWithChildren } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import usePermissions from '../../../hooks/usePermissions';

interface ProtectedRouteProps extends RouteProps {
  action: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  action,
  ...rest
}: PropsWithChildren<ProtectedRouteProps>) => {
  const hasPermission = usePermissions();

  const canDoAction = hasPermission(action);
  return <>{canDoAction && <Route {...rest} />}</>;
};

export default ProtectedRoute;
