import { Box, Typography } from '@mui/material';
import React from 'react';

interface CardHeaderProps {
  actions?: React.ReactNode;
  actionsNoPadding?: boolean;
  title: string;
}

export const CardHeader = ({
  title,
  actions,
  actionsNoPadding
}: CardHeaderProps) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'100%'}
    >
      <Typography variant="h4" sx={{ fontWeight: 700, color: '#242926' }}>
        {title}
      </Typography>
      <Box
        sx={{
          marginTop: actionsNoPadding ? '-20px' : 0,
          marginRight: actionsNoPadding ? '-20px' : 0
        }}
      >
        {actions}
      </Box>
    </Box>
  );
};
