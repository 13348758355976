import React, { useState, useEffect } from 'react';
import {
  DueCourse,
  DueLearningPlan,
  DueLesson,
  LearningPlaylistItems
} from '../../entities/LearningPlaylist';
import BasicAccordion from '../../components/Accordion/BasicAccordion';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import LessonAssignments from './LessonAssignments';
import CourseAssignments from './CourseAssignments';
import LearningPlanAssignments from './LearningPlanAssignments';
import { useTranslation } from 'react-i18next';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';

export interface AssignmentsByDueDateProps {
  learningPlanListItems: LearningPlaylistItems;
}

interface AssignmentsWithDueDate {
  dueDateGroup: moment.Moment;
  completeGroup?: boolean;
  lessons: DueLesson[];
  courses: DueCourse[];
  learningPlans: DueLearningPlan[];
}

interface AccordionItemState {
  [key: string]: boolean;
}

enum DueTypeEnum {
  DueLesson,
  DueCourse,
  DueLearningPlan
}

const AssignmentsByDueDate: React.FC<AssignmentsByDueDateProps> = ({
  learningPlanListItems
}) => {
  const { dueCourses, dueLessons, dueLearningPlans } = learningPlanListItems;
  const [assignmentsDueDate, setAssignmentsDueDate] = useState<
    AssignmentsWithDueDate[]
  >([]);
  const [accordionItemState, setAccordionItemState] = useState<
    AccordionItemState
  >();
  const { t } = useTranslation(['assignments']);

  useEffect(() => {
    const assignments: AssignmentsWithDueDate[] = [];
    dueLessons.forEach(item => {
      groupAssigmentsByDueDate(assignments, item, DueTypeEnum.DueLesson);
    });
    dueCourses.forEach(item => {
      groupAssigmentsByDueDate(assignments, item, DueTypeEnum.DueCourse);
    });
    dueLearningPlans.forEach(item => {
      groupAssigmentsByDueDate(assignments, item, DueTypeEnum.DueLearningPlan);
    });
    assignments.sort((assignment1, assignment2) => {
      return (
        assignment1.dueDateGroup.unix() - assignment2.dueDateGroup.unix()
      );
    });

    let accordionState: AccordionItemState = {};
    assignments.forEach(assignment => {
      accordionState = {
        ...accordionState,
        [assignment.dueDateGroup.format('MM.DD.YY')]: true
      };
    });
    setAccordionItemState(accordionState);
    setAssignmentsDueDate(assignments);
  }, [learningPlanListItems]);

  const groupAssigmentsByDueDate = (
    assignments: AssignmentsWithDueDate[],
    item: DueLesson | DueCourse | DueLearningPlan,
    type: DueTypeEnum
  ) => {
    item.dueDateMoment = absUtcDateToLocalMoment(item.dueDateUtc);

    const assignment = assignments.find(assignmentItem =>
      assignmentItem.dueDateGroup.isSame(item.dueDateMoment, 'day')
    );
    if (assignment) {
      addAssigmentToArray(assignment, item, type);
    } else {
      const newAssignment: AssignmentsWithDueDate = {
        dueDateGroup: absUtcDateToLocalMoment(item.dueDateUtc),
        completeGroup: item.isComplete,
        lessons: [],
        courses: [],
        learningPlans: []
      };
      addAssigmentToArray(newAssignment, item, type);
      assignments.push(newAssignment);
    }
  };

  const addAssigmentToArray = (
    assignment: AssignmentsWithDueDate,
    item: DueLesson | DueCourse | DueLearningPlan,
    type: DueTypeEnum
  ) => {
    if (type === DueTypeEnum.DueLesson) {
      assignment.lessons.push(item as DueLesson);
    } else if (type === DueTypeEnum.DueCourse) {
      assignment.courses.push(item as DueCourse);
    } else if (type === DueTypeEnum.DueLearningPlan) {
      assignment.learningPlans.push(item as DueLearningPlan);
    }
  };

  const handleAccordionChange = (event: any, isExpanded: boolean) => {
    const key = event.currentTarget.id;
    setAccordionItemState({ ...accordionItemState, [key]: isExpanded });
  };

  return (
    <>
      {!!assignmentsDueDate.length ? (
        accordionItemState &&
        assignmentsDueDate.map(item => {
          const dueDateItem = item.dueDateGroup.format('MM.DD.YY');
          const completeGroup = item.completeGroup;

          return (
            <BasicAccordion
              dueDate={dueDateItem}
              key={dueDateItem}
              expanded={accordionItemState[dueDateItem]}
              handleChange={handleAccordionChange}
              completeGroup={completeGroup}
            >
              <Grid
                container
                spacing={4}
                columns={{ xs: 1, md: 12, lg: 12 }}
                sx={containerStyle}
              >
                {!!item.learningPlans.length && (
                  <LearningPlanAssignments
                    dueLearningPlans={item.learningPlans}
                  />
                )}
                {!!item.courses.length && (
                  <CourseAssignments dueCourses={item.courses} />
                )}
                {!!item.lessons.length && (
                  <LessonAssignments dueLessons={item.lessons} />
                )}
              </Grid>
            </BasicAccordion>
          );
        })
      ) : (
        <Grid item>
          <h5>{t('noAssignments', { ns: 'assignments' })}</h5>
        </Grid>
      )}
    </>
  );
};

const containerStyle: SxProps = {
  paddingTop: '1rem'
};

export default AssignmentsByDueDate;
