import React, { ReactNode } from 'react';
import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import BasicButton from '../components/core/BasicButton/BasicButton';
import { TopMenuAccount } from '../components/TopMenu/TopMenuAccount';
import Box from '@mui/material/Box';
import AppLoader from '../components/core/AppLoader/AppLoader';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

const AccessDeniedContainer = ({ children }: { children: ReactNode }) => {
  return (
    <React.Suspense fallback="loading">
      <AppBar position={'static'}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div>
            <img
              src={'https://cdn.fs.brandfolder.com/4qGXTNoS46tZUdvFZpXg'}
              width={'10%'}
              alt={'BuildWitt logo'}
            />
          </div>
          <Box className={'topbar'}>
            <TopMenuAccount />
          </Box>
        </Toolbar>
      </AppBar>
      {children}
    </React.Suspense>
  );
};

export const AccessDeniedLoadingScreen = () => {
  return (
    <React.Suspense fallback="loading">
      <AppLoader variant={'linear'} />
    </React.Suspense>
  );
};

export const AccessDenied = () => {
  const { t } = useTranslation(['common'], { useSuspense: false });

  const auth = useAuth();
  if (auth.user && (window as any).ReactNativeWebView) {
    const payload = {
      access_token: auth.user.access_token,
      access_token_expires: auth.user.expires_at,
      refresh_token: auth.user.refresh_token,
      claims: Object.keys(auth.user.profile).map(claimKey => ({
        key: claimKey,
        value: auth.user?.profile[claimKey]
      }))
    };

    (window as any).ReactNativeWebView.postMessage(JSON.stringify(payload));
  }

  return (
    <AccessDeniedContainer>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        height={'calc(100vh - 64px)'}
      >
        <Grid item textAlign={'center'}>
          <Typography variant={'h4'}>
            {t('noSubscriptionMsg', { ns: 'common' })}
          </Typography>
          <Typography variant={'h6'}>
            {t('launchBWAppQuestion', { ns: 'common' })}
          </Typography>
          <BasicButton href={'buildwitt://app/login'} color={'primary'}>
            {t('launchApp', { ns: 'common' })}
          </BasicButton>
        </Grid>
      </Grid>
    </AccessDeniedContainer>
  );
};
