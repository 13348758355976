import {
  BlobServiceClient,
  BlobUploadCommonResponse,
  ContainerClient
} from '@azure/storage-blob';
import config from '../config';
import { Guid } from 'guid-typescript';
import { Question } from '../entities/Assessment';
import { QuestionTypes } from '../core/constants';
import { TransferProgressEvent } from '@azure/core-http';
import { SubtitlesDTO } from '../entities/Dto/createLessonDto';
import { Subtitles } from '../entities/LessonFormModel';
import { AzureBlobImageInfo } from '../entities/AzureBlobInfo';

const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File,
  blobName: string,
  onProgress?: (progress: TransferProgressEvent) => void,
  blockSize?: number
): Promise<BlobUploadCommonResponse> => {
  const blobClient = containerClient.getBlockBlobClient(blobName);
  let options: any = {
    blobHTTPHeaders: { blobContentType: file.type }
  };
  if (onProgress) {
    options = {
      ...options,
      onProgress: onProgress!,
      maxSingleShotSize: blockSize ? blockSize : 104857600
    };
  }

  return await blobClient.uploadData(file, options);
};

export const uploadCourseThumbToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_COURSEIMAGES_CONTAINER!);
};

export const uploadLessonThumbToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LESSONIMAGES_CONTAINER!);
};

export const uploadLessonSubtitleToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LESSONSUBTITLES_CONTAINER!);
};

export const uploadLessonDocToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LESSONDOCS_CONTAINER!);
};

export const uploadLearningPlanThumbToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LEARNINGPLANIMAGES_CONTAINER!);
};

export const uploadProfilePictureToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_CONTAINER_PROFILEPICTURES!);
};

export const uploadCompanyLogoToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_CONTAINER_COMPANY_LOGOS!);
};

export const uploadLargeFileToBlob = async (
  file: File,
  containerName: string,
  onProgress: (progress: TransferProgressEvent) => void,
  blockSize?: number
) => {
  return uploadFileToBlobName(file, containerName, onProgress, blockSize);
};

export const uploadFileToBlob = async (
  file: File,
  containerName: string,
  onProgress?: (progress: TransferProgressEvent) => void,
  blockSize?: number
) => {
  if (!File) throw new Error('file cannot be null or undefined');

  const blobUrlBase = `https://${config.BLOB_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(
    `${blobUrlBase}/?${config.BLOB_SAS_TOKEN}`
  );

  const containerClient = blobService.getContainerClient(containerName || '');
  const blobName = `bwo_${Guid.create().toString()}${file.name}`;

  const response = await createBlobInContainer(
    containerClient,
    file,
    blobName,
    onProgress,
    blockSize
  );

  if (response.errorCode !== undefined)
    throw new Error(`Blob storage error : ${response.errorCode || ''}`);

  return `${blobUrlBase}/${containerName}/${blobName}`;
};

export const uploadFileToBlobName = async (
  file: File,
  containerName: string,
  onProgress?: (progress: TransferProgressEvent) => void,
  blockSize?: number
) => {
  if (!File) throw new Error('file cannot be null or undefined');

  const blobUrlBase = `https://${config.BLOB_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(
    `${blobUrlBase}/?${config.BLOB_SAS_TOKEN}`
  );

  const containerClient = blobService.getContainerClient(containerName || '');
  const blobName = `bwo_${Guid.create().toString()}${file.name}`;

  const response = await createBlobInContainer(
    containerClient,
    file,
    blobName,
    onProgress,
    blockSize
  );

  if (response.errorCode !== undefined)
    throw new Error(`Blob storage error : ${response.errorCode || ''}`);

  return `${blobName}`;
};

export const checkUploadImageQuestion = async (questions: Question[]) => {
  for (let index = 0; index < questions.length; index++) {
    const currentQuestion = questions[index];
    if (currentQuestion.type === QuestionTypes.MultipleChoiceText) {
      try {
        if (currentQuestion.imageFile) {
          const imageUrl = await uploadCourseThumbToBlob(
            currentQuestion.imageFile
          );
          currentQuestion.imageUrl = imageUrl;
        }
      } catch (error) {
        throw error;
      }
    } else if (currentQuestion.type === QuestionTypes.MultipleChoiceImage) {
      if (currentQuestion.options) {
        for (
          let indexOptions = 0;
          indexOptions < currentQuestion.options.length;
          indexOptions++
        ) {
          const currentOption = currentQuestion.options[indexOptions];
          try {
            if (currentOption.imageFile) {
              const imageUrl = await uploadCourseThumbToBlob(
                currentOption.imageFile
              );
              currentOption.imageUrl = imageUrl;
            }
          } catch (error) {
            throw error;
          }
        }
      }
    }
  }
  return questions;
};

export const checkUploadSubtitle = async (subtitles: Subtitles[]) => {
  let subtitlesDTO: SubtitlesDTO[] = [];
  for (let index = 0; index < subtitles.length; index++) {
    const currentSubtitle = subtitles[index];
    let subtitleUrl = '';
    try {
      if (currentSubtitle.subtitleFile) {
        subtitleUrl = await uploadLessonSubtitleToBlob(
          currentSubtitle.subtitleFile
        );
      } else if (currentSubtitle.url) {
        subtitleUrl = currentSubtitle.url;
      }
      subtitlesDTO.push({
        id: currentSubtitle.id === '' ? Guid.EMPTY : currentSubtitle.id,
        language: currentSubtitle.language,
        url: subtitleUrl
      });
    } catch (error) {
      throw error;
    }
  }
  return subtitlesDTO;
};

const listThumbnailsFromContainer = async (
  containerName: string,
  listKey: string
) => {
  const result: AzureBlobImageInfo[] = [];
  const blobUrlBase = `https://${config.BLOB_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(`${blobUrlBase}/?${listKey}`);

  const containerClient = blobService.getContainerClient(containerName || '');
  for await (const blob of containerClient.listBlobsFlat()) {
    result.push({
      ...blob,
      url: `${blobUrlBase}/${containerName}/${blob.name}`
    });
  }
  return result;
};

export const getColorThumbnails = async () => {
  return await listThumbnailsFromContainer(
    config.BLOB_LESSONGALLERY_COLORCONTAINER!,
    config.BLOB_STORAGE_COLORCONTAINER_LIST_KEY!
  );
};

export const getBWThumbnails = async () => {
  return await listThumbnailsFromContainer(
    config.BLOB_LESSONGALLERY_BWCONTAINER!,
    config.BLOB_STORAGE_BWCONTAINER_LIST_KEY!
  );
};
