import { AppThunkAction } from '../store/index';
import axios from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  EMPLOYEE_UPDATE_PASSWORD,
  RESET_UPDATE_PASSWORD,
  EMPLOYEE_SET_EMPLOYEES,
  EMPLOYEE_SET_EMPLOYEE_GROUPS,
  EMPLOYEE_SET_EMPLOYEES_WORKLOAD,
  EMPLOYEE_UPDATE_TRANSACTION_STATUS,
  EMPLOYEE_RESET_TRANSACTION_STATUS,
  EMPLOYEE_SET_LEARNING_PROFILE,
  EMPLOYEE_SET_ASSIGNMENTS_STATUS,
  EMPLOYEE_DATA_IS_LOADING
} from './actionTypes';
import config from './../config';
import {
  UpdatePasswordModel,
  Employee,
  EmployeeGroup,
  EmployeeWorkload,
  EmployeeAssignmentsStatus
} from '../entities/Employee';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';

export interface UpdatePasswordAction {
  type: 'EMPLOYEE_UPDATE_PASSWORD';
  passwordUpdated: TransactionStatusEnum;
}

export interface ResetUpdatePasswordAction {
  type: 'RESET_UPDATE_PASSWORD';
}

export interface RequestEmployeesAction {
  type: 'EMPLOYEE_SET_EMPLOYEES';
  employees: Employee[];
}

export interface RequestEmployeeGroupsAction {
  type: 'EMPLOYEE_SET_EMPLOYEE_GROUPS';
  employeeGroups: EmployeeGroup[];
}

export interface RequestEmployeesWorkloadAction {
  type: 'EMPLOYEE_SET_EMPLOYEES_WORKLOAD';
  employeeWorkload: EmployeeWorkload[];
}

export interface UpdateEmployeeTransactionStatusAction {
  type: 'EMPLOYEE_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetEmployeeTransactionStatusAction {
  type: 'EMPLOYEE_RESET_TRANSACTION_STATUS';
}

export interface RequestEmployeeLearningProfileAction {
  type: 'EMPLOYEE_SET_LEARNING_PROFILE';
  employeesLearningProfile: Employee[];
}

export interface RequestEmployeeAssignmentsStatusAction {
  type: 'EMPLOYEE_SET_ASSIGNMENTS_STATUS';
  employeeAssignmentsStatus: EmployeeAssignmentsStatus[]
}

export interface EmployeeDataIsLoadingAction {
  type: 'EMPLOYEE_DATA_IS_LOADING';
  dataIsLoading: boolean
}

export const actionCreators = {
  updatePassword: (
    updatePassword: UpdatePasswordModel
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));
      dispatch({ type: RESET_UPDATE_PASSWORD });

      const res = await axios.put(
        `${config.AUTHORITY_API_URL!}users/updatePasword`,
        updatePassword
      );
      dispatch({
        type: EMPLOYEE_UPDATE_PASSWORD,
        passwordUpdated: res.data
          ? TransactionStatusEnum.Successfull
          : TransactionStatusEnum.Failed
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestEmployees: (companyId: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const res = await axios.get(
        `${config.AUTHORITY_API_URL!}employee/employee-list?companyId=${companyId}`
      );

      dispatch({
        type: EMPLOYEE_SET_EMPLOYEES,
        employees: res.data
      });

      dispatch(appActions.setIsLoading(false));
    }
  },
  requestEmployeeGroups: (
    companyId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const res = await axios.get(
        `${config.AUTHORITY_API_URL!}employee-groups/${companyId}`
      );

      dispatch({
        type: EMPLOYEE_SET_EMPLOYEE_GROUPS,
        employeeGroups: res.data
      });

      dispatch(appActions.setIsLoading(false));
    }
  },
  requestEmployeesWorkload: (
    companyId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}employeeworkloads/by-company/${companyId}`
        );

        dispatch({
          type: EMPLOYEE_SET_EMPLOYEES_WORKLOAD,
          employeeWorkload: res.data
        });
      } catch (e) {
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  setGroupWorkload: (
    employeeList: string[],
    lesssonPerDay: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      let resStatus: number = 0;
      try {
        const res = await axios.put(
          `${config.STUDENT_API_URL!}employeeworkloads/batch/${lesssonPerDay}`,
          employeeList
        );

        resStatus = res.status;
        const resGet = await axios.get(
          `${config.STUDENT_API_URL!}employeeLearningProfile/all`
        );

        dispatch({
          type: EMPLOYEE_SET_LEARNING_PROFILE,
          employeesLearningProfile: resGet.data
        });
      } catch (e) {
      } finally {
        dispatch({
          type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  setEmployeeWorkload: (
    employeeId: string,
    lesssonPerDay: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      let resStatus: number = 0;
      try {
        const res = await axios.put(
          `${config.STUDENT_API_URL!}employeeworkloads/${employeeId}/${lesssonPerDay}`,
          undefined
        );

        resStatus = res.status;
        if (resStatus == HttpStatusEnum.OK)
        {
          const resGet = await axios.get(
            `${config.STUDENT_API_URL!}employeeLearningProfile/all`
          );
  
          dispatch({
            type: EMPLOYEE_SET_LEARNING_PROFILE,
            employeesLearningProfile: resGet.data
          });
        }
      } catch (e) {
      } finally {
        dispatch({
          type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  requestEmployeesLearningProfile: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}employeeLearningProfile/all`
        );

        dispatch({
          type: EMPLOYEE_SET_LEARNING_PROFILE,
          employeesLearningProfile: res.data
        });
      } catch (e) {
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  requestEmployeeAssignmentsStatus: (
    assignmentType: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: EMPLOYEE_DATA_IS_LOADING,
        dataIsLoading: true
      });

      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}assignments/${assignmentType}/status`
        );

        dispatch({
          type: EMPLOYEE_SET_ASSIGNMENTS_STATUS,
          employeeAssignmentsStatus: res.data
        });
      } catch (e) {
      } finally {
        dispatch({
          type: EMPLOYEE_DATA_IS_LOADING,
          dataIsLoading: false
        });
      }
    }
  },
  setEmployeeStartTime: (
    employeeId: string,
    dailyStartTimeMinutesUtc: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      let resStatus: number = 0;
      try {
        const res = await axios.put(
          `${config.STUDENT_API_URL!}employeetimeshiftstart/${employeeId}/${dailyStartTimeMinutesUtc}`,
          undefined
        );

        resStatus = res.status;
        if (resStatus == HttpStatusEnum.OK){
          const resGet = await axios.get(
            `${config.STUDENT_API_URL!}employeeLearningProfile/all`
          );
  
          dispatch({
            type: EMPLOYEE_SET_LEARNING_PROFILE,
            employeesLearningProfile: resGet.data
          });
        }
      } catch (e) {} 
      finally {
        dispatch({
          type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  setGroupStartTime: (
    employeeList: string[],
    startTimeMinutes: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      let resStatus: number = 0;
      try {
        const res = await axios.put(
          `${config.STUDENT_API_URL!}employeetimeshiftstart/batch/${startTimeMinutes}`,
          employeeList
        );

        resStatus = res.status;
        if (resStatus === HttpStatusEnum.OK)
        {
          const resGet = await axios.get(
            `${config.STUDENT_API_URL!}employeeLearningProfile/all`
          );
  
          dispatch({
            type: EMPLOYEE_SET_LEARNING_PROFILE,
            employeesLearningProfile: resGet.data
          });
        }
      } catch (e) {
      } finally {
        dispatch({
          type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  resetTransactionStatus: () => ({
    type: EMPLOYEE_RESET_TRANSACTION_STATUS
  }),
  resetUpdatePassword: () =>
    ({
      type: RESET_UPDATE_PASSWORD
    } as ResetUpdatePasswordAction)
};

export type KnownAction =
  | UpdatePasswordAction
  | ResetUpdatePasswordAction
  | RequestEmployeesAction
  | RequestEmployeeGroupsAction
  | RequestEmployeesWorkloadAction
  | UpdateEmployeeTransactionStatusAction
  | ResetEmployeeTransactionStatusAction
  | RequestEmployeeLearningProfileAction
  | RequestEmployeeAssignmentsStatusAction
  | EmployeeDataIsLoadingAction
  | SetLoadingAction;
