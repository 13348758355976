import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Course } from '../../entities/Course';
import CourseCard from '../CourseCard/CourseCard';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { useTranslation } from 'react-i18next';

export interface CourseCardListProps {
  courses: Course[];
  cardComplement: React.FC<{course: Course, cardAuxiliarFunction?: (id?: string) => void}>;
  shouldShowDrafCourses: Boolean;
  cardAuxiliarFunction?: (id?: string) => void;
}

const CourseCardList: React.FC<CourseCardListProps> = ({
  courses,
  cardComplement: Component,
  shouldShowDrafCourses,
  ...restProps
}) => {
  const user = useCurrentUser();
  const userCompanyId = user?.profile[UserClaims.CompanyId];
  const { t } = useTranslation(['courses']);

  if (!shouldShowDrafCourses) {
    courses = courses.filter(c => !c.isDraft);
  }

  return (
    <div style={containerStyle}>
        <Row>
          {!!courses.length ? (
            courses.map(course => (
              <Col xs={12} sm={12} md={4} lg={4} style={cardItemStyle} key={course.id}>
                <CourseCard
                  width="100%"
                  height="325px"
                  course={course}
                  showEdit={(course.companyId === userCompanyId)}
                >
                  <Component course={course} {...restProps} />
                </CourseCard>
              </Col>
            ))
          ) : (
            <h3>{t('noCoursesAvailableMsg', {ns: 'courses'})}</h3>
          )}
        </Row>
    </div>
  );
};

const containerStyle: CSSProperties = {
  width: '100%',
};

const cardItemStyle = {
  paddingTop: '10px',
  paddingBottom: '17px'
};
export default CourseCardList;
