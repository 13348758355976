import React from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import BasicButton from '../../components/core/BasicButton/BasicButton';
import { actionCreators as appActions } from '../../actions/appState';
import { actionCreators as companyActions } from '../../actions/companies';
import { ApplicationState } from '../../store';
import { CompanyProfileDTO } from '../../entities/Dto/createCompanyDto';
import { SimpleFormPage } from '../../components/Pages';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { NotAvailablePage } from '../../components/NotAvailablePage/NotAvailablePage';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BasicButtonTypeEnum } from '../../core/enums';
import { CompanyProfileForm } from './CompanyProfileForm';
import { CompanyProfileButtonStyles } from './companyProfileStyles';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyProfileValidationSchema } from './CompanyProfileValidationSchema';

export const EditCompanyProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, watch, formState, reset, setValue } =
    useForm<CompanyProfileDTO>({
      resolver: yupResolver(CompanyProfileValidationSchema)
    });
  const watchIsCompanyVerify = watch('isCompanyVerify');

  const { companyProfileId } = useParams<{ companyProfileId: string }>();

  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const industryCategories = useSelector(
    (state: ApplicationState) => state.appState?.industryCategories
  );

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  React.useEffect(() => {
    dispatch(appActions.getIndustryCategories(companyProfileId));
  }, []);

  React.useEffect(() => {
    if (currentCompanyProfile) {
      reset({
        ...currentCompanyProfile
      });
    }
  }, [currentCompanyProfile]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  if (!isLoading && industryCategories && !industryCategories.length) {
    return <NotAvailablePage />;
  }

  const goToCompanyProfile = () => {
    history.push(`/company/profile/${companyProfileId}`);
  };

  const onSubmit: SubmitHandler<CompanyProfileDTO> = data => {
    dispatch(companyActions.updateDWCompany(companyProfileId, data, history));
  };

  return (
    <SimpleFormPage title={"Let's get your Company set up"}>
      <CompanyProfileForm
        title="Edit your Company Profile"
        submitHandler={onSubmit}
        watch={watch}
        handleSubmit={handleSubmit}
        setValue={setValue}
        formState={formState}
        control={control}
        industryCategories={industryCategories}
        bottomActions={
          <Stack direction="row" sx={{ textAlign: 'center' }} spacing={2}>
            <BasicButton
              onClick={goToCompanyProfile}
              color="primary"
              style={CompanyProfileButtonStyles}
            >
              Cancel
            </BasicButton>
            <BasicButton
              type={BasicButtonTypeEnum.Submit}
              color="primary"
              style={CompanyProfileButtonStyles}
              disable={!watchIsCompanyVerify}
            >
              Save Changes
            </BasicButton>
          </Stack>
        }
      />
    </SimpleFormPage>
  );
};
