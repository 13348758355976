import React, { CSSProperties, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import useCurrentUser, { useUserRole } from '../../hooks/useCurrentUser';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import TopEmployees from './TopEmployees';
import WeeklyStatistics from './WeeklyStatistics';
import { UserClaims, UserRoles } from '../../core/constants';
import DailyLessons from '../assignments/DailyLessons';
import { CircularProgress } from '@mui/material';
import { ApplicationState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as studentActions } from '../../actions/student';
import AssignmentsByDueDateAsIs from '../assignments/AssignmentsByDueDateAsIs';
import { NavLink } from 'react-router-dom';

const StyleTab = styled(Tab)<TabProps>(() => ({
  '&.Mui-selected': {
    backgroundColor: 'transparent !important'
  }
}));

const Dashboard = () => {
  const theme = useTheme();
  const [userHasRole] = useUserRole();
  const dispatch = useDispatch();
  const [value, setValue] = useState<string>('admin');
  const { t } = useTranslation(['common', 'dashboard', 'assignments']);
  const user = useCurrentUser();

  const learningPlayList = useSelector(
    (state: ApplicationState) => state.student?.learningPlayList
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const employeeId = user?.profile[UserClaims.EmployeeId] as string;

  useEffect(() => {
    if (employeeId) {
      dispatch(studentActions.getEmployeePlaylist(employeeId));
    }
  }, [employeeId]);

  const fontTab: CSSProperties = {
    fontSize: '20px',
    textTransform: 'capitalize',
    color: theme.palette.primary.main
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setValue(newValue);
  };

  const AdminTabs = (
    <TabContext value={value}>
      <Box sx={boxStyle}>
        <TabList onChange={handleChange} aria-label="Dashboard Tab">
          <StyleTab
            label={
              <Typography sx={fontTab}>
                {t('admin', { ns: 'dashboard' })}
              </Typography>
            }
            value="admin"
          />
          <StyleTab
            label={
              <Typography sx={fontTab}>
                {t('myLearning', { ns: 'dashboard' })}
              </Typography>
            }
            value="learning"
          />
        </TabList>
      </Box>
      <TabPanel value="admin" sx={tabPanelStyle}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item container direction={'column'} xs={12} lg={8} xl={9}>
              <Grid item>
                <Overview />
                <br />
                <WeeklyStatistics />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <TopEmployees />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value="learning" sx={tabPanelStyle}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
              <Typography sx={titlesStyle}>
                {t('dailyLessons', { ns: 'assignments' })}
              </Typography>

              {!isLoading && learningPlayList ? (
                <DailyLessons
                  isHorizontalView={true}
                  dailyLessons={learningPlayList?.dailyLessons}
                />
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
              <br />
              <br />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Typography sx={titlesStyle}>
                  {t('dueDates', { ns: 'dashboard' })}
                </Typography>
                <NavLink
                  className="nav-link"
                  activeClassName="current-link"
                  to={'/assignments'}
                >
                  <Typography sx={allAssignmentsStyle}>
                    {t('seeAllAssignments', { ns: 'dashboard' })}
                  </Typography>
                </NavLink>
              </div>
              <br />
              {!isLoading && learningPlayList ? (
                <AssignmentsByDueDateAsIs
                  learningPlanListItems={learningPlayList}
                />
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <div style={verticalLine}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
              <Typography sx={titlesStyle}>
                {t('progress', { ns: 'dashboard' })}
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </TabContext>
  );

  const EmployeeTab = (
    <TabContext value={'learning'}>
      <Box sx={boxStyle}>
        <TabList onChange={handleChange} aria-label="Dashboard Tab">
          <StyleTab
            label={
              <Typography sx={fontTab}>
                {t('myLearning', { ns: 'dashboard' })}
              </Typography>
            }
            value="learning"
          />
        </TabList>
      </Box>
      <TabPanel value="learning" sx={tabPanelStyle}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
              <Typography sx={titlesStyle}>
                {t('dailyLessons', { ns: 'assignments' })}
              </Typography>

              {!isLoading && learningPlayList ? (
                <DailyLessons
                  isHorizontalView={true}
                  dailyLessons={learningPlayList?.dailyLessons}
                />
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
              <br />
              <br />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Typography sx={titlesStyle}>
                  {t('dueDates', { ns: 'dashboard' })}
                </Typography>
                <NavLink
                  className="nav-link"
                  activeClassName="current-link"
                  to={'/assignments'}
                >
                  <Typography sx={allAssignmentsStyle}>
                    {t('seeAllAssignments', { ns: 'dashboard' })}
                  </Typography>
                </NavLink>
              </div>
              <br />
              {!isLoading && learningPlayList ? (
                <AssignmentsByDueDateAsIs
                  learningPlanListItems={learningPlayList}
                />
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
              <div style={verticalLine}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
              <Typography sx={titlesStyle}>
                {t('progress', { ns: 'dashboard' })}
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </TabContext>
  );

  return (
    <>
      <PageContainer>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Box sx={boxContainerStyle}>
              {userHasRole(UserRoles.CompanyAdmin) ? AdminTabs : EmployeeTab}
            </Box>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};

const boxContainerStyle: SxProps = {
  width: '100%',
  typography: 'body1'
};

const boxStyle: SxProps = {
  borderBottom: 1,
  borderColor: 'divider'
};

const tabPanelStyle: SxProps = {
  paddingLeft: '0',
  paddingRight: '0'
};

const titlesStyle: SxProps = {
  fontSize: '30px',
  marginBottom: '17px'
};

const allAssignmentsStyle: SxProps = {
  fontSize: '16px',
  marginTop: '10px',
  color: '#929292'
};

const verticalLine: CSSProperties = {
  borderLeft: '1px',
  borderLeftStyle: 'solid',
  borderLeftColor: '#99999980',
  height: '100%'
};

export default Dashboard;
