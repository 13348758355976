import React from 'react';
import { Guid } from 'guid-typescript';
import { useDispatch, useSelector } from 'react-redux';
import { TransferProgressEvent } from '@azure/core-http';

import { actionCreators } from '../../actions/lessons';
import { LessonQuiz } from '../../core/constants';
import { ContentMedium, LessonFormModel } from '../../entities/LessonFormModel';
import { getCall } from '../../services/rest-service';
import config from '../../config';
import {
  checkUploadSubtitle,
  uploadLargeFileToBlob
} from '../../services/blobStorage-service';
import { AssetInfo } from '../../entities/assetInfo';
import { actionCreators as tagActions } from '../../actions/tags';
import { CreateLessonDto } from '../../entities/Dto/createLessonDto';
import HttpStatusError from '../../core/CustomErrors/HttpRequestError';
import { useTranslation } from 'react-i18next';
import { actionCreators as appActions } from '../../actions/appState';
import { ApplicationState } from '../../store';
import { actionCreators as courseActions } from '../../actions/courses';
import { useParams } from 'react-router';

export const useLesson = (isInCourses: boolean) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'lessons', 'quizzes']);
  const params: any = useParams();
  const [createError, setCreateError] = React.useState<string>('');
  const [uploadVideoProgress, setUploadVideoProgress] = React.useState<number>(
    0
  );
  const [refresh, setRefresh] = React.useState(0);
  const doRefresh = () => setRefresh(current => current + 1);

  const [showAddLessonModal, setShowAddLessonModal] = React.useState<boolean>(
    false
  );
  const [showAssessmentModal, setShowAssessmentModal] = React.useState<boolean>(
    false
  );

  const [showEditLessonModal, setShowEditLessonModal] = React.useState<boolean>(
    false
  );

  const [showAssignLessonModal, setShowAssignLessonModal] = React.useState<
    boolean
  >(false);

  const [isEdit, setIsEdit] = React.useState(false);

  const courseLessonNames = useSelector(
    (state: ApplicationState) => state.courses?.courseLessonNames
  );

  const userProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );

  const closeLessonModal = () => {
    setShowAddLessonModal(false);
    // TODO: uncomment the next line when assessments are part of community lessons
    // setShowAssessmentModal(true);
  };

  const handleUploadProgress = (
    progress: TransferProgressEvent,
    file: File
  ) => {
    const fileTotalBytes = file?.size;
    const valueProgress = Math.floor(
      (progress.loadedBytes / fileTotalBytes!) * 100
    );
    setUploadVideoProgress(valueProgress);
  };

  const handleSaveLesson = async (
    lessonData: LessonFormModel,
    isEdit = true
  ) => {
    setCreateError('');
    if (lessonData) {
      if (lessonData.contentMedium === ContentMedium.Quiz && !isEdit) {
        dispatch(
          actionCreators.setCurrentLesson({
            assessmentId: Guid.EMPTY,
            id: '',
            title: lessonData.title,
            description: '',
            order: 0,
            companyId: '',
            courseId: '',
            contentMedium: lessonData.contentMedium,
            videoUrl: '',
            videoId: '',
            thumbnailUrl: lessonData.thumbnailUrl,
            learningMaterialUrl: '',
            externalVideoUrl: '',
            lessonType: LessonQuiz,
            tags: [],
            subtitles: [],
            isVideoEncoding: false,
            isEncodingFailed: false
          })
        );
        return;
      }

      let upVideoUrl = '';
      let assetName = '';
      setUploadVideoProgress(1);
      if (!lessonData.externalVideoUrl && lessonData.video) {
        const assetUrl = await getCall(
          `${config.COURSES_API_URL!}lessons/asset?name=${
            'vid_' + Guid.create()
          }`
        );
        const assetInfo: AssetInfo = assetUrl.data;
        assetName = assetInfo.assetName;
        upVideoUrl = await uploadLargeFileToBlob(
          lessonData.video,
          assetInfo.containerName,
          (progress: TransferProgressEvent) =>
            handleUploadProgress(progress, lessonData.video!)
        ); //upload video file
      }

      const lessonDto: CreateLessonDto = {
        title: lessonData.title,
        description: lessonData.description,
        order: 0,
        externalVideoUrl: lessonData.externalVideoUrl,
        tags: lessonData.tags,
        companyId: '',
        contentMedium: lessonData.contentMedium,
        thumbnailUrl: lessonData.thumbnailUrl,
        documentUrl: '',
        lessonFileName: '',
        lessonFileContentType: '',
        assetName: assetName,
        assessmentId: lessonData.assessmentId,
        subtitles: [],
        userProfileId: userProfile?.id
      };

      if (lessonData.subtitles) {
        const checkupload = await checkUploadSubtitle(lessonData.subtitles);
        lessonDto.subtitles = checkupload;
      }

      try {
        let createdLessonId = '';
        if (isInCourses) {
          createdLessonId = ((await dispatch(
            actionCreators.saveLessonForCourse(
              lessonDto,
              lessonData.thumbnailFile!,
              lessonData.pdfFile!,
              params.id
            )
          )) as unknown) as string;
        } else {
          createdLessonId = ((await dispatch(
            actionCreators.saveLesson(
              lessonDto,
              lessonData.thumbnailFile!,
              lessonData.pdfFile!
            )
          )) as unknown) as string;
        }

        await dispatch(tagActions.requestTags());

        if (isInCourses) {
          await dispatch(courseActions.requestCourseLessonNames(params.id));
        } else {
          await dispatch(actionCreators.requestCompanyLessons());
        }

        await dispatch(actionCreators.requestCompanyLessons());
        closeLessonModal();

        return createdLessonId;
      } catch (e) {
        if (!(e instanceof HttpStatusError)) {
          const error = e as any;
          const errorMessage = !error.response
            ? t('networkError', { ns: 'common' })
            : t('anErrorOccurred', { ns: 'common' });
          setCreateError(errorMessage);
        }
        throw e;
      }
    }
  };

  const handleEditLesson = async (lessonId: string) => {
    dispatch(appActions.setIsLoading(true));
    setIsEdit(true);

    if (isInCourses) {
      await dispatch(actionCreators.requestLessonByEntryId(lessonId));
    } else {
      await dispatch(actionCreators.requestLessonById(lessonId));
    }

    dispatch(appActions.setIsLoading(false));

    if (isInCourses) {
      if (
        courseLessonNames &&
        courseLessonNames?.filter(x => x.isEncoding && x.id == lessonId)
          .length > 0
      ) {
        alert(t('lessonVideoIsEncoding', { ns: 'lessons' }));
      } else {
        setShowEditLessonModal(true);
      }
    } else {
      setShowEditLessonModal(true);
    }
  };

  const handleAssessmentGoBack = (lessonId: string) => {
    setShowAssessmentModal(false);

    if (isEdit) {
      handleEditLesson(lessonId);
    } else {
      setShowAddLessonModal(true);
    }
  };

  const handleCloseEditModal = async () => {
    dispatch(actionCreators.setCurrentLesson(null));
    setShowEditLessonModal(false);
  };

  const handleFinishEditLesson = () => {
    setShowEditLessonModal(false);
    setShowAssessmentModal(true);
  };

  const handleCloseAssessmentModal = () => {
    dispatch(actionCreators.setCurrentLesson(null));
    setShowAssessmentModal(false);
  };

  const handleCloseEditModalOnLessonDeletion = async () => {
    setShowEditLessonModal(false);
    doRefresh();
  };

  const handleOpenAssignLessonModal = async (lessonId: string) => {
    dispatch(appActions.setIsLoading(true));
    await dispatch(actionCreators.requestLessonById(lessonId));

    dispatch(appActions.setIsLoading(false));
    setShowAssignLessonModal(true);
  };

  const handleCloseAssignLessonModal = () => {
    dispatch(actionCreators.setCurrentLesson(null));
    setShowAssignLessonModal(false);
    doRefresh();
  };

  const handleAssignLessonGoBack = (lessonId: string) => {
    setShowAssignLessonModal(false);
    setShowAssessmentModal(true);
  };

  return {
    api: {
      handleSaveLesson,
      handleAssessmentGoBack,
      handleEditLesson,
      handleCloseEditModal,
      handleFinishEditLesson,
      handleCloseAssessmentModal,
      setShowAddLessonModal,
      handleOpenAssignLessonModal,
      closeLessonModal,
      setShowEditLessonModal,
      handleCloseEditModalOnLessonDeletion,
      handleCloseAssignLessonModal,
      handleAssignLessonGoBack,
      setIsEdit
    },
    state: {
      isEdit,
      uploadVideoProgress,
      showAddLessonModal,
      showAssessmentModal,
      showAssignLessonModal,
      showEditLessonModal,
      createError,
      refresh
    }
  };
};
