import React, {CSSProperties} from 'react';
import { useTheme } from '@mui/material/styles';

export interface PanelProps {
  width: string;
  height?: string;
  paddingLeft?: string;
  paddingRight?: string;
}

const Panel: React.FC<PanelProps> = ({ width, height, paddingLeft = '20px', paddingRight = '20px', children }) => {
  const theme = useTheme();

  const cardStyle: CSSProperties = {
    backgroundColor: `${theme.palette.secondary.main}`,
    borderRadius: '14px',
    borderColor: `${theme.palette.grey[100]}`,
    borderStyle: 'solid',
    borderWidth: '1px',
    display: 'flex',
    minWidth: '200px',
    minHeight: '200px',
    flexDirection: 'column' as 'column'
  };

  return (
    <div style={{ ...cardStyle, width: width, height: height, paddingLeft: paddingLeft, paddingRight: paddingRight  }}>{children}</div>
  );
};

export default Panel;
