import React, { CSSProperties } from 'react';
import { Grid, Typography, Stack, Divider } from '@mui/material';
import profileRightBG from '../../assets/profileRightBG.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Link, useHistory } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { useSelector } from 'react-redux';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { Card } from '../../components/Card';

const RightBannerInfo = () => {
  const history = useHistory();

  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  if (isLoading) {
    <FallbackLoader />;
  }

  return (
    <div>
      <Card
        component={'a'}
        href={'https://dirtworld.com/jobs'}
        target="_blank"
        rel="noreferrer"
        style={{
          margin: 'auto',
          flexGrow: 1,
          backgroundImage: `url(${profileRightBG})`,
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '310px',
          display: 'block'
        }}
        padding={'small'}
      >
        <div style={containerDWSummit}>
          <Grid item xs={12} md={12} sx={{ width: '100%' }}>
            <div style={dwSummitTopStyle}>
              <Typography gutterBottom style={infoIStyle}>
                Find Your
                <br />
                Next Career
              </Typography>
              <Typography style={infoIIStyle}>
                Browse Jobs <ArrowForwardIosIcon style={{ fontSize: '14px' }} />
              </Typography>
            </div>
          </Grid>
        </div>
      </Card>

      <br />

      <Card gridProps={{ spacing: 2 }} padding={'small'}>
        <Grid item xs={12} sm={12} mb={1}>
          <Link
            to={{
              pathname: 'https://dirtworld.com/tell-us-your-story'
            }}
            target="_blank"
            style={infoIVStyle}
          >
            <Typography gutterBottom style={infoVIStyle}>
              Community
            </Typography>
            <Typography gutterBottom style={infoIIIStyle}>
              How’d you get into dirt?
            </Typography>
            <Typography style={infoIVStyle}>
              Tell us your story{' '}
              <ArrowForwardIosIcon
                style={{ fontSize: '16px', color: '#262625' }}
              />
            </Typography>
          </Link>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} mb={1}>
          <Link
            to={{
              pathname: 'https://store.dirtworld.com/discount/DIRTWORLDMEMBER10'
            }}
            target="_blank"
            style={infoIVStyle}
          >
            <Typography gutterBottom style={infoIIIStyle}>
              Use your members-only discount in the Dirt World Store
            </Typography>
            <Typography style={infoIVStyle}>
              Visit the Store and Get 10% off
              <ArrowForwardIosIcon
                style={{ fontSize: '16px', color: '#262625' }}
              />
            </Typography>
          </Link>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={12} mb={1}>
          <Link
            to={{
              pathname:
                'https://www.facebook.com/groups/betterdirtworldcommunity'
            }}
            target="_blank"
            style={infoIVStyle}
          >
            <Typography gutterBottom style={infoIIIStyle}>
              Hear from your peers
            </Typography>
            <Typography style={infoIVStyle}>
              Join the Dirt World facebook group{' '}
              <ArrowForwardIosIcon
                style={{ fontSize: '16px', color: '#262625' }}
              />
            </Typography>
          </Link>
        </Grid>
      </Card>

      <br />

      <Card gridProps={{ spacing: 2 }} padding={'small'}>
        <Grid item xs={12} sm={12}>
          {currentCompanyProfile ? (
            <>
              <Link
                to={`/company/profile/${currentCompanyProfile?.id}`}
                style={{ padding: '0px' }}
              >
                <Stack alignItems={'flex-start'}>
                  <Typography style={infoIIIStyle}>
                    <AddCircleRoundedIcon /> Edit {currentCompanyProfile?.name}
                    <ArrowForwardIosIcon
                      style={{ fontSize: '16px', color: '#262625' }}
                    />
                  </Typography>
                </Stack>
              </Link>
            </>
          ) : (
            <>
              <Link to={`/company/profile/create`} style={{ padding: '0px' }}>
                <Stack direction="row" spacing={2}>
                  <AddCircleRoundedIcon
                    style={{ fontSize: '40px', color: '#262625' }}
                  />
                  <Stack alignItems={'flex-start'}>
                    <Typography style={infoIIIStyle}>
                      Are you an employer?
                    </Typography>
                    <Typography style={infoIVStyle}>
                      Create a company profile
                      <ArrowForwardIosIcon
                        style={{ fontSize: '16px', color: '#262625' }}
                      />
                    </Typography>
                  </Stack>
                </Stack>
              </Link>
            </>
          )}
        </Grid>
      </Card>
    </div>
  );
};

const containerDWSummit: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  height: '100%'
};

const dwSummitTopStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '90%',
  marginLeft: 20,
  marginTop: 20
};

const infoIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '36px',
  color: '#262625'
};

const infoIIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  color: '#262625'
};

const infoIIIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#262625'
};

const infoIVStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(38, 38, 37, 0.7)',
  textTransform: 'capitalize',
  marginBottom: '2px',
  marginRight: '2px'
};

const infoVIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '36px',
  lineHeight: '55px',
  color: '#242926'
};

export default RightBannerInfo;
