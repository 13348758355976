import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { ApplicationState } from '../../store';
import { AdditionalInformationSection } from './AdditionalInformation/AdditionalInformationSection';
import { CertificationSection } from './Certification/CertificationSection';
import { EducationSection } from './Education/EducationSection';
import { IdentificationSection } from './Identification/IdentificationSection';
import { JobApplicationQuestionsSection } from './JobApplicationQuestions/JobApplicationQuestionsSection';
import { WorkExperienceSection } from './WorkExperience/WorkExperienceSection';
import { actionCreators } from '../../actions/userProfile';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import RightBannerInfo from './RightBannerInfo';
import { Page } from '../../components/Pages';

export const EditDirtWorldProfile = () => {
  const dispatch = useDispatch();
  const currentUserProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const user = useCurrentUser();
  const userAccountId = user?.profile[UserClaims.UserId] as string;

  React.useEffect(() => {
    dispatch(actionCreators.getCurrentUserProfileByUserId(userAccountId));
  }, [dispatch, userAccountId, currentUserProfile?.id]);

  if (isLoading) {
    <FallbackLoader />;
  }

  return (
    <Page>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <IdentificationSection />
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack spacing={2}>
            <WorkExperienceSection
              workExperiences={currentUserProfile?.workExperiences}
            />
            <EducationSection educations={currentUserProfile?.educations} />
            <CertificationSection
              certifications={currentUserProfile?.certifications}
            />
            {/*Commenting temporarily for MVP.
         
         <AdditionalInformationSection />
          <JobApplicationQuestionsSection /> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <RightBannerInfo />
        </Grid>
      </Grid>
    </Page>
  );
};
