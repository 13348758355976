import React, { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

function createData(name: string, lessonsComplete: number, jobTitle: string) {
  return { name, lessonsComplete, jobTitle };
}
// Mock data for list
const rows = [
  createData('Enzo Ferrari', 52, 'Il Commendatore'),
  createData('Ferruccio Lamborghini', 67, 'Founder'),
  createData('Bruce Mclaren', 13, 'Racing Driver'),
  createData('Nicola Romeo', 56, 'Co-Founder'),
  createData('Henry Ford', 35, 'Founder'),
  createData('Colin Chapman', 46, 'Design Engineer'),
  createData('Carl Benz', 46, 'Founder'),
  createData('Carl Benz', 46, 'Founder'),
  createData('Carl Benz', 46, 'Founder'),
  createData('Carl Benz', 46, 'Founder')
];

const StyledListItem = styled(ListItem)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#282828'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#1B1C1C'
  },
  '&:last-child': {
    borderRadius: '14px'
  },
  height: 65
}));

const ListEmployee = () => {
  return (
    <List style={styledListStyle}>
      {rows.map((row, index) => (
        <StyledListItem
          key={`${index}-${row.name}`}
          secondaryAction={
            <Typography style={completeLessonsStyle}>
              {row.lessonsComplete}
            </Typography>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <img
                className={`img-profile rounded-circle my-4`}
                width="40"
                height="40"
                alt={`${row.name} ${row.jobTitle}`}
                src={require('../../assets/user.png').default}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography style={nameStyle}>{row.name}</Typography>}
            secondary={
              <Typography style={jobTitleStyle}>{row.jobTitle}</Typography>
            }
          />
        </StyledListItem>
      ))}
    </List>
  );
};

const nameStyle: CSSProperties = {
  color: '#FFFFFFDE',
  fontSize: '14px'
};

const jobTitleStyle: CSSProperties = {
  color: '#999999',
  fontSize: '12px'
};

const completeLessonsStyle: CSSProperties = {
  color: '#FFFFFFDE',
  fontSize: '24px'
};

const styledListStyle: CSSProperties = {
  paddingTop: '0px',
  paddingBottom: '0px'
};

export default ListEmployee;
