import React, { useState, useEffect, CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import SearchBox from '../../components/core/SearchBox/SearchBox';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { actionCreators as employeeActions } from '../../actions/employees';
import { ApplicationState } from '../../store';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { EmployeeAssignmentsStatus } from '../../entities/Employee';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../core/constants';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import { lowerCaseFirstLetter } from '../../utils/stringUtils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ManageEmployeeAssignmentsModal from '../../components/ManageEmployeeAssignmentsModal';

export interface LearningTableProps {}

const LearningTable: React.FC<LearningTableProps> = () => {
  const theme = useTheme();
  const { t } = useTranslation([
    'common',
    'lessons',
    'courses',
    'learningPlans',
    'employees',
    'assignments',
    'quizzes'
  ]);
  const dispatch = useDispatch();
  const [selectedAssignmentType, setSelectedAssignmentType] =
    useState<string>('Lessons');
  const [selectedEmployeeRow, setSelectedEmployeeRow] =
    useState<EmployeeAssignmentsStatus>();
  const clearSelectedEmployee = () => setSelectedEmployeeRow(undefined);
  const [dataSource, setDataSource] = useState<EmployeeAssignmentsStatus[]>([]);
  const employeeAssignmentsStatus = useSelector(
    (state: ApplicationState) => state?.employees?.employeeAssignmentsStatus
  );
  const dataIsLoading = useSelector(
    (state: ApplicationState) => state?.employees?.dataIsLoading
  );
  let rowCounter = 0;
  let searchTimeout: any = null;

  useEffect(() => {
    dispatch(
      employeeActions.requestEmployeeAssignmentsStatus(
        lowerCaseFirstLetter(selectedAssignmentType)
      )
    );
  }, [selectedAssignmentType]);

  useEffect(() => {
    if (employeeAssignmentsStatus) {
      setDataSource(employeeAssignmentsStatus);
    }
  }, [employeeAssignmentsStatus]);

  const entityTypes = [
    { label: t('lessons', { ns: 'lessons' }), value: 'Lessons' },
    { label: t('courses', { ns: 'courses' }), value: 'Courses' },
    {
      label: t('learningPlans', { ns: 'learningPlans' }),
      value: 'LearningPlans'
    },
    { label: t('quizzes', { ns: 'quizzes' }), value: 'Quizzes' }
  ];

  const columns: GridColDef[] = [
    {
      field: 'employeeId',
      headerName: t('employee', { ns: 'employees' }),
      flex: 1,
      minWidth: 350,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div className="d-flex ps-1">
          <img
            className={`img-profile rounded-circle`}
            width="36"
            height="36"
            alt={`${params.row.firstName} ${params.row.lastName}`}
            src={
              params.row.photoUrl && params.row.photoUrl.length
                ? params.row.photoUrl
                : require('../../assets/user.png').default
            }
          />
          <div className="d-flex flex-column px-3">
            <span
              style={{ fontSize: 14 }}
            >{`${params.row.firstName} ${params.row.lastName}`}</span>
            <span style={{ fontSize: 12, color: theme.palette.grey[100] }}>
              {params.row.jobTitle}
            </span>
          </div>
        </div>
      )
    },
    {
      field: 'assigned',
      headerName: t(`totalAssigned${selectedAssignmentType}`, {
        ns: 'assignments'
      }),
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<number>) => (
        <span style={dataGridColumnStyle}>{params.value}</span>
      )
    },
    {
      field: 'overdue',
      headerName: t(`overdue${selectedAssignmentType}`, { ns: 'assignments' }),
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<number>) => (
        <span style={dataGridOverdueColumnStyle}>{params.value}</span>
      )
    },
    {
      field: 'completed',
      headerName: t(`completed${selectedAssignmentType}`, {
        ns: 'assignments'
      }),
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<number>) => (
        <span style={dataGridColumnStyle}>{params.value}</span>
      )
    },
    {
      field: '',
      headerName: '',
      minWidth: 170,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<number, EmployeeAssignmentsStatus>
      ) => {
        return (
          <BasicButton
            color="secondary"
            endIcon={<ArrowForwardIcon />}
            onClick={() => setSelectedEmployeeRow(params.row)}
          >
            {t(`manage${selectedAssignmentType}`, { ns: 'assignments' })}
          </BasicButton>
        );
      }
    }
  ];

  const handleSearch = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      const searchResult = employeeAssignmentsStatus?.filter(employee =>
        `${employee.firstName.toLowerCase()} ${employee.lastName.toLowerCase()}`.includes(
          value.toLowerCase()
        )
      );
      setDataSource(searchResult ?? []);
    }, 1000);
  };

  const handleChangeAssignmentType = (items: string[]) => {
    setSelectedAssignmentType(items[0]);
  };

  const handleRefresData = () => {
    dispatch(
      employeeActions.requestEmployeeAssignmentsStatus(
        lowerCaseFirstLetter(selectedAssignmentType)
      )
    );
  };

  return (
    <>
      <Grid
        container
        sx={containerStyle}
        columns={{ xs: 1, md: 6, lg: 6, xl: 6 }}
      >
        <Grid item xs={1} md={3} lg={2} xl={2} sx={gridSearchStyle}>
          <SearchBox
            id="searchEmployees"
            name="searchEmployees"
            value=""
            onChangeValue={handleSearch}
            placeholderText={t('search', { ns: 'common' })}
            width="100%"
            height="45px"
          />
        </Grid>
        <Grid item xs={1} md={2} lg={2} xl={1} sx={gridSelecteStyle}>
          <BasicSelect
            labelId="lessons"
            id="lessons"
            options={entityTypes}
            defaultValue=""
            value={[selectedAssignmentType]}
            handleOnChange={handleChangeAssignmentType}
            style={selectStyle}
            theme={'dark'}
            multiple={false}
            disabled={dataIsLoading}
            sx={{ backgroundColor: 'transparent' }}
          />
        </Grid>
        <Grid item xs={1} md={1} lg={2} xl={3} sx={gridButtonStyle}>
          <ProtectedComponent action={appPermissions.CREATE_GROUP}>
            <BasicButton
              color="secondary"
              style={buttonStyle}
              onClick={handleRefresData}
            >
              <Typography sx={fontButton}>
                {t('refreshData', { ns: 'common' })}
              </Typography>
            </BasicButton>
          </ProtectedComponent>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          sx={{
            height: '50vh',
            width: '100%',
            border: `1px solid ${theme.palette.grey[100]}`,
            backgroundColor: theme.palette.grey.A200,
            borderRadius: '8px',
            '& .row-odd-style': {
              bgcolor: theme.palette.grey.A200
            },
            '& .row-peer-style': {
              bgcolor: theme.palette.common.black
            }
          }}
        >
          {!dataIsLoading && dataSource.length > 0 && (
            <CustomDataGrid
              getRowId={row => row.employeeId}
              rows={dataSource}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              sortingOrder={['desc', 'asc']}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: 'assigned',
                      sort: 'desc'
                    }
                  ]
                }
              }}
              getRowClassName={() => {
                rowCounter++;
                return rowCounter % 2 !== 0
                  ? 'row-odd-style'
                  : 'row-peer-style';
              }}
              disableSelectionOnClick
              disableColumnMenu
              components={{
                ColumnSortedDescendingIcon: () => {
                  return <ArrowDropDownIcon height="5px" width="10px" />;
                },
                ColumnSortedAscendingIcon: () => {
                  return <ArrowDropUpIcon height="5px" width="10px" />;
                }
              }}
            />
          )}
          {!dataIsLoading && dataSource.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <span>{t('noRecords', { ns: 'common' })}</span>
            </Box>
          )}
          {dataIsLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Grid>
      <ManageEmployeeAssignmentsModal
        assignmentType={selectedAssignmentType}
        employeeRow={selectedEmployeeRow}
        clearSelectedEmployeeId={clearSelectedEmployee}
      />
    </>
  );
};

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `none`,
  backgroundColor: theme.palette.common.black,
  borderRadius: '8px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiIconButton-root': {
    color: theme.palette.common.white
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-iconSeparator': {
    color: theme.palette.grey.A100
  },
  '& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
    {
      display: 'none'
    },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '1rem'
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.common.black
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#333333'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    background: '#3e3e3e'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px #3e3e3e',
    borderRadius: '10px'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    background: theme.palette.common.white,
    width: '10px',
    borderRadius: '7px'
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  }
}));

const containerStyle: SxProps = {
  paddingBottom: '1.5rem'
};

const gridSelecteStyle: SxProps = {
  paddingBottom: {
    sm: '0.5rem'
  },
  paddingLeft: {
    md: '0.5rem'
  }
};

const gridSearchStyle: SxProps = {
  paddingBottom: {
    sm: '0.5rem'
  }
};

const gridButtonStyle: SxProps = {
  textAlign: {
    xs: 'start',
    lg: 'end'
  }
};

const selectStyle: CSSProperties = {
  width: '200px',
  height: '45px'
};

const editButton: CSSProperties = {
  padding: '5px',
  background: '#CCC',
  borderRadius: '100%'
};

const dataGridColumnStyle: CSSProperties = {
  fontSize: '20px'
};

const dataGridOverdueColumnStyle: CSSProperties = {
  ...dataGridColumnStyle,
  color: 'rgba(255, 42, 32, 0.87)'
};

export default LearningTable;
