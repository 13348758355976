import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Answer, AssessmentResult } from '../../entities/Assessment';
import { ApplicationState } from '../../store';
import { buildAnswer } from './takeQuizHelpers';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as assessmentActions } from '../../actions/assessments';
import { actionCreators as actionStudent } from '../../actions/student';
import { Lesson } from '../../entities/Lesson';

export const useQuiz = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<{
    courseId: string;
    revisionNumber: number;
    currentLesson: Lesson;
    isFeedback?: boolean;
    results?: AssessmentResult;
    backPath: string;
    isInPlayMode: boolean;
    courseCompletedWithQuiz?: boolean;
  }>();
  const [isFeedback, setIsFeedback] = useState(false);
  const [percentageToPass, setPercentageToPass] = useState<number>(0);
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons?.currentLesson
  );
  const lessons = useSelector(
    (state: ApplicationState) => state.lessons?.lessons
  );
  const completeLessons = useSelector(
    (state: ApplicationState) => state.student?.completedLessons
  );

  const [quizResult, setQuizResult] = useState<AssessmentResult>({
    assessmentId: 'string',
    answers: []
  });

  const [
    isMarkingCompleteInProgress,
    setIsMarkingCompleteInProgress
  ] = useState(false);

  useEffect(() => {
    if (location.state && location.state.currentLesson) {
      dispatch(lessonActions.setCurrentLesson(location.state.currentLesson));
      setIsFeedback(location.state.isFeedback ?? false);
    }
  }, [location]);

  useEffect(() => {
    if (currentLesson) {
      const percentage = Math.floor(
        (currentLesson.correctAnswersToPass! * 100) /
          currentLesson.questions!.length
      );
      setPercentageToPass(percentage);

      const newAnswers = currentLesson.questions!.map(q =>
        buildAnswer(q, location.state.results)
      );

      setQuizResult({
        assessmentId: currentLesson.assessmentId!,
        answers: newAnswers,
        passed: location.state.results?.passed ?? undefined,
        grade: location.state.results?.grade ?? undefined
      });
    }
  }, [currentLesson, location]);

  const saveAnswer = (answer: Omit<Answer, 'type' | 'order'>) => {
    const existingAnswer = quizResult.answers.find(
      a => a.questionId === answer.questionId!
    );

    const newAnswers: Answer[] = [
      ...quizResult.answers.filter(a => a.questionId !== answer.questionId),
      { ...existingAnswer!, ...answer }
    ];

    setQuizResult({
      ...quizResult,
      answers: newAnswers
    });
  };

  const backToPreviousPage = (goToNext: boolean = false) => {
    const currIndex = lessons?.findIndex(x => x.id === currentLesson?.id) ?? -1;
    let nextLesson = currentLesson;
    if (
      goToNext &&
      lessons &&
      lessons?.length > currIndex + 1 &&
      currIndex !== -1
    )
      nextLesson = lessons[currIndex + 1];
    history.push({
      pathname: location.state.backPath,
      state: {
        lesson: nextLesson,
        courseCompletedWithQuiz: location.state.courseCompletedWithQuiz ?? false
      }
    });
  };

  const submitQuiz = async () => {
    const correctAnswerCount = quizResult.answers.filter(a => a.isCorrect)
      .length;
    const grade = (correctAnswerCount / quizResult.answers.length) * 100;
    const passed = grade >= percentageToPass;
    quizResult.passed = passed;
    quizResult.grade = grade;

    let courseIsCompleted = false;
    if (location.state.isInPlayMode) {
      await dispatch(assessmentActions.saveAssessmentResult(quizResult));

      if (quizResult.passed) {
        courseIsCompleted = await markLessonAsCompleted(
          location.state.currentLesson.id,
          location.state.courseId,
          location.state.revisionNumber
        );
      } else {
        const completedLesson = completeLessons?.find( c => c.lessonId === location.state.currentLesson.id);

        if (!completedLesson) {
          dispatch(assessmentActions.markQuizAsFailed(quizResult.assessmentId));
        }
      }
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    history.replace('/lesson/quiz/results', {
      courseId: location.state.courseId,
      revisionNumber: location.state.revisionNumber,
      currentLesson: location.state.currentLesson,
      results: quizResult,
      isFeedback: true,
      backPath: location.state.backPath,
      isInPlayMode: location.state.isInPlayMode,
      courseCompletedWithQuiz: courseIsCompleted
    });
  };

  const markLessonAsCompleted = async (
    lessonId: string,
    courseId?: string,
    revisionNumber?: number
  ): Promise<boolean> => {
    setIsMarkingCompleteInProgress(true);
    const wasCourseCompleted = ((await dispatch(
      actionStudent.markLessonAsCompleted(lessonId, courseId, revisionNumber)
    )) as unknown) as boolean;

    setIsMarkingCompleteInProgress(false);

    return wasCourseCompleted;
  };

  const onRetry = () => {
    history.replace('/lesson/quiz', {
      courseId: location.state.courseId,
      revisionNumber: location.state.revisionNumber,
      currentLesson: location.state.currentLesson,
      results: undefined,
      isFeedback: false,
      backPath: location.state.backPath,
      courseCompletedWithQuiz: undefined,
      isInPlayMode: location.state.isInPlayMode
    });
  };

  return {
    state: {
      currentLesson,
      isFeedback,
      quizResult,
      percentageToPass,
      isMarkingCompleteInProgress
    },
    api: {
      submitQuiz,
      markLessonAsCompleted,
      backToPreviousPage,
      onRetry,
      saveAnswer
    }
  };
};
