import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/student';

import {
  STUDENT_MARK_LESSON_AS_COMPLETED,
  STUDENT_SET_COURSE_COMPLETED_LESSONS,
  STUDENT_SET_IS_LOADING,
  STUDENT_SET_LEARNING_PLAY_LIST,
  STUDENT_SET_ALL_LEARNING_PLAY_LIST,
  STUDENT_UPDATE_TRANSACTION_STATUS,
  STUDENT_RESET_TRANSACTION_STATUS,
  STUDENT_SET_ASSIGNMENT,
  STUDENT_SET_COMPLETED_COURSES,
  STUDENT_SET_COMPLETED_LEARNING_PLANS,
  STUDENT_SET_LEARNING_PROFILE,
  STUDENT_WEEKLY_STATISTICS
} from '../actions/actionTypes';
import { CompletedLessonInfo } from '../entities/CompletedLessonInfo';
import {
  LearningPlaylistItems,
  AssignmentsPlaylistItems,
  EmployeePlayList
} from '../entities/LearningPlaylist';
import { TransactionStatusEnum } from '../core/enums';
import { CompletedCourseInfo } from '../entities/CompletedCourseInfo';
import { CompletedLearningPlanInfo } from '../entities/CompletedLearningPlanInfo';
import { ResultDTO } from '../entities/Dto/responseDTO';
import { LearningProfile } from '../entities/LearningProfile';
import { WeeklyStatistics } from '../entities/WeeklyStatistics';

export interface StudentState {
  errorMessage: string;
  isLoading: boolean;
  completedLessons: CompletedLessonInfo[];
  learningPlayList: LearningPlaylistItems | null;
  employeesPlaylists: ResultDTO<EmployeePlayList[]> | null;
  transactionStatus: TransactionStatusEnum;
  assignmentsItems: AssignmentsPlaylistItems[] | null;
  completedCourses: CompletedCourseInfo[];
  completedLearningPlans: CompletedLearningPlanInfo[];
  learningProfile: LearningProfile | null;
  weeklyStatistics: WeeklyStatistics[] | null;
}

const unloadedState: StudentState = {
  errorMessage: '',
  isLoading: false,
  completedLessons: [],
  learningPlayList: null,
  transactionStatus: TransactionStatusEnum.None,
  assignmentsItems: null,
  completedCourses: [],
  completedLearningPlans: [],
  employeesPlaylists: null,
  learningProfile: null,
  weeklyStatistics: null,
};

export const StudentReducer: Reducer<StudentState> = (
  state: StudentState | undefined,
  incomingAction: Action
): StudentState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case STUDENT_SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };

    case STUDENT_SET_COURSE_COMPLETED_LESSONS:
      return {
        ...state,
        completedLessons: action.completedLessons
      };
    case STUDENT_MARK_LESSON_AS_COMPLETED:
      const lessonWithNewCompleted = state.completedLessons.filter(
        lesson => lesson.lessonId !== action.completedLesson.lessonId
      );
      lessonWithNewCompleted.push(action.completedLesson);

      return {
        ...state,
        completedLessons: lessonWithNewCompleted
      };
    case STUDENT_SET_LEARNING_PLAY_LIST:
      return { ...state, learningPlayList: action.learningPlaylistIems };
    case STUDENT_SET_ALL_LEARNING_PLAY_LIST:
      return { ...state, employeesPlaylists: action.employeesPlaylists };
    case STUDENT_SET_COMPLETED_COURSES:
      return { ...state, completedCourses: action.completedCourses };
    case STUDENT_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case STUDENT_RESET_TRANSACTION_STATUS:
      return { ...state, transactionStatus: TransactionStatusEnum.None };
    case STUDENT_SET_ASSIGNMENT:
      return { ...state, assignmentsItems: action.assignmentsItems };
      case STUDENT_WEEKLY_STATISTICS:
      return { ...state, weeklyStatistics: action.weeklyStatistics };
    case STUDENT_SET_COMPLETED_LEARNING_PLANS:
      return {
        ...state,
        completedLearningPlans: action.completedLearningPlans
      };
    case STUDENT_SET_LEARNING_PROFILE:
      return { ...state, learningProfile: action.learningProfile };
    default:
      return state;
  }
};
