import { Typography, Box, Stack, Grid } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionCreators as userProfileActions } from '../../../actions/userProfile';
import { actionCreators as appActions } from '../../../actions/appState';
import { UserProfileDto } from '../../../entities/UserProfile';

import { ApplicationState } from '../../../store';
import FallbackLoader from '../../../components/FallbackLoader/FallbackLoader';
import { UserProfileDetailParams } from '../../../entities/Common';
import { SimpleFormPage } from '../../../components/Pages';
import { IdentificationForm } from './IdentificationForm';
import { BasicButtonTypeEnum } from '../../../core/enums';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { IdentificationValidationSchema } from './IdentificationValidationSchema';

export const IdentificationSectionEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userProfileId } = useParams<UserProfileDetailParams>();
  const { control, handleSubmit, reset, watch, formState, setValue } =
    useForm<UserProfileDto>({
      resolver: yupResolver(IdentificationValidationSchema)
    });
  const industryCategories = useSelector(
    (state: ApplicationState) => state.appState?.industryCategories
  );
  const currentUserProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const currentCompanyProfileId = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile?.id
  );

  React.useEffect(() => {
    dispatch(appActions.getIndustryCategories(currentCompanyProfileId));
  }, []);

  const onSubmit: SubmitHandler<UserProfileDto> = data => {
    dispatch(
      userProfileActions.updateUserProfile(data, userProfileId, history)
    );
  };

  const goToProfile = () => {
    history.push(`/user/profile/${userProfileId}`);
  };

  React.useEffect(() => {
    if (currentUserProfile) {
      reset({
        ...currentUserProfile
      });
    }
  }, [currentUserProfile]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  if (!isLoading && industryCategories && !industryCategories.length) {
    return (
      <Stack spacing={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <Typography variant="blackTitle">Page not available</Typography>
        </Box>
      </Stack>
    );
  }

  return (
    <SimpleFormPage title="Complete your profile">
      <IdentificationForm
        title="Update your Dirt World Profile"
        control={control}
        watch={watch}
        formState={formState}
        handleSubmit={handleSubmit}
        setValue={setValue}
        submitHandler={onSubmit}
        industryCategories={industryCategories}
        bottomActions={
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} sm={6} textAlign="right">
              <BasicButton
                color="secondary"
                onClick={goToProfile}
                sx={theme => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: 'auto'
                  }
                })}
              >
                Cancel
              </BasicButton>
            </Grid>
            <Grid item xs={12} sm={6} textAlign="left">
              <BasicButton
                type={BasicButtonTypeEnum.Submit}
                color="primary"
                sx={theme => ({
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: 'auto'
                  }
                })}
              >
                Finish
              </BasicButton>
            </Grid>
          </Grid>
        }
      />
    </SimpleFormPage>
  );
};
