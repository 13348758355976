export const validEmail = (email: string): boolean => {
    let regexp = new RegExp('^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$');

    return regexp.test(email);
};

export const validPhoneNumber = (phoneNumber: string): boolean => {
    let regexp = new RegExp('^[+][0-9]*$');

    return regexp.test(phoneNumber);
};

export const validPasswordRules = (password : string) => {
    /* Password Rules => at least 8 characters, one upper case, one lower case, one number and one special character*/
    let regexp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9].*)(?=.*[@$!%#*?%=+.,_\\-\\:;<>|^`\'\/\\&])[A-Za-z0-9\d@$!%#*?%=+.,_\\-\\:;<>|^`\'\/\\&]{8,}');

    return regexp.test(password);
}

export const validImageFormat = (fileType: string) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return validImageTypes.includes(fileType);
};