import React, { CSSProperties, useState } from 'react';
import {
  DueCourse,
  DueLearningPlan,
  DueLesson,
  LearningPlaylistItems
} from '../../entities/LearningPlaylist';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { Box, Typography, useTheme } from '@mui/material';
import Panel from '../../components/core/Panel/Panel';
import { styled } from '@mui/material/styles';
import { Lesson } from '../../entities/Lesson';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LearningPlan } from '../../entities/LearningPlan';
import { useLogEvent } from '../../hooks/useLogEvent';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

export interface AssignmentsByDueDateProps {
  learningPlanListItems: LearningPlaylistItems;
}

enum DueTypeEnum {
  DueLesson,
  DueCourse,
  DueLearningPlan
}

const AssignmentsByDueDateAsIs: React.FC<AssignmentsByDueDateProps> = ({
  learningPlanListItems
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const history = useHistory();
  const { dueCourses, dueLessons, dueLearningPlans } = learningPlanListItems;
  const allItems = [
    ...dueCourses.map(i => ({ ...i, type: DueTypeEnum.DueCourse })),
    ...dueLessons.map(i => ({ ...i, type: DueTypeEnum.DueLesson })),
    ...dueLearningPlans.map(i => ({ ...i, type: DueTypeEnum.DueLearningPlan }))
  ];
  allItems.forEach(
    item => (item.dueDateMoment = absUtcDateToLocalMoment(item.dueDateUtc))
  );
  allItems.sort((a, b) => a.dueDateMoment!.unix() - b.dueDateMoment!.unix());
  const { t } = useTranslation(['assignments']);
  const today = moment();
  const [option, setOption] = useState<string>('allItems');
  const handleChange = (event: { target: { value: string } }) => {
    setOption(event.target.value);
  };

  const itemsOverdue = allItems.filter(item =>
    item.dueDateMoment!.isBefore(today, 'day')
  );
  const itemsDueOn = allItems.filter(item =>
    item.dueDateMoment!.isSameOrAfter(today, 'day')
  );

  const itemsToRender = () => {
    switch (option) {
      case 'itemsOverdue':
        return itemsOverdue;
      case 'itemsDueOn':
        return itemsDueOn;
      default:
        return allItems;
    }
  };

  const overDueStyle: CSSProperties = {
    color: theme.palette.error.light,
    fontSize: '14px',
  };

  const inTimeStyle: CSSProperties = {
    color: theme.palette.primary.main,
    fontSize: '14px',
    marginRight: '0.3rem'
  };

  const handlePlayLesson = (lesson: Lesson) => {
    dispatch(lessonActions.setCurrentLessons([lesson]));
    logEvent.logPlayLessonEvent(lesson.id);

    history.push({
      pathname: `/lessons/play`,
      state: { lesson }
    });
  };

  const handleStartCourse = (courseId: string, revisionNumber: number) => {
    history.push(`/courses/${courseId}/revision/${revisionNumber}`);
  };

  const handleStartLearningPlan = (
    learningPlan: LearningPlan,
    dueDate: string,
    assignmentId: string
  ) => {
    dispatch(learningPlanActions.setCurrentLearningPlan(learningPlan));
    history.push(`/learning-plans/preview/${learningPlan.id}`, {
      dueDate,
      assignmentId
    });
  };

  const PersonalizedInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      fontSize: 13,
      paddingLeft: '5px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      color: '#FF9E00',
      fontWeight: 400,
      fontFamily: [theme.typography.fontFamily].join(',')
    },
    '.MuiNativeSelect-icon ': {
      color: '#FFFFFF8F',
      marginLeft: '-100px'
    },
    '.MuiNativeSelect-select': {
      paddingRight: '20px !important'
    }
  }));

  return (
    <Panel width="100%" height="413px">
      <Grid container sx={gridSelectStyle}>
        <Typography style={showTextStyle}>
          {t('show', { ns: 'assignments' })}:{' '}
        </Typography>
        <NativeSelect
          id="native-select-option"
          value={option}
          onChange={handleChange}
          input={<PersonalizedInput />}
        >
          <option value={'allItems'}>
            {t('allItems', { ns: 'assignments' })}
          </option>
          <option value={'itemsOverdue'}>
            {t('itemsOverdue', { ns: 'assignments' })}
          </option>
          <option value={'itemsDueOn'}>
            {t('itemsDueOn', { ns: 'assignments' })}
          </option>
        </NativeSelect>
      </Grid>
      <Box sx={boxStyle} className="verticalScroll">
        {allItems.length > 0 ? (
          itemsToRender().map(item => {
            const isComplete = item.isComplete;
            const dueDateItem = item.dueDateMoment!.format('MM.DD.YY');
            let title = '';
            let type = '';

            let clickHandler = () => {};
            switch (item.type) {
              case DueTypeEnum.DueLesson:
                const dueLesson = item as DueLesson;
                let lesson = dueLesson.lesson;
                title = dueLesson.lesson.title;
                type = `${t('lesson', { ns: 'assignments' })}`;
                clickHandler = () => {
                  if (isComplete) {
                    lesson = { ...lesson, isComplete: true };
                  }
                  handlePlayLesson(lesson);
                };
                break;
              case DueTypeEnum.DueCourse:
                const dueCourse = item as DueCourse;
                title = dueCourse.course.title;
                type = `${t('course', { ns: 'assignments' })}`;
                clickHandler = () => {
                  handleStartCourse(
                    dueCourse.course.id,
                    dueCourse.course.revisionNumber
                  );
                };
                break;
              case DueTypeEnum.DueLearningPlan:
                const dueLearningPlan = item as DueLearningPlan;
                title = (item as DueLearningPlan).learningPlan.name;
                type = `${t('learningPlan', { ns: 'assignments' })}`;
                clickHandler = () => {
                  handleStartLearningPlan(
                    dueLearningPlan.learningPlan,
                    dueDateItem,
                    dueLearningPlan.assignmentId
                  );
                };
                break;
            }
            return (
              <StyledGrid
                container
                spacing={0}
                padding={1}
                key={`${title}-${item.dueDateUtc}`}
              >
                <Grid item xs="auto" container>
                  <Grid
                    item
                    xs="auto"
                    paddingRight={1}
                    sx={gridOverdueDateStyle}
                  >
                    {item.dueDateMoment!.isBefore(today, 'day') ? (
                      <div>
                        <span style={labelStyle}>
                          {t('overdue', { ns: 'assignments' })}:
                        </span>
                        <span style={overDueStyle}>
                          {item.dueDateMoment!.format('MM.DD.YY')}
                        </span>
                      </div>
                    ) : (
                      <div>
                        <span style={labelStyle}>
                        {t('itemsDueOn', { ns: 'assignments' })}: 
                        </span>
                        <span style={inTimeStyle}>
                          {item.dueDateMoment!.format('MM.DD.YY')}
                        </span>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs="auto" padding={1}>
                    <div style={verticalLine}></div>
                  </Grid>
                </Grid>

                <Grid item xs={6} paddingLeft={1} sx={gridTitleStyle}>
                  <Typography style={itemName}>{title}</Typography>
                  <Typography style={itemType}>{type}</Typography>
                </Grid>
                <Grid item xs sx={gridButtonStyle}>
                  <BasicButton
                    onClick={clickHandler}
                    color={isComplete ? 'revisit' : 'primary'}
                    style={startButtonStyle}
                  >
                    {isComplete
                      ? t('revisit', { ns: 'common' })
                      : t('start', { ns: 'common' })}
                  </BasicButton>
                </Grid>
              </StyledGrid>
            );
          })
        ) : (
          <Grid item>
            <h5>{t('noAssignments', { ns: 'assignments' })}</h5>
          </Grid>
        )}
      </Box>
    </Panel>
  );
};

const StyledGrid = styled(Grid)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#282828'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#1B1C1C'
  },
  height: 76
}));

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

const verticalLine: CSSProperties = {
  borderLeft: '1px',
  borderLeftStyle: 'solid',
  borderLeftColor: '#99999980',
  height: '100%'
};

const itemName: CSSProperties = {
  color: '#FFFFFFDE',
  fontSize: '20px'
};

const itemType: CSSProperties = {
  color: '#999999',
  fontSize: '12px'
};

const boxStyle: CSSProperties = {
  overflowY: 'auto',
  padding: '20px !important'
};

const gridOverdueDateStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const gridTitleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

const gridButtonStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
};

const gridSelectStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  paddingLeft: '20px !important',
  paddingTop: '20px !important'
};

const showTextStyle: CSSProperties = {
  color: '#999999',
  fontSize: '13px',
  marginTop: '0.4rem'
};

const labelStyle: CSSProperties = {
  fontSize: '14px',
  marginRight: '0.2rem'
};

export default AssignmentsByDueDateAsIs;
