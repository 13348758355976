export enum TransactionStatusEnum {
  None,
  Successfull,
  Failed
}

export enum BasicButtonTypeEnum {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset'
}

export enum EntityTypeEnum {
  Course,
  Lesson
}

export enum LessonTypeViewEnum {
  LessonType,
  CourseLessonTypesCount
}

export enum FormModeEnum {
  Create,
  Edit
}

export enum HttpStatusEnum {
  OK = 200,
  Created = 201,
  NotFound = 404,
  Conflict = 409,
  InternalServerError = 500
}

export enum LibraryTypesEnum {
  Company,
  Buildwitt,
  CompanyAndBuildwitt
}
