import React, { CSSProperties, useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { Page } from '../../components/Pages';
import companiesBanner from '../../assets/companiesBanner.png';
import { useTheme } from '@mui/material/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Stack } from '@mui/material';
import { BasicButtonTypeEnum } from '../../core/enums';
import { actionCreators as companyActions } from '../../actions/companies';
import { ICompany } from '../../entities/Company';
import CompanyCard from './CompanyCard';
import FiltersCompanies from './FiltersCompanies';
import { CompaniesFilterDto } from '../../entities/Dto/CompaniesFilterDto';
import { NotAvailablePage } from '../../components/NotAvailablePage/NotAvailablePage';

const CompaniesList = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, reset, formState, setValue } =
    useForm<CompaniesFilterDto>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [companies, setCompanies] = useState<ICompany[]>([]);

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const companiesList = useSelector(
    (state: ApplicationState) => state.companies?.companies
  );

  const industryCategories = useSelector(
    (state: ApplicationState) => state.appState?.industryCategories
  );

  const [applyFilter, setApplyFilter] = useState(true);

  const onSubmit: SubmitHandler<CompaniesFilterDto> = data => {
    console.log(data);
    
    const params = Object.entries(data)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    dispatch(companyActions.getCompanies(params));
    setApplyFilter(false);
  };

  const resetFilters = () => {
    dispatch(companyActions.getCompanies());
    reset({
      Country: '',
      State: '',
      Industry: '',
    });
    setApplyFilter(true);
  };

  useEffect(() => {
    if (companiesList) {
      setCompanies(companiesList);
    }
  }, [companiesList]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  if (!isLoading && industryCategories && !industryCategories.length) {
    return <NotAvailablePage />;
  }

  return (
    <Page>
      <div style={matches ? divBannerMobile : divBannerWeb}>
        <Grid container spacing={2} style={{ marginTop: '10vh' }}>
          <Grid item xs={12} md={12} sx={{ width: '100%' }}>
            <div
              style={
                matches ? containerMobileBannerStyle : containerBannerStyle
              }
            >
              <Typography
                gutterBottom
                style={matches ? textBannerMobileStyle : textBannerStyle}
              >
                Dirt World Companies
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={matches ? containerMobileFilterStyle : containerFilterStyle}
          >
            <FiltersCompanies
              submitHandler={onSubmit}
              watch={watch}
              handleSubmit={handleSubmit}
              setValue={setValue}
              control={control}
              formState={formState}
              industryCategories={industryCategories}
              bottomActions={
                <Stack direction="row" sx={{ textAlign: 'center' }} spacing={2}>
                  {applyFilter && (
                    <Button variant="text" type={BasicButtonTypeEnum.Submit}>
                      <Typography style={spanStyle}>Apply Filters</Typography>
                    </Button>
                  )}

                  {!applyFilter && (
                    <Button variant="text" onClick={resetFilters}>
                      <Typography style={spanStyle}>Reset Filters</Typography>
                    </Button>
                  )}
                </Stack>
              }
            />
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        spacing={3}
        style={{ marginTop: matches ? '70vh' : '40vh' }}
      >
        <Grid item xs={12} md={12} style={companiesSpanStyle}>
          <Typography>Showing [{companies.length}] companies</Typography>
        </Grid>
        {companies.map(company => (
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            key={company.id}
            style={cardItemStyle}
          >
            <CompanyCard companyID={company.id} companyInfo={company} />
          </Grid>
        ))}
      </Grid>
    </Page>
  );
};

const companiesSpanStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
};

const containerBannerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10vh'
};

const containerMobileBannerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '2vh',
  marginTop: '5vh'
};

const textBannerStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '70px',
  lineHeight: '75px',
  color: '#FFFFFF'
};

const textBannerMobileStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '36px',
  lineHeight: '40px',
  color: '#FFFFFF'
};

const divBannerWeb: CSSProperties = {
  background: `url('${companiesBanner}'`,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 50,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '45vh'
};

const divBannerMobile: CSSProperties = {
  background: `url('${companiesBanner}'`,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundPosition: '70% 50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '550px'
};

const spanStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#FFFFFF',
  textTransform: 'capitalize'
};

const cardItemStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '10px'
};

const containerFilterStyle: CSSProperties = {
  marginLeft: '10vh'
};

const containerMobileFilterStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '2vh',
  marginRight: '2vh',
  marginTop: '5vh'
};

export default CompaniesList;
