import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import TextBox from '../../components/core/TextBox/TextBox';
import Textarea from '../../components/core/TextArea/TextArea';
import FileInput from '../../components/core/FileInput/FileInput';
import { LearningPlan } from '../../entities/LearningPlan';
import { ApplicationState } from '../../store';
import { newLearningPlan } from '../../reducers/LearningPlansReducer';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { validImageFormat } from '../../utils/validationsUtils';
import { actionCreators } from '../../actions/learningPlans';
import { useStringLengthControl } from '../../hooks/useFieldsValidation';
import { TransactionStatusEnum, FormModeEnum } from '../../core/enums';
import { useHistory } from 'react-router-dom';
import { buttonStyle, fontButton, rowReverse } from '../../utils/buttonStyles';
import { Typography } from '@mui/material';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import { ThumbnailLibraryPanel } from '../../components/ThumbnailLibraryPanel/ThumbnailLibraryPanel';
import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';

export interface CreateLearningPlanProps {
  formMode: FormModeEnum;
  showModal?: (show: boolean) => void;
}

export interface ValidationFieldsState {
  nameMsg: string;
  descriptionMsg: string;
  thumbnailMsg: string;
}

const initValidationFieldsState: ValidationFieldsState = {
  nameMsg: '',
  descriptionMsg: '',
  thumbnailMsg: ''
};

const CertificateOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const CreateLearningPlan: React.FC<CreateLearningPlanProps> = ({
  formMode,
  showModal
}) => {
  const [learningPlan, setLearningPlan] = useState<LearningPlan>(
    newLearningPlan
  );
  const currentLearningPlan = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlan
  );
  const transactionSatus = useSelector(
    (state: ApplicationState) => state.learningPlans?.transactionStatus
  );
  const [validationFields, setValidationFields] = useState<
    ValidationFieldsState
  >(initValidationFieldsState);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [nameWarningLength, updateNameWarningLength] = useStringLengthControl(
    150,
    125,
    learningPlan.name
  );
  const [
    descriptionWarningLength,
    updateDescriptionWarningLength
  ] = useStringLengthControl(800, 700, learningPlan.description);
  const { t } = useTranslation(['common', 'learningPlans']);

  const { nameMsg, descriptionMsg, thumbnailMsg } = validationFields;
  const { name, description, thumbnailUrl } = learningPlan;
  const [imgFromLib, setImgFromLib] = React.useState<string>('');

  const validationMessages = {
    NAME_MADATORY: t('nameIsRequired', { ns: 'common' }),
    DESCRIPTION_MADATORY: t('descriptionIsRequired', { ns: 'common' }),
    THUMBNAIL_MADATORY: t('thumbnailIsRequired', { ns: 'common' }),
    FILE_FORMAT_ERROR: t('invalidImageFormat', { ns: 'common' }),
    CERTIFICATE_OPTION_MANDATORY: t('selectedOptionIsRequired', {
      ns: 'common'
    }),
    NAME_MAX_CHARACTER_LIMIT: `${t('name', {
      ns: 'common'
    })} ${t('exceedCharacterLimitMsg', { ns: 'common' })} (150)`,
    DESCRIPTION_MAX_CHARACTER_LIMIT: `${t('description', {
      ns: 'common'
    })} ${t('exceedCharacterLimitMsg', { ns: 'common' })} (800)`
  };

  useEffect(() => {
    if (currentLearningPlan) {
      setLearningPlan(currentLearningPlan);
      setImgFromLib(currentLearningPlan.thumbnailUrl);
    }
  }, [currentLearningPlan]);

  useEffect(() => {
    if (transactionSatus === TransactionStatusEnum.Successfull) {
      if (formMode === FormModeEnum.Create)
        history.push('/learning-plans-details');
      else if (formMode === FormModeEnum.Edit && showModal) showModal!(false);
    } else if (transactionSatus === TransactionStatusEnum.Failed)
      alert(t('exceptionWhileCreatingLP', { ns: 'learningPlans' }));

    return () => {
      dispatch(actionCreators.resetLearningPlanTransactionStatus());
    };
  }, [transactionSatus]);

  const handleNameChange = (value: string) => {
    setLearningPlan({ ...learningPlan, name: value });
    updateNameWarningLength(value);
    setValidationFields({
      ...validationFields,
      nameMsg: validateFields('name', value)
    });
  };

  const handleDescriptionChange = (value: string) => {
    setLearningPlan({ ...learningPlan, description: value });
    updateDescriptionWarningLength(value);
    setValidationFields({
      ...validationFields,
      descriptionMsg: validateFields('description', value)
    });
  };

  const handleTextBoxBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    const message = validateFields(id, value);

    setValidationFields({ ...validationFields, [`${id}Msg`]: message });
  };

  const handlefileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const errorMsg = validateFields(e.target.id, e.target.files);

    if (e.target.files) {
      const file = e.target.files[0];
      setThumbnailFile(file);

      if (learningPlan.thumbnailUrl && learningPlan.thumbnailUrl.length) {
        setLearningPlan({ ...learningPlan, thumbnailUrl: '' });
      }
    }
    setValidationFields({ ...validationFields, thumbnailMsg: errorMsg });
  };

  const isDataChanged = () => {
    return (
      currentLearningPlan?.name !== learningPlan.name ||
      currentLearningPlan.description !== learningPlan.description ||
      currentLearningPlan.thumbnailUrl !== learningPlan.thumbnailUrl ||
      currentLearningPlan.thumbnailUrl !== imgFromLib
    );
  };

  const handleSave = () => {
    const validationObj: ValidationFieldsState = {
      nameMsg: validateFields('name', name),
      descriptionMsg: validateFields('description', description),
      thumbnailMsg:
        !thumbnailFile && !thumbnailUrl.length && !imgFromLib
          ? validationMessages.THUMBNAIL_MADATORY
          : ''
    };

    if (
      !validationObj.nameMsg.length &&
      !validationObj.descriptionMsg.length &&
      !validationObj.thumbnailMsg.length
    ) {
      let isChanged: boolean = false;
      if (formMode === FormModeEnum.Edit) isChanged = isDataChanged();

      if (formMode === FormModeEnum.Create || isChanged)
        dispatch(
          actionCreators.saveLearningPlan(
            learningPlan,
            imgFromLib,
            thumbnailFile
          )
        );
      else if (formMode === FormModeEnum.Edit && !showModal)
        history.push('/learning-plans-details');
      else if (formMode === FormModeEnum.Edit && showModal) showModal!(false);
    } else setValidationFields(validationObj);
  };

  const validateFields = (componentId: string, value: any) => {
    let msg = '';

    switch (componentId) {
      case 'name':
        if (!value.trim().length) msg = validationMessages.NAME_MADATORY;
        else if (value.trim().length > 150)
          msg = validationMessages.NAME_MAX_CHARACTER_LIMIT;
        break;

      case 'description':
        if (!value.trim().length) msg = validationMessages.DESCRIPTION_MADATORY;
        else if (value.trim().length > 800)
          msg = validationMessages.DESCRIPTION_MAX_CHARACTER_LIMIT;
        break;

      case 'fileInput':
        if (!value || !value.length)
          msg = validationMessages.THUMBNAIL_MADATORY;
        else if (!validImageFormat(value[0].type))
          msg = validationMessages.FILE_FORMAT_ERROR;
        break;
      default:
        break;
    }

    return msg;
  };

  return (
    <>
      <Row>
        <Col className="d-flex flex-column">
          <FormLabel>
            {t('learningPlanName', { ns: 'learningPlans' })}{' '}
            <span className="text-danger">*</span>
          </FormLabel>
          <TextBox
            id="name"
            name="name"
            placeholderText={t('writeLPName', { ns: 'learningPlans' })}
            width="100%"
            height="40px"
            value={learningPlan?.name}
            maxLength={150}
            onChangeValue={handleNameChange}
            onBlur={handleTextBoxBlur}
          />

          <p className="text-danger">{nameMsg}</p>
          <p className="text-danger">{nameWarningLength}</p>
          <FormLabel>
            {t('learningPlanDescription', { ns: 'learningPlans' })}{' '}
            <span className="text-danger">*</span>
          </FormLabel>
          <Textarea
            id="description"
            name="description"
            placeholderText={t('writeLPDescription', { ns: 'learningPlans' })}
            width="100%"
            value={learningPlan?.description}
            rows={5}
            maxLength={800}
            onChangeValue={handleDescriptionChange}
            onBlur={handleTextBoxBlur}
          />
          <p className="text-danger">{descriptionMsg}</p>
          <p className="text-danger">{descriptionWarningLength}</p>
          <Row>
            <Col>
              <ThumbnailLibraryPanel
                onChange={handlefileChange}
                thumbnailUrl={imgFromLib}
                onItemSelected={(item: AzureBlobImageInfo) => {
                  setThumbnailFile(null);
                  setImgFromLib(item.url);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={rowReverse}>
        <BasicButton color="primary" style={buttonStyle} onClick={handleSave}>
          <Typography style={fontButton}>
            {formMode === FormModeEnum.Create
              ? t('continue', { ns: 'common' })
              : t('save', { ns: 'common' })}
          </Typography>
        </BasicButton>
      </Row>
    </>
  );
};

export default CreateLearningPlan;
