import React, { CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import CourseCard from '../../components/CourseCard/CourseCard';
import { DueCourse } from '../../entities/LearningPlaylist';
import { useTranslation } from 'react-i18next';

interface CourseAssignmentsProps {
  dueCourses: DueCourse[];
}

const CourseAssignments: React.FC<CourseAssignmentsProps> = ({
  dueCourses
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation(['common', 'assignments', 'courses']);

  const handleStartCourse = (courseId: string, revisionNumber: number) => {
    history.push(`/courses/${courseId}/revision/${revisionNumber}`);
  };

  const dueDateStyle: CSSProperties = {
    color: theme.palette.primary.main,
    paddingLeft: '0.3rem'
  };

  const overdueDateStyle: CSSProperties = {
    color: theme.palette.error.main,
    paddingLeft: '0.3rem'
  };

  var today = moment();

  return (
    <>
      {dueCourses.map(dueItem => {
        const { course, isComplete, dueDateMoment } = dueItem;
        const { id, companyId, revisionNumber } = course;

        return (
          <Grid item xs={1} md={6} lg={4} key={id}>
            <CourseCard
              course={course}
              showEdit={false}
              dueDate={dueDateMoment!.toDate()}
              isComplete={isComplete ?? false}
              topTitle={
                companyId
                  ? t('course', { ns: 'courses' }).toUpperCase()
                  : t('bWCourse', { ns: 'courses' }).toUpperCase()
              }
              width="100%"
              height="295.5px"
            >
              <div style={actionButtonsContainerStyle}>
                {isComplete == true ? (
                  <span />
                ) : (
                  <span>
                    {t('dueDate', { ns: 'assignments' })}:
                    <span
                      style={
                        !isComplete && dueDateMoment!.isBefore(today, 'day')
                          ? overdueDateStyle
                          : dueDateStyle
                      }
                    >
                      {dueDateMoment!.format('MM.DD.YY')}
                    </span>
                  </span>
                )}
                <BasicButton
                  onClick={() => handleStartCourse(id, revisionNumber)}
                  color={isComplete ? 'revisit' : 'primary'}
                  style={startButtonStyle}
                >
                  {isComplete
                    ? t('revisit', { ns: 'common' })
                    : t('start', { ns: 'common' })}
                </BasicButton>
              </div>
            </CourseCard>
          </Grid>
        );
      })}
    </>
  );
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

const actionButtonsContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export default CourseAssignments;
