import { Typography } from '@mui/material';
import { WorkExperienceDto } from '../../../entities/UserProfile';
import { getMonthName } from '../../../utils/stringUtils';

export const WorkExperienceDateRange = ({
  workExperience
}: {
  workExperience: WorkExperienceDto;
}) => {
  const renderEndDate = () => {
    if (!workExperience.endMonth && !workExperience.endYear) {
      return 'Present';
    } else if (workExperience.endMonth) {
      return `${getMonthName(parseInt(workExperience.endMonth))} ${
        workExperience.endYear
      }`;
    }
  };
  return (
    <Typography variant="blackCaption">{`${getMonthName(
      parseInt(workExperience.startMonth)
    )} ${workExperience.startYear} - ${renderEndDate()}`}</Typography>
  );
};
