import React, { useState } from 'react';
import { actionCreators } from '../../actions/lessons';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ApplicationState } from '../../store';
import { useParams } from 'react-router-dom';
import LessonCard from '../../components/LessonCard/LessonCard';
import ModalPage from '../../components/core/ModalPage/ModalPage';
import { Col, Row, ButtonGroup } from 'react-bootstrap';
import { CourseLessonName, ImportLessonDto } from '../../entities/Course';
import BasicToggleButton from '../../components/core/BasicToggleButton/BasicToggleButton';
import {
  BuildWittName,
  Buildwitt_Value,
  Company_Value,
  TemporalNewId
} from '../../core/constants';
import SelectEntityButton from '../../components/SelectEntityButton/SelectEntityButton';

// TODO Alejandro: Remove .css files
import './SelectLessons.css';
import { UserClaims } from '../../core/constants';
import { useTranslation } from 'react-i18next';

export interface SelectLessonsProps {
  showModal: boolean;
  closeModal: () => void;
  currentLessons: CourseLessonName[];
  onSave: (selectedLessons: ImportLessonDto[]) => Promise<void>;
  callBack?: () => void;
}

const SelectLessons: React.FC<SelectLessonsProps> = ({
  showModal,
  closeModal,
  currentLessons,
  onSave,
  callBack
}) => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [selectedLessons, setSelectedLessons] = useState<string[]>([]);
  const [lessonsSource, setLessonsSource] = useState(Company_Value);
  const companyName = user?.profile[UserClaims.CompanyName] as string;
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId] as string;

  const allLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allLessons
  );
  const { t } = useTranslation(['common', 'lessons']);

  const lessonSources = [
    {
      text: companyName,
      value: Company_Value
    },
    {
      text: BuildWittName,
      value: Buildwitt_Value
    }
  ];

  React.useEffect(() => {
    if (params && params.id && user) {
      dispatch(actionCreators.requestAllLessons());
    }
  }, [dispatch, user]);

  const onChangeLessonSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLessons(currentSelection => {
      if (e.target.checked) {
        return [...currentSelection, e.target.id];
      } else {
        return currentSelection.filter(lessonId => lessonId !== e.target.id);
      }
    });
  };

  const handleSaveImportLessons = async () => {
    const lessonsToImport = allLessons
      ?.filter(lesson => selectedLessons.includes(lesson.id))
      .map(lesson => ({
        lessonId: lesson.id,
        companyId: lesson.companyId
      }));

    await onSave(lessonsToImport!);
    closeModal();

    if (callBack) callBack();
  };

  const filterParam =
    lessonsSource === Buildwitt_Value ? TemporalNewId : companyId;

  const lessonsToShow =
    allLessons?.filter(lesson => {
      const notInLessons = !currentLessons.some(
        currentLesson => currentLesson.lessonId === lesson.id
      );
      const isInCompany =
        (lessonsSource === Buildwitt_Value &&
          (lesson.companyId === filterParam ||
            lesson.companyId === bWCompanyId)) ||
        lesson.companyId === filterParam;
      return notInLessons && isInCompany;
    }) || [];

  return (
    <ModalPage
      title={t('selectLessons', {ns: 'lessons'})}
      show={showModal}
      showSave={true}
      showCancel={false}
      onSave={handleSaveImportLessons}
      onCancel={closeModal}
      saveText={t('save', {ns: 'common'})}
      cancelText={t('cancel', {ns: 'common'})}
      variant="lg"
      bodyClassName="flex-fill"
    >
      <Row xs={3} className="px-0 flex-shrink-0">
        <Col></Col>
        <Col className="text-right">
          <ButtonGroup>
            {lessonSources.map(source => (
              <BasicToggleButton
                key={`select-company-${source.value}`}
                id={`select-company-${source.value}`}
                type="radio"
                name="lessonsSource"
                value={source.value}
                checked={lessonsSource === source.value}
                color={lessonsSource === source.value ? 'primary' : 'secondary'}
                onChange={e => setLessonsSource(e.currentTarget.value)}
              >
                {source.text}
              </BasicToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="card-container flex-fill">
        {lessonsToShow.map(lesson => (
          <Col className=" mb-3 p-0" md={12} lg={6} key={lesson.id}>
            <div className="pr-3">
              <LessonCard lesson={lesson}>
                <SelectEntityButton
                  entity={lesson}
                  isSelected={selectedLessons.includes(lesson.id)}
                  onChangeSelection={onChangeLessonSelection}
                />
              </LessonCard>
            </div>
          </Col>
        ))}

        {lessonsToShow.length === 0 && (
          <Col className=" mb-3 p-0" xs={12}>
            {t('noLessonsAvailableFor', {ns: 'lessons'})}{' '}
            {lessonsSource === Buildwitt_Value ? 'BuildWitt' : companyName}
          </Col>
        )}
      </Row>
    </ModalPage>
  );
};

export default SelectLessons;
