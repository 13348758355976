import { Container, Paper, Typography } from '@mui/material';
import React from 'react';

interface SimpleFormPageProps extends React.PropsWithChildren<any> {
  title: React.ReactNode;
  aboveFormActions?: React.ReactNode;
}

export const SimpleFormPage = ({
  aboveFormActions,
  title = 'Title',
  children
}: SimpleFormPageProps) => {
  return (
    <>
      <Typography
        variant={'h3'}
        textAlign={'center'}
        sx={theme => ({
          fontWeight: 700,
          paddingBottom: '50px',
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        })}
      >
        {title}
      </Typography>
      <Container
        style={{
          marginTop: '-50px',
          marginBottom: '50px'
        }}
        sx={theme => ({
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        })}
      >
        {aboveFormActions}
      </Container>
      <Container component={Paper} fixed>
        <Container fixed maxWidth={'sm'} sx={{ paddingBottom: '60px' }}>
          {children}
        </Container>
      </Container>
    </>
  );
};
