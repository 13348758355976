import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  actionCreators,
  selectWorkExperienceById
} from '../../../actions/userProfile';
import { WorkExperienceDto } from '../../../entities/UserProfile';

import { ApplicationState } from '../../../store';
import FallbackLoader from '../../../components/FallbackLoader/FallbackLoader';
import { FormDualButtons } from '../FormDualButtons';
import { WorkExperienceForm } from './WorkExperienceForm';
import { SimpleFormPage } from '../../../components/Pages';
import { yupResolver } from '@hookform/resolvers/yup';
import { workExperienceValidationSchema } from './WorkExperienceValidationSchema';

export const WorkExperienceSectionUpdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{
    userProfileId: string;
    workExperienceId: string;
  }>();

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const workExperience = useSelector((state: ApplicationState) =>
    selectWorkExperienceById(state, params.workExperienceId)
  );

  const { control, handleSubmit, reset, setValue, watch, formState } =
    useForm<WorkExperienceDto>({
      resolver: yupResolver(workExperienceValidationSchema)
    });

  const goToProfile = () => {
    history.push(`/user/profile/${params.userProfileId}`);
  };

  const onSubmit: SubmitHandler<WorkExperienceDto> = data => {
    dispatch(
      actionCreators.updateWorkExperience(
        data,
        params.userProfileId,
        params.workExperienceId,
        history
      )
    );
  };

  React.useEffect(() => {
    if (workExperience) {
      reset({
        companyName: workExperience.companyName,
        description: workExperience.description,
        jobTitle: workExperience.jobTitle,
        startMonth: workExperience.startMonth,
        startYear: workExperience.startYear,
        endMonth: workExperience.endMonth,
        endYear: workExperience.endYear,
        isCurrentlyWorking:
          !workExperience.endMonth && !workExperience.endYear ? true : false
      });
    }
  }, [workExperience]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <SimpleFormPage title="Complete your Profile">
      <WorkExperienceForm
        title={'Work Experience'}
        submitHandler={onSubmit}
        setValue={setValue}
        handleSubmit={handleSubmit}
        watch={watch}
        control={control}
        formState={formState}
        bottomActions={
          <FormDualButtons
            cancelOnClick={goToProfile}
            submitButtonText={'Save Changes'}
          />
        }
      />
    </SimpleFormPage>
  );
};
