import { Lesson } from './Lesson';
import { Tag } from './Tag';

export interface LessonFormModel {
  id: string;
  title: string;
  description: string;
  contentMedium: number;
  thumbnailFile: File | null;
  thumbnailUrl: string;
  video: File | null;
  videoUrl: string;
  pdfFile: File | null;
  pdfUrl: string;
  externalVideoUrl: string;
  tags: Array<Tag>;
  assessmentId?: string;
  subtitles?: Array<Subtitles>
}

export interface Subtitles {
  id?: string,
  language: string,
  url?: string | null;
  subtitleFile?: File | null;
  fileType?: string | null;
}

export const ContentMedium = {
  Video: 0,
  Pdf: 1,
  Quiz: 3,
};
