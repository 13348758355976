import React, { useState, CSSProperties, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormLabel from '../core/FormLabel/FormLabel';
import { Question, Answer } from '../../entities/Assessment';
import { useTheme } from '@mui/material/styles';
import TextBox from '../core/TextBox/TextBox';
import BasicButton from '../core/BasicButton/BasicButton';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

export interface NumberQuestionProps {
  question: Question;
  questionResult?: Answer;
  setAssessmentResult: (answer: Answer) => void;
}

const NumberQuestion: React.FC<NumberQuestionProps> = ({
  question,
  questionResult,
  setAssessmentResult
}) => {
  const theme = useTheme();
  const [answer, setAnswer] = useState<string>('');
  const { t } = useTranslation(['assessments']);

  useEffect(() => {
    setAnswer('');
  }, [question]);

  useEffect(() => {
    setAnswer(questionResult?.answerNumber ?? '');
  }, [questionResult]);

  const answerMessageStyle: CSSProperties = {
    color: theme.palette.common.white,
    fontSize: '14px',
    height: '30px'
  };

  const onSubmitResponse = () => {
    if (questionResult) return;

    setAssessmentResult({
      answerNumber: answer,
      questionId: question.id,
      isCorrect: question.numberCorrectAnswer === answer,
      type: question && question.type ? question.type : '',
      order: question.order
    });
  };

  const isAnswerSubmitted = !!questionResult;
  const isSubmitButtonDisabled = isAnswerSubmitted || !answer;
  return (
    <Row>
      <Col>
        <FormLabel>{t('enterCorrectAnswer', {ns: 'assessments'})}</FormLabel>
        <h5>{question.questionText}</h5>
        <Row className="mt-3">
          <Col lg={12}>
            <AnswerWrapper
              isAnswerSubmitted={isAnswerSubmitted}
              isCorrect={answer === question.numberCorrectAnswer}
            >              
            <TextBox
              id="number"
              name="answer"
              value={answer?.toString() || ''}
              onChangeValue={value => setAnswer(value)}
              placeholderText={t('writeYourAnswer', {ns: 'assessments'})}
              width="100%"
              height="48px"
              disabled={isAnswerSubmitted}
              isNumber
            />
            </AnswerWrapper>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={12}>
            <BasicButton
              color="primary"
              onClick={onSubmitResponse}
              disable={isSubmitButtonDisabled}
            >
              {t('submitResponse', {ns: 'assessments'})}
            </BasicButton>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <div style={answerMessageStyle}>
              {isAnswerSubmitted &&
                answer === question.numberCorrectAnswer &&
                t('correctAnswer', {ns: 'assessments'})}
              {isAnswerSubmitted &&
                answer !== question.numberCorrectAnswer &&
                question.incorrectAnswerText}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const AnswerWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isCorrect' && prop !== 'isAnswerSubmitted'
})<{
  isCorrect: boolean;
  isAnswerSubmitted: boolean;
}>(props => ({
  outlineColor:
    props.isAnswerSubmitted && props.isCorrect
      ? props.theme.palette.success.main
      : props.theme.palette.error.light,
  outlineStyle: props.isAnswerSubmitted ? 'solid' : 'none',
  outlineWidth: props.isAnswerSubmitted ? '2px' : '0px',
  borderRadius: '5px'
}));

export default NumberQuestion;
