import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { ApplicationState } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';

export interface AppLoaderProps {
  variant: 'circular' | 'linear';
}

/* this component is used by the app when loading state is present
DO NOT use this component for other specific loading parts, this will be automatically used when appState.isLoading=true
in redux global state*/
const AppLoader: React.FC<AppLoaderProps> = ({ variant }) => {
  const isAppLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const loaderStyle = {
    display: isAppLoading ? 'block' : 'none',
    backgroundColor: '#000'
  };
  return variant == 'circular' ? (
    <CircularProgress style={loaderStyle} />
  ) : (
    <LinearProgress style={loaderStyle} />
  );
};
export default AppLoader;
