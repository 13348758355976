import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { FormTitle } from '../../../components/Pages';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { getYears } from '../../../utils/dateUtils';
import { EducationDto } from '../../../entities/UserProfile';
import React from 'react';
import TextBox from '../../../components/core/TextBox/TextBox';

interface EducationFormProps
  extends Pick<
    UseFormReturn<EducationDto>,
    'control' | 'handleSubmit' | 'formState'
  > {
  submitHandler: SubmitHandler<EducationDto>;
  title: string;
  bottomActions: React.ReactNode;
}

export const EducationForm = ({
  handleSubmit,
  submitHandler,
  bottomActions,
  title,
  control,
  formState
}: EducationFormProps) => {
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormTitle title={title} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.school}>
            <FormLabel>
              School <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="school"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>{formState.errors.school?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>Field of Study</FormLabel>
            <Controller
              name="fieldOfStudy"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>Degree Type</FormLabel>
            <Controller
              name="degreeType"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Select
                  id={name}
                  value={value || ''}
                  onChange={onChange}
                  labelId="degreeType"
                >
                  <MenuItem key={1} value={1}>
                    University
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    High School
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.graduationDate}>
            <FormLabel>
              Completion Year <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="graduationDate"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Select
                  id={name}
                  value={value || ''}
                  onChange={onChange}
                  labelId="graduationDate"
                >
                  {getYears().map((year: number) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.graduationDate?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Additional Details</FormLabel>
            <Controller
              name="details"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {bottomActions}
        </Grid>
      </Grid>
    </form>
  );
};
