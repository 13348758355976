import React, { CSSProperties } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Course } from '../../entities/Course';
import LessonType from '../LessonType/LessonType';
import { LessonTypeViewEnum } from '../../core/enums';
import ProgressLine from '../ProgressLine/ProgressLine';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

export interface CourseInfoPanelProps {
  course: Course;
  totalLessonsCount: number;
  completedLessonsCount: number;
  isLoadingInfo?: boolean;
}

const progressSection = {
  display: 'flex',
  height: '20%',
  width: '100%',
  flexDirection: 'column' as 'column'
};

const CourseInfoPanel: React.FC<CourseInfoPanelProps> = ({
  course,
  completedLessonsCount,
  totalLessonsCount,
  isLoadingInfo
}) => {
  const { videosCount, flashCardsCount, quizesCount } = course;
  const { t } = useTranslation(['lessons', 'courses']);
  const PanelSectionStyle: CSSProperties = {
    maxWidth: '70%'
  };

  return (
    <>
      <Row>
        <Col className="d-inline">
          <LessonType
            viewType={LessonTypeViewEnum.CourseLessonTypesCount}
            videosCount={videosCount}
            flashCardsCount={flashCardsCount}
            quizesCount={quizesCount}
          />
        </Col>
      </Row>
      <Row className="pt-2" style={PanelSectionStyle}>
        <Col>
          <Row>
            <Col>{course?.description}</Col>
          </Row>
          <Row className="pt-4">
            <Col>
              {course?.tags.map(tag => (
                <div key={tag.id} className="tag me-3">
                  {tag.name}
                </div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
      {totalLessonsCount > 0 && (
        <Row style={PanelSectionStyle}>
          <div className="d-flex flex-row justify-content-between mt-5 mb-1">
            <span>{t('courseProgress', {ns: 'courses'})}</span>
            <span>
              {isLoadingInfo ? '?' : completedLessonsCount} /{' '}
              {totalLessonsCount} {t('lessons', {ns: 'lessons'})}
            </span>
          </div>
          <div style={progressSection}>
            {isLoadingInfo && <LinearProgress />}
            {!isLoadingInfo && (
              <ProgressLine
                color={'#44d47a'}
                value={(completedLessonsCount / totalLessonsCount) * 100}
                width="100%"
              ></ProgressLine>
            )}
          </div>
        </Row>
      )}
    </>
  );
};

export default CourseInfoPanel;
