import { Grid } from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { BasicButtonTypeEnum } from '../../core/enums';
import React from 'react';

interface FormDualButtonsProps {
  cancelOnClick: () => void;
  submitButtonText?: string;
}

export const FormDualButtons = ({
  cancelOnClick,
  submitButtonText = 'Finish'
}: FormDualButtonsProps) => {
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={6} textAlign="right">
        <BasicButton
          color="secondary"
          onClick={cancelOnClick}
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              height: 'auto'
            }
          })}
        >
          Cancel
        </BasicButton>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="left">
        <BasicButton
          type={BasicButtonTypeEnum.Submit}
          color="primary"
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              height: 'auto'
            }
          })}
        >
          {submitButtonText}
        </BasicButton>
      </Grid>
    </Grid>
  );
};
