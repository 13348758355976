import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../../actions/courses';
import { actionCreators as actionLessons } from '../../actions/lessons';
import { actionCreators as actionAssessments } from '../../actions/assessments';
import { actionCreators as actionStudent } from '../../actions/student';
import { ApplicationState } from '../../store';
import FlashCardSection from './categories/FlashCardSection';
import QuizSection from './categories/QuizSection';
import VideoSection from './categories/VideoSection';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import PDF from './categories/PDF';
import BackwardIcon from '../../assets/backward.svg';
import { Lesson } from '../../entities/Lesson';
import LessonAssessments from './LessonAssessments';
import { TinyLessonCard } from '../../components/LessonCard/TinyLessonCard';
import { Guid } from 'guid-typescript';
import { useLogEvent } from '../../hooks/useLogEvent';
import useCurrentUser from '../../hooks/useCurrentUser';
import CompletedCourseNotice from './CompletedCourseNotice';
import { LinearProgress } from '@mui/material';
import { LessonVideo, LessonQuiz } from '../../core/constants';

const LessonPreview: React.FC = () => {
  const params = useParams<{ id: string; revision: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const course = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const lessons = useSelector(
    (state: ApplicationState) => state.lessons?.lessons
  );
  const assessment = useSelector(
    (state: ApplicationState) => state.assessments?.currentAssessment
  );
  const completeLessons = useSelector(
    (state: ApplicationState) => state.student?.completedLessons
  );
  const listFailedQuiz = useSelector(
    (state: ApplicationState) => state.assessments?.failedQuizzes
  );
  const location = useLocation<any>();
  const [showCompleteCourse, setShowCompleteCourse] = useState<boolean>(
    (location.state && location.state.courseCompletedWithQuiz) ?? false
  );
  const [
    isMarkingCompleteInProgress,
    setIsMarkingCompleteInProgress
  ] = useState(false);
  const [lessonType, setLessonType] = useState<string>('');
  const [currentLesson, setCurrentLesson] = useState<Lesson>();
  const [activeLesson, setActiveLessonLesson] = useState<string>('');
  const [showAssessment, setShowAssessment] = useState<boolean>(false);
  const selectedIndexLessonRef = useRef<number>();
  const isInPlayMode = location.pathname.match(/play$/) !== null;

  const getLesson = (
    lesson: Lesson,
    lessonid: string,
    lessonType: string,
    index: number,
    activeLesson: string
  ) => {
    if (
      (lessonType === LessonVideo && lessonid != activeLesson) ||
      lessonType === LessonQuiz
    ) {
      setCurrentLesson(lesson);
      setLessonType(lessonType);
      setActiveLessonLesson(lessonid);
      selectedIndexLessonRef.current = index;

      if (lessonType === LessonVideo) {
        dispatch(actionLessons.setCurrentLesson(null));
        dispatch(actionAssessments.requestAssessment(lessonid));
        dispatch(actionLessons.resetPositionTracking());
      }

      if (lessonType == LessonQuiz) {
        dispatch(actionLessons.setCurrentLesson(lesson));
      }

      logEvent.logPlayLessonEvent(lesson.id);
    }
  };

  useEffect(() => {
    if (location.state) {
      setCurrentLesson(location.state.lesson);
      setLessonType(location.state.lesson.lessonType);
      setActiveLessonLesson(location.state.lesson.id);

      if (location.state.lesson.lessonType === LessonVideo)
        dispatch(actionAssessments.requestAssessment(location.state.lesson.id));

      if (location.state.lesson.lessonType === LessonQuiz) {
        dispatch(actionLessons.setCurrentLesson(location.state.lesson));
      }
    }
    if (params && params.id && params.id !== Guid.EMPTY && params.revision) {
      dispatch(actionCreators.requestCourseById(params.id));
      dispatch(
        actionLessons.requestCourseLessonsByRevision(
          params.id,
          parseInt(params.revision)
        )
      );
    }
  }, [params]);

  useEffect(() => {
    if (isInPlayMode && course) {
      dispatch(
        actionStudent.getCompletedLessonsByCourseId(
          course!.id,
          course!.revisionNumber
        )
      );
    }
  }, [course, isInPlayMode]);

  const backToPreviousPage = () => {
    history.goBack();
  };

  const markLessonAsCompleted = async (
    lessonId: string,
    courseId?: string,
    revisionNumber?: number
  ) => {
    setIsMarkingCompleteInProgress(true);
    const wasCourseCompleted = ((await dispatch(
      actionStudent.markLessonAsCompleted(lessonId, courseId, revisionNumber)
    )) as unknown) as boolean;

    if (wasCourseCompleted) {
      setShowCompleteCourse(true);
    }

    setIsMarkingCompleteInProgress(false);
  };

  const handleLessonEnded = (lesson: Lesson) => async () => {
    if (assessment && assessment.questions.length) {
      setShowAssessment(true);
    } else {
      if (isInPlayMode) {
        await markLessonAsCompleted(
          lesson.id,
          course?.id,
          course?.revisionNumber
        );
      }
    }
  };

  const playNextLesson = async () => {
    if (lessons && lessons.length) {
      if (selectedIndexLessonRef.current === undefined) {
        selectedIndexLessonRef.current = lessons.findIndex(
          lesson => lesson.id === currentLesson?.id
        );
      }

      if (isInPlayMode) {
        await markLessonAsCompleted(
          currentLesson!.id,
          course?.id,
          course?.revisionNumber
        );
      }

      if (selectedIndexLessonRef.current < lessons?.length - 1) {
        const newIndex = selectedIndexLessonRef.current + 1;
        const nextLesson = lessons[newIndex];

        getLesson(
          nextLesson,
          nextLesson.id,
          nextLesson.lessonType,
          newIndex,
          activeLesson
        );
      }
    }
    setShowAssessment(false);
  };

  if (!course && params && params.id !== Guid.EMPTY && params.id !== undefined)
    return <></>;
  else
    return (
      <PageContainer>
        <Row className="pb-0">
          <Col md={12}>
            <div>
              <h1 className="color-white">
                <i
                  className="fas fa-sm fa-fw mr-2 text-gray-400"
                  style={BackwardIconStyle}
                  onClick={backToPreviousPage}
                />{' '}
                {course?.title}
              </h1>
            </div>
          </Col>
          <Col xl={9} lg={8}>
            {lessonType == 'Quiz' && <QuizSection />}
            {lessonType == 'Video' && (
              <VideoSection
                currentlesson={currentLesson!}
                onVideoEnded={handleLessonEnded(currentLesson!)}
                onLoaded={true}
              />
            )}
            {lessonType == 'FlashCard' && <FlashCardSection />}
            {lessonType == 'PDF' && <PDF currentlesson={currentLesson!} />}
            {showCompleteCourse && (
              <CompletedCourseNotice
                onClickContinue={() => setShowCompleteCourse(false)}
              />
            )}
            {isMarkingCompleteInProgress && (
              <div className="d-flex flex-column w-100">
                <LinearProgress />
              </div>
            )}
          </Col>
          <Col style={{ ...sidebar }} xl={3} lg={4}>
            {lessons
              ?.filter(x => !x.isVideoEncoding)
              .map((lesson, index) => {
                const isCompleted =
                  completeLessons?.some(
                    completeLesson =>
                      lesson.id === completeLesson.lessonId || lesson.isComplete
                  ) ?? false;
                return (
                  <TinyLessonCard
                    key={lesson.id}
                    lesson={lesson}
                    listFailedQuiz={listFailedQuiz!}
                    onClick={() =>
                      getLesson(
                        lesson,
                        lesson.id,
                        lesson.lessonType,
                        index,
                        activeLesson
                      )
                    }
                    isCompleted={isCompleted && isInPlayMode}
                    index={index}
                    activeLesson={activeLesson}
                  />
                );
              })}
          </Col>
        </Row>
        {assessment && (
          <LessonAssessments
            show={showAssessment}
            assessment={assessment}
            goNextLesson={playNextLesson}
            isInPlayMode={isInPlayMode}
            isModalBusy={isMarkingCompleteInProgress}
          />
        )}
      </PageContainer>
    );
};

const sidebar: CSSProperties = {
  borderRadius: '5px',
  height: '620px',
  overflow: 'auto',
  backgroundColor: '#212121'
};

const BackwardIconStyle: CSSProperties = {
  display: 'inline-block',
  width: 10,
  height: 18,
  cursor: 'pointer',
  backgroundImage: `url(${BackwardIcon})`
};

export default LessonPreview;
