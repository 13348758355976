import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormReturn
} from 'react-hook-form';
import { getMonths, getYears } from '../../../utils/dateUtils';
import { DropdownItemNumber } from '../../../entities/Common';
import React from 'react';
import { WorkExperienceDto } from '../../../entities/UserProfile';
import { FormTitle } from '../../../components/Pages';
import TextBox from '../../../components/core/TextBox/TextBox';

interface WorkExperienceFormProps
  extends Pick<
    UseFormReturn<WorkExperienceDto>,
    'control' | 'handleSubmit' | 'watch' | 'setValue' | 'formState'
  > {
  title: string;
  submitHandler: SubmitHandler<WorkExperienceDto>;
  bottomActions: React.ReactNode;
}

export const WorkExperienceForm = ({
  handleSubmit,
  control,
  watch,
  setValue,
  formState,
  submitHandler,
  bottomActions,
  title
}: WorkExperienceFormProps) => {
  const watchIsCurrentlyWorking = watch('isCurrentlyWorking');

  React.useEffect(() => {
    if (watchIsCurrentlyWorking) {
      setValue('endMonth', '', { shouldValidate: true });
      setValue('endYear', '', { shouldValidate: true });
    }
  }, [watchIsCurrentlyWorking]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormTitle title={title} />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.companyName}>
            <FormLabel>
              Company <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="companyName"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.companyName?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControl fullWidth error={!!formState.errors.jobTitle}>
              <FormLabel>
                Job Title <Typography variant="redCaption">*</Typography>
              </FormLabel>
              <Controller
                name="jobTitle"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <TextBox
                    id={name}
                    name={name}
                    value={value || ''}
                    onChangeValue={onChange}
                  />
                )}
              />
              <FormHelperText>
                {formState.errors.jobTitle?.message}
              </FormHelperText>
            </FormControl>
            <FormControlLabel
              control={
                <Controller
                  name="isCurrentlyWorking"
                  control={control}
                  defaultValue={false}
                  render={({ field: { name, onChange, value } }) => (
                    <Checkbox
                      name={name}
                      checked={!!value}
                      onChange={onChange}
                    />
                  )}
                />
              }
              label="I am currently working on this role"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.startMonth}>
            <FormLabel>
              Start Month <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="startMonth"
              control={control}
              render={({ field: { name, onChange, onBlur, value } }) => (
                <Select
                  id={name}
                  value={value || ''}
                  onChange={selected => {
                    onChange(selected);
                  }}
                  onBlur={onBlur}
                  labelId="startMonth"
                >
                  {getMonths().map((month: DropdownItemNumber) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.startMonth?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.startYear}>
            <FormLabel>
              Start Year <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="startYear"
              control={control}
              render={({ field: { name, onChange, onBlur, value } }) => (
                <Select
                  id={name}
                  value={value || ''}
                  onChange={selected => {
                    onChange(selected);
                  }}
                  onBlur={onBlur}
                  labelId="startYear"
                >
                  {getYears().map((year: number) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.startYear?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.endMonth}>
            <FormLabel>End Month</FormLabel>
            <Controller
              name="endMonth"
              control={control}
              render={({ field: { name, onChange, onBlur, value } }) => (
                <Select
                  id={name}
                  value={value || ''}
                  onChange={selected => {
                    onChange(selected);
                  }}
                  onBlur={onBlur}
                  disabled={watchIsCurrentlyWorking}
                  labelId="endMonth"
                >
                  {getMonths().map((month: DropdownItemNumber) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.endMonth?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.endYear}>
            <FormLabel>End Year</FormLabel>
            <Controller
              name="endYear"
              control={control}
              render={({ field: { name, onChange, onBlur, value } }) => (
                <Select
                  id={name}
                  value={value || ''}
                  onChange={selected => {
                    onChange(selected);
                  }}
                  onBlur={onBlur}
                  disabled={watchIsCurrentlyWorking}
                  labelId="endYear"
                >
                  {getYears().map((year: number) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>{formState.errors.endYear?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.description}>
            <FormLabel>
              Responsibilities <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.description?.message}
            </FormHelperText>
          </FormControl>
          <Typography variant="blackCaption">
            Include keywords regarding specific types of equipment, materials,
            software or projects.
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {bottomActions}
        </Grid>
      </Grid>
    </form>
  );
};
