import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { EducationDto } from '../../../entities/UserProfile';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';
import { getDegreeType } from '../../../utils/stringUtils';

export const EducationSectionDetailsItem = ({
  education,
  userProfileId
}: {
  education: EducationDto;
  userProfileId: string;
}) => {
  const history = useHistory();

  const editEducationDetails = () => {
    history.push(
      `/user/profile/${userProfileId}/education/${education.id}/edit`
    );
  };

  return (
    <div>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'flex-start'}
        spacing={2}
        elevation={0}
        component={Paper}
        sx={{
          backgroundColor: 'rgba(237, 236, 232, 0.5)',
          padding: 2,
          border: '1px solid #ccc'
        }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent={'space-between'}
          flexWrap={'nowrap'}
        >
          <Typography variant="blackTitleMedium">{education.school}</Typography>
          <Button
            variant="edit"
            endIcon={<EditIcon />}
            onClick={editEducationDetails}
          >
            Edit
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="blackSubTitle">
              {getDegreeType(education.degreeType)}
            </Typography>
            <Typography variant="blackSubTitle">
              {education.fieldOfStudy}
            </Typography>
            <Typography variant="blackCaption">
              {education.graduationDate}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
