import { appPermissions, UserClaims, UserRoles } from '../core/constants';

import useCurrentUser from './useCurrentUser';

const permissionMap: { [key: string]: string[] } = {
  [UserRoles.CompanyAdmin]: [],
  [UserRoles.Employee]: [
    appPermissions.PREVIEW_COURSE,
    appPermissions.VIEW_DASHBOARD,
    appPermissions.VIEW_EMPLOYEE_ASSIGNMENTS,
    appPermissions.VIEW_ASSIGNMENTS,
    appPermissions.VIEW_COURSES,
    appPermissions.ADD_LESSON,
    appPermissions.VIEW_LESSONS,
    appPermissions.VIEW_PROGRESS,
    appPermissions.VIEW_MESSAGES,
    appPermissions.VIEW_SETTINGS,
    appPermissions.VIEW_LEARNING_PLANS
  ]
};

const permissionIsInRole = (userRole: string) => (action: string) => {
  // For now, admin user will have access to anything
  if (userRole === UserRoles.CompanyAdmin) {
    return true;
  }
  return permissionMap[userRole].includes(action);
};

const usePermissions = () => {
  const user = useCurrentUser();
  const userRole = user?.profile[UserClaims.UserRole] as
    | string
    | string[];
  let isAdmin = false;

  if (Array.isArray(userRole)) {
    isAdmin = (userRole as string[]).includes(UserRoles.CompanyAdmin);
  } else {
    isAdmin = (userRole as string) === UserRoles.CompanyAdmin;
  }

  const finalUserRole = isAdmin ? UserRoles.CompanyAdmin : UserRoles.Employee;
  return permissionIsInRole(finalUserRole);
};

export default usePermissions;
