import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/address';
import {
  COUNTRIES_IS_LOADING,
  COUNTRIES_SET_COUNTRIES,
  STATES_SET_STATES,
  CITIES_SET_CITIES,
  STATES_IS_LOADING,
  CITIES_IS_LOADING
} from '../actions/actionTypes';
import { Country, State } from '../entities/Countries';

export interface AddressesState {
  isLoadingCountries: boolean;
  isLoadingStates: boolean;
  isLoadingCities: boolean;
  countries: Country[];
  states: State[];
  cities: string[];
}

const unloadState: AddressesState = {
  isLoadingCountries: false,
  isLoadingStates: false,
  isLoadingCities: false,
  countries: [],
  states: [],
  cities: []
};

export const AddressesReducers: Reducer<AddressesState> = (
  state: AddressesState | undefined,
  incomingAction: Action
): AddressesState => {
  if (state === undefined) {
    return unloadState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case COUNTRIES_IS_LOADING:
      return { ...state, isLoadingCountries: action.isLoadingCountries };
    case STATES_IS_LOADING:
      return { ...state, isLoadingStates: action.isLoadingStates };
    case CITIES_IS_LOADING:
      return { ...state, isLoadingCities: action.isLoadingCities };
    case COUNTRIES_SET_COUNTRIES:
      return { ...state, countries: action.countries };
    case STATES_SET_STATES:
      return { ...state, states: action.states };
    case CITIES_SET_CITIES:
      return { ...state, cities: action.cities };
    default:
      return state;
  }
};
