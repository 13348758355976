import React from 'react';
import { AuthProvider } from 'react-oidc-context';

import { User } from 'oidc-client-ts';
import config from '../../config';
import { Login } from './Login';

export interface IPrivateRouteProps {
  children: any;
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children }) => {
  const onSigninCallback = (user: User | void) => {
    if (user) {
      if (user.state && typeof user.state === 'string') {
        window.location.href = user.state;
      } else {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    }
  };

  return (
    <AuthProvider
      authority={config.AUTHORITY ?? ''}
      client_id={config.CLIENT_ID ?? ''}
      redirect_uri={config.REDIRECT_URI}
      client_secret={config.CLIENT_SECRET}
      loadUserInfo={true}
      onSigninCallback={onSigninCallback}
      scope={config.SCOPES}
      automaticSilentRenew
    >
      <Login>{children}</Login>
    </AuthProvider>
  );
};

export default PrivateRoute;
