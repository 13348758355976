import React, {CSSProperties}  from 'react';
import { Col, Row, ProgressBar, Container, Button } from 'react-bootstrap';
import { Lesson } from '../../../entities/Lesson';
import { useTranslation } from 'react-i18next';

interface PDFProps  {
    currentlesson: Lesson
}

const PDF: React.FC<PDFProps> = ({currentlesson}) => {
    const { t } = useTranslation(['common']);

    return (
    <>
        {currentlesson.learningMaterialUrl ?
        ( <embed src={currentlesson.learningMaterialUrl} width="100%" height="80%" 
        type="application/pdf"></embed>
        ) : (
            <h3>{t('noLearningMaterial', {ns: 'common'})}</h3>
        )
        }

        <div>
            <Row>
                <Col style={infoSectionBottom} md={12}>
                <h3>{currentlesson.title}</h3>
                <p>{currentlesson.description}</p>
                </Col>
            </Row>
        </div>
        
    </>
    )
}


const infoSectionBottom: CSSProperties = {
    justifyContent: 'space-between',
}

export default PDF;