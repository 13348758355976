import React, { CSSProperties } from 'react';
import { makeStyles } from '@mui/styles';
import { useSignOut } from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import {
  AboutTheDirtWorldItems,
  CareersInDirtWorldItems,
  ContactUsItems,
  ContractorResourcesItems,
  DirtWorldLifeStyleItems,
  MissionItems
} from './TopMenuElementsMobile';

type Anchor = 'top' | 'left' | 'bottom' | 'right';


const useStyles = makeStyles(() => ({
  hamburger: {
    transition: 'transform 0.2s ease-out',
    '&.open': {
      transform: 'rotate(90deg)',
    }
  },
  accordion: {
    paddingInline: 68,
    "&.MuiAccordion-root:before": {
      height: 0
    }
  }
}));

export const TopMenuAccountMobile: React.FC<{}> = () => {
  const { signOut } = useSignOut();
  const anchor = 'top';
  const classes = useStyles();
  const history = useHistory();
  const userProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );

  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const { t } = useTranslation(['common']);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const accordionStyle = {
    backgroundColor: '#262625',
    border: 'none'
  }

  const logout = async () => {
    /** TODO: Card 726 investigate why auth.signoutRedirect() doesn't work as expected */
    //user.userManager.signoutRedirect();
    handleCloseUserMenu();
    await signOut();
  };

  const goToUserProfileDetails = () => {
    handleCloseUserMenu();
    history.push(`/user/profile/${userProfile?.id}`);
  };

  const goToCompanyProfileDetails = () => {
    handleCloseUserMenu();
    history.push(`/company/profile/${currentCompanyProfile?.id}`);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, background: '#262625'
      }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Accordion className={classes.accordion} sx={{...accordionStyle, marginTop: 4}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white', fontSize: 20 }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <span style={titleStyle}>About the Dirt World</span>
        </AccordionSummary>
        <AccordionDetails>
          <AboutTheDirtWorldItems />
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} sx={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white', fontSize: 20 }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <span style={titleStyle}>Mission</span>
        </AccordionSummary>
        <AccordionDetails>
          <MissionItems />
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} sx={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white', fontSize: 20 }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <span style={titleStyle}>Careers in Dirt World</span>
        </AccordionSummary>
        <AccordionDetails>
          <CareersInDirtWorldItems />
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} sx={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white', fontSize: 20 }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <span style={titleStyle}>Dirt World Lifestyle</span>
        </AccordionSummary>
        <AccordionDetails>
          <DirtWorldLifeStyleItems />
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} sx={accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'white', fontSize: 20 }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <span style={titleStyle}>Contractor Resources</span>
        </AccordionSummary>
        <AccordionDetails>
          <ContractorResourcesItems />
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={false} className={classes.accordion} sx={accordionStyle}>
        <AccordionSummary>
          <a
            href="https://dirtworld.com/contact"
            target="_blank"
            rel="noreferrer"
          >
            <span style={titleStyle}>Contact us</span>
          </a>
        </AccordionSummary>
      </Accordion>

      {userProfile && (
        <Accordion className={classes.accordion} sx={{...accordionStyle, marginBottom: 7}}>
          <AccordionSummary
            sx={{ backgroundColor: '#4D4E49', border: 'none', borderRadius: 22 }}
            expandIcon={<ExpandMoreIcon sx={{ color: 'white', fontSize: 20 }} />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <span style={titleStyle}>
              {userProfile.name} {userProfile.lastName}
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={2}>
              <MenuItem
                key="editDirtWorldProfile"
                onClick={goToUserProfileDetails}
              >
                <Typography textAlign="center" color="white">
                  Edit Dirt World Profile
                </Typography>
              </MenuItem>
              {currentCompanyProfile && (
                <MenuItem
                  key="editCompanyProfile"
                  onClick={goToCompanyProfileDetails}
                >
                  <Typography textAlign="center" color="white">
                    Edit Company Profile
                  </Typography>
                </MenuItem>
              )}
              <MenuItem key="logout" onClick={logout}>
                <Typography textAlign="center" color="white">
                  {t('signOut', { ns: 'common' })}
                </Typography>
              </MenuItem>
            </Stack>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', flexGrow: 0 }}
      key={anchor}
    >
      <IconButton className={`${classes.hamburger} ${!!state[anchor] && 'open'}`} sx={{ p: 0, ml: 2 }} onClick={toggleDrawer(anchor, !state[anchor])}>
        {!!state[anchor] ? <CloseIcon style={{ color: '#fff', fontSize: '32px' }} /> : <MenuIcon style={{ color: '#fff', fontSize: '32px' }}/>}
      </IconButton>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        sx={{
          "& .MuiDrawer-paper": {
            top: 56,
            borderTop: '1px solid gray'
          },
        }}
      >
        {list(anchor)}
      </Drawer>
    </Box>
  );
};

const titleStyle: CSSProperties = {
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#FFF'
};
