import React, { useEffect, useState } from 'react';
import TrueFalseQuestion2 from './TrueFalseQuestion2';
import { AnswerStatus, Question2ContainerProps } from './Types';

const TrueFalseQuestion2Container: React.FC<Question2ContainerProps> = ({
  question,
  savedAnswer,
  setAnswer,
  isShowingResultsView,
  shouldShowCorrectAnswer
}) => {
  const [answerStatus, setAnswerStatus] = useState(
    isShowingResultsView ? AnswerStatus.AnswerSubmitted : AnswerStatus.NoAnswer
  );

  const onSelectAnswer = (answer?: boolean) => {
    if (!isShowingResultsView) {
      setAnswerStatus(AnswerStatus.AnsweredSelected);
      setAnswer({
        questionId: question.id,
        type: question.type!,
        answerChoice: answer,
        isCorrect: answer === question.trueIsCorrectAnswer,
        order: question.order
      });
    }
  };

  useEffect(() => {
    if (isShowingResultsView) {
      setAnswerStatus(AnswerStatus.AnswerSubmitted);
    }
  }, [isShowingResultsView])

  return (
    <TrueFalseQuestion2
      questionText={question.questionText}
      showFeedback={isShowingResultsView && shouldShowCorrectAnswer}
      correctAnswer={question.trueIsCorrectAnswer!}
      answerStatus={answerStatus}
      onAnswerReceived={onSelectAnswer}
      receivedAnswer={savedAnswer?.answerChoice}
    />
  );
};
export default TrueFalseQuestion2Container;
