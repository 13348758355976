import React, { CSSProperties, useEffect, useState } from 'react';
import TextArea from '../core/TextArea/TextArea';
import FormLabel from '../core/FormLabel/FormLabel';
import FileInput from '../core/FileInput/FileInput';
import TextBox from '../core/TextBox/TextBox';
import { Radio } from '@mui/material';
import { Question } from '../../entities/Assessment';
import { Option } from '../../entities/Assessment';
import { truncateAzureUrl } from '../../utils/stringUtils';
import { useTranslation } from 'react-i18next';

const panelStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column' as 'column'
};

interface MultipleChoiceQuestionDefinitionProps {
  question: Question;
  changeHandler: (prop: string, value: any) => void;
}
interface OptionEditMode extends Option {
  _id?: string;
}

let idOptionCounter = 0;

export const getNextOptionId = () => {
  return `option-${idOptionCounter++}`;
};

const MultipleChoiceQuestionDefinition: React.FC<MultipleChoiceQuestionDefinitionProps> = ({
  question,
  changeHandler
}) => {
  const [fileError, setFileError] = useState<boolean>(false);
  const [questionError, setQuestionError] = useState<boolean>(false);
  const [optionError, setOptionError] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionEditMode[]>([]);
  const { t } = useTranslation(['assessments', 'common']);

  useEffect(() => {
    if (question.options?.length === 0) {
      setOptions([
        {
          _id: 'option-0',
          id: '',
          optionLabel: '',
          optionText: '',
          isCorrect: false
        },
        {
          _id: 'option-1',
          id: '',
          optionLabel: '',
          optionText: '',
          isCorrect: false
        },
        {
          _id: 'option-2',
          id: '',
          optionLabel: '',
          optionText: '',
          isCorrect: false
        },
        {
          _id: 'option-3',
          id: '',
          optionLabel: '',
          optionText: '',
          isCorrect: false
        }
      ]);
    } else {
      setOptions(question.options!);
    }
  }, [question.options]);

  const optionsUI = ['A', 'B', 'C', 'D'];

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return validImageTypes.includes(fileType);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      changeHandler('imageFile', file);
      const fileType = file['type'];
      if (IsValidImageFormat(fileType)) {
        setFileError(false);
      } else {
        setFileError(true);
      }
    } else {
      setFileError(true);
    }
  };

  const handleChangeRadio = (option: OptionEditMode) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newOptions = options.map(opt =>
      opt === option
        ? { ...option, isCorrect: event.target.checked }
        : { ...opt, isCorrect: false }
    );
    changeHandler('options', newOptions);
    setOptions(newOptions);
  };

  const handleChangeText = (option: OptionEditMode) => (value: string) => {
    const newOptions = options.map(opt =>
      opt === option ? { ...option, optionText: value } : opt
    );
    changeHandler('options', newOptions);
    setOptions(newOptions);
  };

  return (
    <div style={panelStyle}>
      <FormLabel>{t('uploadImage', {ns: 'common'})}</FormLabel>
      <FileInput
        initialText={t('chooseFile', {ns: 'common'})}
        onChange={handleChangeFile}
        id="fileInput"
        name="fileInput"
        type="thumbnail"
      />
      {question.imageUrl && <span>{truncateAzureUrl(question.imageUrl)}</span>}
      {fileError && <p className="text-danger">{t('incorrectImageFormat', {ns: 'common'})}</p>}
      <br />
      <FormLabel>
        {t('question', {ns: 'assessments'})} <span className="text-danger">*</span>
      </FormLabel>
      <TextArea
        id=""
        name="questionText[]"
        value={question.questionText}
        onChangeValue={value => changeHandler('questionText', value)}
        placeholderText={t('writeQuestionHere', {ns: 'assessments'})}
        width="100%"
        rows={5}
      ></TextArea>
      {questionError && (
        <p className="text-danger"> {t('questionFieldRequired', {ns: 'assessments'})} </p>
      )}
      <br />
      <FormLabel>
        {t('checkCorrectResponse', {ns: 'assessments'})}{' '}
        <span className="text-danger">*</span>
      </FormLabel>
      <div style={panelStyle}>
        {options.map((opt: any, index: number) => (
          <React.Fragment key={index}>
            <div>
              <Radio
                checked={opt.isCorrect}
                onChange={handleChangeRadio(opt)}
                name="radio-buttons"
                inputProps={{ 'aria-label': `${optionsUI[index]}` }}
                style={paddingRadio}
                sx={colorRadio}
              />
              <TextBox
                id={`option${optionsUI[index]}`}
                name={`option${optionsUI[index]}`}
                value={opt.optionText}
                onChangeValue={handleChangeText(opt)}
                placeholderText={`${t('answer', {ns: 'assessments'})} ${optionsUI[index]}`}
                width="93%"
                height="35px"
              ></TextBox>
            </div>
            <br />
          </React.Fragment>
        ))}
        {optionError && <p className="text-danger">{t('optionRequired', {ns: 'common'})}</p>}
        <br />
      </div>
    </div>
  );
};

const colorRadio: CSSProperties = {
  color: '#FFFFFF'
};

const paddingRadio: CSSProperties = {
  paddingRight: 15
};

export default MultipleChoiceQuestionDefinition;
