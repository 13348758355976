import { Button, Grid, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { useHistory, useParams } from 'react-router';
import { UserProfileDetailParams } from '../../../entities/Common';
import { Card } from '../../../components/Card';
import { CardHeader } from '../../../components/Card/CardHeader';

export const CertificationSectionEmpty = () => {
  const { userProfileId } = useParams<UserProfileDetailParams>();
  const history = useHistory();
  const createCertification = () => {
    history.push(`/user/profile/${userProfileId}/certification/create`);
  };
  return (
    <Card
      gridProps={{
        spacing: 2,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CardHeader title={'Licenses / Certifications'} />
      <Grid item xs={12}>
        <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
          <Typography variant="blackSubTitle">
            Section is empty. Please create one.
          </Typography>
          <Button
            variant="edit"
            endIcon={<EditIcon />}
            onClick={createCertification}
          >
            Create
          </Button>
        </Stack>
      </Grid>
    </Card>
  );
};
