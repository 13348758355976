import React, { CSSProperties, useState } from 'react';
import { Grid, Paper, Typography, Stack } from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useHistory } from 'react-router';
import { ApplicationState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { JobPostForm } from '../../entities/JobPostForm';
import { CreatePostJobDto } from '../../entities/Dto/createJobPostDto';
import HttpStatusError from '../../core/CustomErrors/HttpRequestError';
import { useTranslation } from 'react-i18next';
import { actionCreators as jobsActions } from '../../actions/jobs';

const DuplicatePostJob = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'lessons']);
  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const [jobInfo, setJobInfo] = useState(
    props.location.state.jobDetailData
  );
  const [createError, setCreateError] = useState<string>('');

  const onSaveDuplicatePostJob = async (jobPostData: any) => {
    const jobPostDto: CreatePostJobDto = {
      jobTitle: `${jobPostData.jobTitle} (duplicate)`,
      title: `${jobPostData.jobTitle} (duplicate)`,
      jobCategory: jobPostData.jobCategory,
      jobType: jobPostData.jobType,
      compensation: jobPostData.compensation,
      postingAddress: jobInfo.postingAddress ? jobPostData.postingAddress : null,
      description: jobPostData.description,
      howToApply: jobPostData.howToApply,
      contactEmailAddress: jobPostData.contactEmailAddress,
      expiresAtUtc: jobPostData.expiresAtUtc,
      companyProfileId: currentCompanyProfile!.id
    };
  
    try {
      let createdPostJobId = '';
      createdPostJobId = (await dispatch(
        jobsActions.duplicateJobPost(jobPostDto, jobInfo.id, history)
      )) as unknown as string;
      return createdPostJobId;
    } catch (e) {
      if (!(e instanceof HttpStatusError)) {
        const error = e as any;
        const errorMessage = !error.response
          ? t('networkError', { ns: 'common' })
          : t('anErrorOccurred', { ns: 'common' });
        setCreateError(errorMessage);
      }
      throw e;
    }
  };

  const goToCompanyProfileDetails = () => {
    history.push(
      `/company/profile/${currentCompanyProfile?.id}`
    );
  };

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} style={{ paddingLeft: 80, paddingRight: 80 }}>
        <Grid item xs={2} md={2}></Grid>
        <Grid item xs={8} md={8}>
          <Paper
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundColor: '#fff',
              height: '400px',
              borderRadius: '10px'
            }}
          >
            <div style={openJobsContainer}>
              <Grid item xs={12} md={12} style={{ width: '100%' }}></Grid>
              <Grid item xs={12} md={12} style={{ width: '100%' }}>
                <div style={openJobsMiddleStyle}>
                  <Typography style={labelConfirmationStyle}>
                    Duplicate Job Post?
                  </Typography>
                </div>
                <div style={buttonsConfirmationStyle}>
                  <BasicButton
                    onClick={goToCompanyProfileDetails}
                    color="gray"
                    style={{ height: '45px', width: '150px', marginRight: 10 }}
                  >
                    <label style={{ ...labelButtonStyle, color: '#262625' }}>
                      Cancel
                    </label>
                  </BasicButton>
                  <BasicButton
                    onClick={() => onSaveDuplicatePostJob(jobInfo)}
                    color="primary"
                    style={{ height: '45px', width: '150px', marginLeft: 10 }}
                  >
                    <label style={labelButtonStyle}>Proceed</label>
                  </BasicButton>
                </div>
              </Grid>
              <Grid item xs={12} md={12} style={{ width: '100%' }}></Grid>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2} md={2}></Grid>
      </Grid>
    </Stack>
  );
};

const openJobsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
};

const labelConfirmationStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '40px',
  lineHeight: '61px',
  color: '#262625'
};

const openJobsMiddleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  padding: 10
};
const buttonsConfirmationStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  padding: 10
};

const labelButtonStyle: CSSProperties = {
  color: '#FFF',
  fontSize: '16px',
  marginTop: '6px',
  fontWeight: '700',
  lineHeight: '16px'
};

const iconCheckStyle: CSSProperties = {
  width: '86px',
  height: '71px',
  color: '#7A7C6F'
};

export default DuplicatePostJob;
