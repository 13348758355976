import React, { CSSProperties, useState, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Row, Col, Spinner } from 'react-bootstrap';

import AssessmentAccordion from '../AssessmentAccordion/AssessmentAccordion';
import { actionCreators } from '../../actions/lessons';
import { ApplicationState } from '../../store';
import { Question } from '../../entities/Assessment';
import { QuestionTypes, LessonQuiz } from '../../core/constants';
import { buttonSaveStyle, fontButton } from '../../utils/buttonStyles';
import { Typography } from '@mui/material';
import HorizontalLine from '../core/HorizontalLine/HorizontalLine';
import Counter from '../core/Counter/Counter';
import { Guid } from 'guid-typescript';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Lesson } from '../../entities/Lesson';
import { LessonFormModel } from '../../entities/LessonFormModel';

interface ModalAssessmentProps {
  title: string;
  onClose: () => void;
  onGoBack: (lessonEntryId: string) => void;
  onGoToNextStep?: () => void;
  variant: 'sm' | 'lg' | 'xl' | undefined;
  isEdit?: boolean;
  onBeforeSave?: (lessonModel: LessonFormModel) => Promise<string | undefined>;
}

export interface QuestionEditMode extends Question {
  _id?: string;
}

let questionCounter = 0;

export const getNextQuestionId = () => {
  return `question-${questionCounter++}`;
};

const isNullOrEmpty = (str: string) => {
  return !str || str === null || str.trim().length === 0;
};

const questionHaveOptions = (question: Question) => {
  return question.options && question.options.length > 0;
};

const isInvalidQuestion = (question: Question) => {
  if (isNullOrEmpty(question.questionText)) {
    return true;
  }
  switch (question.type) {
    case QuestionTypes.MultipleChoiceText:
      return (
        !questionHaveOptions(question) ||
        !question.options!.some(option => option.isCorrect) ||
        question.options!.some(option => isNullOrEmpty(option.optionText))
      );
    case QuestionTypes.MultipleChoiceImage:
      return (
        !questionHaveOptions(question) ||
        !question.options!.some(option => option.isCorrect) ||
        question.options!.every(option => !option.imageFile && !option.imageUrl)
      );
    case QuestionTypes.Number:
      return isNullOrEmpty(question.numberCorrectAnswer);
  }
};

const ModalAssessment: FC<ModalAssessmentProps> = ({
  title,
  variant,
  onClose,
  onGoBack,
  onGoToNextStep,
  onBeforeSave,
  isEdit = false
}) => {
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState<QuestionEditMode[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons!.currentLesson
  );
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [mainCounter, setMainCounter] = useState<number>(0);
  const history = useHistory();
  const { t } = useTranslation(['common', 'lessons', 'assessments']);

  const lessonId = currentLesson?.id;
  const lessonEntryId = currentLesson?.lessonEntryId;
  const assessmentId = currentLesson?.assessmentId;
  const correctAnswersToPass = correctAnswers;
  const numberCorrectAnswers = currentLesson?.correctAnswersToPass;

  const onSave = async () => {
    setSaveInProgress(true);
    let tempLessonId = currentLesson!.id;
    try {
      if (!isEdit && onBeforeSave) {
        const createdLessonId = await onBeforeSave({
          id: currentLesson!.id,
          title: currentLesson!.title,
          description: currentLesson!.description,
          contentMedium: currentLesson!.contentMedium,
          thumbnailFile: null,
          thumbnailUrl: currentLesson!.thumbnailUrl,
          video: null,
          videoUrl: currentLesson!.videoUrl,
          pdfFile: null,
          pdfUrl: currentLesson!.learningMaterialUrl,
          externalVideoUrl: currentLesson!.externalVideoUrl,
          tags: currentLesson!.tags,
          assessmentId: currentLesson!.assessmentId,
          subtitles: currentLesson!.subtitles
        });

        tempLessonId = createdLessonId!;
      }

      const questionsDto: Question[] = questions.map(question => ({
        questionText: question.questionText,
        trueIsCorrectAnswer: question.trueIsCorrectAnswer,
        type: question.type,
        id: question.id,
        imageFile: question.imageFile,
        imageUrl: question.imageUrl,
        options: question.options,
        numberCorrectAnswer: question.numberCorrectAnswer
      }));

      const isEditing =
        currentLesson?.assessmentId !== Guid.EMPTY &&
        currentLesson?.assessmentId !== '' &&
        currentLesson?.assessmentId !== null;

      const updateAssessmentIdInLesson =
        currentLesson?.lessonType === LessonQuiz &&
        (currentLesson.assessmentId === Guid.EMPTY ||
          currentLesson.assessmentId === '');

      await dispatch(
        actionCreators.saveAssessment(
          questionsDto,
          tempLessonId!,
          isEditing,
          assessmentId ?? null,
          correctAnswersToPass,
          updateAssessmentIdInLesson
        )
      );

      onClose();
      if (onGoToNextStep) onGoToNextStep();
    } catch (e) {
      setSaveInProgress(false);
    }
  };

  useEffect(() => {
    if (!isEdit) {
      return;
    }

    if (
      currentLesson?.lessonType === LessonQuiz &&
      currentLesson?.assessmentId !== Guid.EMPTY
    )
      dispatch(
        actionCreators.requestQuizQuestions(currentLesson?.assessmentId!)
      );
    else if (
      currentLesson?.lessonType !== LessonQuiz ||
      (currentLesson?.lessonType === LessonQuiz &&
        currentLesson?.assessmentId === Guid.EMPTY)
    )
      dispatch(actionCreators.requestAssessmentQuestions(lessonId!));

    return () => {
      setQuestions([]);
    };
  }, [isEdit]);

  useEffect(() => {
    if (currentLesson?.questions) {
      const questionsToEdit = currentLesson!.questions.map(q => {
        return { ...q, _id: getNextQuestionId() };
      });
      setQuestions(questionsToEdit);
    }
  }, [currentLesson?.questions]);

  const anyQuestionInvalid = questions.some(q => isInvalidQuestion(q));
  const atLeastOneQuestion = questions.length > 0;
  const formValidForQuiz =
    atLeastOneQuestion && mainCounter > 0 && correctAnswersToPass > 0;
  const isSaveButtonDisabled =
    anyQuestionInvalid ||
    (currentLesson?.lessonType === LessonQuiz && !formValidForQuiz);

  return (
    <Modal
      dialogClassName="modal-dialog-scrollable"
      show
      size={variant}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ border: 'none' }}
    >
      <Modal.Header closeButton closeVariant="white" onHide={onClose}>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('addQuestionToCreateLesson', { ns: 'lessons' })}{' '}
          {currentLesson?.lessonType === LessonQuiz
            ? t('quiz', { ns: 'lessons' })
            : t('assessment', { ns: 'assessments' })}{' '}
        </p>
        <AssessmentAccordion
          questions={questions}
          setQuestions={setQuestions}
          lessonType={currentLesson?.lessonType}
        />
        {currentLesson?.lessonType === LessonQuiz && (
          <div style={correctQuestionContainer}>
            <HorizontalLine color={'#999999'} line={3} withSize={'100%'} />
            <Counter
              numberQuestions={questions.length}
              setCorrectAnswers={setCorrectAnswers}
              numberCorrectAnswers={numberCorrectAnswers}
              setMainCounter={setMainCounter}
            />
            <HorizontalLine color={'#999999'} line={3} withSize={'100%'} />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer style={modalFooter}>
        {saveInProgress && (
          <Row>
            <Col>
              <div style={{ textAlign: 'center' }}>
                <Spinner animation="border" />
              </div>
            </Col>
          </Row>
        )}
        {!saveInProgress && (
          <>
            <Button
              className="ml-0 pl-0 text-left"
              variant="link"
              style={textStyle}
              onClick={() => onGoBack(lessonEntryId! ?? lessonId!)}
            >
              {t('backToStep1', { ns: 'common' })}
            </Button>
            <Button
              style={{ ...buttonSave, ...buttonSaveStyle }}
              onClick={onSave}
              disabled={isSaveButtonDisabled}
            >
              <Typography style={fontButton}>
                {t('save', { ns: 'common' })}
              </Typography>
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const modalFooter: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  border: 0
};

const buttonSave: CSSProperties = {
  borderRadius: '50px',
  border: '0',
  backgroundColor: '#FF9E00',
  color: '#0E0D0D'
};

const textStyle: CSSProperties = {
  color: '#FFFFFF',
  textDecoration: 'underline'
};

const correctQuestionContainer: CSSProperties = {
  marginTop: 25
};

export default ModalAssessment;
