import React, { CSSProperties, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Panel from '../core/Panel/Panel';
import EmployeeList from '../../components/EmployeeList/EmployeeList';
import SearchBox from '../core/SearchBox/SearchBox';
import { Employee, EmployeeGroup } from '../../entities/Employee';
import { LearningPlanAssignment } from '../../entities/LearningPlan';
import { LessonAssignment } from '../../entities/Lesson';
import { AssignCourseToEmployeeDto } from '../../entities/Course';
import { DropdownItem } from '../../entities/Common';
import { SortEmployeeOption } from '../../entities/SortEmployeeOption';
import TextDropdownList from '../core/TextDropdownList/TextDropdownList';
import BasicSelect from '../core/BasicSelect/BasicSelect';
import DatePickerInput from '../core/DatePickerInput/DatePickerInput';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import { LearningPlanAssignByOptions } from '../../core/constants';
import { useTranslation } from 'react-i18next';

export interface AssignEmployeesPanelProps {
  employees: Employee[];
  employeeGroups: EmployeeGroup[];
  assignment:
    | LearningPlanAssignment
    | LessonAssignment
    | AssignCourseToEmployeeDto;
  setAssignment: (assigment: any) => void;
}

const AssignEmployeesPanel: React.FC<AssignEmployeesPanelProps> = ({
  employees,
  employeeGroups,
  assignment,
  setAssignment
}) => {
  const [groups, setGroups] = useState<DropdownItem[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [employeesToShow, setEmployeesToShow] = useState<Employee[]>([]);
  const [sortOrder, setSortOrder] = useState<number>(
    SortEmployeeOption.Assigned
  );
  const [assignedEmployees, setAssignedEmployees] = useState<string[]>(
    assignment.employeeIds
  );
  const { t } = useTranslation([
    'learningPlans',
    'courses',
    'lessons',
    'common',
    'assignments'
  ]);

  useEffect(() => {
    setEmployeesToShow(sortEmployees(sortOrder, employees));
  }, [employees]);

  useEffect(() => {
    setGroups(getListItems(employeeGroups));
  }, [employeeGroups.length]);

  useEffect(() => {
    setEmployeesToShow(sortEmployees(sortOrder, employeesToShow));
  }, [sortOrder]);

  useEffect(() => {
    setAssignment({ ...assignment, employeeIds: [...assignedEmployees] });
  }, [assignedEmployees.length]);

  useEffect(() => {
    setAssignedEmployees(assignment.employeeIds);
  }, [assignment.employeeIds.length]);

  const assignByOptions: DropdownItem[] = [
    {
      label: t('dueDate', { ns: 'assignments' }),
      value: LearningPlanAssignByOptions.DueDate
    },
    {
      label: t('employeesWorkloadBudget', { ns: 'assignments' }),
      value: LearningPlanAssignByOptions.EmployeesWorkloadBudget
    }
  ];

  const getListItems = (list: EmployeeGroup[]) => {
    return list.map(item => ({ label: item.name, value: item.id }));
  };

  const dropdownContainerStyle: CSSProperties = {
    width: '100%',
    textAlign: 'right',
    paddingTop: '20px'
  };

  const handleOnChangeAssignBy = (values: string[]) => {
    if (values[0] === LearningPlanAssignByOptions.EmployeesWorkloadBudget)
      setAssignment({ ...assignment, assignBy: values[0], dueDate: null });
    else setAssignment({ ...assignment, assignBy: values[0] });
  };

  const handleOnChangeGroupList = (values: string[]) => {
    setGroupIds(values);
  };

  const handleOnChangeDatePick = (date: Date) => {
    setAssignment({ ...assignment, dueDate: date });
  };

  const handleSearch = (value: string) => {
    value = value.toLowerCase();

    const filtered = employees.filter(
      employee =>
        `${employee.firstName} ${employee.lastName}`
          .toLowerCase()
          .includes(value) ||
        (employee.email ?? '').toLowerCase().includes(value) ||
        (employee.phoneNumber ?? '').toLowerCase().includes(value) ||
        employee.groups.some(group => group.name.toLowerCase().includes(value))
    );

    setEmployeesToShow(sortEmployees(sortOrder, filtered));
  };

  const handleSortChange = (e: any) => {
    setSortOrder(parseInt(e.target.value));
  };

  const orderByAssigned = (emps: Employee[]) => {
    const assignedEmps = emps.slice().map(emp => {
      return {
        employee: emp,
        assigned: assignedEmployees.includes(emp.id)
      };
    });
    const sorted = assignedEmps.sort(
      (a, b) => Number(a.assigned) - Number(b.assigned)
    );
    return sorted.map(x => x.employee);
  };

  const sortEmployees = (option: number, employeesToSort: Employee[]) => {
    switch (option) {
      case SortEmployeeOption.Assigned:
        return orderByAssigned(employeesToSort).reverse();
      case SortEmployeeOption.Unassigned:
        return orderByAssigned(employeesToSort);
      case SortEmployeeOption.Az:
        return employeesToSort
          .slice()
          .sort((a, b) => a.firstName.localeCompare(b.firstName));
      case SortEmployeeOption.Za:
        return employeesToSort
          .slice()
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .reverse();
      default:
        return employeesToSort;
    }
  };

  const assignLabel = (() => {
    const objectAssignment: any = assignment;
    let label: string = '';

    if (objectAssignment.learningPlanId !== undefined)
      label = t('learningPlan', { ns: 'learningPlans' });
    else if (objectAssignment.lessonId !== undefined)
      label = t('lesson', { ns: 'lessons' });
    else if (objectAssignment.courseId !== undefined)
      label = t('course', { ns: 'courses' });
    return label;
  })();

  return (
    <>
      <Row>
        <Col>
          <Panel width="100%" height="100%">
            <Row className="pb-4">
              <Col md={12} lg={6} className="pt-1">
                <SearchBox
                  id="search"
                  value=""
                  name="search"
                  width="100%"
                  height="38px"
                  placeholderText={t('search', { ns: 'common' })}
                  variant="light"
                  onChangeValue={handleSearch}
                />
              </Col>
              <Col md={12} lg={6} className="pt-1">
                <BasicSelect
                  labelId="groups"
                  id="groups"
                  options={groups}
                  defaultValue=""
                  placeholder={t('quickAssignToGroup', { ns: 'assignments' })}
                  handleOnChange={handleOnChangeGroupList}
                />
                <div style={dropdownContainerStyle}>
                  <TextDropdownList
                    onChange={handleSortChange}
                    label={`${t('sort', { ns: 'common' })}:`}
                    items={[
                      {
                        value: SortEmployeeOption.Assigned,
                        text: t('assigned', { ns: 'common' })
                      },
                      {
                        value: SortEmployeeOption.Unassigned,
                        text: t('unassigned', { ns: 'common' })
                      },
                      { value: SortEmployeeOption.Az, text: 'a-z' },
                      { value: SortEmployeeOption.Za, text: 'z-a' }
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <EmployeeList
                  employees={employeesToShow}
                  groupIds={groupIds}
                  assignedEmployeeIds={assignedEmployees}
                  onUpdateAssignedEmployees={setAssignedEmployees}
                />
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
      <Row className="pt-3">
        <FormLabel>
          {`${t('assign', { ns: 'common' })} ${assignLabel} ${t('by', {
            ns: 'common'
          })}`}
          <span className="text-danger">*</span>
        </FormLabel>
        <BasicSelect
          labelId="assignBy"
          id="assignBy"
          options={assignByOptions}
          defaultValue=""
          placeholder={t('select', { ns: 'common' })}
          multiple={false}
          value={assignment.assignBy ? [assignment.assignBy] : []}
          handleOnChange={handleOnChangeAssignBy}
        />
        <div className="pt-2"></div>
        {assignment.assignBy === 'DueDate' && (
          <>
            <FormLabel>
              {t('selectFinalDueDate', { ns: 'assignments' })}{' '}
              <span className="text-danger">*</span>
            </FormLabel>
            <div>
              <DatePickerInput
                disablePastDays={true}
                onChange={handleOnChangeDatePick}
                value={assignment.dueDate}
              />
            </div>
          </>
        )}
      </Row>
    </>
  );
};

export default AssignEmployeesPanel;
