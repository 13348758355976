import { ApplicationState, AppThunkAction } from '../store';
import {
  USERPROFILE_SET_CURRENT_USERPROFILE,
  USERROLE_SET_USERROLE
} from './actionTypes';
import axios from 'axios';
import config from '../config';
import { HttpStatusEnum } from '../core/enums';
import {
  CertificationDto,
  EducationDto,
  UserProfileDto,
  WorkExperienceDto
} from '../entities/UserProfile';
import { createSelector } from 'reselect';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { uploadProfilePictureToBlob } from '../services/blobStorage-service';
import { History } from 'history';
import { enqueueSnackbar } from 'notistack';

export const actionCreators = {
  getCurrentUserProfileByUserId:
    (userAccountId: string | undefined): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let userProfile: UserProfileDto | null = null;
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}userprofile/byUserId/${userAccountId}`
          );

          if (res.status === HttpStatusEnum.OK) {
            userProfile = res.data;

            dispatch({
              type: USERPROFILE_SET_CURRENT_USERPROFILE,
              profile: userProfile,
              profileNotFound: !userProfile
            });
          }
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  getUserRole:
    (userAccountId: string | undefined): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}userprofile/roles/${userAccountId}`
          );

          if (res.status === HttpStatusEnum.OK) {
            dispatch({
              type: USERROLE_SET_USERROLE,
              role: res.data[0].role
            });
          }
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  createUserProfile:
    (userProfileData: UserProfileDto): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let userProfile: UserProfileDto;
        try {
          if (userProfileData.profileImage) {
            const photoUrl = await uploadProfilePictureToBlob(
              userProfileData.profileImage
            );
            userProfileData.photoURL = photoUrl;
          }

          var { profileImage, ...userProfileDataRequest } = userProfileData;

          const res = await axios.post(
            `${config.USERPROFILE_API_URL!}userprofile`,
            userProfileDataRequest
          );

          userProfile = res.status === HttpStatusEnum.Created ? res.data : null;
          dispatch({
            type: USERPROFILE_SET_CURRENT_USERPROFILE,
            profile: userProfile,
            profileNotFound: !userProfile
          });

          const message = 'Dirt World profile created succesfully';
          enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
          const message = 'Error while creating user profile.';
          enqueueSnackbar(message, { variant: 'error' });
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  updateUserProfile:
    (
      userProfileData: UserProfileDto,
      userProfileId: string,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let userProfile: UserProfileDto;
        try {
          if (userProfileData.profileImage) {
            const photoUrl = await uploadProfilePictureToBlob(
              userProfileData.profileImage
            );
            userProfileData.photoURL = photoUrl;
          }

          var { profileImage, ...userProfileDataRequest } = userProfileData;

          const res = await axios.put(
            `${config.USERPROFILE_API_URL!}userprofile/${userProfileId}`,
            userProfileDataRequest
          );

          userProfile = res.status === HttpStatusEnum.Created ? res.data : null;
          dispatch({
            type: USERPROFILE_SET_CURRENT_USERPROFILE,
            profile: userProfile,
            profileNotFound: !userProfile
          });
          const message = 'Dirt World profile updated succesfully';
          enqueueSnackbar(message, { variant: 'success' });
          history.push(`/user/profile/${userProfileId}`);
        } catch (e) {
          const message = 'Error while updating user profile.';
          enqueueSnackbar(message, { variant: 'error' });
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setUserProfile:
    (userProfile: UserProfileDto): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({
        type: USERPROFILE_SET_CURRENT_USERPROFILE,
        profile: userProfile,
        profileNotFound: !userProfile
      });
    },
  createWorkExperience:
    (
      workExperienceData: WorkExperienceDto,
      userProfileId: string | undefined,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        if (userProfileId) {
          dispatch(appActions.setIsLoading(true));

          try {
            const { isCurrentlyWorking, ...workExperienceRequestData } =
              workExperienceData;

            await axios.post(
              `${config.USERPROFILE_API_URL!}userprofile/${userProfileId}/workExperience`,
              workExperienceRequestData
            );
            const message = 'Work Experience created successfully';
            enqueueSnackbar(message, { variant: 'success' });
            history.push(`/user/profile/${userProfileId}`);
          } catch (e) {
            const message = 'Error while creating Work Experience.';
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(appActions.setIsLoading(false));
          }
        }
      }
    },
  updateWorkExperience:
    (
      workExperienceData: WorkExperienceDto,
      userProfileId: string | undefined,
      workExperienceId: string | undefined,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        if (userProfileId && workExperienceId) {
          dispatch(appActions.setIsLoading(true));

          const { isCurrentlyWorking, ...workExperienceRequestData } =
            workExperienceData;
          try {
            await axios.put(
              `${config.USERPROFILE_API_URL!}userprofile/${userProfileId}/workExperience/{${workExperienceId}}`,
              workExperienceRequestData
            );
            const message = 'Work Experience updated Successfully';
            enqueueSnackbar(message, { variant: 'success' });
            history.push(`/user/profile/${userProfileId}`);
          } catch (e) {
            const message = 'Error while updating Work Experience.';
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(appActions.setIsLoading(false));
          }
        }
      }
    },
  createEducation:
    (
      educationData: EducationDto,
      userProfileId: string | undefined,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        if (userProfileId) {
          dispatch(appActions.setIsLoading(true));

          try {
            await axios.post(
              `${config.USERPROFILE_API_URL!}userprofile/${userProfileId}/education`,
              educationData
            );
            const message = 'Education created succesfully';
            enqueueSnackbar(message, { variant: 'success' });
            history.push(`/user/profile/${userProfileId}`);
          } catch (e) {
            const message = 'Error while creating Education.';
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(appActions.setIsLoading(false));
          }
        }
      }
    },
  updateEducation:
    (
      educationData: EducationDto,
      userProfileId: string | undefined,
      educationId: string | undefined,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        if (userProfileId && educationId) {
          dispatch(appActions.setIsLoading(true));

          try {
            await axios.put(
              `${config.USERPROFILE_API_URL!}userprofile/${userProfileId}/education/{${educationId}}`,
              educationData
            );
            const message = 'Education updated succesfully';
            enqueueSnackbar(message, { variant: 'success' });
            history.push(`/user/profile/${userProfileId}`);
          } catch (e) {
            const message = 'Error while updating Education.';
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(appActions.setIsLoading(false));
          }
        }
      }
    },
  createCertification:
    (
      certificationData: CertificationDto,
      userProfileId: string | undefined,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        if (userProfileId) {
          dispatch(appActions.setIsLoading(true));

          try {
            await axios.post(
              `${config.USERPROFILE_API_URL!}userprofile/${userProfileId}/certification`,
              certificationData
            );
            const message = 'Certification created succesfully';
            enqueueSnackbar(message, { variant: 'success' });
            history.push(`/user/profile/${userProfileId}`);
          } catch (e) {
            const message = 'Error while creating Certification.';
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(appActions.setIsLoading(false));
          }
        }
      }
    },
  updateCertification:
    (
      certificationData: CertificationDto,
      userProfileId: string | undefined,
      certificationId: string | undefined,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        if (userProfileId && certificationId) {
          dispatch(appActions.setIsLoading(true));

          try {
            await axios.put(
              `${config.USERPROFILE_API_URL!}userprofile/${userProfileId}/certification/{${certificationId}}`,
              certificationData
            );
            const message = 'Certification updated succesfully';
            enqueueSnackbar(message, { variant: 'success' });
            history.push(`/user/profile/${userProfileId}`);
          } catch (e) {
            const message = 'Error while updating Certification.';
            enqueueSnackbar(message, { variant: 'error' });
          } finally {
            dispatch(appActions.setIsLoading(false));
          }
        }
      }
    }
};

const selectAllWorkExperiences = (state: ApplicationState) =>
  state.userProfile?.currentUserProfile?.workExperiences;

const selectWorkExperienceId = (
  _state: ApplicationState,
  workExperienceId: string | undefined
) => workExperienceId;

export const selectWorkExperienceById = createSelector(
  [selectAllWorkExperiences, selectWorkExperienceId],
  (workExperiences, workExperienceId) => {
    if (workExperienceId != null) {
      return workExperiences?.find(
        workExperience => workExperience.id === workExperienceId
      );
    }
    return null;
  }
);

const selectAllEducations = (state: ApplicationState) =>
  state.userProfile?.currentUserProfile?.educations;

const selectEducationId = (
  _state: ApplicationState,
  educationId: string | undefined
) => educationId;

export const selectEducationById = createSelector(
  [selectAllEducations, selectEducationId],
  (selectAllEducations, selectEducationId) => {
    if (selectEducationId != null) {
      return selectAllEducations?.find(
        education => education.id === selectEducationId
      );
    }
    return null;
  }
);

const selectAllCertifications = (state: ApplicationState) =>
  state.userProfile?.currentUserProfile?.certifications;

const selectCertificationId = (
  _state: ApplicationState,
  certificationId: string | undefined
) => certificationId;

export const selectCertificationById = createSelector(
  [selectAllCertifications, selectCertificationId],
  (selectAllCertifications, selectCertificationId) => {
    if (selectCertificationId != null) {
      return selectAllCertifications?.find(
        certification => certification.id === selectCertificationId
      );
    }
    return null;
  }
);

export interface SetCurrentUserProfileLoading {
  type: 'USERPROFILE_SET_IS_LOADING';
  isLoading: boolean;
}

export interface GetUserProfileByUserId {
  type: 'USERPROFILE_SET_CURRENT_USERPROFILE';
  profile: UserProfileDto | null;
  profileNotFound: boolean | null;
}

export interface GetUserRolesByUserId {
  type: 'USERROLE_SET_USERROLE';
  role: number | 0;
}

export type KnownAction =
  | SetLoadingAction
  | GetUserProfileByUserId
  | SetCurrentUserProfileLoading
  | GetUserRolesByUserId;
