import React, { CSSProperties } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint } from '@mui/system';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import BasicButton from '../../core/BasicButton/BasicButton';

export interface ModalComponentProps {
    showModal: boolean,
    title: string,
    savingInProgress: boolean,
    maxWidth: Breakpoint | false,
    saveButtonText: string,
    showSaveButton?: boolean,
    showCloseIcon?: boolean,
    onClose: () => void,
    onSave: () => void
}

const CustomDialog = styled(Dialog)`
    & .MuiDialogActions-root {
        justify-content: center;
        padding: 0 24px 20px;
    }
    & .MuiPaper-root {
        height: auto;
        border: 1px solid #999;
    }
`;

const dialogActionStyle: SxProps = {
    marginBottom: '1rem'
}

const progressIconStyle: CSSProperties = {
    textAlign: 'center'
}

const ModalComponent: React.FC<React.PropsWithChildren<ModalComponentProps>> = ({
    showModal,
    title,
    savingInProgress,
    maxWidth,
    saveButtonText,
    onClose,
    onSave,
    children,
    showSaveButton = true,
    showCloseIcon = true
}) => {
    const theme = useTheme();

    const iconStyle = {
        position: 'absolute' as 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
    }

    return(
        <CustomDialog
            open={showModal}
            onClose={onClose}
            maxWidth={maxWidth}
            fullWidth={true}
        >
            <DialogTitle>
                {title}
                {
                    showCloseIcon && 
                    <IconButton
                        aria-label='close'
                        onClick={onClose}
                        sx={{...iconStyle}}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions sx={dialogActionStyle}>
                {savingInProgress && (
                    <div style={progressIconStyle}>
                        <CircularProgress />
                    </div>
                )}
                {!savingInProgress && showSaveButton && (
                    <BasicButton
                            color="primary"
                            width='160px'
                            height='45px'
                            onClick={onSave}
                        >
                            {saveButtonText}
                    </BasicButton>
                )}
            </DialogActions>
        </CustomDialog>
    );
}

export default ModalComponent;