import React, { useState, useEffect, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AssignCourseToEmployeeDto } from '../../entities/Course';
import { ApplicationState } from '../../store';
import useCurrentUser from '../../hooks/useCurrentUser';
import { actionCreators as employeeActions } from '../../actions/employees';
import { actionCreators as courseActions } from '../../actions/courses';
import { LearningPlanAssignByOptions } from '../../core/constants';
import { TransactionStatusEnum } from '../../core/enums';
import { newAssignCourseToEmployeeDto } from '../../reducers/CoursesReducer';
import AssignModal from '../../components/AssignModal/AssignModal';
import { useTranslation } from 'react-i18next';

export interface AssignCourseProps {
  open: boolean;
  onClose: () => void;
}

const AssignCourse: React.FC<AssignCourseProps> = ({ open, onClose }) => {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const currentCourse = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const transactionStatus = useSelector(
    (state: ApplicationState) => state.courses?.transactionStatus
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [courseAssignment, setCourseAssignment] = useState<
    AssignCourseToEmployeeDto
  >({ ...newAssignCourseToEmployeeDto });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (currentCourse && !courseAssignment.courseId.length)
      setCourseAssignment({
        ...newAssignCourseToEmployeeDto,
        courseId: currentCourse.id,
        assignBy: LearningPlanAssignByOptions.EmployeesWorkloadBudget
      });
  }, [currentCourse]);

  useEffect(() => {
    dispatch(employeeActions.requestEmployeeGroups(companyId));
    dispatch(employeeActions.requestEmployees(companyId));
  }, [companyId]);

  useEffect(() => {
    setSaveInProgress(false);
    if (transactionStatus === TransactionStatusEnum.Failed) {
      const message = t('anExceptionOccurred', {ns: 'common'});
      setErrorMessage(message);
      alert(message);
    } else if (transactionStatus === TransactionStatusEnum.Successfull) {
      alert(t('savedSucessfully', {ns: 'common'}));
      setErrorMessage('');
      onClose();
    }

    if (transactionStatus !== TransactionStatusEnum.None)
      dispatch(courseActions.resetCourseTransactionStatus());

    return () => {
      dispatch(courseActions.resetCourseTransactionStatus());
    };
  }, [transactionStatus]);

  const handleAssign = () => {
    let message: string = '';

    if (!courseAssignment.employeeIds.length)
      message = t('selectEmployee', {ns: 'common'});
    else if (
      !courseAssignment.assignBy ||
      (courseAssignment.assignBy === LearningPlanAssignByOptions.DueDate &&
        !courseAssignment.dueDate)
    )
      message = t('requiredFieldsMustBeFilled', {ns: 'common'});

    if (message.length) {
      alert(message);
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    dispatch(courseActions.assignCourseToEmployees(courseAssignment));
  };

  return (
    <AssignModal
      showModal={open}
      assignToEntity={currentCourse}
      errorMessage={errorMessage}
      savingInProgress={saveInProgress}
      onAssign={handleAssign}
      onClose={onClose}
      employees={employees || []}
      employeeGroups={employeeGroups || []}
      assignment={courseAssignment}
      setAssignment={setCourseAssignment}
    />
  );
};

export default AssignCourse;
