import React from 'react';
import ListEmployee from './ListEmployees';
import Panel from '../../components/core/Panel/Panel';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { useTranslation } from 'react-i18next';
import DashboardWidget from './DashboardWidget';

const TopEmployees = () => {
  const [option, setOption] = React.useState('');
  const { t } = useTranslation(['dashboard']);
  const handleChange = (event: { target: { value: string } }) => {
    setOption(event.target.value);
  };
  const PersonalizedInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #999999',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      color: '#ffffff',
      fontWeight: 400,
      // Use the system font instead of the default Roboto font.
      fontFamily: [theme.typography.fontFamily].join(',')
    },
    '.MuiNativeSelect-icon ': {
      color: '#FFFFFF8F'
    }
  }));

  return (
    <DashboardWidget title={t('topEmployees', { ns: 'dashboard' })}>
      <Panel width="100%" height="auto" paddingLeft="0px" paddingRight="0px">
        <FormControl sx={{ mb: 2, ml: 2, mr: 2, mt: 2 }} variant="standard">
          <NativeSelect
            id="native-select-option"
            value={option}
            onChange={handleChange}
            input={<PersonalizedInput />}
          >
            <option value={'lessonsCompleted'}>Lessons Completed</option>
            <option value={'coursesCompleted'}>Courses Completed</option>
            <option value={'learningPlansCompleted'}>
              Learning Plans Completed
            </option>
          </NativeSelect>
        </FormControl>
        <ListEmployee />
      </Panel>
    </DashboardWidget>
  );
};

export default TopEmployees;
