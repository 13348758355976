import React, { CSSProperties, useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  ButtonBase,
  Stack,
  Container,
  Box
} from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useHistory } from 'react-router';
import Stepper from './Stepper';
import { CreatePostJobDto } from '../../entities/Dto/createJobPostDto';
import HttpStatusError from '../../core/CustomErrors/HttpRequestError';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as jobsActions } from '../../actions/jobs';
import { ApplicationState } from '../../store/index';
import { Page } from '../../components/Pages';
import { Card } from '../../components/Card';
import RichTextInput from '../../components/RichTextInput/RichTextInput';
import { JobsTypeOptions, payRateOptions } from '../../core/constants';

const JobPreview = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'lessons']);
  const [createError, setCreateError] = useState<string>('');
  const [jobInfo, setJobInfo] = useState<CreatePostJobDto>(
    props.location.state.jobPostData
  );
  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const goToCompanyDetail = () => {
    history.push(`/company/profile/${currentCompanyProfile?.id}`);
    dispatch(jobsActions.resetJobDetailPreview());
  };

  const jobType = JobsTypeOptions.find(jt => jt.value.toString() === jobInfo?.jobType.toString());
  const payRate = payRateOptions.find(pro => pro.value === jobInfo?.compensation.toString());

  const history = useHistory();

  const onSavePostJob = async (jobPostData: CreatePostJobDto) => {
    const jobPostDto: CreatePostJobDto = {
      jobTitle: jobPostData.jobTitle,
      title: jobPostData.jobTitle,
      jobCategory: jobPostData.jobCategory,
      jobType: jobPostData.jobType,
      jobLocationType: jobPostData.jobLocationType,
      compensation: jobPostData.compensation,
      postingAddress: jobPostData.postingAddress,
      description: jobPostData.description,
      howToApply: jobPostData.howToApply,
      contactEmailAddress: jobPostData.contactEmailAddress,
      expiresAtUtc: jobPostData.expiresAtUtc,
      companyProfileId: currentCompanyProfile!.id
    };

    try {
      let createdPostJobId = '';
      createdPostJobId = (await dispatch(
        jobsActions.createJobPost(jobPostDto, history)
      )) as unknown as string;
      return createdPostJobId;
    } catch (e) {
      if (!(e instanceof HttpStatusError)) {
        const error = e as any;
        const errorMessage = !error.response
          ? t('networkError', { ns: 'common' })
          : t('anErrorOccurred', { ns: 'common' });
        setCreateError(errorMessage);
      }
      throw e;
    }
  };

  return (
    <Page>
      <Typography
        variant={'h3'}
        textAlign={'center'}
        sx={theme => ({
          fontWeight: 700,
          paddingBottom: '20px',
          [theme.breakpoints.down('sm')]: {
            display: 'none'
          }
        })}
      >
        Review your job post
      </Typography>
      <div>
        <Container
          sx={theme => ({
            [theme.breakpoints.down('sm')]: {
              display: 'none'
            }
          })}
        >
          <Stepper firstStep={true} secondStep={true} />
        </Container>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card gridProps={{ spacing: 4 }} mobileCenterAlign>
            <Grid item xs={12} sm={6} lg={2}>
              <ButtonBase
                sx={{ width: '166px', height: '166px', borderRadius: '5px' }}
              >
                <div
                  style={{
                    background: currentCompanyProfile!.logoUrl
                      ? `url('${currentCompanyProfile!.logoUrl}'`
                      : '#EDECE8',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '5px',
                    border: 'solid 1px #ccc'
                  }}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Typography style={jobNameStyle}>{jobInfo.jobTitle}</Typography>
              <Box
                display={'grid'}
                sx={{
                  gridTemplate: 'repeat(4, min-content) / min-content 1fr'
                }}
                textAlign={'left'}
                rowGap={'20px'}
                columnGap={'40px'}
              >
                <span style={tagsStyle}>Category</span>
                <span style={informationStyle}>{jobInfo?.jobCategory}</span>
                <span style={tagsStyle}>Location</span>
                <span style={informationStyle}>
                  {`${jobInfo.postingAddress.city}, ${jobInfo.postingAddress.state}, ${jobInfo.postingAddress.country}`}
                </span>
                <span style={tagsStyle}>Pay rate</span>
                <span style={informationStyle}>{payRate?.label}</span>
                <span style={tagsStyle}>Type</span>
                <span style={informationStyle}>{jobType?.label}</span>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ textAlign: 'right' }}>
              <div style={buttonsPreviewStyle}>
                <BasicButton
                  onClick={goToCompanyDetail}
                  color="gray"
                  style={{ height: '34px', marginRight: '5px' }}
                >
                  <label style={finishStyle}>Cancel</label>
                </BasicButton>
                <BasicButton
                  onClick={() => {
                    history.push({
                      pathname: `/company/profile/jobs/post-job-create`
                    });
                  }}
                  color="gray"
                  style={{ height: '34px', marginRight: '5px' }}
                >
                  <label style={finishStyle}>Edit</label>
                </BasicButton>
                <BasicButton
                  onClick={() => onSavePostJob(jobInfo)}
                  color="gray"
                  style={{ height: '34px' }}
                >
                  <label style={finishStyle}>Finish</label>
                </BasicButton>
              </div>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundColor: '#fff',
              height: '600px',
              borderRadius: '10px'
            }}
          >
            <div style={openJobsContainer}>
              <Grid item xs={12} md={12} style={{ width: '100%' }}>
                <div style={openJobsTopStyle}>
                  <Typography gutterBottom style={openJobsSpan}>
                    Description
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={12} style={{ width: '100%' }}>
                <div style={openJobsMiddleStyle}>
                  <RichTextInput
                    value={jobInfo.description ? jobInfo.description : ''}
                    disabled={true}
                  />
                </div>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

const jobNameStyle: CSSProperties = {
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '120%',
  color: '#232323',

  marginBottom: '20px'
};

const tagsStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  color: '#232323',
  marginRight: '20px'
};

const informationStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#232323'
};

const openJobsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%'
};

const openJobsTopStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  padding: 10
};

const openJobsSpan: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '36px',
  lineHeight: '55px',
  color: '#242926',
  marginBottom: '10px'
};

const noOpenJobsSpan: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '26px',
  color: '#26262580',
  marginBottom: '10px'
};

const openJobsMiddleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  //justifyContent: 'center',
  //alignContent: 'center',
  //alignItems: 'center',
  padding: 10
};

const postJobLabelStyle: CSSProperties = {
  color: '#FFF',
  fontSize: '16px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '16px'
};

const cancelStyle: CSSProperties = {
  color: '#262625',
  fontSize: '14px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '14px'
};

const finishStyle: CSSProperties = {
  color: '#262625',
  fontSize: '14px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '14px'
};

const buttonsPreviewStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
};

export default JobPreview;
