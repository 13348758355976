import React, { CSSProperties } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ProgressIcon: React.FC = () => {
    return (
        <div style={progressContainerStyle}>
            <CircularProgress />
        </div>
    )
}

const progressContainerStyle: CSSProperties = {
    width: '100%',
    paddingTop: '2rem',
    textAlign: 'center'
}

export default ProgressIcon; 