import { useAuth } from 'react-oidc-context';
import { persistStore } from 'redux-persist';
import { useStore } from 'react-redux';
import config from '../config';

const useCurrentUser = () => {
  return useAuth().user;
};

export const useSignOut = () => {
  const user = useAuth();
  const store = useStore();

  const signOut = async () => {
    persistStore(store).purge();
    await user.signoutRedirect();
  };

  return { signOut };
};

export const useUserRole = () => {
  const user = useCurrentUser();

  const userHasRole = (roleName: string) => {
    let values = [];
    if (Array.isArray(user?.profile['BuildWitt.User.Role'])) {
      values = user?.profile['BuildWitt.User.Role']!;
    } else {
      values = [user?.profile['BuildWitt.User.Role']!];
    }

    return values.includes(roleName);
  };

  return [userHasRole];
};

export default useCurrentUser;
