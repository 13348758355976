import moment from 'moment';
import { Box, Button, MenuItem, Select } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import axios from 'axios';
import config from '../../config';
import { actions } from './state';
import { Assignment } from './DueDateModal';

const lessonNameGetter = (params: GridRenderCellParams) =>
  params.row.lesson?.title;
const courseNameGetter = (params: GridRenderCellParams) =>
  params.row.course?.title;
const learningPlanNameGetter = (params: GridRenderCellParams) =>
  params.row.learningPlanRevision?.name;

const dateGetter = (params: GridRenderCellParams) =>
  moment(params.value).format('MM.DD.YY');

const getCompletionDateColumn = (): GridColDef => ({
  field: 'completionDateUtc',
  headerName: 'Completed On',
  valueGetter: params => (params.row.isComplete ? dateGetter(params) : '')
});

const getNameColumn = (assignmentType: string): GridColDef => ({
  field: 'name',
  headerName: `${assignmentType} Name`,
  valueGetter: params => {
    switch (assignmentType) {
      case 'Courses':
        return courseNameGetter(params);
      case 'Lessons':
      case 'Quizzes':
        return lessonNameGetter(params);
      case 'LearningPlans':
        return learningPlanNameGetter(params);
    }
  },
  flex: 1
});

const getRemoveColumn = (
  assignmentType: string,
  unassign: unassignFunc
): GridColDef => ({
  field: 'remove',
  headerName: 'Remove',
  renderCell: params =>
    params.row.isComplete ? (
      ''
    ) : (
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Button
          color={'secondary'}
          sx={{ minWidth: 'unset' }}
          size={'small'}
          onClick={() => unassign(assignmentType, params.id.toString())}
        >
          <Delete />
        </Button>
      </Box>
    )
});

export const getDueDateColumns = (
  assignmentType: string,
  unassign: unassignFunc,
  setAssignment: (assignment: Assignment) => void
): GridColDef[] => [
  getNameColumn(assignmentType),
  {
    field: 'createdTimestampUtc',
    headerName: 'Assigned On',
    valueGetter: dateGetter
  },
  {
    field: 'dueDateUtc',
    headerName: 'Due Date',
    valueGetter: dateGetter
  },
  getCompletionDateColumn(),
  {
    field: 'actions',
    headerName: ' ',
    minWidth: 120,
    renderCell: params =>
      params.row.isComplete ? (
        ''
      ) : (
        <Button
          color={'secondary'}
          size={'small'}
          sx={{ minWidth: 'unset' }}
          onClick={() => setAssignment(params.row)}
        >
          Edit Due Date
        </Button>
      )
  },
  getRemoveColumn(assignmentType, unassign)
];

export const getWorkloadColumns = (
  assignmentType: string,
  unassign: unassignFunc
): GridColDef[] => [
  getNameColumn(assignmentType),
  getCompletionDateColumn(),
  getRemoveColumn(assignmentType, unassign)
];

export const getAssignments = (assignmentType: string, employeeId: string) =>
  axios.get(
    `${config.STUDENT_API_URL!}assignments/${employeeId}/${assignmentType.toLowerCase()}`
  );

export const getVideoLessons = async (employeeId: string) => {
  const lessonsResponse = await getAssignments('lessons', employeeId);
  return {
    data: lessonsResponse.data.filter(
      (x: any) => x.lesson?.lessonType === 'Video'
    )
  };
};

export const getQuizLessons = async (employeeId: string) => {
  const lessonsResponse = await getAssignments('lessons', employeeId);
  return {
    data: lessonsResponse.data.filter(
      (x: any) => x.lesson?.lessonType === 'Quiz'
    )
  };
};

export const getAssignmentFetcher = async (
  assignmentType: string,
  employeeId: string
) => {
  switch (assignmentType) {
    case 'Courses':
      return getAssignments(assignmentType, employeeId);
    case 'Lessons':
      return getVideoLessons(employeeId);
    case 'Quizzes':
      return getQuizLessons(employeeId);
    case 'LearningPlans':
      return getAssignments(assignmentType, employeeId);
  }
};

type unassignFunc = (assignmentType: string, assignmentId: string) => void;
const unassignAssignment = (assignmentType: string, assignmentId: string) =>
  axios.delete(
    `${config.STUDENT_API_URL!}assignments/unassign/${assignmentType.toLowerCase()}/${assignmentId}`
  );
const unassignLessons = (assignmentId: string) =>
  unassignAssignment('lesson', assignmentId);
const unassignCourses = (assignmentId: string) =>
  unassignAssignment('course', assignmentId);
const unassignLearningPlans = (assignmentId: string) =>
  unassignAssignment('learningplan', assignmentId);

export const getUnassignmentCall = async (
  assignmentType: string,
  assignmentId: string
) => {
  switch (assignmentType) {
    case 'Courses':
      return unassignCourses(assignmentId);
    case 'Lessons':
      return unassignLessons(assignmentId);
    case 'Quizzes':
      return unassignLessons(assignmentId);
    case 'LearningPlans':
      return unassignLearningPlans(assignmentId);
  }
};

const putAssignment = (assignmentType: string, assignmentId: string) =>
  `${config.STUDENT_API_URL!}assignments/${assignmentType}/${assignmentId}`;
const putLesson = (assignmentId: string) =>
  putAssignment('lesson', assignmentId);
const putCourse = (assignmentId: string) =>
  putAssignment('course', assignmentId);
const putLearningPlan = (assignmentId: string) =>
  putAssignment('learningplan', assignmentId);
export const getUpdateAssignmentEndpoint = (
  assignmentType: string,
  assignmentId: string
) => {
  switch (assignmentType) {
    case 'Courses':
      return putCourse(assignmentId);
    case 'Lessons':
      return putLesson(assignmentId);
    case 'Quizzes':
      return putLesson(assignmentId);
    case 'LearningPlans':
      return putLearningPlan(assignmentId);
    default:
      return '';
  }
};
