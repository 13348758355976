import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useCurrentUser from '../hooks/useCurrentUser';
import { ApplicationState } from '../store';
import { Paper, Grid, CircularProgress, Box } from '@mui/material';

export const HomePage = () => {
  const user = useCurrentUser();
  const userProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const EditDirtWorldProfile = `/user/profile/${userProfile?.id}`;

  if (!isLoading && user && !userProfile) {
    return <Redirect to="/user/profile/create" />;
  }

  if (!isLoading && user && userProfile) {
    return <Redirect to={EditDirtWorldProfile} />;
  }

  return (
    <div>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        component={Paper}
        spacing={2}
        sx={{ padding: '50px', height: '50vh' }}
      >
        <Grid item>
          <CircularProgress color="info" size="10em" />
        </Grid>
      </Grid>
    </div>
  );
};
