import { AppThunkAction } from '../store/index';
import axios from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { TAGS_SET_TAGS } from './actionTypes';
import config from './../config';
import { Tag } from '../entities/Tag';

export interface RequestTagsAction {
  type: 'TAGS_SET_TAGS';
  tags: Tag[];
}

export const actionCreators = {
  requestTags: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL!}tag/community/all`);
      dispatch({
        type: TAGS_SET_TAGS,
        tags: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  }
};

export type KnownAction = RequestTagsAction | SetLoadingAction;
