import React from 'react';
import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SxProps } from '@mui/system';

export interface OverviewSlotProps {
  label: string | undefined;
  description: string;
  labelColor: string;
}

const Overview: React.FC<OverviewSlotProps> = ({
  label,
  description,
  labelColor
}) => {
  const numberInfoCardStyle = { ...numberInfoCardBaseStyle, color: labelColor };
  return (
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
      <Card sx={cardSize}>
        <CardContent>
          <Typography sx={numberInfoCardStyle}>
            {label !== undefined ? label : 'loading...'}
          </Typography>
          <Typography sx={textInfoCard}>{description}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};
const numberInfoCardBaseStyle: SxProps = {
  fontSize: '36px',
  color: '#FF9E00'
};

const textInfoCard: SxProps = {
  fontSize: '15px',
  color: '#C4C4C4'
};

const cardSize: SxProps = {
  height: '102px',
  borderRadius: '10px',
  borderColor: '#999999',
  borderStyle: 'solid',
  borderWidth: '1px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px'
};

export default Overview;
