import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/jobs';

import {
  JOBLISTER_IS_LOADING,
  JOBLISTER_SET_CATEGORIES,
  JOBLISTER_SET_JOB_DETAIL,
  JOBLISTER_SET_JOB_DETAIL_PREVIEW,
  JOBLISTER_SET_JOBS,
  JOBLISTER_SET_JOB_TYPES,
  SET_FILTERS
} from '../actions/actionTypes';
import { JobsCategories } from '../entities/JobsCategories';
import { JobDetail } from '../entities/JobDetail';
import { CreatePostJobDto } from '../entities/Dto/createJobPostDto';
import { JobsList } from '../entities/JobsList';
import { FiltersDTO } from '../entities/Dto/FiltersDTO';
import { JobTypes } from '../entities/JobTypes';

export interface JobsState {
  jobsCategories: JobsCategories[];
  jobDetail: JobDetail | null;
  jobDetailPreview: CreatePostJobDto | null;
  jobsList: JobsList[];
  filters: FiltersDTO | null;
  jobTypes: JobTypes[];
  isLoading: boolean;
}

const unloadedState: JobsState = {
  jobsCategories: [],
  jobDetail: null,
  jobDetailPreview: null,
  jobsList: [],
  jobTypes: [],
  filters: null,
  isLoading: false
};

export const JobsReducers: Reducer<JobsState> = (
  state: JobsState | undefined,
  incomingAction: Action
): JobsState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = incomingAction as KnownAction;

  switch (action.type) {
    case JOBLISTER_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case JOBLISTER_SET_CATEGORIES:
      return { ...state, jobsCategories: action.jobsCategories };
    case JOBLISTER_SET_JOB_DETAIL:
      return { ...state, jobDetail: action.jobDetail };
    case JOBLISTER_SET_JOB_DETAIL_PREVIEW:
      return { ...state, jobDetailPreview: action.jobDetailPreview };
    case JOBLISTER_SET_JOBS:
      return {...state, jobsList: action.jobsList};
    case SET_FILTERS:
      return {...state, filters: action.filters};
    case JOBLISTER_SET_JOB_TYPES: 
      return {...state, jobTypes: action.jobTypes}
    default:
      return state;
  }
};
