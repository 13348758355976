import React, { CSSProperties } from 'react';
import podw from '../../assets/podw.png';
import PoweredByLogo from '../../assets/PoweredBuildWitt.png';
import { Image } from 'mui-image';
import { Box, Container, Divider, Grid, Paper, Stack } from '@mui/material';

export const AboutTheDirtWorldItems = () => {
  return (
    <Stack spacing={2}>
      <a
        href="https://dirtworld.com/dirt-101/why-is-the-dirt-world-important"
        target="_blank"
        rel="noreferrer"
      >
        <span style={infoStyle}>Why is the Dirt World Important?</span>
      </a>
      <a href="https://dirtworld.com/dirt-101" target="_blank" rel="noreferrer">
        <span style={infoStyle}>Dirt 101</span>
      </a>
    </Stack>
  );
};

export const MissionItems = () => (
  <>
    <a
      href="https://dirtworld.com/our-mission/get-involved"
      target="_blank"
      rel="noreferrer"
    >
      <span style={infoStyle}>Get Involved</span>
    </a>
  </>
);
export const CareersInDirtWorldItems = () => (
  <Stack spacing={2}>
    <a
      href="https://dirtworld.com/careers/roles"
      target="_blank"
      rel="noreferrer"
    >
      <span style={infoStyle}>Roles</span>
    </a>
    <a href="https://dirtworld.com/jobs" target="_blank" rel="noreferrer">
      <span style={infoStyle}>Current Opportunities</span>
    </a>
    <a href="https://dirtworld.com/companies" target="_blank" rel="noreferrer">
      <span style={infoStyle}>Companies of the Dirt World</span>
    </a>
    <a
      href="https://dirtworld.com/people-in-dirt"
      target="_blank"
      rel="noreferrer"
    >
      <span style={infoStyle}>People in the Dirt World</span>
    </a>
  </Stack>
);

export const DirtWorldLifeStyleItems = () => (
  <Stack spacing={2}>
    <a href="https://store.dirtworld.com/" target="_blank" rel="noreferrer">
      <span style={infoStyle}>Dirt World Store</span>
    </a>
    <a
      href="https://better.dirtworld.com/collections/individual-memberships"
      target="_blank"
      rel="noreferrer"
    >
      <span style={infoStyle}>Dirt World Kits</span>
    </a>
  </Stack>
);
export const ContractorResourcesItems = () => (
  <Stack spacing={2}>
    <a
      href="https://dirtworld.com/dirt-world-summit"
      target="_blank"
      rel="noreferrer"
    >
      <span style={infoStyle}>Dirt World Summit 2023</span>
    </a>
    <a
      href="https://dirtworld.com/dirt-101/workforce-survey-2022"
      target="_blank"
      rel="noreferrer"
    >
      <span style={infoStyle}>Workforce Survey</span>
    </a>
    <a
      href="https://www.buildwitt.com/learning-hub"
      target="_blank"
      rel="noreferrer"
    >
      <span style={infoStyle}>Learning Center</span>
    </a>
  </Stack>
);

export const ContactUsItems = () => (
  <Stack spacing="20px">
    <a href="https://www.buildwitt.com/" target="_blank" rel="noreferrer">
      <Image
        src={podw}
        width={375}
        height={192}
        title="Dirt World"
        duration={0}
        shiftDuration={0}
      />
    </a>
    <span style={infoStyle}>
      Want to join us on our mission on making the Dirt <br /> World a better
      place?{' '}
      <a
        href="https://dirtworld.com/our-mission/get-involved"
        target="_blank"
        rel="noreferrer"
        style={{ color: '#CCFF00' }}
      >
        Get Involved
      </a>
    </span>
  </Stack>
);

const infoStyle: CSSProperties = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#EDECE8CC'
};
