import React, { CSSProperties, FC } from 'react';
import { Grid, Paper, Typography, Button, ButtonBase } from '@mui/material';
import { ICompany } from '../../entities/Company';
import placeholderCompany from '../../assets/companiesPlaceholder.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';

export interface CompanyListProps {
  companyID?: string;
  companyInfo: ICompany;
}

export interface TierIconProps {
  tier?: string;
}

export const TierIcon: FC<TierIconProps> = ({ tier }) => {
  switch (tier) {
    case 'Premium':
      return <CheckCircleIcon style={tierPremium} />;
    case 'Pro':
      return <CheckCircleIcon style={tierPro} />;
    default:
      return <CheckCircleIcon style={{ display: 'none' }} />;
  }
};

const CompanyCard: FC<CompanyListProps> = ({ companyID, companyInfo }) => {
  return (
    <Grid container component={Paper} sx={cardContainerStyle} elevation={0}>
      <Grid item xs={6} sm={6} lg={6}>
        <ButtonBase
          sx={{
            width: '108px',
            height: '108px',
            borderRadius: '5px',
            marginTop: '10px'
          }}
        >
          <div
            style={{
              background: companyInfo?.logoUrl
                ? `url('${companyInfo.logoUrl}'`
                : `url('${placeholderCompany}'`,
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: '5px',
              border: 'solid 1px #ccc'
            }}
          />
        </ButtonBase>
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        lg={6}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        {companyInfo.tier !== 'Free' && (
          <Typography style={sponsorLabel}>SPONSORED</Typography>
        )}
      </Grid>
      <Grid item spacing={2}>
        {companyInfo.tier !== 'Free' ? (
          <Grid item xs mt={1}>
            <Button
              style={{ padding: '0px' }}
              endIcon={<TierIcon tier={companyInfo?.tier} />}
            >
              <Link
                to={{
                  pathname: companyInfo?.hubSpotUrl
                }}
                target="_blank"
              >
                <Typography style={companyNameStyle}>
                  {companyInfo?.name}
                </Typography>
              </Link>
            </Button>
          </Grid>
        ) : (
          <Grid item xs mt={1}>
            <Button
              style={{ padding: '0px' }}
              endIcon={<TierIcon tier={companyInfo?.tier} />}
            >
              {companyInfo?.websiteUrl !== null ? (
                <Link
                  to={{
                    pathname: companyInfo?.websiteUrl
                  }}
                  target="_blank"
                >
                  <Typography style={companyNameStyle}>
                    {companyInfo?.name}
                  </Typography>
                </Link>
              ) : (
                <Typography style={companyNameStyle}>
                  {companyInfo?.name}
                </Typography>
              )}
            </Button>
          </Grid>
        )}

        <Grid item xs mt={1}>
          <Typography style={industriesStyle}>
            {companyInfo?.city && companyInfo?.state ? (
              <span>{`${companyInfo?.city}, ${companyInfo?.state}`}</span>
            ) : (
              <span style={industriesStyle}>No country/state</span>
            )}
            {' - '}
            {companyInfo.industryCategories!.length > 0 ? (
              <span>
                {companyInfo.industryCategories!.map((industry, index) => (
                  <span key={industry.id} style={industriesStyle}>
                    {(index ? ', ' : '') + industry.name}
                  </span>
                ))}
              </span>
            ) : (
              <span style={industriesStyle}>No cateogories</span>
            )}
          </Typography>
        </Grid>
        <Grid item xs mt={1}>
          <Typography style={taglineStyle}>{companyInfo?.tagline}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const companyNameStyle: CSSProperties = {
  fontWeight: 700,
  fontSize: 24,
  color: '#262625',
  textTransform: 'capitalize',
  textAlign: 'left',
  lineHeight: '30px'
};

const industriesStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  color: '#7A7C6F'
};

const taglineStyle: CSSProperties = {
  fontWeight: 400,
  fontSize: 16,
  color: '#262625',
  lineHeight: '26px'
};

const sponsorLabel: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '3px',
  color: 'rgba(38, 38, 37, 0.5)'
};

const cardContainerStyle: CSSProperties = {
  padding: 2,
  margin: 'auto',
  flexGrow: 1,
  backgroundColor: '#FFF',
  borderRadius: '10px',
  width: '100%',
  marginTop: '10px',
  marginBottom: '10px'
};

const tierPremium: CSSProperties = { color: '#FFBF00', fontSize: '25px' };
const tierPro: CSSProperties = { color: '#7A7C6F', fontSize: '25px' };

export default CompanyCard;
