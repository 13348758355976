import { IndustryCategory } from './IndustryCategory';

export interface IUserProfile {
  name: string;
  lastName: string;
  email?: string;
  photoURL: string;
  location: string;
  jobTitle: string;
  employer: string;
  employmentType: string;
  userAccountId?: string;
  id: string;
}

export interface UserProfileDto {
  id: string;
  name: string;
  lastName: string;
  email?: string;
  photoURL?: string;
  profileImage?: File;
  location: string;
  biography: string;
  jobTitle?: string;
  industry?: string;
  employer?: string;
  employmentType?: string;
  iAm?: number;
  userAccountId?: string;
  workExperiences?: WorkExperienceDto[];
  educations?: EducationDto[];
  certifications?: CertificationDto[];
  address: {
    country?: string;
    state?: string;
    city?: string;
  };
  industryCategories: IndustryCategory[];
  phoneNumber?: string;
}

export interface WorkExperienceDto {
  id?: string;
  companyName: string;
  jobTitle: string;
  startMonth: string;
  startYear: string;
  endMonth?: string;
  endYear?: string;
  description: string;
  userProfileId?: string;
  isCurrentlyWorking?: boolean;
}

export interface EducationDto {
  id?: string;
  degreeType: number;
  fieldOfStudy: string;
  school: string;
  graduationDate: string;
  details: string;
}

export interface CertificationDto {
  id?: string;
  skillName: string;
  grantingInstitution: string;
  dateAwarded: string;
  expirationDate?: string;
  details: string;
  doesNotExpire?: boolean;
}

export interface AdditionalInformationDto {
  categories: string[];
  authorizedToWorkInTheUnitedStates: boolean;
  subscribeToJobNotifications: boolean;
  openToRelocation: boolean;
  openToTravel: boolean;
}

export interface JobApplicationQuestionDto {
  whyChooseYou: string;
  whyThisCareer: string;
}

export enum UserRoles {
  member = 0,
  admin = 1
}
