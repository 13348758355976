import * as yup from 'yup';

export const CompanyProfileValidationSchema = yup
  .object({
    name: yup.string().required('First name is required.'),
    companySize: yup.number().required('Company size is required.'),
    companyBusinessType: yup
      .number()
      .required('Company business type is required.'),
    industryCategories: yup.array().min(1, 'Industry/Specialty is required.')
  })
  .required();
