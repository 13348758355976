import React, { CSSProperties, useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import BasicButton from '../../components/core/BasicButton/BasicButton';
import { actionCreators as jobsActions } from '../../actions/jobs';
import { ApplicationState } from '../../store';
import { SimpleFormPage } from '../../components/Pages';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BasicButtonTypeEnum } from '../../core/enums';
import { CreatePostJobDto } from '../../entities/Dto/createJobPostDto';
import JobPostForm from './JobPostForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { JobFormValidationSchema } from './JobFormValidationSchema';

export const EditJobPost = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, watch, reset, formState, setValue } =
    useForm<CreatePostJobDto>({
      resolver: yupResolver(JobFormValidationSchema)
    });

  const { jobID } = useParams<{ jobID: string }>();

  useEffect(() => {
    if (jobID) {
      dispatch(jobsActions.getJobDetail(jobID));
    }
  }, [dispatch, jobID]);

  const currentJobPost = useSelector(
    (state: ApplicationState) => state.jobs?.jobDetail
  );

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const goToJobDetails = () => {
    history.push(`/company/profile/jobs/post-job-detail/${jobID}`);
  };

  const onSubmit: SubmitHandler<CreatePostJobDto> = data => {
    dispatch(jobsActions.updateJobPost(data, jobID, history));
  };

  useEffect(() => {
    if (currentJobPost) {
      reset({
        companyProfileId: currentJobPost.companyProfileId,
        title: currentJobPost.title,
        expiresAtUtc: currentJobPost.expiresAtUtc,
        jobTitle: currentJobPost.title,
        jobCategory: currentJobPost.jobCategory,
        jobType: currentJobPost.jobType,
        jobLocationType: currentJobPost.jobLocationType,
        compensation: currentJobPost.compensation,
        postingAddress: currentJobPost.postingAddress,
        description: currentJobPost.description,
        howToApply: currentJobPost.howToApply,
        contactEmailAddress: currentJobPost.contactEmailAddress
      });
    }
  }, [currentJobPost]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <SimpleFormPage title={'Edit Job Post'}>
      <JobPostForm
        submitHandler={onSubmit}
        watch={watch}
        handleSubmit={handleSubmit}
        setValue={setValue}
        control={control}
        formState={formState}
        editMode={true}
        bottomActions={
          <Stack direction="row" sx={{ textAlign: 'center' }} spacing={2}>
            <BasicButton
              onClick={goToJobDetails}
              color="gray"
              style={JobPostButtonStyles}
            >
              <label style={labelButtonStyle}>Cancel</label>
            </BasicButton>
            <BasicButton
              type={BasicButtonTypeEnum.Submit}
              color="primary"
              style={JobPostButtonStyles}
            >
              Save Changes
            </BasicButton>
          </Stack>
        }
      />
    </SimpleFormPage>
  );
};

const JobPostButtonStyles: CSSProperties = {
  width: '225px',
  height: '44px'
};

const labelButtonStyle: CSSProperties = {
  marginTop: '6px',
  fontWeight: '700',
  lineHeight: '16px',
  color: '#262625'
};

export default EditJobPost;
