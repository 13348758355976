import React, { CSSProperties, FC } from 'react';
import Check from '../../assets/check.svg';
import Cross from '../../assets/cross.svg';
import MovieLineIcon from '../../assets/movie-line.svg';
import FlashCardPdfIcon from '../../assets/flash-card-pdf.svg';
import QuizCardIcon from '../../assets/quizIcon.png';
import { Lesson } from '../../entities/Lesson';
import { Row, Col, Container } from 'react-bootstrap';

const lessonTitle: CSSProperties = {
  padding: '15px',
  margin: '20px',
  borderRadius: '7px',
  cursor: 'pointer'
};

const lessonTitleStyle: CSSProperties = {
  fontSize:'16px'
}

const stylelessonTitle: CSSProperties = {
  backgroundColor: '#3E3E3E'
};

const stylelessonSelected: CSSProperties = {
  backgroundColor: '#fa9e04',
};

const stylelessonTitleProgress: CSSProperties = {
  border: '2px solid #44d47a',
};

const styleQuizFailedProgress: CSSProperties = {
  border: '2px solid #FF2A20',
};

const separator: CSSProperties = {};

const styleCheck: CSSProperties = {
  padding: '0px 0px'
};

const videoLessonTypeStyle: CSSProperties = {
  display: 'inline-block',
  backgroundRepeat: 'no-repeat',
  textAlign: 'center',
  width: '15px',
  height: '15px',
  marginTop: '5px'
};

export interface TinyLessonCardProps {
  lesson: Lesson;
  onClick: () => void;
  isCompleted: boolean;
  index: number;
  activeLesson: string;
  listFailedQuiz?: string[];
}

export const TinyLessonCard: FC<TinyLessonCardProps> = ({
  lesson,
  onClick,
  isCompleted,
  activeLesson,
  listFailedQuiz
}) => {
  let lessonTypeImg: string = '';
  const isFailed = listFailedQuiz?.includes(lesson.assessmentId!) && !isCompleted;

  switch (lesson.lessonType.toLowerCase()) {
    case 'video':
      lessonTypeImg = MovieLineIcon;
      break;
    case 'pdf':
      lessonTypeImg = FlashCardPdfIcon;
      break;
    case 'quiz':
      lessonTypeImg = QuizCardIcon;
      break;
    default:
      break;
  }

  return (
    <React.Fragment key={lesson.id}>
      <div
        onClick={onClick}
        style={{
          ...lessonTitle,
          ...(isCompleted
            ? stylelessonTitleProgress
            : activeLesson === lesson.id
            ? stylelessonSelected
            : stylelessonTitle),
          ...(lesson.lessonType === 'Quiz' && isFailed
            ? styleQuizFailedProgress
            : activeLesson === lesson.id
            ? stylelessonSelected
            : stylelessonTitle)
        }}
      >
        <Container className="p-0">
          <Row className="justify-content-md-center">
            {isCompleted && !isFailed && (
              <Col md="auto">
                <img style={styleCheck} src={Check} alt='check icon'/>
              </Col>
            )}
            {lesson.lessonType === 'Quiz' && isFailed && (
              <Col md="auto">
                <img style={styleCheck} src={Cross} alt='cross icon fail'/>
              </Col>
            )}
            <Col>
              <span style={lessonTitleStyle}>{lesson.title}</span>
              <div>
                <i
                  className="fas fa-sm fa-fw mr-1"
                  style={{
                    ...videoLessonTypeStyle,
                    backgroundImage: `url(${lessonTypeImg})`
                  }}
                ></i>
                <span className="mt-1 align-top">{lesson.lessonType}</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={separator}></div>
    </React.Fragment>
  );
};
