import { Typography } from '@mui/material';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

interface CompletedCourseNoticeProps {
  onClickContinue: () => void;
}

const CompletedCourseNotice: React.FC<CompletedCourseNoticeProps> = ({
  onClickContinue
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Row className="pb-0" style={componentStyle}>
      <Col md={12} className="text-center">
        <h4>{t('congratulationsFinishedCourse', {ns: 'common'})}</h4>
        <BasicButton
          style={continueBtnStyle}
          color="primary"
          onClick={onClickContinue}
        >
          <Typography>{t('continue', {ns: 'common'})}</Typography>
        </BasicButton>
      </Col>
    </Row>
  );
};

const continueBtnStyle = {
  marginTop: '20px',
  width: '180px'
};

const componentStyle = {
  background: '#1F201F',
  border: '1px solid #2ED47A',
  borderRadius: '5px',
  height: '225px',
  padding: '60px',
  position: 'absolute' as 'absolute',
  left: '50%',
  top: '35%',
  transform: 'translate(-50%,-50%)'
};

export default CompletedCourseNotice;
