import {
  $applyNodeReplacement,
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  ParagraphNode,
  SerializedElementNode,
  SerializedParagraphNode
} from 'lexical';

export class CustomParagraphNode extends ParagraphNode {
  static getType() {
    return 'custom-paragraph';
  }

  static clone(node: CustomParagraphNode): CustomParagraphNode {
    return new CustomParagraphNode(node.__key);
  }

  createDOM(config: EditorConfig) {
    return super.createDOM(config);
  }

  static importDOM(): DOMConversionMap | null {
    return {
      p: () => ({
        conversion: convertParagraphElement,
        priority: 3
      })
    };
  }

  static importJSON(
    serializedNode: SerializedParagraphNode
  ): CustomParagraphNode {
    const node = $createParagraphNode();
    node.setFormat(serializedNode.format);
    node.setIndent(serializedNode.indent);
    node.setDirection(serializedNode.direction);
    return node;
  }

  exportJSON(): SerializedElementNode {
    return {
      ...super.exportJSON(),
      type: 'custom-paragraph',
      version: 1
    };
  }
}

function convertParagraphElement(domNode: Node): DOMConversionOutput {
  return {
    node: $createParagraphNode(domNode)
  };
}

export function $createParagraphNode(domNode?: Node): CustomParagraphNode {
  const b = domNode as HTMLElement;
  const hasTextAlign = b ? b.style.textAlign : false;
  const newNode = new CustomParagraphNode();
  if (hasTextAlign) {
    // @ts-ignore
    newNode.setFormat(b.style.textAlign);
  }
  return $applyNodeReplacement(newNode);
}
