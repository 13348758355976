import React, { CSSProperties } from 'react';
import MovieLineIcon from '../../assets/movie-line.svg';
import FlashCardPdfIcon from '../../assets/flash-card-pdf.svg';
import QuizIcon from '../../assets/quiz.svg';
import { LessonTypeViewEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';

export interface LessonTypeProps {
  viewType: LessonTypeViewEnum;
  type?: string;
  videosCount?: number;
  flashCardsCount?: number;
  quizesCount?: number;
}

const LessonType: React.FC<LessonTypeProps> = ({
  type,
  viewType,
  videosCount,
  flashCardsCount,
  quizesCount
}) => {
  const { t } = useTranslation(['lessons']);

  const MovieLineIconStyle: CSSProperties = {
    width: 16,
    height: 16,
    display: 'inline-block',
    backgroundImage: `url(${MovieLineIcon})`
  };

  const FlashCardPdfIconStyle: CSSProperties = {
    ...MovieLineIconStyle,
    width: 12,
    height: 12,
    backgroundImage: `url(${FlashCardPdfIcon})`
  };

  const QuizIconStyle: CSSProperties = {
    ...MovieLineIconStyle,
    backgroundImage: `url(${QuizIcon})`
  };

  const MainIconContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  };

  const cssTypeLesson =
    viewType === LessonTypeViewEnum.LessonType
      ? type === 'Video'
        ? MovieLineIconStyle
        : QuizIconStyle
      : MovieLineIconStyle;

  return (
    <>
      {viewType === LessonTypeViewEnum.CourseLessonTypesCount ? (
        <div style={MainIconContainerStyle}>
          <div className="d-inline-flex align-items-center">
            <i
              className="fas fa-sm fa-fw mr-2 text-gray-400"
              style={MovieLineIconStyle}
            />
            <div className="pe-4">
              <span className="text-white">{videosCount}</span> Videos
            </div>
          </div>
          <div className="d-inline-flex align-items-center">
            <i
              className="fas fa-sm fa-fw mr-2 text-gray-400"
              style={QuizIconStyle}
            />
            <span className="pe-4">
              <span className="text-white">{quizesCount}</span> {t('quizzes', {ns: 'lessons'})}
            </span>
          </div>
        </div>
      ) : (
        <>
          {
            <>
              <i
                className="fas fa-sm fa-fw mr-2 text-gray-400"
                style={cssTypeLesson}
              />
              <span className="pe-4">{` ${type}`}</span>
            </>
          }
        </>
      )}
    </>
  );
};

export default LessonType;
