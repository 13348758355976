import DirtWorldLogo from '../../assets/DirtWorldLogo.svg';
import PoweredByLogo from '../../assets/PoweredBuildWitt.png';
import { Image } from 'mui-image';
import { CSSProperties } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Footer = () => {
  const theme = useTheme();
  const isSmallMedia = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Paper
      sx={{
        backgroundColor: '#262625',
        border: 0,
        borderRadius: 0,
        padding: '60px 0 30px'
      }}
      elevation={3}
    >
      <Container>
        <Grid container justifyContent={'space-evenly'}>
          <Grid item>
            <Stack spacing="15px">
              <a href="https://dirtworld.com/" target="_blank" rel="noreferrer">
                <Image
                  src={DirtWorldLogo}
                  width={isSmallMedia ? 191 : 241}
                  height={isSmallMedia ? 35.7 : 45.09}
                  title="Dirt World"
                  duration={0}
                  shiftDuration={0}
                />
              </a>

              <a
                href="https://www.buildwitt.com/"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  src={PoweredByLogo}
                  height={17}
                  fit="contain"
                  title="BuildWitt"
                  duration={0}
                  shiftDuration={0}
                />
              </a>
            </Stack>
          </Grid>

          {!isSmallMedia && (
            <>
              <Grid item>
                <Stack spacing="20px">
                  <span style={titleStyle}>About the Dirt World</span>
                  <a
                    href="https://dirtworld.com/dirt-101/what-is-the-dirt-world"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>What is the Dirt World</span>
                  </a>
                  <a
                    href="https://dirtworld.com/dirt-101/why-is-the-dirt-world-important"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>
                      Why is the Dirt World Important?
                    </span>
                  </a>
                  <a
                    href="https://dirtworld.com/dirt-101"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Dirt 101</span>
                  </a>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing="20px">
                  <span style={titleStyle}>Careers in Dirt World</span>
                  <a
                    href="https://dirtworld.com/careers/roles"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Roles</span>
                  </a>
                  <a
                    href="https://dirtworld.com/jobs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Current Opportunities</span>
                  </a>
                  <a
                    href="https://dirtworld.com/companies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Companies of the Dirt World</span>
                  </a>
                  <a
                    href="https://dirtworld.com/people-in-dirt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>People in the Dirt World</span>
                  </a>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing="20px">
                  <span style={titleStyle}>Dirt World Lifestyle</span>
                  <a
                    href="https://store.dirtworld.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Dirt World Store</span>
                  </a>
                  <a
                    href="https://better.dirtworld.com/collections/individual-memberships"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Dirt World Kits</span>
                  </a>
                  <Box paddingTop="30px">
                    <span style={titleStyle}>Mission</span>
                  </Box>
                  <a
                    href="https://dirtworld.com/our-mission/roadmap"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Roadmap</span>
                  </a>
                  <a
                    href="https://dirtworld.com/our-mission/get-involved"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Get Involved</span>
                  </a>
                </Stack>
              </Grid>
              <Grid item>
                <Stack spacing="20px">
                  <span style={titleStyle}>Contractor Resources</span>
                  <a
                    href="https://dirtworld.com/dirt-world-summit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Dirt World Summit 2023</span>
                  </a>
                  <a
                    href="https://dirtworld.com/dirt-101/workforce-survey-2022"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Workforce Survey</span>
                  </a>
                  <a
                    href="https://www.buildwitt.com/learning-hub"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span style={infoStyle}>Learning Center</span>
                  </a>
                </Stack>
              </Grid>
            </>
          )}

          {!isSmallMedia && (
            <Grid item xs={12}>
              <Divider sx={{ color: '#EDECE833', margin: '60px 0 0' }} />
            </Grid>
          )}
          <Grid item xs={12} marginTop={'30px'}>
            <Grid
              container
              columnSpacing={3}
              sx={theme => ({
                [theme.breakpoints.down('sm')]: {
                  justifyContent: 'center'
                }
              })}
            >
              <Grid item>
                <span style={legalStyle}>© 2023 Dirt World</span>
              </Grid>

              {!isSmallMedia && (
                <>
                  <Grid item>
                    <a
                      href="https://www.buildwitt.com/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span style={legalStyle}>Privacy Policy</span>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://www.buildwitt.com/cookie-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span style={legalStyle}>Cookie Policy</span>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://dirtworld.com/terms-and-conditions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span style={legalStyle}>Terms & Conditions</span>
                    </a>
                  </Grid>
                  <Grid item>
                    <a
                      href="https://dirtworld.com/contact"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span style={legalStyle}>Contact us</span>
                    </a>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

const titleStyle: CSSProperties = {
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  color: '#FFF'
};

const infoStyle: CSSProperties = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#EDECE8CC'
};

const legalStyle: CSSProperties = {
  color: '#80808080',
  fontSize: '14px'
};
