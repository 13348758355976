import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/lessons';
import {
  EmployeeLessonAssignment,
  Lesson,
  LessonAssignment
} from '../entities/Lesson';
import { TransactionStatusEnum } from '../core/enums';

import {
  LESSON_SET_LESSONS,
  LESSON_SET_EDITING,
  LESSON_BY_ID,
  LESSON_SET_ALL_LESSONS,
  LESSONS_SET_ASSESSMENT_QUESTIONS,
  LESSON_SET_POSITION_TRACKING,
  LESSON_UPDATE_TRANSACTION_STATUS,
  LESSON_RESET_TRANSACTION_STATUS,
  LESSON_SET_EMPLOYEE_ASSIGNMENTS,
  LESSONS_SET_QUIZ_QUESTIONS
} from '../actions/actionTypes';
import { Question } from '../entities/Assessment';
import { sortByKey } from '../utils/sorting';

export interface LessonsState {
  lessons: Lesson[];
  allLessons: Lesson[];
  currentLesson: Lesson | null;
  currentVideoPosition: number;
  currentEmployeeLessonAssignments: EmployeeLessonAssignment[];
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
  isTracked: boolean;
}

const unloadedState: LessonsState = {
  lessons: [],
  allLessons: [],
  currentLesson: null,
  currentVideoPosition: 0,
  currentEmployeeLessonAssignments: [],
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: '',
  isTracked: true
};

export const newLessonAssignment: LessonAssignment = {
  lessonId: '',
  employeeIds: []
};

export const LessonsReducer: Reducer<LessonsState> = (
  state: LessonsState | undefined,
  incomingAction: Action
): LessonsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case LESSON_SET_LESSONS:
      return {
        ...state,
        lessons: action.lessons
      };
    case LESSON_SET_EDITING:
      return { ...state, currentLesson: action.lesson };
    case LESSON_BY_ID:
      return { ...state, currentLesson: action.lesson };
    case LESSON_SET_ALL_LESSONS:
      return {
        ...state,
        allLessons: action.allLessons.slice().sort(sortByKey<Lesson>('title'))
      };
    case LESSON_SET_EMPLOYEE_ASSIGNMENTS:
      return {
        ...state,
        currentEmployeeLessonAssignments: action.employeeLessonAssigment
      };
    case LESSONS_SET_ASSESSMENT_QUESTIONS:
      const mappedQuestions: Question[] = action.questions.map(q => {
        return {
          id: q.id,
          questionText: q.questionText,
          type: q.type,
          trueIsCorrectAnswer: q.trueIsCorrectAnswer,
          options: q.options,
          imageUrl: q.imageUrl,
          imageFile: q.imageFile,
          numberCorrectAnswer: q.numberCorrectAnswer
        };
      });

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson!,
          questions: mappedQuestions,
          assessmentId: action.assessmentId,
          correctAnswersToPass: action.correctAnswersToPass
        }
      };
    case LESSON_SET_POSITION_TRACKING:
      return {
        ...state,
        currentVideoPosition: action.positionTracking,
        isTracked: action.isTracked
      };
    case LESSON_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case LESSON_RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: TransactionStatusEnum.None,
        errorMessage: ''
      };
    case LESSONS_SET_QUIZ_QUESTIONS:
      const mappedQuizQuestions: Question[] = action.questions.map(q => {
        return {
          id: q.id,
          questionText: q.questionText,
          type: q.type,
          trueIsCorrectAnswer: q.trueIsCorrectAnswer,
          options: q.options,
          imageUrl: q.imageUrl,
          imageFile: q.imageFile,
          numberCorrectAnswer: q.numberCorrectAnswer,
          order: q.order
        };
      });

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson!,
          questions: mappedQuizQuestions,
          correctAnswersToPass: action.correctAnswersToPass
        }
      };
    default:
      return state;
  }
};
