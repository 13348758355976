import React, { CSSProperties, useRef, useEffect } from 'react';
import { Grid, Paper, Typography, ButtonBase, Stack, Box } from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as jobsActions } from '../../actions/jobs';
import { actionCreators as userActions } from '../../actions/userProfile';
import { ApplicationState } from '../../store/index';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { UserClaims } from '../../core/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { Page } from '../../components/Pages';
import { Card } from '../../components/Card';
import useCurrentUser from '../../hooks/useCurrentUser';
import RichTextInput from '../../components/RichTextInput/RichTextInput';
import { JobsTypeOptions, payRateOptions } from '../../core/constants';
import { UserRoles } from '../../entities/UserProfile';

const JobDetail = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const params = useParams<{
    jobID: string;
  }>();

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const jobDetailData = useSelector(
    (state: ApplicationState) => state.jobs?.jobDetail
  );

  const user = useCurrentUser();
  const userAccountId = user?.profile[UserClaims.UserId] as string;
  const userRole = useSelector((state: ApplicationState) => state.userProfile?.role);
  const userIsAdmin = userRole === UserRoles.admin;

  useEffect(() => {
    if (params.jobID) {
      dispatch(jobsActions.getJobDetail(params.jobID));
    }
  }, [dispatch, params.jobID]);

  useEffect(() => {
    dispatch(userActions.getUserRole(userAccountId));
  }, [dispatch, userAccountId])

  const goToDuplicateJob = () => {
    history.push({
      pathname: `/company/profile/jobs/duplicate-post-job`,
      state: { jobDetailData }
    });
  };

  const goToDeleteJob = () => {
    history.push(`/company/profile/jobs/delete-post-job/${params.jobID}`);
  };

  const goToEditJob = () => {
    history.push(`/company/profile/jobs/${params.jobID}/edit`);
  };

  const jobType = JobsTypeOptions.find(jt => jt.value === jobDetailData?.jobType);
  const payRate = payRateOptions.find(pro => pro.value === jobDetailData?.compensation.toString());

  if (isLoading) {
    return <FallbackLoader />;
  }

  if (!isLoading && !jobDetailData) {
    return (
      <Stack spacing={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <Typography variant="blackTitle">Page not available</Typography>
        </Box>
      </Stack>
    );
  }

  const renderJobActions = () => (
    <div style={buttonsPreviewStyle}>
      <BasicButton
        onClick={goToDeleteJob}
        color="gray"
        style={{ height: '34px', marginRight: '5px' }}
        endIcon={<DeleteIcon style={{ color: '#262625' }} />}
      >
        <label style={finishStyle}>Delete</label>
      </BasicButton>
      <BasicButton
        onClick={goToDuplicateJob}
        color="gray"
        style={{ height: '34px', marginRight: '5px' }}
        endIcon={
          <ContentCopyTwoToneIcon style={{ color: '#262625' }} />
        }
      >
        <label style={finishStyle}>Duplicate</label>
      </BasicButton>
      <BasicButton
        onClick={goToEditJob}
        color="gray"
        style={{ height: '34px' }}
        endIcon={<EditIcon style={{ color: '#262625' }} />}
      >
        <label style={finishStyle}>Edit</label>
      </BasicButton>
    </div>
  )

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card gridProps={{ spacing: 4 }} mobileCenterAlign>
            <Grid item xs={12} sm={6} lg={2}>
              <ButtonBase
                sx={{ width: '166px', height: '166px', borderRadius: '5px' }}
              >
                <div
                  style={{
                    background: jobDetailData?.companyProfileLogoUrl
                      ? `url('${jobDetailData.companyProfileLogoUrl}'`
                      : '#EDECE8',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '5px',
                    border: 'solid 1px #ccc'
                  }}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Typography style={jobNameStyle}>
                {jobDetailData?.jobTitle}
              </Typography>
              <Typography
                gutterBottom
                sx={{ fontWeight: 700, color: '#7A7C6F', marginBottom: '40px' }}
              >
                {jobDetailData?.companyProfileName}
              </Typography>
              <Box
                display={'grid'}
                sx={{
                  gridTemplate: 'repeat(4, min-content) / min-content 1fr'
                }}
                textAlign={'left'}
                rowGap={'20px'}
                columnGap={'40px'}
              >
                <span style={tagsStyle}>Category</span>
                <span style={informationStyle}>
                  {jobDetailData?.jobCategory}
                </span>
                <span style={tagsStyle}>Location</span>
                <span style={informationStyle}>
                  {jobDetailData!.postingAddress === null
                    ? 'No posting address info'
                    : `${jobDetailData!.postingAddress.city}, ${
                        jobDetailData!.postingAddress.state
                      }, ${jobDetailData!.postingAddress.country}`}
                </span>
                <span style={tagsStyle}>Pay rate</span>
                <span style={informationStyle}>
                  {payRate?.label}
                </span>
                <span style={tagsStyle}>Type</span>
                <span style={informationStyle}>
                  {jobType?.label}
                  </span>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ textAlign: 'right' }}>
              {userIsAdmin && renderJobActions()}
              <div style={datesStyle}>
                <Typography mb={2}>
                  <span style={dateInformationStyle}>
                    Posted on:{' '}
                    {moment(jobDetailData?.publishedOnUtc).format('ll')}
                  </span>
                </Typography>
                <Typography mb={2}>
                  <span style={dateInformationStyle}>
                    Expires on:{' '}
                    {moment(jobDetailData?.expiresAtUtc).format('ll')}
                  </span>
                </Typography>
              </div>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundColor: '#fff',
              minHeight: '600px',
              borderRadius: '10px'
            }}
          >
            <div style={openJobsContainer}>
              <Grid item xs={12} md={12} style={{ width: '100%' }}>
                <div style={openJobsTopStyle}>
                  <Typography gutterBottom style={openJobsSpan}>
                    Description
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={12} style={{ width: '100%' }}>
                <div style={openJobsMiddleStyle}>
                  <RichTextInput
                    value={
                      jobDetailData?.description
                        ? jobDetailData?.description
                        : ''
                    }
                    disabled={true}
                  />
                </div>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Page>
  );
};

const jobNameStyle: CSSProperties = {
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '120%',
  color: '#232323'
};

const tagsStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  textTransform: 'capitalize',
  color: '#232323'
};

const informationStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#232323'
};

const openJobsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  width: '100%'
};

const openJobsTopStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
  padding: 10
};

const openJobsSpan: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '36px',
  lineHeight: '55px',
  color: '#242926',
  marginBottom: '10px'
};

const noOpenJobsSpan: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '26px',
  color: '#26262580',
  marginBottom: '10px'
};

const openJobsMiddleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  padding: 10
};

const finishStyle: CSSProperties = {
  color: '#262625',
  fontSize: '14px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '14px'
};

const buttonsPreviewStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
};

const datesStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '90%',
  justifyContent: 'end'
};

const dateInformationStyle: CSSProperties = {
  fontStyle: 'italic',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '26px',
  color: '#232323'
};

export default JobDetail;
