import React from 'react';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OverviewSlot from './OverviewSlot';
import DashboardWidget from './DashboardWidget';
import axios from 'axios';
import config from '../../config';

const slotsMap: { [key: string]: string } = {
  assignedLessonsCount: `${config.STUDENT_API_URL}assignments/lesson/count`,
  totalCompletedLessons: `${config.STUDENT_API_URL}lessonprogress/complete/count`,
  totalOverdueLessons: `${config.STUDENT_API_URL}assignments/overdue/lesson/count`,
  totalTimeSpentWatchingLessons: `${config.COURSES_API_URL}lessons/timeSpent`,
  totalActiveUsers: `${config.STUDENT_API_URL}employeeLearningProfile/users/active/count`,
  totalInactiveUsers: `${config.STUDENT_API_URL}employeeLearningProfile/users/inactive/count`
};

interface OverviewStats {
  assignedLessonsCount?: number;
  totalCompletedLessons?: number;
  totalOverdueLessons?: number;
  totalTimeSpentWatchingLessons?: number;
  totalActiveUsers?: number;
  totalInactiveUsers?: number;
}

const Overview = () => {
  const { t } = useTranslation(['dashboard']);
  const theme = useTheme();

  const [stats, setStats] = React.useState<OverviewStats>({});

  React.useEffect(() => {
    Object.keys(slotsMap).forEach(key => {
      axios.get(slotsMap[key]).then(response => {
        setStats(current => ({
          ...current,
          [key]: response.data || 0
        }));
      });
    });
  }, []);

  const secondsToHm = (seconds: number) => {
    const totalMinutes = seconds / 60;
    const hours = totalMinutes >= 60 ? totalMinutes / 60 : 0;
    const minutesRemainder = totalMinutes % 60;
    return `${hours.toFixed()}h ${minutesRemainder.toFixed()}m`;
  };

  return (
    <DashboardWidget title={t('overview', { ns: 'dashboard' })}>
      <Grid container spacing={2}>
        <OverviewSlot
          label={stats.assignedLessonsCount?.toString()}
          description={t('assignedLessons')}
          labelColor={theme.palette.primary.main}
        />
        <OverviewSlot
          label={stats.totalCompletedLessons?.toString()}
          description={t('totalCompletedLessons')}
          labelColor={theme.palette.success.main}
        />
        <OverviewSlot
          label={stats.totalOverdueLessons?.toString()}
          description={t('totalOverdueLessons')}
          labelColor={theme.palette.error.light}
        />
        <OverviewSlot
          label={secondsToHm(stats.totalTimeSpentWatchingLessons || 0)}
          description={t('totalTimeSpentWatchingLessons')}
          labelColor={theme.palette.primary.main}
        />
        <OverviewSlot
          label={stats.totalActiveUsers?.toString()}
          description={t('totalActiveUsers')}
          labelColor={theme.palette.success.main}
        />
        <OverviewSlot
          label={stats.totalInactiveUsers?.toString()}
          description={t('totalInactiveUsers')}
          labelColor={theme.palette.error.light}
        />
      </Grid>
    </DashboardWidget>
  );
};

export default Overview;
