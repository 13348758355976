import React from 'react'
import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/styles';

const NotificationToast = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: '#CCFF00',
      color: '#262625',
      fontFamily: 'Titillium Web',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px'
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: '#FF0505',
      color: '#EDECE8',
      fontFamily: 'Titillium Web',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px'
    }
  }));

export default NotificationToast