import {
  createTheme,
  ThemeProvider,
  styled,
  ThemeOptions
} from '@mui/material/styles';
/* TODO: complete theme */
const themeConfig: ThemeOptions = {
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: '#262625',
      light: '#C4C4C4',
      dark: '#242926',
      contrastText: '#242926'
    },
    secondary: {
      main: '#edece8',
      dark: '#282828'
    },
    error: {
      main: '#FF0000',
      light: '#FF2A20'
    },
    success: {
      main: '#2ED47A'
    },
    info: {
      main: '#4D4E49'
    },
    grey: {
      '50': '#C4C4C4',
      '100': '#999999',
      '200': '#8f8f8f',
      '300': '#A9B3B7',
      '400': '#282828',
      '500': '#929292',
      '600': '#3E3E3E',
      '700': '#EBEBEB',
      '800': '#0E0D0D',
      '900': '#5B5B5B',
      A100: '#3C4444',
      A200: '#232424',
      A400: '#3D3D3D',
      A700: 'rgba(255, 255, 255, 0.56)'
    }
  },
  typography: {
    fontFamily: [
      'Titillium Web',
      'IBM Plex Sans',
      'Open Sans',
      'sans-serif'
    ].join(','),
    h6: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#262625'
    },
    h3: {
      color: '#EDECE8'
    },
    blackTitle: {
      fontSize: '40px',
      fontWeight: '700',
      color: '#262625'
    },
    blackTitleMedium: {
      fontSize: '25px',
      fontWeight: '700',
      color: '#262625'
    },
    blackSubTitle: {
      fontSize: '20px',
      fontWeight: '400',
      color: '#4E4E4C'
    },
    blackCaption: {
      fontSize: '16px',
      fontWeight: '400',
      color: '#262625'
    },
    redCaption: {
      fontSize: '16px',
      color: '#e74a3b'
    },
    blackCaptionItalic: {
      fontSize: '14px',
      fontWeight: '400',
      color: '#262625',
      fontStyle: 'italic'
    },
    blackStrongCaption: {
      color: '#000',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '14px'
    },
    body1: {
      color: '#000'
    },
    body2: {
      color: '#000'
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          background: 'rgba(237, 236, 232, 0.5)',
          border: '1px solid #D9D9D9',
          bordeRadius: '5px'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'edit' },
          style: {
            background: '#EDECE8',
            borderRadius: 22,
            color: '#262625',
            fontWeight: 700,
            fontSize: 16,
            height: 'min-content'
          }
        }
      ]
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100% !important',
          '&.Mui-focused': {
            border: '1px solid #D9D9D9',
            minHeight: '40px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'white',
              minHeight: '40px'
            },
            '& .MuiSvgIcon-root': {
              color: '#878787'
            }
          },
          backgroundColor: '#EDECE880 !important',
          border: '1px solid #D9D9D9',
          color: '#1B1C1C',
          minHeight: '40px !important',
          fontFamily: 'inherit',
          fontSize: '14px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '#3d3d3d'
          },
          '& .MuiOutlinedInput-input': {
            height: '10px !important'
          },
          '& .MuiOutlinedInput-input:focus': {
            backgroundColor: 'transparent !important',
            borderRadius: '5px'
          },
          '& .MuiSvgIcon-root': {
            color: '#878787'
          },
          '&.Mui-disabled': {
            color: '#999'
          }
        },
        input: {
          '&.Mui-disabled': {
            webkitTextFillColor: '#999'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: '14px'
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          borderRadius: '10px',
          padding: '10px'
        }
      },
      defaultProps: {
        elevation: 0,
        square: true
      }
    }
  }
};

export default themeConfig;
