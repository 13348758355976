import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  actionCreators,
  selectCertificationById
} from '../../../actions/userProfile';
import FallbackLoader from '../../../components/FallbackLoader/FallbackLoader';
import { CertificationDto } from '../../../entities/UserProfile';

import { ApplicationState } from '../../../store';
import { CertificationForm } from './CertificationForm';
import { FormDualButtons } from '../FormDualButtons';
import { SimpleFormPage } from '../../../components/Pages';
import { yupResolver } from '@hookform/resolvers/yup';
import { CertificationValidationSchema } from './CertificationValidationSchema';

export const CertificationSectionUpdate = () => {
  const history = useHistory();
  const params = useParams<{
    userProfileId: string;
    certificationId: string;
  }>();
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const certification = useSelector((state: ApplicationState) =>
    selectCertificationById(state, params.certificationId)
  );

  const dispatch = useDispatch();
  const { control, handleSubmit, reset, setValue, watch, formState } =
    useForm<CertificationDto>({
      resolver: yupResolver(CertificationValidationSchema)
    });

  const goToProfile = () => {
    history.push(`/user/profile/${params.userProfileId}`);
  };

  const onSubmit: SubmitHandler<CertificationDto> = data => {
    dispatch(
      actionCreators.updateCertification(
        data,
        params.userProfileId,
        params.certificationId,
        history
      )
    );
  };

  React.useEffect(() => {
    if (certification) {
      reset({
        skillName: certification.skillName,
        details: certification.details,
        grantingInstitution: certification.grantingInstitution,
        dateAwarded: certification.dateAwarded,
        expirationDate: certification.expirationDate,
        doesNotExpire: !certification.expirationDate ? true : false
      });
    }
  }, [certification]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <SimpleFormPage title={'Complete your profile'}>
      <CertificationForm
        title={'License / Certification'}
        submitHandler={onSubmit}
        setValue={setValue}
        watch={watch}
        formState={formState}
        bottomActions={
          <FormDualButtons
            cancelOnClick={goToProfile}
            submitButtonText="Save Changes"
          />
        }
        handleSubmit={handleSubmit}
        control={control}
      />
    </SimpleFormPage>
  );
};
