import React from 'react';
import { TopMenuAccount } from './TopMenuAccount';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/Blount_Logo.png';
import DirtWorldLogo from '../../assets/DirtWorldLogo.svg';
import Box from '@mui/material/Box';
import BasicButton from '../core/BasicButton/BasicButton';
import { UserClaims } from '../../core/constants';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import NotificationPanel from '../Notifications/NotificationPanel';
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Image } from 'mui-image';
import { styled, useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { TopMenuAccountMobile } from './TopMenuAccountMobile';

export interface TopMenuProps {
  isMobile: boolean;
  handleDrawerToggle: () => void;
}

const logoStyle = {
  maxWidth: '120px',
  maxHeight: '30px'
};

const appBarStyle = {
  zIndex: 1400
};

export const TopMenu: React.FC<TopMenuProps> = ({
  isMobile,
  handleDrawerToggle
}) => {
  const user = useCurrentUser();
  const companyLogo =
    (user?.profile[UserClaims.CompanyLogoUrl] as string) || logo;
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="static" style={appBarStyle}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={6} sm={6} mb={1}>
              <a href="https://dirtworld.com/" target="_blank" rel="noreferrer">
                <Image
                  src={DirtWorldLogo}
                  width={matches ? 160.33 : 250}
                  height={matches ? 30 : 46.87}
                  duration={0}
                  shiftDuration={0}
                />
              </a>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              mb={1}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              {matches ? <TopMenuAccountMobile /> : <TopMenuAccount />}
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
