import { AppThunkAction } from '../store/index';
import axios, { AxiosError, Method } from 'axios';
import {
  EmployeeLessonAssignment,
  Lesson,
  LessonAssignment
} from '../entities/Lesson';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import {
  LESSON_SET_LESSONS,
  LESSON_BY_ID,
  LESSON_SET_ALL_LESSONS,
  LESSONS_SET_ASSESSMENT_QUESTIONS,
  LESSON_SET_POSITION_TRACKING,
  LESSON_SAVE_POSITION_TRACKING,
  LESSON_UPDATE_TRANSACTION_STATUS,
  LESSON_RESET_TRANSACTION_STATUS,
  LESSON_SET_EMPLOYEE_ASSIGNMENTS,
  LESSONS_SET_QUIZ_QUESTIONS
} from './actionTypes';
import config from './../config';
import {
  uploadLessonThumbToBlob,
  uploadLessonDocToBlob,
  checkUploadImageQuestion
} from '../services/blobStorage-service';
import { ContentMedium } from '../entities/LessonFormModel';
import { CreateLessonDto } from '../entities/Dto/createLessonDto';
import { UpdateLessonDto } from '../entities/Dto/UpdateLessonDto';
import { Question } from '../entities/Assessment';
import { Guid } from 'guid-typescript';
import HttpStatusError from '../core/CustomErrors/HttpRequestError';

export interface RequestLessonsAction {
  type: 'LESSON_SET_LESSONS';
  lessons: Lesson[];
}

export interface RequestPositionTracking {
  type: 'LESSON_SET_POSITION_TRACKING';
  positionTracking: number;
  isTracked: boolean;
}

export interface SavePositionTracking {
  type: 'LESSON_SAVE_POSITION_TRACKING';
  videoId: string;
  playbackPosition: number;
}

export interface SetEditingLessonAction {
  type: 'LESSON_SET_EDITING';
  lesson: Lesson;
}

export interface RequestLessonByIdAction {
  type: 'LESSON_BY_ID';
  lesson: Lesson | null;
}

export interface RequestAllLessonsAction {
  type: 'LESSON_SET_ALL_LESSONS';
  allLessons: Lesson[];
}

export interface RequestAssessmentQuestionsAction {
  type: 'LESSONS_SET_ASSESSMENT_QUESTIONS';
  questions: Question[];
  assessmentId: string;
  correctAnswersToPass: number;
}

export interface UpdateLessonTransactionStatusAction {
  type: 'LESSON_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetLessonTransactionStatusAction {
  type: 'LESSON_RESET_TRANSACTION_STATUS';
}

export interface RequestEmployeeLessonAssigment {
  type: 'LESSON_SET_EMPLOYEE_ASSIGNMENTS';
  employeeLessonAssigment: EmployeeLessonAssignment[];
}

export interface RequestQuizQuestionsAction {
  type: 'LESSONS_SET_QUIZ_QUESTIONS';
  questions: Question[];
  correctAnswersToPass: number;
}

export const actionCreators = {
  requestLessons: (courseId: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState && appState.lessons?.lessons && Guid.isGuid(courseId)) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}courses/${courseId}/lesson`
      );
      dispatch({
        type: LESSON_SET_LESSONS,
        lessons: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestLessonByEntryId: (
    lessonEntryId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}lessons/byEntry?lessonEntryId=${lessonEntryId}`
      );
      dispatch({
        type: LESSON_BY_ID,
        lesson: { ...res.data, lessonEntryId: lessonEntryId }
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestLessonById: (lessonId: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}lessons?lessonId=${lessonId}`
      );
      dispatch({
        type: LESSON_BY_ID,
        lesson: { ...res.data }
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  saveLessonForCourse: (
    lessonDto: CreateLessonDto,
    thumbnailFile: File,
    pdfFile: File,
    courseId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      try {
        if (thumbnailFile) {
          const thumbUrl = await uploadLessonThumbToBlob(thumbnailFile);
          lessonDto.thumbnailUrl = thumbUrl;
        }
        if (lessonDto.contentMedium === ContentMedium.Pdf && pdfFile) {
          lessonDto.documentUrl = await uploadLessonDocToBlob(pdfFile);
          lessonDto.lessonFileName = pdfFile.name;
          lessonDto.lessonFileContentType = pdfFile.type;
        }
        const res = await axios.post(
          `${config.COURSES_API_URL!}courses/${courseId}/lesson`,
          lessonDto,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        );

        if (res.status === HttpStatusEnum.OK) {
          await dispatch({
            type: LESSON_BY_ID,
            lesson: res.data
          });

          return res.data.id;
        }

        return '';
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  saveLesson: (
    lessonDto: CreateLessonDto,
    thumbnailFile: File,
    pdfFile: File
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));
      try {
        if (thumbnailFile) {
          const thumbUrl = await uploadLessonThumbToBlob(thumbnailFile);
          lessonDto.thumbnailUrl = thumbUrl;
        }
        if (lessonDto.contentMedium === ContentMedium.Pdf && pdfFile) {
          lessonDto.documentUrl = await uploadLessonDocToBlob(pdfFile);
          lessonDto.lessonFileName = pdfFile.name;
          lessonDto.lessonFileContentType = pdfFile.type;
        }
        
        const lessonId = await axios
          .post(`${config.COURSES_API_URL!}community/lessons`, lessonDto, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(async res => {
            await dispatch({
              type: LESSON_BY_ID,
              lesson: res.data
            });

            return res.data.id;
          })
          .catch((e: AxiosError) => {
            if (e.response?.status === HttpStatusEnum.Conflict) {
              throw new HttpStatusError(
                e.response.data as string,
                HttpStatusEnum.Conflict
              );
            }
          });

        return lessonId;
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  updateLesson: (
    lessonDto: UpdateLessonDto,
    thumbnailFile: File,
    pdfFile: File
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      try {
        if (thumbnailFile) {
          const thumbUrl = await uploadLessonThumbToBlob(thumbnailFile);
          lessonDto.thumbnailUrl = thumbUrl;
        }
        if (lessonDto.contentMedium === ContentMedium.Pdf && pdfFile) {
          lessonDto.documentUrl = await uploadLessonDocToBlob(pdfFile);
          lessonDto.lessonFileName = pdfFile.name;
          lessonDto.lessonFileContentType = pdfFile.type;
        }
        await axios.put(`${config.COURSES_API_URL!}lessons`, lessonDto, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        });
        const res = await axios.get(`${config.COURSES_API_URL!}lessons/all`);
        dispatch({
          type: LESSON_SET_ALL_LESSONS,
          allLessons: res.data
        });
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },

  deleteLesson: (lessonId: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      try {
        await axios.delete(
          `${config.COURSES_API_URL!}lessons/?lessonId=${lessonId}`,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        );
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  setCurrentLessons: (lessons: Lesson[]) => ({
    type: LESSON_SET_LESSONS,
    lessons: lessons
  }),
  setCurrentLesson: (lesson: Lesson | null) => ({
    type: LESSON_BY_ID,
    lesson: lesson
  }),
  requestAllLessons: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL!}lessons/all`);
      dispatch({
        type: LESSON_SET_ALL_LESSONS,
        allLessons: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestCompanyLessons: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL!}community/lessons/all`);
      dispatch({
        type: LESSON_SET_ALL_LESSONS,
        allLessons: res.data
      });
    }
  },
  requestAssessmentQuestions: (
    lessonId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState && appState.lessons?.currentLesson) {
      dispatch(appActions.setIsLoading(true));
      try {
        const res = await axios.get(
          `${config.ASSESSMENT_API_URL!}assessments/lesson/${lessonId}`
        );

        dispatch({
          type: LESSONS_SET_ASSESSMENT_QUESTIONS,
          questions: res.data.questions,
          assessmentId: res.data.id,
          correctAnswersToPass: res.data.correctAnswersToPass
        });
      } catch (e) {
        const error = e as AxiosError;
        if (error?.response?.status === 404) {
          dispatch({
            type: LESSONS_SET_ASSESSMENT_QUESTIONS,
            questions: [],
            assessmentId: '',
            correctAnswersToPass: 0
          });
        }
      }

      dispatch(appActions.setIsLoading(false));
    }
  },
  saveAssessment: (
    questionsDto: Question[],
    lessonId: string,
    isEditing: boolean,
    assessmentId: string | null,
    correctAnswersToPass: number | null,
    updateAssessmentIdInLesson: boolean
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      try {
        const method: Method = isEditing ? 'PUT' : 'POST';
        const newQuestions = await checkUploadImageQuestion(questionsDto);
        const data = {
          lessonId,
          questions: newQuestions,
          id: assessmentId,
          correctAnswersToPass: correctAnswersToPass
        };
        const backendResponse = await axios({
          method,
          headers: {
            'Access-Control-Allow-Origin': '*'
          },
          url: `${config.ASSESSMENT_API_URL!}assessments/lesson`,
          data
        });

        if (updateAssessmentIdInLesson) {
          await axios.put(
            `${config.COURSES_API_URL!}lessons/assessment/${lessonId}/${
              backendResponse.data.id
            }/`,
            null
          );
        }
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  requestPositionTracking: (
    videoId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.lessons?.lessons) {
      dispatch(appActions.setIsLoading(true));
      let res = null;
      let response;
      let tracked = false;
      try {
        res = await axios.get(
          `${config.LEADERS_API_URL!}Playback/tracking/${videoId}/`
        );
        if (res.status === HttpStatusEnum.OK && res.data.data != null) {
          response = res.data.data.playbackPosition;
          tracked = response > 0;
        } else {
          response = 0;
          tracked = false;
        }
      } catch (err) {
        //@ts-ignore
        let errorStatus = err.response.status;
        if (errorStatus === HttpStatusEnum.InternalServerError) {
          response = 0;
          tracked = false;
        }
        if (errorStatus === HttpStatusEnum.NotFound) {
          response = 0;
          tracked = false;
        }
      }
      dispatch({
        type: LESSON_SET_POSITION_TRACKING,
        positionTracking: await response,
        isTracked: tracked
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  savePositionTracking: (
    videoId: string,
    playbackPosition: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      let data = {
        videoId: videoId,
        playbackPosition: playbackPosition
      };
      try {
        await axios.post(`${config.LEADERS_API_URL!}Playback/tracking/`, data);
      } catch (err) {
        console.error(err);
      }
      dispatch({
        type: LESSON_SAVE_POSITION_TRACKING,
        videoId: videoId,
        playbackPosition: playbackPosition
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  resetPositionTracking: () => ({
    type: LESSON_SET_POSITION_TRACKING,
    positionTracking: 0
  }),

  assignLessonToEmployees: (
    lessonAssignment: LessonAssignment
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      let httpStatus: number = 0;
      try {
        const res = await axios.post(
          `${config.STUDENT_API_URL!}assignments/assign/lesson/employees/`,
          lessonAssignment
        );
        httpStatus = res.status;
      } catch (e) {
      } finally {
        dispatch(appActions.setIsLoading(false));
        dispatch({
          type: LESSON_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            httpStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
      }
    }
  },
  requestEmployeeLessonAssignments: (
    lessonId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}assignments/lesson/${lessonId}`
        );
        dispatch({
          type: LESSON_SET_EMPLOYEE_ASSIGNMENTS,
          employeeLessonAssigment: res.data
        });
      } catch (e) {
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  requestCourseLessonsByRevision: (
    courseId: string,
    revisionNumber: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.lessons?.lessons && Guid.isGuid(courseId)) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}courses/${courseId}/${revisionNumber}/lesson`
      );
      dispatch({
        type: LESSON_SET_LESSONS,
        lessons: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  resetTransactionStatus: () => ({
    type: LESSON_RESET_TRANSACTION_STATUS
  }),
  requestQuizQuestions: (
    assessmentId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState && appState.lessons?.currentLesson) {
      dispatch(appActions.setIsLoading(true));
      try {
        const res = await axios.get(
          `${config.ASSESSMENT_API_URL!}assessments/lesson/${assessmentId}/assessment`
        );

        dispatch({
          type: LESSONS_SET_QUIZ_QUESTIONS,
          questions: res.data.questions,
          correctAnswersToPass: res.data.correctAnswersToPass
        });
      } catch (e) {
        const error = e as AxiosError;
        if (error?.response?.status === 404) {
          dispatch({
            type: LESSONS_SET_QUIZ_QUESTIONS,
            questions: [],
            correctAnswersToPass: 0
          });
        }
      }

      dispatch(appActions.setIsLoading(false));
    }
  }
};

export type KnownAction =
  | SetLoadingAction
  | RequestLessonsAction
  | RequestLessonByIdAction
  | SetEditingLessonAction
  | RequestAllLessonsAction
  | RequestAssessmentQuestionsAction
  | RequestPositionTracking
  | SavePositionTracking
  | UpdateLessonTransactionStatusAction
  | ResetLessonTransactionStatusAction
  | RequestEmployeeLessonAssigment
  | RequestAssessmentQuestionsAction
  | RequestQuizQuestionsAction;
