import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/system';
import { useTranslation } from 'react-i18next';

export interface LearningPlanPageHeaderProps {
  title: string;
  goBackLinkText: string;
  buttonText: string;
  handleGoBack: () => void;
  handleButtonClick: () => void;
  handleDelete: () => void;
}

const LearningPlanPageHeader: React.FC<LearningPlanPageHeaderProps> = ({
  title,
  goBackLinkText,
  buttonText,
  handleGoBack,
  handleButtonClick,
  handleDelete
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['learningPlans']);

  const styleIcon = {
    marginRight: '8px'
  };

  const styleBackToStep1 = {
    color: theme.palette.grey[100],
    cursor: 'pointer'
  };

  const deleteButtonStyle = {
    width: '160px',
    height: '45px'
  };

  const goBackStyle: SxProps<Theme> = {
    paddingBottom: '0.5rem'
  };

  const buttonContainerStyle: SxProps<Theme> = {
    display: 'flex',
    justifyContent: {
      xs: 'flex-start',
      lg: 'flex-end'
    },
    flexWrap: {
      xs: 'wrap',
      md: 'nowrap'
    },
    gap: {
      xs: '0.3rem',
      md: '1rem'
    }
  };

  return (
    <Grid container columns={{ xs: 1, lg: 12 }}>
      <Grid item xs={1} lg={5} sx={goBackStyle}>
        <h3>{title}</h3>
        <span style={styleBackToStep1} onClick={handleGoBack}>
          <FontAwesomeIcon icon={faChevronLeft} style={styleIcon} />
          {goBackLinkText}
        </span>
      </Grid>
      <Grid item xs={1} lg={7} sx={buttonContainerStyle}>
        <BasicButton
          color="delete"
          style={deleteButtonStyle}
          onClick={handleDelete}
        >
          {t('deleteLearningPlan', {ns: 'learningPlans'})}
        </BasicButton>

        <BasicButton
          color="primary"
          style={buttonStyle}
          onClick={handleButtonClick}
        >
          <Typography sx={fontButton}>{buttonText}</Typography>
        </BasicButton>
      </Grid>
    </Grid>
  );
};

export default LearningPlanPageHeader;
