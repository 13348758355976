import React, { ReactNode, useEffect, useState } from 'react';
import { AuthProvider, useAuth } from 'react-oidc-context';
import axios from 'axios';

import {
  AccessDenied,
  AccessDeniedLoadingScreen
} from '../../pages/AccessDenied';
import { CreateUserProfile } from '../../pages/CreateDirtWorldProfileOld';
import { UserClaims } from '../../core/constants';
import { actionCreators } from '../../actions/appState';
import { actionCreators as companyProfileActions } from '../../actions/companies';
import { actionCreators as profileActions } from '../../actions/userProfile';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'oidc-client-ts';
import config from '../../config';
import { ApplicationState } from '../../store';
import { HttpStatusEnum } from '../../core/enums';
import { Redirect } from 'react-router';

export interface IPrivateRouteProps {
  children: any;
}

export const Login = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [profileReqIsLoading, setProfileReqIsLoading] =
    useState<boolean>(false);
  //const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
  const trainingSubClaim =
    auth.user?.profile[UserClaims.TrainingEmployeeSubscription];
  const isLoading = auth.isLoading || !auth.user || auth.user.expired === true;

  const userProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );
  const isLoadingUserProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.isLoading
  );

  useEffect(() => {
    if (!auth.isLoading && (auth.user?.expired || !auth.isAuthenticated))
      auth.signinSilent().catch(err => {
        if (err?.message.match(/login_required/)) {
          auth.signinRedirect({ state: window.location.href });
        } else if (err?.message.match(/invalid_grant/)) {
          auth.signinRedirect({
            state: window.location.href
          });
        }
      });
  }, [auth.isLoading]);

  useEffect(() => {
    dispatch(actionCreators.setIsLoading(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (auth.user) {
      firstLoginActions();
    }
  }, [auth.user, dispatch]);

  const firstLoginActions = async () => {
    try {
      dispatch(actionCreators.setIsLoading(true));
      const userId = auth.user?.profile[UserClaims.UserId] as string;
      const response = await axios.get(
        `${config.USERPROFILE_API_URL!}userprofile/byUserId/${userId}`
      );

      // Load User Profile
      if (response.status === HttpStatusEnum.OK && response.data) {
        if (!response.data.length) {
          dispatch(profileActions.setUserProfile(response.data));
        }
      }

      // Load company profile
      const companyProfileResponse = await axios.get(
        `${config.USERPROFILE_API_URL!}company/byUserId/${userId}`
      );
      if (response.status === HttpStatusEnum.OK && response.data) {
        if (!response.data.length) {
          dispatch(
            companyProfileActions.setCompanyProfile(companyProfileResponse.data)
          );
        }
      }

      dispatch(actionCreators.setIsLoading(false));
    } catch (err) {
      dispatch(actionCreators.setIsLoading(false));
    }
  };

  if (isLoading || isLoadingUserProfile) {
    return <AccessDeniedLoadingScreen />;
  }

  if (auth.user) {
    return <>{children}</>;
  } else {
    return <AccessDenied />;
  }
};
