import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  getColorThumbnails,
  getBWThumbnails
} from '../../services/blobStorage-service';
import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';
import { ThumbnailListView } from '../ThumbnailListView/ThumbnailListView';
import { InputFileUpload } from '../InputFileUpload/InputFileUpload';
import CircularProgress from '@mui/material/CircularProgress';
import { CSSProperties } from 'react';

interface ThumbnailLibraryPanelProps {
  onItemSelected: (item: any) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  thumbnailUrl: string;
}
interface ThumbnailLibraryTabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabStyle: CSSProperties = {
  backgroundColor: 'Transparent !important',
  textTransform: 'capitalize'
};

const TabContainerStyle: CSSProperties = {
  borderRadius: '5px',
  borderWidth: '1px',
  borderStyle: 'solid'
};

const tabLoaderStyle: CSSProperties = {
  textAlign: 'center',
  width: '100%',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const ThumbnailLibraryTab = (props: ThumbnailLibraryTabProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`library-tabpanel-${index}`}
      aria-labelledby={`library-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `library-tab-${index}`,
    'aria-controls': `library-tabpanel-${index}`
  };
};

export const ThumbnailLibraryPanel = ({
  onItemSelected,
  onChange,
  thumbnailUrl
}: ThumbnailLibraryPanelProps) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [colorThumbnails, setColorThumbnails] = React.useState<
    AzureBlobImageInfo[]
  >([]);
  const [bwThumbnails, setBwThumbnails] = React.useState<AzureBlobImageInfo[]>(
    []
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleItemClick = (item: AzureBlobImageInfo) => {
    if (onItemSelected) {
      onItemSelected(item);
    }
  };

  const loadThumbnails = async () => {
    setIsLoading(true);
    const colorThumbs = await getColorThumbnails();
    const bwThumbs = await getBWThumbnails();
    setColorThumbnails(colorThumbs);
    setBwThumbnails(bwThumbs);
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadThumbnails();
  }, []);

  return (
    <Box sx={{ width: '100%' }} style={TabContainerStyle}>
      {isLoading ? (
        <Box sx={{ display: 'flex' }} style={tabLoaderStyle}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="Thumbnail Library"
              id="panelTabs"
            >
              <Tab label="Upload" {...a11yProps(0)} style={TabStyle} />
              <Tab label="Color" {...a11yProps(1)} style={TabStyle} />
              <Tab label="Black and White" {...a11yProps(2)} style={TabStyle} />
            </Tabs>
          </Box>
          <ThumbnailLibraryTab value={selectedTab} index={0}>
            <InputFileUpload
              thumbnailUrl={thumbnailUrl}
              initialText=""
              onChange={onChange}
            />
          </ThumbnailLibraryTab>
          <ThumbnailLibraryTab value={selectedTab} index={1}>
            <ThumbnailListView
              items={colorThumbnails}
              onItemSelected={handleItemClick}
              selectedImgUrl={thumbnailUrl}
            />
          </ThumbnailLibraryTab>
          <ThumbnailLibraryTab value={selectedTab} index={2}>
            <ThumbnailListView
              items={bwThumbnails}
              onItemSelected={handleItemClick}
              selectedImgUrl={thumbnailUrl}
            />
          </ThumbnailLibraryTab>
        </>
      )}
    </Box>
  );
};
