import React, { useEffect, useState, CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { DropdownItem } from '../../entities/Common';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import {
  StartTimes,
  DefaultStartTimeShiftInMinutesUtc
} from '../../core/constants';
import { EmployeeGroup } from '../../entities/Employee';
import { GroupStartTimeModel } from '../../entities/Employee';
import { useTranslation } from 'react-i18next';

export interface GroupStartTimeProps {
  employeeGroups: EmployeeGroup[];
  groupStartTime: GroupStartTimeModel;
  errorMessage: string;
  setGroupStartTime: (groupStartTime: GroupStartTimeModel) => void;
}

const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column'
};

const itemStyle: SxProps = {
  paddingBottom: '1rem'
};

const GroupWorkload: React.FC<GroupStartTimeProps> = ({
  employeeGroups,
  groupStartTime,
  errorMessage,
  setGroupStartTime
}) => {
  const theme = useTheme();
  const [groups, setGroups] = useState<DropdownItem[]>([]);
  const [applyAllChecked, setApplyAllChecked] = useState<boolean>(false);

  const { t } = useTranslation(['employees']);

  useEffect(() => {
    setGroupStartTime({
      groupId: '',
      applyToAll: false,
      startTimeMinutes: DefaultStartTimeShiftInMinutesUtc
    });
  }, []);

  useEffect(() => {
    if (employeeGroups) {
      const items = employeeGroups.map(group => {
        return { label: group.name, value: group.id };
      });
      setGroups(items);
    }
  }, [employeeGroups]);

  const handleGroupChange = (items: string[]) => {
    if (items.length)
      setGroupStartTime({ ...groupStartTime, groupId: items[0] });
  };

  const handleStartTimeChange = (items: string[]) => {
    if (items.length) {
      const startTime = items[0] as unknown;
      setGroupStartTime({
        ...groupStartTime,
        startTimeMinutes: startTime as number
      });
    }
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let newGroupStartTime = { ...groupStartTime, applyToAll: checked };
    if (checked) {
      newGroupStartTime.groupId = '';
    }
    setGroupStartTime(newGroupStartTime);
    setApplyAllChecked(checked);
  };

  const mandatoryStyle: CSSProperties = {
    color: theme.palette.error.main
  };

  const errorStyle: CSSProperties = {
    color: theme.palette.error.main,
    paddingBottom: '1rem'
  };

  const checkBoxStyle: SxProps = {
    color: theme.palette.primary.main
  };

  const checkBoxLabelStyle: SxProps = {
    color: theme.palette.common.white
  };

  const checkBoxGridStyle: SxProps = {
    paddingLeft: '1rem'
  };

  return (
    <Grid container sx={containerStyle}>
      {errorMessage.length > 0 && (
        <span style={errorStyle}>{errorMessage}</span>
      )}
      <Grid item>
        <FormLabel>
          {t('chooseGroupOrAll', { ns: 'employees' })}
          <span style={mandatoryStyle}>*</span>
        </FormLabel>
        <Grid container xs={12}>
          <Grid item xs={6}>
            <BasicSelect
              options={groups}
              multiple={false}
              value={
                groupStartTime.groupId === '' ? [] : [groupStartTime.groupId]
              }
              placeholder={t('groupName', { ns: 'employees' })}
              handleOnChange={handleGroupChange}
              disabled={applyAllChecked}
              theme="dark"
            />
          </Grid>
          <Grid item xs={6} sx={checkBoxGridStyle}>
            <FormControlLabel
              control={
                <Checkbox sx={checkBoxStyle} onChange={handleCheckBoxChange} />
              }
              sx={checkBoxLabelStyle}
              checked={applyAllChecked}
              label={t('applyToAllEmployees', { ns: 'employees' })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={itemStyle}>
        <FormLabel>
          {t('startTime', { ns: 'employees' })}
          <span style={mandatoryStyle}>*</span>
        </FormLabel>
        <BasicSelect
          options={StartTimes}
          value={[groupStartTime.startTimeMinutes.toString()]}
          multiple={false}
          handleOnChange={handleStartTimeChange}
          theme="dark"
        />
      </Grid>
    </Grid>
  );
};

export default GroupWorkload;
