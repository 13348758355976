import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/companies';

import {
  COMPANY_IS_LOADING,
  COMPANY_SET_COMPANIES,
  COMPANY_SET_COMPANY,
  COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE,
  COMPANIES_GET_ADMINS,
  COMPANY_GET_EMPLOYEE_BY_QUERY,
  COMPANIES_SET_ADMIN,
  COMPANY_REMOVE_ADMIN,
  COMPANIES_IS_LOADING
} from '../actions/actionTypes';
import { ICompany } from '../entities/Company';
import { CompanyProfileDTO } from '../entities/Dto/createCompanyDto';
import { UserProfileDto } from '../entities/UserProfile';

export interface CompaniesState {
  companies: ICompany[];
  isLoading: boolean;
  company: ICompany | null;
  currentCompanyProfile: CompanyProfileDTO | null;
  admins: UserProfileDto[];
  employeesQueryResult: UserProfileDto[];
}

const unloadedState: CompaniesState = {
  companies: [],
  isLoading: false,
  company: null,
  currentCompanyProfile: null,
  admins: [],
  employeesQueryResult: []
};

export const CompaniesReducer: Reducer<CompaniesState> = (
  state: CompaniesState | undefined,
  incomingAction: Action
): CompaniesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case COMPANY_SET_COMPANIES:
      return { ...state, companies: action.companies };
    case COMPANY_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case COMPANY_SET_COMPANY:
      return { ...state, company: action.company };
    case COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE:
      return { ...state, currentCompanyProfile: action.companyProfile };
    case COMPANIES_GET_ADMINS:
      return { ...state, admins: action.admins };
    case COMPANY_GET_EMPLOYEE_BY_QUERY:
      return { ...state, employeesQueryResult: action.employees };
    case COMPANIES_SET_ADMIN:
      return { ...state, admins: [...state.admins, action.admin] };
    case COMPANY_REMOVE_ADMIN:
      return {
        ...state,
        admins: state.admins.filter(admin => admin.id !== action.adminId)
      };
    case COMPANIES_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    default:
      return state;
  }
};
