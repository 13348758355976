import * as React from 'react';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import BasicButton from '../BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

interface ModalPageProps {
  children: any;
  title: string;
  show: boolean;
  onSave?: () => void;
  onCancel: any;
  saveText: string;
  cancelText: string;
  showSave: boolean;
  showCancel: boolean;
  variant: 'sm' | 'lg' | 'xl' | undefined;
  bodyClassName?: string;
  modalClassName?: string;
  contentClassName?: string;
  backdrop?: 'static' | true | false;
  keyboard?: boolean;
  extraOptions?: any[]
}

const ModalPage: FC<ModalPageProps> = ({
  children,
  title,
  show,
  onSave,
  onCancel,
  saveText = 'Save',
  cancelText = 'Close',
  showSave = true,
  showCancel = true,
  variant = 'sm',
  bodyClassName = '',
  modalClassName = 'styled-modal',
  contentClassName = '',
  backdrop = true,
  keyboard = true,
  extraOptions = []
}: ModalPageProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Modal
      show={show}
      onHide={onCancel}
      style={modalStyle}
      size={variant}
      aria-labelledby={`example-modal-sizes-title-${variant}`}
      className={modalClassName}
      contentClassName={contentClassName}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title className="text-white">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyClassName}>{children}</Modal.Body>
      {(showCancel || showSave) && (
        <Modal.Footer>
          {showCancel && (
            <BasicButton onClick={onCancel} color="primary">
              {cancelText === 'Close' ? t('close', {ns: 'common'}) : cancelText}
            </BasicButton>
          )}
          {extraOptions.map(component => component)}
          {showSave && (
            <BasicButton onClick={onSave!} color="primary">
              {saveText === 'Save' ? t('save', {ns: 'common'}) : saveText}
            </BasicButton>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

const modalStyle = {
  color: '#999999'
};

export default ModalPage;
