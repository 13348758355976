import { AppStateReducer, AppState } from '../reducers/AppStateReducer';
import { CoursesReducer, CoursesState } from '../reducers/CoursesReducer';
import { LessonsReducer, LessonsState } from '../reducers/LessonsReducer';
import { TagsReducer, TagsState } from '../reducers/TagsReducer';
import { EmployeesReducer, EmployeesState } from '../reducers/EmployeesReducer';
import {
  LearningPlansReducer,
  LearningPlanState
} from '../reducers/LearningPlansReducer';
import {
  AssessmentsReducer,
  AssessmentsState
} from '../reducers/AssessmetsReducer';
import { StudentReducer, StudentState } from '../reducers/StudentReducer';
import {
  NotificationsReducer,
  NotificationsState
} from '../reducers/NotificationsReducer';
import {
  UserProfileReducer,
  UserProfileState
} from '../reducers/UserProfileReducer';
import { CompaniesReducer, CompaniesState } from '../reducers/CompaniesReducer';
import { JobsState, JobsReducers } from '../reducers/JobsReducers';
import {
  AddressesReducers,
  AddressesState
} from '../reducers/AddressesReducers';

// The top-level state object
export interface ApplicationState {
  appState: AppState | undefined;
  courses: CoursesState | undefined;
  lessons: LessonsState | undefined;
  tags: TagsState | undefined;
  employees: EmployeesState | undefined;
  learningPlans: LearningPlanState | undefined;
  assessments: AssessmentsState | undefined;
  student: StudentState | undefined;
  notifications: NotificationsState | undefined;
  userProfile: UserProfileState | undefined;
  companies: CompaniesState | undefined;
  jobs: JobsState | undefined;
  addresses: AddressesState | undefined;
}

export const reducers = {
  appState: AppStateReducer,
  courses: CoursesReducer,
  lessons: LessonsReducer,
  tags: TagsReducer,
  employees: EmployeesReducer,
  learningPlans: LearningPlansReducer,
  assessments: AssessmentsReducer,
  student: StudentReducer,
  notifications: NotificationsReducer,
  userProfile: UserProfileReducer,
  companies: CompaniesReducer,
  jobs: JobsReducers,
  addresses: AddressesReducers
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
