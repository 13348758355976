import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useStringLengthControl = (
        limit: number,
        showWarningIn: number,
        initText: string
    ) => {
        const { t } = useTranslation(['common']);

        const getWarningText = (text: string) : string => {
            return text.length >= showWarningIn ?
                `${ limit - text.length } ${t('charactersRemaining', {ns: 'common'})}.`
                : '';
        } 
            
        const [ warningText, setWarningText ] = useState<string>(() => getWarningText(initText));
        
        const evaluateTextLength = (text : string) => {
            setWarningText(getWarningText(text));
        }

        return [warningText, evaluateTextLength] as const
}