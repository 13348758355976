import React, { CSSProperties } from 'react';
import { Employee } from '../../entities/Employee';
import { useTheme } from '@mui/material/styles';
import SelectEntityButton from '../../components/SelectEntityButton/SelectEntityButton';
import { useTranslation } from 'react-i18next';

export interface EmployeeListItemProps {
    employee: Employee;
    isAssgined: boolean;
    handleChangeAssign: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }
  
const EmployeeListItem : React.FC<EmployeeListItemProps> = ({
    employee,
    isAssgined,
    handleChangeAssign
  }) => {
    const { t } = useTranslation(['common']);
    const theme = useTheme();
    const { id, firstName, lastName, email, phoneNumber, isCompanyAdmin, photoUrl, title, groups } = employee;
    let groupNames : string = '';
  
    groups.forEach(g => 
      groupNames += !groupNames.length ? g.name : `, ${g.name}`
    );
  
    const grayStyle: CSSProperties = {
      color: theme.palette.grey[100]
    }

    return(<tr>
       <td>
          <div className="d-inline-flex">
            <img
                className={`img-profile rounded-circle ${title ? 'my-4' : 'my-3' }`}
                width="40"
                height="40"
                alt={ `${firstName} ${lastName}` }
                src={ photoUrl && photoUrl.length
                        ? photoUrl 
                        : require('../../assets/user.png').default}
            />
            <div  className="d-flex flex-column ps-3 mt-4" >
              <span >{`${firstName} ${lastName}`}</span>
              <span style={grayStyle}>{title}</span>
            </div>
          </div>
       </td>
       <td className="align-middle">
        {email && email.length ? email : phoneNumber}
       </td>
       <td className="align-middle">
        {groupNames}
       </td>
       <td className="text-center align-middle">
          <SelectEntityButton 
            entity={employee}
            isSelected={isAssgined}
            onChangeSelection={handleChangeAssign}
            isSelectedText={t('unassign', {ns: 'common'})}
            notSelectedText={t('assign', {ns: 'common'})}
          />
       </td>
    </tr>)
  };

export default EmployeeListItem;
