import React, { CSSProperties, useState, FC } from 'react';
import TextArea from '../core/TextArea/TextArea';
import FormLabel from '../core/FormLabel/FormLabel';
import TextBox from '../core/TextBox/TextBox';
import { Question } from '../../entities/Assessment';
import { useTranslation } from 'react-i18next';

const panelStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column' as 'column'
};

export interface NumberQuestionDefinitionProps {
  question: Question;
  changeHandler: (prop: string, value: any) => void;
}

const NumberQuestionDefinition: FC<NumberQuestionDefinitionProps> = ({
  question,
  changeHandler
}) => {
  const [questionError, setQuestionError] = useState<boolean>(false);
  const [optionError, setOptionError] = useState<boolean>(false);
  const { t } = useTranslation(['assessments']);

  const handleChangeOption = (e: string) => {
    if (e) {
      setQuestionError(false);
    } else {
      setQuestionError(true);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event) {
      setOptionError(false);
    } else {
      setOptionError(true);
    }
  };

  return (
    <div style={panelStyle}>
      <FormLabel>
        {t('question', {ns: 'assessments'})} <span className="text-danger">*</span>
      </FormLabel>
      <TextArea
        id="assessmentQuestion"
        name="questionText[]"
        value={question.questionText}
        onChangeValue={value => changeHandler('questionText', value)}
        placeholderText={t('writeQuestionHere', {ns: 'assessments'})}
        width="100%"
        rows={5}
      ></TextArea>
      {questionError && (
        <p className="text-danger"> {t('questionFieldRequired', {ns: 'assessments'})} </p>
      )}
      <br />
      <FormLabel>
        {t('correctNumber', {ns: 'assessments'})}: <span className="text-danger">*</span>
      </FormLabel>
      <div style={panelStyle}>
        <TextBox
          id="number"
          name="numberCorrectAnswer[]"
          value={question.numberCorrectAnswer}
          onChangeValue={value => changeHandler('numberCorrectAnswer', value)}
          placeholderText={t('writeYourAnswer', {ns: 'assessments'})}
          width="100%"
          height="35px"
          isNumber
        ></TextBox>
      </div>
      {optionError && <p className="text-danger">{t('answerMustBeNumber', {ns: 'assessments'})}</p>}
      <br />
    </div>
  );
};

const colorRadio: CSSProperties = {
  color: '#FFFFFF'
};

const paddingRadio: CSSProperties = {
  paddingRight: 15
};

export default NumberQuestionDefinition;
