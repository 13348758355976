import React, { useState, CSSProperties } from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { useSignOut } from '../../hooks/useCurrentUser';
import config from '../../config';
import usePermissions from '../../hooks/usePermissions';
import { appPermissions } from '../../core/constants';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import TopMenuElements from './TopMenuElements';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const useStyles = makeStyles(() => ({
  hamburger: {
    transition: 'transform 0.6s ease-out',
    '&.open': {
      transform: 'rotate(90deg)',
    },
  },
}));

export const TopMenuAccount: React.FC<{}> = () => {
  const [isShow, setShow] = useState(false);
  const { signOut } = useSignOut();
  const anchor = 'top';
  const classes = useStyles();
  const user = useCurrentUser();
  const history = useHistory();
  const hasPermission = usePermissions();
  const userProfile = useSelector(
    (state: ApplicationState) => state.userProfile?.currentUserProfile
  );

  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const { t } = useTranslation(['common']);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const logout = async () => {
    /** TODO: Card 726 investigate why auth.signoutRedirect() doesn't work as expected */
    //user.userManager.signoutRedirect();
    handleCloseUserMenu();
    await signOut();
  };

  const goToUserProfileDetails = () => {
    handleCloseUserMenu();
    history.push(`/user/profile/${userProfile?.id}`);
  };

  const goToCompanyProfileDetails = () => {
    handleCloseUserMenu();
    history.push(`/company/profile/${currentCompanyProfile?.id}`);
  };

  const handlePostJob = () => {
    handleCloseUserMenu();
    if (currentCompanyProfile) {
      history.push({
        pathname: `/company/profile/jobs/post-job-create`
      });
    } else {
      history.push({
        pathname: `/company/profile/create`
      });
    }
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        backgroundColor: '#262625'
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <TopMenuElements />
    </Box>
  );

  const userFirstName = userProfile?.name ?? '';

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', flexGrow: 0 }}
      key={anchor}
    >
      {userProfile && (
        <Button
          onClick={handlePostJob}
          variant="contained"
          style={buttonJobStyle}
        >
          <label style={postJobLabelStyle}>Post a Job</label>
        </Button>
      )}
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              color: 'black'
            }
          }
        }}
        title={user ? userFirstName : ''}
      >
        <Button
          onClick={handleOpenUserMenu}
          variant="contained"
          style={buttonStyle}
          endIcon={<KeyboardArrowDownIcon style={{ color: '#fff' }} />}
        >
          {user ? (
            <label style={usernameLabelStyle}>{userFirstName}</label>
          ) : (
            <Avatar
              alt="Remy Sharp"
              src={require('../../assets/user.png').default}
            />
          )}
        </Button>
      </Tooltip>
      <IconButton className={`${classes.hamburger} ${!!state[anchor] && 'open'}`} sx={{ p: 0, ml: 2 }} onClick={toggleDrawer(anchor, !state[anchor])}>
        {!!state[anchor] ? <CloseIcon style={{ color: '#fff', fontSize: '32px' }} /> : <MenuIcon style={{ color: '#fff', fontSize: '32px' }}/>}
      </IconButton>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        sx={{
          "& .MuiDrawer-paper": {
            top: 60
          }
        }}
      >
        {list(anchor)}
      </Drawer>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {userProfile && (
          <MenuItem key="editDirtWorldProfile" onClick={goToUserProfileDetails}>
            <Typography textAlign="center" color="black">
              Edit Dirt World Profile
            </Typography>
          </MenuItem>
        )}
        {currentCompanyProfile && (
          <MenuItem
            key="editCompanyProfile"
            onClick={goToCompanyProfileDetails}
          >
            <Typography textAlign="center" color="black">
              Edit Company Profile
            </Typography>
          </MenuItem>
        )}
        <MenuItem key="logout" onClick={logout}>
          <Typography textAlign="center" color="black">
            {t('signOut', { ns: 'common' })}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const buttonStyle: CSSProperties = {
  borderRadius: '25px',
  textTransform: 'none' as 'none',
  opacity: 1,
  border: '#4D4E49',
  backgroundColor: '#4D4E49',
  marginRight: '10px'
};

const buttonJobStyle: CSSProperties = {
  borderRadius: '25px',
  textTransform: 'none' as 'none',
  opacity: 1,
  border: '#CCFF00',
  backgroundColor: '#CCFF00',
  marginRight: '10px'
};

const usernameLabelStyle: CSSProperties = {
  color: '#fff',
  fontSize: '14px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '14px'
};

const postJobLabelStyle: CSSProperties = {
  color: '#262625',
  fontSize: '14px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '14px'
};
