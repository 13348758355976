import { AppThunkAction } from '../store/index';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  LEARNING_PLAN_NEW,
  LEARNING_PLAN_UPDATE,
  LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
  LEARNING_PLAN_RESET_TRANSACTION_STATUS,
  LEARNING_PLAN_SET_COURSES,
  LEARNING_PLAN_SET_PLANS,
  LEARNING_PLAN_UPDATE_ASSIGNMENT
} from './actionTypes';
import config from './../config';
import { LearningPlan, LearningPlanAssignment } from '../entities/LearningPlan';
import { uploadLearningPlanThumbToBlob } from '../services/blobStorage-service';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import { Course } from '../entities/Course';
import { TemporalNewId } from '../core/constants';

export interface NewLearningPlanAction {
  type: 'LEARNING_PLAN_NEW';
}

export interface UpdateLearningPlanAction {
  type: 'LEARNING_PLAN_UPDATE';
  learningPlan: LearningPlan;
}

export interface UpdateLearningPlanTransactionStatusAction {
  type: 'LEARNING_PLAN_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetLearningPlanTransactionStatusAction {
  type: 'LEARNING_PLAN_RESET_TRANSACTION_STATUS';
}

export interface ResetLearningPlanTransactionStatusAction {
  type: 'LEARNING_PLAN_RESET_TRANSACTION_STATUS';
}

export interface GetLearningPlanCoursesAction {
  type: 'LEARNING_PLAN_SET_COURSES';
  learningPlanCourses: Course[];
}

export interface RequestLearningPlansAction {
  type: 'LEARNING_PLAN_SET_PLANS';
  plans: LearningPlan[];
}

export interface UpdateLearningPlansAssigment {
  type: 'LEARNING_PLAN_UPDATE_ASSIGNMENT';
  learningPlanAssigment: LearningPlanAssignment;
}

export const actionCreators = {
  newLearningPlan: () => ({
    type: LEARNING_PLAN_NEW
  }),
  saveLearningPlan: (
    learningPlanDto: LearningPlan,
    imgUrl: string,
    file?: File | undefined | null
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      if (file) {
        const blobUrl = await uploadLearningPlanThumbToBlob(file);
        learningPlanDto.thumbnailUrl = blobUrl;
      } else if (imgUrl) {
        learningPlanDto.thumbnailUrl = imgUrl;
      }

      let res: AxiosResponse<any, any>;

      if (learningPlanDto.id === TemporalNewId) {
        res = await axios.post(
          `${config.STUDENT_API_URL!}learningplans`,
          learningPlanDto
        );
      } else {
        res = await axios.put(
          `${config.STUDENT_API_URL!}learningplans`,
          learningPlanDto
        );
      }

      if (res.status === HttpStatusEnum.OK) {
        dispatch({
          type: LEARNING_PLAN_UPDATE,
          learningPlan: res.data
        });
      }

      dispatch({
        type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
        transactionStatus:
          res.status === HttpStatusEnum.OK
            ? TransactionStatusEnum.Successfull
            : TransactionStatusEnum.Failed,
        errorMessage: ''
      });

      dispatch(appActions.setIsLoading(false));
    }
  },
  deleteLearningPlan: (
    learningPlanId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));
      let resStatus: number = 0;
      try {
        const res = await axios.delete(
          `${config.STUDENT_API_URL!}learningplans/${learningPlanId}`
        );
        resStatus = res.status;
      } catch (e) {
        const error = e as AxiosError;
        if (error && error.response) resStatus = error.response.status;
      } finally {
        dispatch({
          type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });

        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  deleteFullLearningPlan: (
    learningPlanId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));
      let resStatus: number = 0;
      try {
        const res = await axios.delete(
          `${config.STUDENT_API_URL!}learningplans/${learningPlanId}?isFull=true`
        );
        resStatus = res.status;
      } catch (e) {
        const error = e as AxiosError;
        if (error && error.response) resStatus = error.response.status;
      } finally {
        dispatch({
          type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });

        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  deleteLearningPlanCourses: (
    learningPlanId: string,
    courseId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const res = await axios.delete(
        `${config.STUDENT_API_URL!}learningplans/courses/${learningPlanId}/${courseId}`
      );

      dispatch({
        type: LEARNING_PLAN_UPDATE,
        learningPlan: res.data
      });

      dispatch({
        type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
        transactionStatus:
          res.status === HttpStatusEnum.OK
            ? TransactionStatusEnum.Successfull
            : TransactionStatusEnum.Failed,
        errorMessage: ''
      });

      dispatch(appActions.setIsLoading(false));
    }
  },
  getLearningPlanCourses: (
    courseIds: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const res = await axios.put(
        `${config.COURSES_API_URL!}courses/byIds`,
        `"${courseIds}"`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      dispatch({
        type: LEARNING_PLAN_SET_COURSES,
        learningPlanCourses: res.data
      });

      dispatch(appActions.setIsLoading(false));
    }
  },
  assignLearningPlanToEmployees: (
    assignLearningPlan: LearningPlanAssignment
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      // dispatch(appActions.setIsLoading(true));

      let resStatus: number = 0;
      try {
        const res = await axios.post(
          `${config.STUDENT_API_URL!}assignments/assign/learningplan/employees`,
          assignLearningPlan
        );

        resStatus = res.status;
      } catch (e) {
      } finally {
        dispatch({
          type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });

        // dispatch(appActions.setIsLoading(false));
      }
    }
  },
  resetLearningPlanTransactionStatus: () => ({
    type: LEARNING_PLAN_RESET_TRANSACTION_STATUS
  }),
  setLearningPlanCourses: (courses: Course[]) => ({
    type: LEARNING_PLAN_SET_COURSES,
    learningPlanCourses: courses
  }),
  setCurrentLearningPlan: (learingPlan: LearningPlan | null) => ({
    type: LEARNING_PLAN_UPDATE,
    learningPlan: learingPlan
  }),
  setLearningPlansAssigment: (
    learingPlanAssignment: LearningPlanAssignment | null
  ) => ({
    type: LEARNING_PLAN_UPDATE_ASSIGNMENT,
    learingPlanAssignment
  }),
  updatePlans: (learingPlans: LearningPlan[]) => ({
    type: LEARNING_PLAN_SET_PLANS,
    plans: learingPlans
  }),
  requestPlans: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();

    if (appState && appState.courses?.courses) {
      dispatch(appActions.setIsLoading(true));

      const res = await axios.get(`${config.STUDENT_API_URL!}learningPlans`);
      dispatch({
        type: LEARNING_PLAN_SET_PLANS,
        plans: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestPlan: (id: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const res = await axios.get(
        `${config.STUDENT_API_URL!}learningPlans/${id}`
      );
      dispatch({
        type: LEARNING_PLAN_UPDATE,
        learningPlan: res.data
      });

      dispatch(appActions.setIsLoading(false));
    }
  },
  getCoursesByLearningPlanAssignment: (
    lpAssignmentId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const res = await axios.get(
        `${config.STUDENT_API_URL!}assignments/courses/${lpAssignmentId}`
      );

      dispatch({
        type: LEARNING_PLAN_SET_COURSES,
        learningPlanCourses: res.data
      });

      dispatch(appActions.setIsLoading(false));
    }
  }
};

export type KnownAction =
  | NewLearningPlanAction
  | UpdateLearningPlanAction
  | UpdateLearningPlanTransactionStatusAction
  | ResetLearningPlanTransactionStatusAction
  | GetLearningPlanCoursesAction
  | RequestLearningPlansAction
  | UpdateLearningPlansAssigment
  | SetLoadingAction;
