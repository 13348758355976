import React from 'react';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
export interface TextAreaProps {
  value: string;
  onChangeValue?: (value: any) => void;
  placeholderText?: string;
  id: string;
  name: string;
  width: string;
  rows: number;
  maxLength?: number;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  isInvalid?: boolean;
}
const TextArea: React.FC<TextAreaProps> = ({
  id,
  name,
  value,
  onChangeValue,
  placeholderText,
  width,
  rows,
  maxLength = 500,
  onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {},
  disabled = false,
  isInvalid = false
}) => {
  const [inputValue, setInputValue] = React.useState(value);
  React.useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);
  const theme = useTheme();
  const inputStyle = {
    backgroundColor: '#EDECE880',
    padding: '5px',
    color: theme.palette.primary.contrastText,
    borderRadius: '5px',
    outline: 'none' as 'none',
    paddingLeft: '8px',
    fontFamily: theme.typography.fontFamily,
    width: width,
    resize: 'none' as 'none',
    borderColor: isInvalid ? theme.palette.error.main : '#D9D9D9',
    borderWidth: isInvalid ? 1 : 1,
    borderStyle: isInvalid ? 'solid' : 'solid'
  };
  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    if (onChangeValue) {
      onChangeValue(e.target.value);
    }
  };
  return (
    <textarea
      id={id}
      name={name}
      value={inputValue}
      onChange={e => handleChange(e)}
      onBlur={onBlur}
      style={inputStyle}
      placeholder={placeholderText}
      rows={rows}
      maxLength={maxLength}
      disabled={disabled}
    />
  );
};

export default TextArea;
