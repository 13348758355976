import React, { CSSProperties, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  ButtonBase,
  Stack,
  Box,
  IconButton
} from '@mui/material';
import rightBG from '../../assets/rightBG.png';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddIcon from '@mui/icons-material/Add';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as companyActions } from '../../actions/companies';
import { ApplicationState } from '../../store';
import JobListingComponent from './JobListingComponent';
import { Link, useHistory, useParams } from 'react-router-dom';
import useCurrentUser from '../../hooks/useCurrentUser';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { Page } from '../../components/Pages';
import { Card } from '../../components/Card';
import { CardHeader } from '../../components/Card/CardHeader';
import ManageAdminModal from '../../components/ManageAdminModal/ManageAdminModal';
import config from '../../config';

const DirtWorldCompanyInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const params = useParams<{
    companyProfileId: string;
  }>();
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const [showManageAdminModal, setShowManageAdminModal] = useState(false);

  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const admins =
    useSelector((state: ApplicationState) => state.companies?.admins) ?? [];

  useEffect(() => {
    if (params.companyProfileId) {
      dispatch(companyActions.getCompanyById(params.companyProfileId));
    }
  }, [dispatch, params.companyProfileId]);

  const onShowCompanyAdmins = () => {
    if (params.companyProfileId) {
      dispatch(companyActions.getAdminsByCompanies(params.companyProfileId));
      setShowManageAdminModal(true);
    }
  };

  const onSetAdminToCompany = (userId: string) => {
    dispatch(companyActions.setAdminToCompany(params.companyProfileId, userId));
  };

  const onRemoveAdmin = (userId: string) => {
    dispatch(companyActions.removeAdmin(params.companyProfileId, userId));
  };

  if (isLoading) {
    return <FallbackLoader />;
  }

  if (!isLoading && !currentCompanyProfile) {
    return (
      <Stack spacing={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <Typography variant="blackTitle">Page not available</Typography>
        </Box>
      </Stack>
    );
  }

  const goToEditProfile = () => {
    history.push(`/company/profile/${currentCompanyProfile?.id}/edit`);
  };

  return (
    <Page>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <ManageAdminModal
            showModal={showManageAdminModal}
            setAdmin={onSetAdminToCompany}
            removeAdmin={onRemoveAdmin}
            admins={admins}
            onClose={() => setShowManageAdminModal(false)}
          />
          <Card gridProps={{ spacing: 4 }} mobileCenterAlign>
            <Grid item xs={12} sm={6} md={3}>
              <ButtonBase
                sx={{ width: '166px', height: '166px', borderRadius: '5px' }}
              >
                <div
                  style={{
                    background: currentCompanyProfile?.logoUrl
                      ? `url('${currentCompanyProfile.logoUrl}'`
                      : '#EDECE8',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: '5px',
                    border: 'solid 1px #ccc'
                  }}
                />
              </ButtonBase>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom style={companyNameStyle}>
                    {currentCompanyProfile!.name}
                  </Typography>
                  {currentCompanyProfile?.industryCategories ? (
                    <span>
                      {currentCompanyProfile?.industryCategories.map(
                        industry => (
                          <Typography key={industry.id} style={industryStyle}>
                            {industry.name}
                          </Typography>
                        )
                      )}
                    </span>
                  ) : (
                    <Typography style={industryStyle}>
                      No industries cateogories
                    </Typography>
                  )}
                  {currentCompanyProfile!.address === null ? (
                    <Typography style={locationStyle}>
                      No Address Information
                    </Typography>
                  ) : (
                    <Typography style={locationStyle}>
                      {currentCompanyProfile!.address.city},{' '}
                      {currentCompanyProfile!.address.state},{' '}
                      {currentCompanyProfile!.address.country}
                    </Typography>
                  )}
                  <Link
                    to={{
                      pathname: `${currentCompanyProfile!.websiteUrl}`
                    }}
                    target="_blank"
                  >
                    <Typography style={companySiteStyle}>
                      {currentCompanyProfile!.websiteUrl}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <BasicButton
                onClick={goToEditProfile}
                color="secondary"
                style={{ height: '34px', color: '#EDECE8', marginRight: 7 }}
                endIcon={
                  <EditIcon
                    style={{
                      color: '#000',
                      fontSize: '16px'
                    }}
                  />
                }
              >
                Edit Company Profile
              </BasicButton>
              {config.FF_MANAGE_ADMINS && (
                <BasicButton
                  onClick={onShowCompanyAdmins}
                  color="secondary"
                  style={{ height: '34px', color: '#EDECE8' }}
                  endIcon={
                    <EditIcon
                      style={{
                        color: '#000',
                        fontSize: '16px'
                      }}
                    />
                  }
                >
                  Manage Admins
                </BasicButton>
              )}
            </Grid>
          </Card>
        </Grid>
        <Grid container item xs={12} md={8} spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={'Open Jobs'}
                actions={
                  <IconButton
                    color="default"
                    onClick={() => {
                      history.push({
                        pathname: `/company/profile/jobs/post-job-create`
                      });
                    }}
                    aria-label="Add"
                    size="large"
                  >
                    <AddIcon style={{ color: '#262625', fontSize: 32 }} />
                  </IconButton>
                }
              />
              {currentCompanyProfile?.openJobPostings &&
              currentCompanyProfile.openJobPostings.length ? (
                <Grid item xs={12} md={12} style={{ width: '100%' }}>
                  <div style={listJobsStyle}>
                    {currentCompanyProfile?.openJobPostings.map(job => (
                      <JobListingComponent
                        key={job.id}
                        jobID={job.id}
                        jobTitle={job.jobTitle}
                        expirationDate={job.expiresAtUtc}
                      />
                    ))}
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} md={12} style={{ width: '100%' }}>
                  <div style={openJobsMiddleStyle}>
                    <Typography style={noOpenJobsSpan}>
                      You have no open jobs
                    </Typography>
                    <BasicButton
                      onClick={() => {
                        history.push({
                          pathname: `/company/profile/jobs/post-job-create`
                        });
                      }}
                      color="primary"
                    >
                      Post a Job
                    </BasicButton>
                  </div>
                </Grid>
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title={'Expired Jobs'} />
              {currentCompanyProfile?.expiredJobPostings &&
              currentCompanyProfile.expiredJobPostings.length ? (
                <Grid item xs={12} md={12} style={{ width: '100%' }}>
                  <div style={listJobsStyle}>
                    {currentCompanyProfile?.expiredJobPostings.map(job => (
                      <JobListingComponent
                        key={job.id}
                        jobID={job.id}
                        jobTitle={job.jobTitle}
                        expirationDate={job.expiresAtUtc}
                        expiresLabel={'Expired'}
                      />
                    ))}
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} md={12} style={{ width: '100%' }}>
                  <div style={openJobsMiddleStyle}>
                    <Typography style={noOpenJobsSpan}>
                      You have no expired jobs
                    </Typography>
                    <BasicButton
                      onClick={() => {
                        history.push({
                          pathname: `/company/profile/jobs/post-job-create`
                        });
                      }}
                      color="primary"
                    >
                      Post a Job
                    </BasicButton>
                  </div>
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            style={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundImage: `url(${rightBG})`,
              backgroundPosition: '50% 50%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '350px',
              display: 'block'
            }}
            component={'a'}
            href={'https://dirtworld.com/dirt-world-summit'}
            target="_blank"
            rel="noreferrer"
          >
            <div style={containerDWSummit}>
              <Grid item xs={12} md={12}>
                <div style={dwSummitTopStyle}>
                  <Typography gutterBottom style={infoIStyle}>
                    COMING OCTOBER 2023
                  </Typography>
                  <Typography style={infoIIStyle}>
                    Dirt World Summit ‘23
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Typography style={bottomSpanInfo}>
                  Learn more about this event{' '}
                  <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                </Typography>
              </Grid>
            </div>
          </Card>
          <br />
          <Card
            style={{
              display: 'block'
            }}
            component={'a'}
            padding={'small'}
            href={'https://dirtworld.com/contact'}
            target="_blank"
            rel="noreferrer"
          >
            <Grid item xs={3}>
              <CheckCircleRoundedIcon style={{ fontSize: 50 }} />
            </Grid>
            <Grid item xs={9} container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom style={infoIIIStyle}>
                    Upgrade your profile and gain more exposure
                  </Typography>
                  <Typography style={infoIVStyle}>
                    Become a sponsored member{' '}
                    <ArrowForwardIosIcon
                      style={{ fontSize: '16px', color: '#262625' }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

const companyNameStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: '30px',
  color: '#1B1C1C'
};

const industryStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '28px',
  color: '#4E4E4C'
};

const locationStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '28px',
  color: '#4E4E4C',
  marginTop: '10px'
};

const companySiteStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '26px',
  color: '#7A7C6F'
};

const containerDWSummit: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  height: '100%'
};

const dwSummitTopStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  height: '100%',
  marginTop: 20
};

const bottomSpanInfo: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '26px',
  color: '#FFFFFF'
};

const infoIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '20px',
  color: '#7A7C6F',
  textAlign: 'center',
  letterSpacing: '0.09em'
};

const infoIIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '33px',
  lineHeight: '40px',
  color: '#262625'
};

const infoIIIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '24px',
  color: '#262625'
};

const infoIVStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(38, 38, 37, 0.7)'
};

const listJobsStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
};

const noOpenJobsSpan: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '26px',
  color: '#26262580',
  marginBottom: '10px'
};

const openJobsMiddleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  padding: 10
};

export default DirtWorldCompanyInfo;
