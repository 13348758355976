import { AppThunkAction, ApplicationState } from '../store';
import {
  COUNTRIES_IS_LOADING,
  STATES_IS_LOADING,
  CITIES_IS_LOADING,
  COUNTRIES_SET_COUNTRIES,
  CITIES_SET_CITIES,
  STATES_SET_STATES
} from './actionTypes';
import axios from 'axios';
import { createSelector } from 'reselect';
import { HttpStatusEnum } from '../core/enums';
import { Country, State } from '../entities/Countries';
import { countriesToHighlight } from '../core/constants';

export interface SetCountriesIsLoading {
  type: 'COUNTRIES_IS_LOADING';
  isLoadingCountries: boolean;
}
export interface SetStatesIsLoading {
  type: 'STATES_IS_LOADING';
  isLoadingStates: boolean;
}

export interface SetCitiesIsLoading {
  type: 'CITIES_IS_LOADING';
  isLoadingCities: boolean;
}

export interface RequestCountriesAction {
  type: 'COUNTRIES_SET_COUNTRIES';
  countries: Country[];
}

export interface RequestCitiesAction {
  type: 'CITIES_SET_CITIES';
  cities: string[];
}

export interface RequestProvincesAction {
  type: 'STATES_SET_STATES';
  states: State[];
}

const baseURL = 'https://countriesnow.space/api/v0.1/countries';

export const actionCreators = {
  getCountries:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: COUNTRIES_IS_LOADING,
          isLoadingCountries: true
        });

        let countries: Country[] = [];
        try {
          const res = await axios.get(`${baseURL}/flag/images`);

          if (res.status === HttpStatusEnum.OK) countries = res.data.data;
        } catch (e) {
        } finally {
          dispatch({
            type: COUNTRIES_SET_COUNTRIES,
            countries
          });

          dispatch({
            type: COUNTRIES_IS_LOADING,
            isLoadingCountries: false
          });
        }
      }
    },
  getStatesFromCountries:
    (countryName: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: STATES_IS_LOADING,
          isLoadingStates: true
        });

        let states: State[] = [];
        try {
          const res = await axios.post(`${baseURL}/states`, {
            country: countryName
          });

          if (res.status === HttpStatusEnum.OK) states = res.data.data.states;
        } catch (e) {
        } finally {
          dispatch({
            type: STATES_SET_STATES,
            states
          });

          dispatch({
            type: STATES_IS_LOADING,
            isLoadingStates: false
          });
        }
      }
    },
  getCitiesFromStates:
    (countryName: string, stateName: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: CITIES_IS_LOADING,
          isLoadingCities: true
        });

        let cities: string[] = [];
        try {
          const res = await axios.post(`${baseURL}/state/cities`, {
            country: countryName,
            state: stateName
          });

          if (res.status === HttpStatusEnum.OK) cities = res.data.data;
        } catch (e) {
        } finally {
          dispatch({
            type: CITIES_SET_CITIES,
            cities
          });

          dispatch({
            type: CITIES_IS_LOADING,
            isLoadingCities: false
          });
        }
      }
    }
};

const selectCountries = (state: ApplicationState) => state.addresses?.countries;
const selectStates = (state: ApplicationState) => state.addresses?.states;
const selectCities = (state: ApplicationState) => state.addresses?.cities;

export const selectCountryItems = createSelector(selectCountries, items => {
  if (!!items && !!items.length) {
    var countriesWithoutHighlight = items
      .filter(item => !countriesToHighlight.includes(item.name))
      .map(item => item.name);
    return countriesToHighlight.concat(countriesWithoutHighlight);
  }
  return [];
});

export const selectStateItems = createSelector(selectStates, items => {
  if (!!items && !!items.length) {
    return items.map(item => item.name);
  }
  return [];
});

export const selectCityItems = createSelector(selectCities, items => {
  if (!!items && !!items.length) {
    return items;
  }
  return [];
});

export type KnownAction =
  | SetCountriesIsLoading
  | SetStatesIsLoading
  | SetCitiesIsLoading
  | RequestCountriesAction
  | RequestCitiesAction
  | RequestProvincesAction;
