import React from 'react';




const FlashCardSection: React.FC = () => {
    return (
        <h1>FlashCard Section</h1>
    )
}




export default FlashCardSection;