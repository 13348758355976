import { Action, Reducer } from 'redux';
import { APP_SET_INDUSTRY_CATEGORIES } from '../actions/actionTypes';
import { KnownAction, APP_STATE_LOADING } from '../actions/appState';
import { IndustryCategory } from '../entities/IndustryCategory';

export interface AppState {
  isLoading: boolean;
  industryCategories: IndustryCategory[];
}
const unloadedState: AppState = {
  isLoading: false,
  industryCategories: []
};

export const AppStateReducer: Reducer<AppState> = (
  state: AppState | undefined,
  incomingAction: Action
): AppState => {
  if (state === undefined) {
    return unloadedState;
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case APP_STATE_LOADING:
      return { ...state, isLoading: action.isLoading };
    case APP_SET_INDUSTRY_CATEGORIES:
      return { ...state, industryCategories: action.industryCategories };
    default:
      return state;
  }
};
