import { Grid, Paper, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridProps } from '@mui/material/Grid';

interface CardProps extends React.PropsWithChildren<any> {
  padding?: 'small' | 'medium';
  mobileCenterAlign?: boolean;
  gridProps?: GridProps;
}
export const Card = ({
  children,
  gridProps,
  mobileCenterAlign,
  padding = 'medium',
  ...props
}: CardProps) => {
  const theme = useTheme();
  const isSmallMedia = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      {...props}
      sx={{
        borderRadius: '10px',
        borderColor: '#ccc',
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: padding === 'medium' ? '40px' : '20px'
      }}
      elevation={0}
    >
      <Grid
        container
        justifyContent={
          isSmallMedia && mobileCenterAlign ? 'center' : 'space-between'
        }
        sx={{
          ['&>.MuiGrid-item']:
            isSmallMedia && mobileCenterAlign
              ? {
                  textAlign: 'center'
                }
              : {}
        }}
        {...gridProps}
      >
        {children}
      </Grid>
    </Paper>
  );
};
