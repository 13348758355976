import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EducationSectionItem } from './EducationSectionItem';
import React, { CSSProperties } from 'react';
import { useHistory, useParams } from 'react-router';
import { UserProfileDetailParams } from '../../../entities/Common';
import { EducationDto } from '../../../entities/UserProfile';
import { EducationSectionEmpty } from './EducationSectionEmpty';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { CardHeader } from '../../../components/Card/CardHeader';
export const EducationSection = ({
  educations
}: {
  educations: EducationDto[] | undefined;
}) => {
  const [accordionOpen, setAccordionOpen] = React.useState(true);
  const history = useHistory();
  const { userProfileId } = useParams<UserProfileDetailParams>();

  const educationDetails = () => {
    history.push(`/user/profile/${userProfileId}/education`);
  };

  if (!educations || (educations && educations.length === 0)) {
    return <EducationSectionEmpty />;
  }

  return (
    <Accordion expanded={accordionOpen}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setAccordionOpen(!accordionOpen)}
          />
        }
        sx={{ cursor: 'unset !important' }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction={'row'} spacing={2}>
          <CardHeader title={'Education'} />
          <BasicButton
            color="secondary"
            endIcon={
              <EditIcon
                style={{
                  color: '#000',
                  fontSize: '16px'
                }}
              />
            }
            onClick={educationDetails}
            style={{ height: '30px', color: '#EDECE8', marginTop: '5px' }}
          >
            Edit
          </BasicButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {educations?.map(education => (
          <EducationSectionItem education={education} key={education.id} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
