import React, { CSSProperties } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { Lesson } from '../../entities/Lesson';
import {
  LessonFormModel,
  ContentMedium,
  Subtitles
} from '../../entities/LessonFormModel';
import FormLabel from '../core/FormLabel/FormLabel';
import TextBox from '../core/TextBox/TextBox';
import FileInput from '../core/FileInput/FileInput';
import BasicButton from '../core/BasicButton/BasicButton';
import TextArea from '../core/TextArea/TextArea';
import RadioButton from '../core/RadioButton/RadioButton';
import TagInput, { TagEntity } from '../TagInput/TagInput';
import { Tag } from '../../entities/Tag';
import { Guid } from 'guid-typescript';
import { ProgressBar } from '../../components/core/ProgressBar/ProgressBar';
import {
  truncateAzureUrl,
  truncateLargeAzureUrl
} from '../../utils/stringUtils';
import {
  addLanguageStyle,
  buttonAddContainer,
  buttonLargeStyle,
  buttonStyle,
  fontAddButton,
  fontButton,
  fontDeleteButton
} from '../../utils/buttonStyles';
import { IconButton, Typography } from '@mui/material';
import VideoThumbnail from '../../assets/VideoAsset.png';
import HttpStatusError from '../../core/CustomErrors/HttpRequestError';
import { HttpStatusEnum } from '../../core/enums';
import { FormErrors } from '../core/FormErrors/FormErrors';
import DropDownlist from '../core/DropDownList/DropDownList';
import { LanguagesSubtitles } from '../../core/constants';
import BasicSelect from '../core/BasicSelect/BasicSelect';
import TrashIcon from '../Icons/TrashIcon';
import { useTranslation } from 'react-i18next';
import { ThumbnailLibraryPanel } from '../../components/ThumbnailLibraryPanel/ThumbnailLibraryPanel';
import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';

const emptyformModel = {
  id: '',
  title: '',
  description: '',
  contentMedium: 0,
  thumbnailFile: null,
  thumbnailUrl: '',
  video: null,
  videoUrl: '',
  pdfFile: null,
  pdfUrl: '',
  externalVideoUrl: '',
  tags: [],
  subtitleFile: null,
  subtitles: []
};

const errorStyle = {
  color: 'red'
};

export interface LessonFormProps {
  lesson: Lesson;
  companyTags: Array<Tag>;
  handleSaveLesson: (lessonModel: LessonFormModel, isEdit: boolean) => void;
  onSaveComplete: () => void;
  isEdit: boolean;
  showProgress?: boolean;
  progressValue?: number;
  setIsConfirmationModalOpen?: (isOpen: boolean) => void;
}

export interface SubtitleType {
  type: string;
}

export interface SubtitleEditMode extends Subtitles {
  _id?: string;
  type?: string;
}

let subtitleCounter = 0;

export const getNextSubtitleId = () => {
  return `subtitle-${subtitleCounter++}`;
};

const LessonForm: React.FC<LessonFormProps> = ({
  lesson,
  companyTags,
  handleSaveLesson,
  onSaveComplete,
  isEdit,
  showProgress = false,
  progressValue = 0,
  setIsConfirmationModalOpen
}) => {
  const [lessonData, setLessonData] = React.useState<LessonFormModel>(
    emptyformModel
  );

  const [fileError, setFileError] = React.useState<boolean>(false);
  const [subFileError, setSubFileError] = React.useState<boolean>(false);
  const [titleError, setTitleError] = React.useState<boolean>(false);
  const [descriptionError, setDescriptionError] = React.useState<boolean>(
    false
  );
  const [PDFErrorValue, setPDFErrorValue] = React.useState<boolean>(false);
  const [PDFError, setPDFError] = React.useState<boolean>(false);
  const [videoErrorValue, setVideoErrorValue] = React.useState<boolean>(false);
  const [videoError, setVideoError] = React.useState<boolean>(false);

  const [contentMedium, setContentMedium] = React.useState<number>(
    ContentMedium.Video
  );

  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [invalidForm, setInvalidForm] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [tags, setTags] = React.useState<Array<Tag>>([]);
  const [subtitles, setSubtitles] = React.useState<SubtitleEditMode[]>([]);
  const { t } = useTranslation(['common', 'lessons']);

  const mapLessonToModel = (lesson: Lesson) => {
    const res: LessonFormModel = {
      id: lesson.id,
      title: lesson.title,
      description: lesson.description,
      contentMedium: lesson.contentMedium,
      thumbnailFile: null,
      thumbnailUrl: lesson.thumbnailUrl,
      video: null,
      videoUrl: lesson.videoUrl,
      pdfFile: null,
      pdfUrl: lesson.learningMaterialUrl,
      externalVideoUrl: lesson.externalVideoUrl,
      tags: lesson.tags,
      assessmentId: lesson.assessmentId,
      subtitles: lesson.subtitles
    };
    return res;
  };

  React.useEffect(() => {
    if (lesson) {
      const formData = mapLessonToModel(lesson);
      setLessonData(formData);
      if (lesson.contentMedium === ContentMedium.Pdf) {
        setContentMedium(ContentMedium.Pdf);
      }
      if (lesson.contentMedium === ContentMedium.Quiz) {
        setContentMedium(ContentMedium.Quiz);
      }
      setTags(lesson.tags ? lesson.tags : []);
    }
  }, [lesson, lesson.id]);

  React.useEffect(() => {
    if (lesson?.subtitles) {
      const subtitlesToEdit = lesson.subtitles!.map(q => {
        return { ...q, _id: getNextSubtitleId() };
      });
      setSubtitles(subtitlesToEdit);
    }
  }, [lesson?.subtitles]);

  const handleChangeDescription = (e: string) => {
    setLessonData({ ...lessonData, description: e });
  };

  const handleChangeContentMedium = (e: any) => {
    setContentMedium(parseInt(e.target.value));
    setLessonData({ ...lessonData, contentMedium: parseInt(e.target.value) });
  };

  const handleChangeTitle = (e: string) => {
    if (e) {
      setTitleError(false);
    } else {
      setTitleError(true);
    }
    setLessonData({ ...lessonData, title: e });
  };

  const handleChangeExternalUrl = (e: string) => {
    if (e.length > 0) {
      setLessonData({ ...lessonData, externalVideoUrl: e });
      if (!isValidURL(e)) {
        setVideoErrorValue(true);
      }
    } else {
      setVideoErrorValue(false);
    }
  };

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return validImageTypes.includes(fileType);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setLessonData({ ...lessonData, thumbnailFile: file, thumbnailUrl: '' });
      const fileType = file['type'];
      if (IsValidImageFormat(fileType)) {
        setFileError(false);
      } else {
        setFileError(true);
      }
    } else {
      setFileError(true);
    }
  };

  const handleChangePdf = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setLessonData({ ...lessonData, pdfFile: file });
      const fileType = file['type'];
      const validTypes = ['application/pdf'];
      if (validTypes.includes(fileType)) {
        setPDFErrorValue(false);
      } else {
        setPDFErrorValue(true);
      }
    } else {
      setPDFError(true);
    }
  };

  const IsValidVideoFormat = (fileType: string) => {
    const validVideoTypes = [
      'video/mp4',
      'video/x-flv',
      'video/quicktime',
      'video/x-msvideo',
      'video/x-ms-wmv'
    ];
    return validVideoTypes.includes(fileType);
  };

  const handleChangeVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setLessonData({ ...lessonData, video: file });
      const fileType = file['type'];
      if (IsValidVideoFormat(fileType)) {
        setVideoErrorValue(false);
      } else {
        setVideoErrorValue(true);
      }
    }
  };

  const IsValidSubtitleFormat = (fileType: string) => {
    const validSubtitleTypes = ['text/vtt'];
    return validSubtitleTypes.includes(fileType);
  };

  const handleAddSubtitle = (subtitleEdit: SubtitleEditMode) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      var fileExtension = file['name'].split('.').pop();
      const fileType = fileExtension === 'vtt' ? 'text/vtt' : 'other/fileType';
      if (IsValidSubtitleFormat(fileType)) {
        setFileError(false);
        setSubFileError(false);
        const newSubtitles = subtitles.map(opt =>
          opt._id === subtitleEdit._id
            ? { ...subtitleEdit, subtitleFile: file, fileType: fileType }
            : opt
        );
        setSubtitles(newSubtitles);
        setLessonData({ ...lessonData, subtitles: newSubtitles });
      } else {
        setFileError(true);
        setSubFileError(true);
        const newSubtitles = subtitles.map(opt =>
          opt._id === subtitleEdit._id
            ? { ...subtitleEdit, subtitleFile: file, fileType: fileType }
            : opt
        );
        setSubtitles(newSubtitles);
        setLessonData({ ...lessonData, subtitles: newSubtitles });
      }
    } else {
      setFileError(true);
      setSubFileError(true);
    }
  };

  const isValidURL = (url: string) => {
    var res = url.match(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    );
    return !!res;
  };

  const handleSave = async (lessonData: LessonFormModel) => {
    const infoSub = lessonData.subtitles?.map(sub =>
      sub.fileType !== 'text/vtt' && sub.fileType !== undefined
        ? sub.fileType
        : ''
    );

    const clean = (arr: (string | null)[] | undefined) =>
      arr?.reduce((acc: (string | null)[] | undefined, el: string | null) => {
        if (el) {
          acc?.push(el);
        }
        return acc;
      }, []);

    const subType = clean(infoSub);

    try {
      setInvalidForm(false);
      setErrors([]);
      if (
        lessonData &&
        lessonData.title &&
        (lessonData.thumbnailFile === null
          ? VideoThumbnail
          : (lessonData.thumbnailFile &&
              IsValidImageFormat(lessonData.thumbnailFile.type)) ||
            lessonData.thumbnailUrl) &&
        subType?.length === 0 &&
        ((lessonData.contentMedium === ContentMedium.Pdf &&
          ((lessonData.pdfFile &&
            lessonData.pdfFile.type === 'application/pdf') ||
            lessonData.pdfUrl)) ||
          (lessonData.contentMedium === ContentMedium.Video &&
            ((lessonData.video && IsValidVideoFormat(lessonData.video.type)) ||
              lessonData.videoUrl ||
              (lessonData.externalVideoUrl &&
                isValidURL(lessonData.externalVideoUrl)))))
      ) {
        setSaveInProgress(true);
        await handleSaveLesson({ ...lessonData, tags: tags }, isEdit);
        setSaveInProgress(false);
        onSaveComplete();
      } else {
        setInvalidForm(true);
        let errorsArray: string[] = [];
        if (!lessonData.title) {
          errorsArray.push(t('titleRequired', { ns: 'common' }));
        }

        if (
          lessonData.thumbnailFile &&
          !IsValidImageFormat(lessonData.thumbnailFile.type) &&
          !lessonData.thumbnailUrl
        ) {
          errorsArray.push(t('invalidImageFormat', { ns: 'common' }));
        }

        if (subType!.length > 0) {
          errorsArray.push(t('invalidSubtitleFormat', { ns: 'common' }));
        }

        setErrors(errorsArray);
      }
    } catch (e) {
      if (e instanceof HttpStatusError) {
        if (e.status === HttpStatusEnum.Conflict) {
          setErrors([e.message]);
        }
      } else {
        setErrors([t('anErrorOccurred', { ns: 'common' })]);
      }
      setInvalidForm(true);
      setSaveInProgress(false);
    }
  };

  const handleSaveQuiz = async (lessonData: LessonFormModel) => {
    try {
      setInvalidForm(false);
      setErrors([]);
      if (lessonData && lessonData.title && lessonData.contentMedium) {
        setSaveInProgress(true);
        await handleSaveLesson(
          {
            ...lessonData,
            assessmentId:
              lessonData.assessmentId && lessonData.assessmentId !== Guid.EMPTY
                ? lessonData.assessmentId
                : Guid.EMPTY
          },
          isEdit
        );
        setSaveInProgress(false);
        onSaveComplete();
      } else {
        let errorsArray: string[] = [];
        setInvalidForm(true);
        if (!lessonData.title) {
          errorsArray.push(t('titleRequired', { ns: 'common' }));
        }
        setErrors(errorsArray);
      }
    } catch (e) {
      if (e instanceof HttpStatusError) {
        if (e.status === HttpStatusEnum.Conflict) {
          setErrors([e.message]);
        }
      } else {
        setErrors([t('anErrorOccurred', { ns: 'common' })]);
      }

      setInvalidForm(true);
      setSaveInProgress(false);
    }
  };

  const onDeleteTag = (index: number) => {
    const tagsArray = tags.slice(0);
    tagsArray.splice(index, 1);
    setTags(tagsArray);
  };

  const onAdditionTag = (newTag: TagEntity) => {
    if (!tags.find(tag => tag.name === newTag.name)) {
      const tagsArray = [
        ...tags,
        {
          id: !newTag.id ? Guid.create().toString() : (newTag.id as string),
          name: newTag.name
        }
      ];
      setTags(tagsArray);
    }
  };

  const handleOnChangeSubtitle = (subtitleEdit: SubtitleEditMode) => (
    items: string[]
  ) => {
    const subSelect = items[0] as string;
    const newSubtitles = subtitles.map(q =>
      q._id === subtitleEdit._id ? { ...subtitleEdit, language: subSelect } : q
    );
    setSubtitles(newSubtitles);
    setLessonData({ ...lessonData, subtitles: newSubtitles });
  };

  const onAddSubtitleClick = () => {
    const newSubtitle = {
      _id: getNextSubtitleId(),
      id: '',
      language: '',
      url: ''
    };
    setSubtitles(subtitles.concat(newSubtitle));
  };

  const onDeleteSubtitle = (subtitle: SubtitleEditMode) => () => {
    const newSubtitle = subtitles.filter(st => subtitle !== st);
    setLessonData({ ...lessonData, subtitles: newSubtitle });
    setSubtitles(newSubtitle);
  };

  const handleLibraryItemSelected = (item: AzureBlobImageInfo) => {
    if (item) {
      setLessonData({
        ...lessonData,
        thumbnailFile: null,
        thumbnailUrl: item.url
      });
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          {invalidForm && <FormErrors errors={errors} />}
          <FormLabel>
            {t('lessonName', { ns: 'lessons' })}
            <span className="text-danger">*</span>
          </FormLabel>
          <br />
          <TextBox
            id="lessonName"
            name="lessonName"
            value={lessonData.title}
            onChangeValue={handleChangeTitle}
            placeholderText={t('name', { ns: 'common' })}
            width="100%"
            height="30px"
            disabled={saveInProgress}
          ></TextBox>
          {titleError && (
            <p className="text-danger">
              {t('nameIsRequired', { ns: 'common' })}
            </p>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <br />
          <FormLabel>
            {t('contentMedium', { ns: 'common' })}
            <span className="text-danger">*</span>
          </FormLabel>
          <div
            style={{
              width: '40%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <RadioButton
                id="video"
                name="medium"
                value={ContentMedium.Video}
                checked={contentMedium == ContentMedium.Video}
                onChange={handleChangeContentMedium}
                labelText="Video"
                disabled={isEdit || saveInProgress}
              />
            </div>
            <div>
              <RadioButton
                id="quiz"
                name="medium"
                value={ContentMedium.Quiz}
                checked={contentMedium === ContentMedium.Quiz}
                onChange={handleChangeContentMedium}
                labelText={t('quiz', { ns: 'lessons' })}
                disabled={isEdit || saveInProgress}
              />
            </div>
            {/* <div>
              <RadioButton
                id="pdf"
                name="medium"
                checked={contentMedium == ContentMedium.Pdf}
                value={ContentMedium.Pdf}
                onChange={handleChangeContentMedium}
                labelText="Pdf"
                disabled={isEdit || saveInProgress}
              />
            </div> */}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="radioDiv"></div>
        </Col>
      </Row>
      {contentMedium === ContentMedium.Video && (
        <>
          <Row>
            <FormLabel>
              {t('selectVideo', { ns: 'common' })}
              <span className="text-danger">*</span>
            </FormLabel>
            {/* <Col>
            <TextBox
              id="extvideoUrl"
              name="extvideoUrl"
              value={lessonData.externalVideoUrl}
              onChangeValue={handleChangeExternalUrl}
              placeholderText="Add a video link"
              width="100%"
              height="35px"
              disabled={saveInProgress}
            ></TextBox>
            {videoErrorValue && (
              <p className="text-danger">Add a valid format</p>
            )}
          </Col>
          <Col xs={1} md={1} lg={1} style={{ textAlign: 'center' }}>
            <FormLabel>or</FormLabel>
          </Col> */}
            <Col className="pb-2">
              <FileInput
                initialText={t('uploadVideo', { ns: 'common' })}
                onChange={handleChangeVideo}
                id="fileInput"
                name="fileInput"
                type="video"
              />
              {lessonData.videoUrl && (
                <span>{truncateAzureUrl(lessonData.videoUrl)}</span>
              )}
            </Col>
            {videoError && (
              <p className="text-danger">
                {t('videoLinkRequired', { ns: 'common' })}
              </p>
            )}
          </Row>
          <div>
            <Row>
              <FormLabel>{t('selectSubtitle', { ns: 'common' })}</FormLabel>
              {subtitles.map((subtitle: SubtitleEditMode, index) => (
                <React.Fragment key={index}>
                  <Col xs={4} md={4} lg={4} className="pb-2">
                    <BasicSelect
                      id="subLanguage"
                      placeholder={t('selectLanguage', { ns: 'common' })}
                      options={LanguagesSubtitles}
                      value={
                        subtitle.language === '' ? [] : [subtitle.language]
                      }
                      multiple={false}
                      handleOnChange={handleOnChangeSubtitle(subtitle)}
                    />
                  </Col>
                  <Col xs={7} md={7} lg={7} className="pb-2">
                    <FileInput
                      initialText={t('uploadSubtitle', { ns: 'common' })}
                      onChange={handleAddSubtitle(subtitle)}
                      id="fileInput"
                      name="fileInput"
                      type="vtt"
                    />
                    {subtitle.url && (
                      <span style={subtitleSpan}>
                        <br />
                        {truncateLargeAzureUrl(subtitle.url)}
                      </span>
                    )}
                  </Col>
                  <Col
                    xs={1}
                    md={1}
                    lg={1}
                    style={buttonAddContainer}
                    className={subtitle.url ? 'pb-5' : 'pb-3'}
                  >
                    <IconButton
                      color="default"
                      onClick={onDeleteSubtitle(subtitle)}
                      aria-label="Delete"
                      style={trashIconStyle}
                      size="small"
                    >
                      <TrashIcon viewBox="-9 -9 34 34" />
                    </IconButton>
                  </Col>
                </React.Fragment>
              ))}
              <Col
                className="pb-2"
                xs={2}
                md={2}
                lg={2}
                style={buttonAddContainer}
              >
                <BasicButton
                  color="primary"
                  onClick={onAddSubtitleClick}
                  style={addLanguageStyle}
                >
                  <Typography sx={fontAddButton}>+</Typography>
                </BasicButton>
              </Col>
            </Row>
          </div>
        </>
      )}
      {contentMedium === ContentMedium.Pdf && (
        <Row>
          <Col className="pb-2">
            <FormLabel>
              {t('selectPdf', { ns: 'common' })}
              <span className="text-danger">*</span>
            </FormLabel>
            <br />
            {lessonData.pdfUrl && <span>{lessonData.pdfUrl}</span>}
            <FileInput
              initialText={t('uploadPdf', { ns: 'common' })}
              onChange={handleChangePdf}
              id="fileInput"
              name="fileInput"
              type="pdf"
            />
            {PDFErrorValue && (
              <p className="text-danger">
                {t('addValidFormat', { ns: 'common' })}
              </p>
            )}
            {PDFError && (
              <p className="text-danger">
                {t('videoFileRequired', { ns: 'common' })}
              </p>
            )}
          </Col>
        </Row>
      )}
      {contentMedium != ContentMedium.Quiz && (
        <>
          <Row>
            <Col className="pb-2">
              {lessonData.thumbnailUrl && (
                <span>
                  <br />
                  {truncateAzureUrl(lessonData.thumbnailUrl)}
                </span>
              )}
              {/* {fileError && ContentMedium.Quiz && (
                <p className="text-danger">A file is required </p>
              )} */}
            </Col>
          </Row>
          <Row>
            <Col>
              <ThumbnailLibraryPanel
                onChange={handleChangeFile}
                thumbnailUrl={lessonData.thumbnailUrl}
                onItemSelected={(item: AzureBlobImageInfo) => {
                  handleLibraryItemSelected(item);
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <FormLabel>{t('lessonDescription', { ns: 'lessons' })}</FormLabel>
              <TextArea
                id="lessonDescription"
                name="lessonDescription"
                value={lessonData.description}
                onChangeValue={handleChangeDescription}
                placeholderText={t('writeLessonDescription', { ns: 'lessons' })}
                width="100%"
                rows={5}
                disabled={saveInProgress}
              ></TextArea>
              {descriptionError && ContentMedium.Quiz && (
                <p className="text-danger">
                  {t('descriptionIsRequired', { ns: 'common' })}
                </p>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <FormLabel>{t('addTags', { ns: 'common' })}</FormLabel>
              <TagInput
                addOnBlur={true}
                tags={tags}
                suggestions={companyTags}
                onDelete={onDeleteTag}
                onAddition={onAdditionTag}
                onInput={(input: string) => {}}
                onBlur={() => {}}
                allowNew={true}
                placeholderText={t('writeTagHere', { ns: 'common' })}
              />
            </Col>
          </Row>
          {showProgress && saveInProgress && lessonData.video && (
            <>
              <br />
              <ProgressBar
                progress={progressValue}
                text={t('uploadProgress', { ns: 'common' })}
              />
            </>
          )}
        </>
      )}
      <Row>
        <Col>
          <div style={{ textAlign: 'right' }}>
            <br />
            {isEdit && (
              <BasicButton
                onClick={() =>
                  setIsConfirmationModalOpen && setIsConfirmationModalOpen(true)
                }
                color="delete"
                disable={saveInProgress}
                style={{ ...deleteStyle, ...buttonStyle }}
              >
                <Typography sx={fontDeleteButton}>
                  {t('deleteLesson', { ns: 'lessons' })}
                </Typography>
              </BasicButton>
            )}
            {contentMedium === ContentMedium.Quiz && (
              <BasicButton
                onClick={() => handleSaveQuiz(lessonData)}
                color="primary"
                disable={saveInProgress || lessonData.title === ''}
                style={buttonLargeStyle}
              >
                <Typography sx={fontButton}>
                  {t('step2addQuestions', { ns: 'lessons' })}
                </Typography>
              </BasicButton>
            )}

            {contentMedium !== ContentMedium.Quiz && (
              <BasicButton
                onClick={() => handleSave(lessonData)}
                color="primary"
                disable={saveInProgress}
                style={buttonStyle}
              >
                <Typography sx={fontButton}>
                  {t('saveAndContinue', { ns: 'common' })}
                </Typography>
              </BasicButton>
            )}

            {saveInProgress && lessonData.video && (
              <>
                <br />
                <span style={errorStyle}>
                  {t('uploadInProgress', { ns: 'common' })}
                </span>
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const deleteStyle: CSSProperties = {
  marginRight: 16
};

const trashIconStyle: CSSProperties = {
  background: '#999999',
  width: '35px',
  height: '35px',
  marginLeft: '1px'
};

const subtitleContainer: CSSProperties = {
  display: 'flex-start',
  alignItems: 'center',
  marginTop: '-20px',
  marginBottom: '10px'
};
const subtitleSpan: CSSProperties = { marginBottom: '10px' };

export default LessonForm;
