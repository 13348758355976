import React, { CSSProperties, FC, useMemo } from 'react';
import { Grid, Paper, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
export interface JobListingProps {
  jobID?: string;
  jobTitle?: string;
  expirationDate: string;
  expiresLabel?: string;
}

const JobListingComponent: FC<JobListingProps> = ({
  jobID,
  jobTitle,
  expirationDate,
  expiresLabel = 'Expires'
}) => {
  const history = useHistory();

  const goToJobDetails = () => {
    history.push(`/company/profile/jobs/post-job-detail/${jobID}`);
  };

  const expirationDisplayDate = useMemo(() => {
    const expirationMoment = moment(expirationDate);
    const tenDaysAgo = moment().subtract(10, 'days');
    const tenDaysLater = moment().add(10, 'days');
    const isBetween = expirationMoment.isBetween(tenDaysAgo, tenDaysLater);
    return isBetween
      ? expirationMoment.fromNow()
      : expirationMoment.format('MM.DD.YY');
  }, [expirationDate]);

  return (
    <Grid item xs={12} md={12}>
      <Paper
        sx={{
          p: 2,
          margin: 'auto',
          flexGrow: 1,
          backgroundColor: '#EDECE8',
          borderRadius: '10px',
          width: '100%',
          marginTop: '10px',
          marginBottom: '10px'
        }}
        elevation={0}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Button onClick={goToJobDetails} style={{ padding: '0px' }}>
                  <Typography gutterBottom style={jobTitleStyle} variant={'h6'}>
                    {jobTitle}
                  </Typography>
                </Button>
                <Typography style={infoAppliedStyle} variant={'body2'}>
                  {expiresLabel}:{' '}
                  <span style={{ fontWeight: 700 }}>
                    {expirationDisplayDate}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const jobTitleStyle: CSSProperties = {
  fontWeight: 700,
  color: '#262625',
  textTransform: 'capitalize'
};

const infoAppliedStyle: CSSProperties = {
  color: '#262625'
};

export default JobListingComponent;
