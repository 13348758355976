import { Grid, Stack, Typography } from '@mui/material';
import { WorkExperienceDto } from '../../../entities/UserProfile';
import { WorkExperienceDateRange } from './WorkExperienceDateRange';

export const WorkExperienceSectionItem = ({
  workExperience
}: {
  workExperience: WorkExperienceDto;
}) => {
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}
      sx={{ padding: '15px' }}
    >
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="blackTitleMedium">
            {workExperience.jobTitle}
          </Typography>
          <Typography variant="blackSubTitle">
            {workExperience.companyName}
          </Typography>
          <WorkExperienceDateRange workExperience={workExperience} />
          <Typography variant="blackCaption">
            {workExperience.description}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
