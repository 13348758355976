

export default class HttpStatusError extends Error {
    
    constructor(public message: string, public status: number){
        super(message);
        
        
    }
}
