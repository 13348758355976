import * as firebase from '../firebase';
import { logEvent } from "firebase/analytics";
import useCurrentUser from '../hooks/useCurrentUser';
import { UserClaims } from '../core/constants';

export const useLogEvent = () => {
    const user = useCurrentUser();
    const employeeId: string = user ? user?.profile[UserClaims.EmployeeId] as string : '';
    const userId: string = user ? user?.profile[UserClaims.UserId] as string : '';

    const logEventObject = {
        logPlayLessonEvent: (lessonId: string) => {
            if (employeeId.length && userId.length){
                logEvent(firebase.analytics, 'play_lesson', {
                    lesson_id: lessonId,
                    employee_id: employeeId,
                    user_guid: userId
                  });
            }
        }
    }

    return logEventObject;
}