import React, { CSSProperties, useState, useEffect } from 'react';
import TextArea from '../core/TextArea/TextArea';
import FormLabel from '../core/FormLabel/FormLabel';
import FileInput from '../core/FileInput/FileInput';
import { Radio } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { Question } from '../../entities/Assessment';
import { Option } from '../../entities/Assessment';
import { truncateAzureUrl } from '../../utils/stringUtils';
import { useTranslation } from 'react-i18next';

const panelStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column' as 'column'
};

export interface MultipleChoiceImageQuestionDefinitionProps {
  question: Question;
  changeHandler: (prop: string, value: any) => void;
}

interface OptionEditMode extends Option {
  _id?: string;
}

const MultipleChoiceImageQuestionDefinition: React.FC<MultipleChoiceImageQuestionDefinitionProps> = ({
  question,
  changeHandler
}) => {
  const [fileError, setFileError] = useState<boolean>(false);
  const [questionError, setQuestionError] = useState<boolean>(false);
  const [optionError, setOptionError] = useState<boolean>(false);
  const [options, setOptions] = useState<OptionEditMode[]>([]);
  const { t } = useTranslation(['assessments', 'common']);

  useEffect(() => {
    if (question.options?.length === 0) {
      setOptions([
        {
          _id: 'option-0',
          id: '',
          optionLabel: '',
          imageFile: undefined,
          optionText: '',
          isCorrect: false
        },
        {
          _id: 'option-1',
          id: '',
          optionLabel: '',
          imageFile: undefined,
          optionText: '',
          isCorrect: false
        },
        {
          _id: 'option-2',
          id: '',
          optionLabel: '',
          imageFile: undefined,
          optionText: '',
          isCorrect: false
        },
        {
          _id: 'option-3',
          id: '',
          optionLabel: '',
          imageFile: undefined,
          optionText: '',
          isCorrect: false
        }
      ]);
    } else {
      setOptions(question.options!);
    }
  }, [question.options]);

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return validImageTypes.includes(fileType);
  };

  const handleChangeFile = (option: OptionEditMode) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const fileType = file['type'];
      if (IsValidImageFormat(fileType)) {
        setFileError(false);
        const newOptions = options.map(opt =>
          opt === option ? { ...option, imageFile: file } : opt
        );
        setOptions(newOptions);
        changeHandler('options', newOptions);
      } else {
        setFileError(true);
      }
    } else {
      setFileError(true);
    }
  };

  const handleChangeRadio = (option: OptionEditMode) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newOptions = options.map(opt =>
      opt === option
        ? { ...option, isCorrect: event.target.checked }
        : { ...opt, isCorrect: false }
    );
    changeHandler('options', newOptions);
    setOptions(newOptions);
  };

  const optionQuestionUI = [0, 1, 2, 3];

  return (
    <div style={panelStyle}>
      <FormLabel>
        {t('question', {ns: 'assessments'})} <span className="text-danger">*</span>
      </FormLabel>
      <TextArea
        id="assessmentQuestion"
        name="questionText[]"
        value={question.questionText}
        onChangeValue={value => changeHandler('questionText', value)}
        placeholderText={t('writeQuestionHere', {ns: 'assessments'})}
        width="100%"
        rows={5}
      ></TextArea>
      {questionError && (
        <p className="text-danger"> {t('questionFieldRequired', {ns: 'assessments'})} </p>
      )}
      <br />
      <FormLabel>
        {t('checkCorrectResponse', {ns: 'assessments'})}{' '}
        <span className="text-danger">*</span>
      </FormLabel>
      <div style={panelStyle}>
        {options.map((opt, index: number) => (
          <React.Fragment key={index}>
            <Row>
              <Col xs={1} md={1} lg={1} style={{ textAlign: 'center' }}>
                <Radio
                  checked={opt.isCorrect}
                  onChange={handleChangeRadio(opt)}
                  value="imageA"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': `Image ${index}` }}
                  style={paddingRadio}
                  sx={colorRadio}
                />
              </Col>
              <Col xs={11} md={11} lg={11}>
                <FileInput
                  initialText={t('chooseFile', {ns: 'common'})}
                  onChange={handleChangeFile(opt)}
                  id="fileInput"
                  name="fileInput"
                  type="thumbnail"
                />
                {opt.imageUrl && <span>{truncateAzureUrl(opt.imageUrl)}</span>}
              </Col>
            </Row>
            <br />
          </React.Fragment>
        ))}
        {optionError && <p className="text-danger">{t('optionRequired', {ns: 'common'})}</p>}
        <br />
      </div>
    </div>
  );
};

const colorRadio: CSSProperties = {
  color: '#FFFFFF'
};

const paddingRadio: CSSProperties = {
  paddingRight: 15
};

const fileInputStyle: CSSProperties = {
  width: '90%',
  height: '35px'
};

export default MultipleChoiceImageQuestionDefinition;
