import React, { CSSProperties } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { BasicButtonTypeEnum } from '../../../core/enums';

type PartialMaterialButtonProps = Omit<
  ButtonProps,
  | 'children'
  | 'onClick'
  | 'width'
  | 'height'
  | 'color'
  | 'disable'
  | 'style'
  | 'type'
>;

export interface BasicButtonProps extends PartialMaterialButtonProps {
  children: any;
  onClick?: () => void;
  width?: string;
  height?: string;
  color:
    | 'primary'
    | 'secondary'
    | 'alternative'
    | 'delete'
    | 'confirm'
    | 'revisit'
    | 'gray'
    | 'transparent';
  disable?: boolean;
  style?: React.CSSProperties;
  type?: BasicButtonTypeEnum;
}

const BasicButton: React.FC<BasicButtonProps> = ({
  children,
  onClick = () => {},
  width,
  height,
  color,
  disable,
  style,
  type = BasicButtonTypeEnum.Button,
  ...props
}) => {
  const theme = useTheme();
  const buttonStyle: CSSProperties = {
    ...style,
    borderRadius: '25px',
    textTransform: 'none' as 'none',
    opacity: disable ? 0.5 : 1,
    fontWeight: 700,
    border:
      color === 'alternative'
        ? `1px solid ${theme.palette.error.light}`
        : color === 'delete'
        ? '1px solid red'
        : color === 'transparent'
        ? 'transparent'
        : 'none',
    backgroundColor:
      color === 'primary'
        ? `${theme.palette.primary.main}`
        : color === 'secondary'
        ? `${theme.palette.secondary.main}`
        : color === 'confirm'
        ? '#d50c0c'
        : color === 'transparent'
        ? 'transparent'
        : color === 'gray'
        ? '#EDECE8'
        : color === 'revisit'
        ? `${theme.palette.grey[900]}`
        : `${theme.palette.common.black}`,
    color:
      color === 'primary'
        ? `${theme.palette.common.white}`
        : color === 'secondary'
        ? `${theme.palette.common.black}`
        : `${theme.palette.common.white}`
  };
  return (
    <Button
      disabled={disable}
      variant="contained"
      onClick={onClick}
      type={type}
      style={
        width && height
          ? { ...buttonStyle, width: width, height: height }
          : buttonStyle
      }
      {...props}
    >
      {children}
    </Button>
  );
};

export default BasicButton;
