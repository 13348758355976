import React from 'react';
import { useTheme } from '@emotion/react';

export interface PublicJobsListProps {
  color?: string;
  children: any
}

const FormLabel: React.FC<any> = ({ color = '#262625', children }) => {
  const theme = useTheme();
  const labelStyle = {
    color: color,
    lineHeight: '24px',
    fontSize: '16px',
    marginTop: '5px'
  };
  return <label style={labelStyle}>{children}</label>;
};

export default FormLabel;
