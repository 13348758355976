// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDUbra5vvcwCt0EiPJLzFms5Nz-60APzFU",
    authDomain: "buildwitt-training.firebaseapp.com",
    projectId: "buildwitt-training",
    storageBucket: "buildwitt-training.appspot.com",
    messagingSenderId: "132273287959",
    appId: "1:132273287959:web:0697d9331333e5f4febd52",
    measurementId: "G-C7H6QD0Z4L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

