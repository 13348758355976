import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
  CircularProgress
} from '@mui/material';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import TextBox from '../../components/core/TextBox/TextBox';
import { useDispatch, useSelector } from 'react-redux';

import {
  actionCreators as addressesActions,
  selectCityItems,
  selectCountryItems,
  selectStateItems
} from '../../actions/address';
import { ApplicationState } from '../../store';
import { DropdownItem } from '../../entities/Common';
import { CompanyProfileDTO } from '../../entities/Dto/createCompanyDto';
import {
  CompanySizeOptions,
  CompanyBusinessTypeOptions
} from '../../core/constants';
import { FormTitle } from '../../components/Pages';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { MuiFileInput } from 'mui-file-input';
import { IndustryCategory } from '../../entities/IndustryCategory';
import Autocomplete from '@mui/material/Autocomplete';

interface CompanyProfileFormProps
  extends Pick<
    UseFormReturn<CompanyProfileDTO>,
    'control' | 'watch' | 'handleSubmit' | 'setValue' | 'formState'
  > {
  title: string;
  industryCategories?: IndustryCategory[];
  submitHandler: SubmitHandler<CompanyProfileDTO>;
  bottomActions: React.ReactNode;
}

export const CompanyProfileForm = ({
  title,
  control,
  watch,
  formState,
  industryCategories = [],
  handleSubmit,
  setValue,
  submitHandler,
  bottomActions
}: CompanyProfileFormProps) => {
  const [companySizeOptions] = useState<DropdownItem[]>(CompanySizeOptions);
  const [companyBusinessTypeOptions] = useState<DropdownItem[]>(
    CompanyBusinessTypeOptions
  );

  const dispatch = useDispatch();
  const watchCountry = watch('address.country');
  const watchState = watch('address.state');

  const isLoadingCountries = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCountries
  );
  const isLoadingStates = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingStates
  );
  const isLoadingCities = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCities
  );

  const countryItems = useSelector(selectCountryItems);

  const stateItems = useSelector(selectStateItems);

  const cityItems = useSelector(selectCityItems);

  useEffect(() => {
    if (watchCountry)
      dispatch(addressesActions.getStatesFromCountries(watchCountry));
  }, [watchCountry]);

  useEffect(() => {
    if (watchState && watchCountry)
      dispatch(addressesActions.getCitiesFromStates(watchCountry, watchState));
  }, [watchState]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormTitle title={title} />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.name}>
            <FormLabel>
              Company Name
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value ?? ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>{formState.errors.name?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormLabel>
            Country
            <Typography variant="redCaption">*</Typography>
          </FormLabel>
          <Controller
            name="address.country"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <>
                <Autocomplete
                  disablePortal
                  options={countryItems ?? []}
                  loading={isLoadingCountries}
                  value={value}
                  onChange={(_, newValue) => {
                    setValue('address.state', '');
                    setValue('address.city', '');
                    formOnChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingCountries && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <FormLabel>
            State
            <Typography variant="redCaption">*</Typography>
          </FormLabel>
          <Controller
            name="address.state"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <>
                <Autocomplete
                  disablePortal
                  options={stateItems ?? []}
                  loading={isLoadingStates}
                  value={value}
                  onChange={(_, newValue) => {
                    setValue('address.city', '');
                    formOnChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingStates && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <FormLabel>
            City
            <Typography variant="redCaption">*</Typography>
          </FormLabel>
          <Controller
            name="address.city"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <>
                <Autocomplete
                  disablePortal
                  options={cityItems ?? []}
                  loading={isLoadingCities}
                  value={value}
                  onChange={(_, newValue) => {
                    formOnChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingCities && (
                              <CircularProgress color="inherit" size={20} />
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Website</FormLabel>
            <Controller
              name="websiteUrl"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value ?? ''}
                  onChangeValue={onChange}
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Tagline</FormLabel>
            <Controller
              name="tagline"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value ?? ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              This will be displayed on the Company Directory page.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.industryCategories}>
            <FormLabel>
              Industry/Specialty
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="industryCategories"
              control={control}
              defaultValue={[]}
              render={({ field: { value, onChange: formOnChange } }) => (
                <Autocomplete
                  disablePortal
                  getOptionLabel={option => option.name}
                  options={industryCategories ?? []}
                  renderInput={params => <TextField {...params} />}
                  value={value}
                  onChange={(_, newValue) => {
                    formOnChange(newValue);
                  }}
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              )}
            />
            <FormHelperText>
              {formState.errors.industryCategories?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.companySize}>
            <FormLabel>
              Company Size
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="companySize"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  onChange={onChange}
                  value={value ?? ''}
                  displayEmpty
                  defaultValue={''}
                >
                  <MenuItem disabled value={''}>
                    - select -
                  </MenuItem>
                  {companySizeOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.industryCategories?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!formState.errors.companyBusinessType}>
            <FormLabel>
              What describes your business best?
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="companyBusinessType"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  onChange={onChange}
                  value={value ?? ''}
                  displayEmpty
                  defaultValue={''}
                >
                  {companyBusinessTypeOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.companyBusinessType?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Company Logo</FormLabel>
            <Controller
              name="logoFile"
              control={control}
              render={({ field: { name, onChange, value }, fieldState }) => (
                <MuiFileInput
                  id={name}
                  value={value ?? null}
                  onChange={onChange}
                  helperText={fieldState.invalid ? 'File is invalid' : ''}
                  error={fieldState.invalid}
                />
              )}
            />
            <FormHelperText>
              <Typography variant="blackCaptionItalic">
                Image must be 400px by 400px, less than 1mb, png or jpeg.
              </Typography>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Controller
                  name="isCompanyVerify"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox checked={value ?? false} onChange={onChange} />
                  )}
                />
              }
              label={
                <label style={{ color: '#262625', fontSize: '15px' }}>
                  I verify that I am an authorized representative of this
                  organization and have the right to act on its behalf in the
                  creation and management of this page.
                </label>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {bottomActions}
        </Grid>
      </Grid>
    </form>
  );
};
