import { Box } from '@mui/material';
import React, { CSSProperties } from 'react';
import { styled } from '@mui/system';
import { LearningPlan } from '../../entities/LearningPlan';
import { useTheme } from '@mui/material';
import Video from '../../assets/Video.png';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appPermissions } from '../../core/constants';
import ProtectedComponent from '../core/ProtectedComponent/ProtectedComponent';
import BlackCheck from '../../assets/blackcheck.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface LearningPlanCardProps {
  plan: LearningPlan;
  showEdit?: boolean;
  dueDate?: string;
  onEdit?: () => void;
  width?: any;
  isComplete?: boolean;
  style?: CSSProperties;
}

const CompleteCardHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-1px',
  background: '#2ED47A',
  color: '#0E0D0D',
  width: '117px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem'
});

const OverdueCardHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-1px',
  background: '#FF2A20',
  color: '#0E0D0D',
  width: '117px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem'
});

const CompletedLearningPlanHeader = ({label}: {label: string}) => (
  <CompleteCardHeaderComponent>
    <img src={BlackCheck} style={{ width: '10px', margin: '-3px 4px 0px' }} />
    {label}
  </CompleteCardHeaderComponent>
);

const OverdueLearningPlanHeader = ({label}: {label: string}) => (
  <OverdueCardHeaderComponent>
    <span style={overdueSpanStyle}>{label}</span>
  </OverdueCardHeaderComponent>
);

const LearningPlanCard: React.FC<React.PropsWithChildren<
  LearningPlanCardProps
>> = ({
  plan,
  showEdit = true,
  dueDate,
  onEdit,
  width = '100%',
  isComplete = false,
  style,
  children
}) => {
  const { t } = useTranslation(['assignments', 'common', 'learningPlans', 'courses']);
  const theme = useTheme();
  const cardStyle: CSSProperties = {
    backgroundSize: 'cover' as 'cover',
    backgroundRepeat: 'no-repeat',
    borderLeftWidth: '1px',
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    borderRightWidth: '0px',
    borderTopRightRadius: '14px',
    borderBottomRightRadius: '14px',
    borderTopLeftRadius: '14px',
    borderBottomLeftRadius: '14px',
    borderColor: isComplete
      ? theme.palette.success.light
      : `${theme.palette.grey['100']}`,
    borderRightColor: isComplete
      ? theme.palette.success.light
      : `${theme.palette.primary.main}`,
    borderStyle: 'solid',
    minHeight: '295.5px',
    width: width,
    display: 'flex',
    flexDirection: 'row' as 'row',
    background: `linear-gradient(0deg, #1B1C1C 0%,  #1B1C1C 6.25%, rgba(27, 28, 28, 0) 100%), url('${plan.thumbnailUrl}')`,
    position: 'relative',
    ...style
  };
  const rightDivStyle = {
    width: '2%',
    minWidth: '30px',
    minHeight: '295.5px',
    height: '100%',
    backgroundColor: `${theme.palette.primary.main}`,
    borderTopRightRadius: '14px',
    borderBottomRightRadius: '14px',
    textAlign: 'center' as 'center'
  };
  const cardSelector = {
    textTransform: 'uppercase' as 'uppercase',
    marginLeft: '15px',
    width: '100%',
    minWidth: '295.5px',
    textAlign: 'center' as 'center',
    paddingRight: '25px',
    color: theme.palette.grey[800],
    fontWeight: '600'
  };
  const leftDivStyle = {
    width: '98%',
    height: '100%',
    display: 'flex',
    minHeight: '295.5px',
    flexDirection: 'column' as 'column',
    padding: '20px 23px',
    justifyContent: 'space-between'
  };

  const planTitle = {
    fontWeight: 'bold',
    fontSize: '24px'
  };

  const learningPlanTitle: CSSProperties = {
    fontSize: '15px',
    fontWeight: '400',
    color: theme.palette.primary.main
  };

  const coursesCount: CSSProperties = {
    fontSize: '11px',
    paddingLeft: '0.3rem'
  };

  const editContainerStyle: CSSProperties = {
    fontSize: '15px',
    textDecoration: 'underline',
    cursor: 'pointer'
  };

  const editIconStyle: CSSProperties = {
    marginLeft: '0.5rem'
  };

  const iconContainerStyle: CSSProperties = {
    textAlign: 'right',
    paddingTop: isComplete ? '1.5rem' : '0'
  };

  const separatorStyle: CSSProperties = {
    padding: '0 0.7rem',
    color: theme.palette.grey[100]
  };

  const dueDateStyle: CSSProperties = {
    color: theme.palette.primary.main,
    paddingLeft: '0.3rem'
  };

  const overdueDateStyle: CSSProperties = {
    color: theme.palette.error.main,
    paddingLeft: '0.3rem'
  };

  const handleEdit = () => {
    if (onEdit) onEdit();
  };

  var today = moment();
  var pastDueDate = moment(dueDate);

  return (
    <Box style={cardStyle}>
      {isComplete && <CompletedLearningPlanHeader label={t('completed', {ns: 'assignments'})} />}
      {!isComplete && pastDueDate.isBefore(today, 'day') &&  <OverdueLearningPlanHeader label={t('overdue', {ns: 'assignments'})} />}
      <div style={leftDivStyle}>
        <div style={iconContainerStyle}>
          <ProtectedComponent action={appPermissions.ASSIGN_LEARNING_PLANS}>
            {showEdit && (
              <span style={editContainerStyle} onClick={handleEdit}>
                {t('edit', {ns: 'common'})}
                <FontAwesomeIcon
                  icon={faPen}
                  color="white"
                  style={editIconStyle}
                />
              </span>
            )}
          </ProtectedComponent>
        </div>
        <div>
          <span style={learningPlanTitle}>{t('learningPlan', {ns: 'learningPlans'})}</span>
          <br />
          <span style={planTitle}>{plan.name}</span>
          {isComplete == true ? (
            <div>
              <img src={Video} />
              <span style={coursesCount}>{` ${plan.courseCount} ${t('courses', {ns: 'courses'})}`}</span>
              <br />
            </div>
          ) : (
            <div>
              <img src={Video} />
              <span style={coursesCount}>
                {` ${plan.courseCount} ${t('courses', {ns: 'courses'})}`}
                {dueDate && (
                  <>
                    <span style={separatorStyle}>|</span>
                    {t('dueDate', {ns: 'assignments'})}:{' '}
                    <span
                      style={
                        !isComplete && pastDueDate.isBefore(today, 'day')
                          ? overdueDateStyle
                          : dueDateStyle
                      }
                    >
                      {dueDate}
                    </span>
                  </>
                )}
              </span>
              <br />
            </div>
          )}
          <div>
            {' '}
            <br />
          </div>
          {children}
        </div>
      </div>
      <div style={rightDivStyle}>
        <p className="vertical-text" style={cardSelector}>
          {t('learningPlan', {ns: 'learningPlans'})}
        </p>
      </div>
    </Box>
  );
};

const overdueSpanStyle: CSSProperties = {
  fontSize: '17px',
};

export default LearningPlanCard;
