import React, { CSSProperties, FC } from 'react';
import { Grid, Paper, Typography, Button, ButtonBase } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { JobsList } from '../../entities/JobsList';
import { Card } from '../../components/Card';
import { JobsTypeOptions } from '../../core/constants';
import { Page } from '../../components/Pages';
import moment from 'moment';

export interface PublicJobsListProps {
  jobID?: string;
  jobInfo?: JobsList;
}

const PublicJobsList: FC<PublicJobsListProps> = ({ jobID, jobInfo }) => {
  const history = useHistory();

  const goToJobDetails = () => {
    history.push(`/company/profile/jobs/post-job-detail/${jobID}`);
  };

  const jobType = JobsTypeOptions.find(jt => jt.value === jobInfo?.jobType);

  const daysAgoDate = (publishedDate: any) => {
    const now = moment();
    const days = now.diff(publishedDate, 'days');
    return days === 1 ? days + ' day ago' : days + ' days ago';
  };

  return (
    <Grid
      container
      spacing={2}
      sx={jobContainer}
      elevation={0}
      component={Paper}
    >
      <Grid item xs={12} sm={6} lg={2}>
        <ButtonBase
          sx={{
            width: '108px',
            height: '108px',
            borderRadius: '5px',
            marginTop: '10px'
          }}
        >
          <div
            style={{
              background: jobInfo?.companyProfileLogoUrl
                ? `url('${jobInfo.companyProfileLogoUrl}'`
                : '#EDECE8',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: '5px',
              border: 'solid 1px #ccc'
            }}
          />
        </ButtonBase>
      </Grid>
      <Grid item direction="column" spacing={2}>
        <Grid item xs>
          <Button onClick={goToJobDetails} style={{ padding: '0px' }}>
            <Typography style={jobTitleStyle}>{jobInfo?.title}</Typography>
          </Button>
          <Typography style={companyNameStyle}>
            {jobInfo?.companyName}
          </Typography>
          <Typography style={countryInfoStyle}>
            {`${jobInfo?.city}, ${jobInfo?.state}`}
          </Typography>
          <Typography style={jobTypeStyle}>{jobType?.label}</Typography>
          <Typography style={dateStyle}>
            Posted: {daysAgoDate(jobInfo?.publishedOnUtc)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const jobTitleStyle: CSSProperties = {
  fontWeight: 700,
  fontSize: 28,
  color: '#232323',
  textTransform: 'capitalize',
  textAlign: 'left'
};

const companyNameStyle: CSSProperties = {
  fontWeight: 700,
  fontSize: 20,
  color: '#8B9162'
};

const countryInfoStyle: CSSProperties = {
  fontWeight: 400,
  fontSize: 16,
  color: '#232323'
};

const jobTypeStyle: CSSProperties = {
  fontWeight: 400,
  fontSize: 16,
  color: '#232323'
};

const dateStyle: CSSProperties = {
  fontWeight: 400,
  fontSize: 12,
  color: '#232323',
  fontStyle: 'italic',
  marginTop: 20
};

const jobContainer: CSSProperties = {
  padding: 2,
  margin: 'auto',
  flexGrow: 1,
  backgroundColor: '#FFF',
  borderRadius: '10px',
  width: '100%',
  marginTop: '10px',
  marginBottom: '10px'
};

export default PublicJobsList;
