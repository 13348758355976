import React, { useState, useRef, CSSProperties, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import {
  Question,
  Assessment,
  AssessmentResult,
  Answer
} from '../../entities/Assessment';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import AssessmentFactory from '../../components/AssessmentFactory/AssessmentFactory';
import { actionCreators } from '../../actions/assessments';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

export interface LessonAssessmentProps {
  show: boolean;
  assessment: Assessment;
  goNextLesson: () => void;
  isInPlayMode: boolean;
  isModalBusy?: boolean;
}

const LessonAssessments: React.FC<LessonAssessmentProps> = ({
  show,
  assessment,
  goNextLesson,
  isInPlayMode,
  isModalBusy = false
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [lessonQuestions, setLessonQuestions] = useState<Question[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [showBack, setShowBack] = useState<boolean>(false);
  const [assessmentResult, setAssessmentResult] = useState<AssessmentResult>({
    assessmentId: assessment.id,
    answers: []
  });
  const questionIndexRef = useRef<number>(0);
  const { t } = useTranslation(['common', 'assessments']);

  useEffect(() => {
    const orderedQuestions = assessment.questions.map((question, index) => {
      return { ...question, order: index + 1 };
    });
    questionIndexRef.current = 0;
    setLessonQuestions(orderedQuestions);
    setCurrentQuestion(orderedQuestions[questionIndexRef.current]);
    setAssessmentResult({ assessmentId: assessment.id, answers: [] });
  }, [assessment, assessment.questions.length]);

  useEffect(() => {
    if (
      isInPlayMode &&
      assessmentResult.answers.length &&
      lessonQuestions.length === assessmentResult.answers.length
    ) {
      dispatch(actionCreators.saveAssessmentResult(assessmentResult));
    }
  }, [assessmentResult, isInPlayMode]);

  useEffect(() => {
    setShowBack(!(currentQuestion?.order === 1));
  }, [currentQuestion]);

  const modalStyle: CSSProperties = {
    color: theme.palette.grey[100]
  };

  const handleBack = () => {
    if (questionIndexRef.current > 0) {
      questionIndexRef.current--;
      setCurrentQuestion(lessonQuestions[questionIndexRef.current]);
    }
  };

  const handleNext = () => {
    if (lessonQuestions.length === assessmentResult.answers.length) {
      goNextLesson();
      return;
    }

    if (questionIndexRef.current < lessonQuestions.length - 1) {
      if (
        !assessmentResult.answers.find(
          a => a.questionId === currentQuestion?.id
        )
      )
        return;

      questionIndexRef.current++;
      setCurrentQuestion(lessonQuestions[questionIndexRef.current]);
    }
  };

  const getAssessmentResults = (answer: Answer) => {
    setAssessmentResult({
      ...assessmentResult,
      answers: [...assessmentResult.answers, answer]
    });
  };

  const answer = assessmentResult.answers?.find(
    answer => answer.questionId === currentQuestion?.id
  );
  const isNextEnable = !!answer;
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      style={modalStyle}
      variant="lg"
      size="lg"
      centered={true}
      contentClassName="modal-body-auto-height"
    >
      <Modal.Header closeButton={false}>
        <Modal.Title className="text-white">
          {t('selectCorrectAnswer', { ns: 'assessments' })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AssessmentFactory
          question={currentQuestion!}
          assessmentResults={assessmentResult}
          setAssessmentResult={getAssessmentResults}
        />
      </Modal.Body>
      <Modal.Footer>
        {isModalBusy && (
          <div className="d-flex flex-column w-100">
            <LinearProgress />
          </div>
        )}
        <div className="d-flex flex-row w-100">
          <div className="w-50 text-left">
            {showBack && (
              <BasicButton
                color="secondary"
                width="103px"
                height="45px"
                onClick={handleBack}
              >
                {t('back', { ns: 'common' })}
              </BasicButton>
            )}
          </div>
          <div className="w-50 text-right">
            <FormLabel>
              {t('question', { ns: 'common' })}{' '}
              <span className="text-white pe-3">
                {currentQuestion?.order}/{lessonQuestions.length}
              </span>
            </FormLabel>
            <BasicButton
              color="primary"
              width="103px"
              height="45px"
              onClick={handleNext}
              disable={!isNextEnable}
            >
              {t('next', { ns: 'common' })}
            </BasicButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LessonAssessments;
