import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../../actions/courses';
import { actionCreators as actionTags } from '../../actions/tags';
import { Course } from '../../entities/Course';
import { ApplicationState } from '../../store';
import CourseForm from '../../components/CourseForm/CourseForm';
import { fontButton, buttonStyle, rowReverse } from '../../utils/buttonStyles';
import { Typography } from '@mui/material';
import BackgroundCoursePattern from '../../assets/BackgroundCoursePattern.svg';
import { FormErrors } from '../../components/core/FormErrors/FormErrors';
import { useTranslation } from 'react-i18next';

const emptyCourse: Course = {
  id: '',
  title: '',
  description: '',
  thumbnailUrl: '',
  companyId: '',
  isDraft: true,
  tags: [],
  videosCount: 0,
  flashCardsCount: 0,
  quizesCount: 0,
  revisionNumber: 0
};

export interface EditCourseProps {
  closeModal: () => void;
}

const EditCourse: React.FC<EditCourseProps> = ({ closeModal }) => {
  const [courseData, setCourseData] = React.useState<Course>(emptyCourse);
  const [courseFile, setCourseFile] = React.useState<any>(null);

  const dispatch = useDispatch();
  const [courseToEdit, setCourseToEdit] = React.useState<Course>(emptyCourse);
  const [invalidForm, setInvalidForm] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const { t } = useTranslation(['common']);
  const [img, setImg] = React.useState<string>('');

  const course = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );

  const companyTags = useSelector(
    (state: ApplicationState) => state.tags?.tags
  );

  React.useEffect(() => {
    if (course) {
      setCourseToEdit(course);
      setCourseData(course);
      dispatch(actionTags.requestTags());
      setImg(course.thumbnailUrl);
    }
  }, [course]);

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg+xml'
    ];
    return validImageTypes.includes(fileType);
  };

  const handleSaveCourse = async () => {
    setInvalidForm(false);
    setErrors([]);
    if (
      courseData &&
      courseData.title &&
      courseData.description &&
      courseData.tags.length &&
      (courseFile === null && !img
        ? BackgroundCoursePattern
        : (courseFile && IsValidImageFormat(courseFile.type)) || img)
    ) {
      await dispatch(actionCreators.editCourse(courseData, img, courseFile));
      await dispatch(actionTags.requestTags());
      closeModal();
    } else {
      setInvalidForm(true);
      let errorsArray: string[] = [];
      if (!courseData.title) {
        errorsArray.push(t('titleRequired', { ns: 'common' }));
      }
      if (!courseData.description) {
        errorsArray.push(t('descriptionIsRequired', { ns: 'common' }));
      }
      if (!courseData.tags.length) {
        errorsArray.push(t('oneTagRequired', { ns: 'common' }));
      }

      if (courseFile && !IsValidImageFormat(courseFile.type)) {
        errorsArray.push(t('invalidImageFormat', { ns: 'common' }));
      }
      setErrors(errorsArray);
    }
  };

  return (
    <>
      <Row>
        <Col className="d-flex flex-column">
          {invalidForm && <FormErrors errors={errors} />}
          <CourseForm
            course={courseToEdit}
            companyTags={companyTags || []}
            onUpdateThumbnail={setCourseFile}
            onUpdateCourseInfo={setCourseData}
            onUpdateImageFromLib={setImg}
          ></CourseForm>
        </Col>
      </Row>
      <Row style={rowReverse}>
        <BasicButton
          onClick={async () => {
            handleSaveCourse();
          }}
          color="primary"
          style={buttonStyle}
        >
          <Typography style={fontButton}>
            {t('save', { ns: 'common' })}
          </Typography>
        </BasicButton>
      </Row>
    </>
  );
};

export default EditCourse;
