import React, { useState, CSSProperties, useEffect } from 'react';
import { Question, Answer, Option } from '../../entities/Assessment';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export interface MultipleChoiceQuestionProps {
  question: Question;
  questionResult?: Answer;
  setAssessmentResult: (answer: Answer) => void;
}

const imgStyles: CSSProperties = {
  marginTop: '12px',
  marginBottom: '12px',
  maxHeight: '60%'
};

const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  question,
  questionResult,
  setAssessmentResult
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['assessments']);
  const hasValidImg = !!question.imageUrl;

  const [selectionKey, setSelectionKey] = useState<string | undefined>(
    questionResult?.selectedOptionId
  );

  useEffect(() => {
    if (questionResult) {
      if (question && question.options) {
        setSelectionKey(questionResult?.selectedOptionId);
      }
    }
  }, [questionResult]);

  const isIncorrectStyle: CSSProperties = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderColor: theme.palette.error.light
  };

  const isCorrectStyle: CSSProperties = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderColor: theme.palette.success.light
  };

  const selectableItemsStyle: CSSProperties = {
    borderRadius: '5px',
    margin: hasValidImg ? '12px' : '12px 0px',
    padding: '15px',
    backgroundColor: '#3e3e3e',
    width: hasValidImg ? '100%' : '45%',
    color: 'white'
  };

  const container: CSSProperties = {
    display: 'flex',
    flexDirection: hasValidImg ? 'row' : 'column'
  };

  const boxWithImages: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '50%'
  };

  const boxWithoutImages: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  };

  const imageAndTitleBox: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  };

  const answerMessageStyle: CSSProperties = {
    color: theme.palette.common.white,
    fontSize: '14px'
  };

  const handleSelection = (
    event: React.MouseEvent<HTMLDivElement>,
    selectedOption: Option
  ) => {
    if (questionResult) return;

    setSelectionKey(selectedOption.id);

    setAssessmentResult({
      questionId: question.id,
      isCorrect: selectedOption.isCorrect,
      type: question && question.type ? question.type : '',
      selectedOptionId: selectedOption?.id,
      order: question?.order
    });
  };

  return (
    <>
      <div style={container}>
        <div style={imageAndTitleBox}>
          {hasValidImg && <img style={imgStyles} src={question.imageUrl} />}
          <h4>{question.questionText}</h4>
        </div>

        <div style={hasValidImg ? boxWithImages : boxWithoutImages}>
          {question.options?.map((answer: any) => {
            let finalStyle = { ...selectableItemsStyle };
            if (answer.id == selectionKey) {
              if (answer.isCorrect) {
                finalStyle = { ...finalStyle, ...isCorrectStyle };
              } else {
                finalStyle = { ...finalStyle, ...isIncorrectStyle };
              }
            }
            return (
              <div
                style={finalStyle}
                key={answer.id}
                onClick={e => handleSelection(e, answer)}
              >
                <span>{answer.optionText}</span>
              </div>
            );
          })}
        </div>
      </div>
      {questionResult && (
        <div style={answerMessageStyle}>
          {questionResult.isCorrect
            ? t('correctAnswer', {ns: 'assessments'})
            : `${t('correctAnswerIs', {ns: 'assessments'})} ` +
              question.options?.find(option => option.isCorrect)?.optionText}
        </div>
      )}
    </>
  );
};

export default MultipleChoiceQuestion;
