import moment from 'moment';

// This function will take a Date with Timezone information and
// Will convert it to a localized moment date, ignoring the time/timezone information
export const absUtcDateToLocalMoment = (utcDate: Date) => {
  return moment(moment.utc(utcDate).format('YYYY-MM-DD'));
};

export const getYears = () => {
  const nYearsPrior = 100;
  const year = new Date().getFullYear();
  return Array.from(
    { length: nYearsPrior },
    (v, i) => year - nYearsPrior + i + 1
  ).reverse();
};

export const getFutureYears = () => {
  const nYearsAfter = 10;
  const year = new Date().getFullYear();
  return Array.from({ length: nYearsAfter }, (v, i) => year + i);
};
export const getMonths = () => {
  return [
    { label: 'Jan', value: 1 },
    { label: 'Feb', value: 2 },
    { label: 'Mar', value: 3 },
    { label: 'Apr', value: 4 },
    { label: 'May', value: 5 },
    { label: 'Jun', value: 6 },
    { label: 'Jul', value: 7 },
    { label: 'Ago', value: 8 },
    { label: 'Sep', value: 9 },
    { label: 'Oct', value: 10 },
    { label: 'Nov', value: 11 },
    { label: 'Dec', value: 12 }
  ];
};
