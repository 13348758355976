import { Box, Stack, Typography } from '@mui/material';

export const NotAvailablePage = ({
  message = 'Page not available'
}: {
  message?: string;
}) => {
  return (
    <Stack spacing={2}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <Typography variant="blackTitle">{message}</Typography>
      </Box>
    </Stack>
  );
};
