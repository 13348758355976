import React, { useEffect, CSSProperties, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Guid } from 'guid-typescript';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { ApplicationState } from '../../store';
import { actionCreators as lessonActions } from '../../actions/lessons';
import useCurrentUser from '../../hooks/useCurrentUser';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

export interface StartQuizProps {}

const StartQuiz: React.FC<StartQuizProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const params = useParams<{ id: string; revisionNumber: string }>();
  const user = useCurrentUser();
  const [percentageToPass, setPercentageToPass] = useState<number>(0);
  const [showContent, setShowContent] = useState<boolean>(false);
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons?.currentLesson
  );
  const isloading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const { t } = useTranslation(['common', 'quizzes']);

  useEffect(() => {
    if (currentLesson) {
      if (
        currentLesson.assessmentId &&
        currentLesson.assessmentId !== Guid.EMPTY
      )
        dispatch(
          lessonActions.requestQuizQuestions(currentLesson.assessmentId)
        );
      else dispatch(lessonActions.requestAssessmentQuestions(currentLesson.id));

      setShowContent(true);
    }
  }, [currentLesson?.id]);

  useEffect(() => {
    if (
      currentLesson &&
      currentLesson.questions?.length &&
      currentLesson.correctAnswersToPass
    ) {
      const percentage = Math.floor(
        (currentLesson.correctAnswersToPass * 100) /
          currentLesson.questions?.length
      );
      setPercentageToPass(percentage);
    }
  }, [currentLesson?.questions?.length]);

  const percentageStyle: CSSProperties = {
    color: theme.palette.success.main
  };

  const containerStyle: SxProps = {
    color: theme.palette.common.white,
    fontSize: '18px'
  };

  const startQuiz = () => {
    history.push('/lesson/quiz', {
      courseId: params.id,
      revisionNumber: params.revisionNumber,
      currentLesson: currentLesson,
      backPath: history.location.pathname,
      isInPlayMode: location.pathname.match(/play$/) !== null
    });
  };

  return (
    <Grid container sx={containerStyle}>
      <Grid item xs={12} className="text-center pb-4 pt-5">
        <span style={titleStyle}>{currentLesson?.title}</span>
      </Grid>
      {(!showContent || isloading) && (
        <Grid item xs={12} className="text-center pt-4 pb-4">
          <CircularProgress />
        </Grid>
      )}
      {showContent &&
        !isloading &&
        (!currentLesson?.questions || !currentLesson.questions.length) && (
          <Grid item xs={12} className="text-center pt-4 pb-4">
            {t('noQuestionsFound', { ns: 'quizzes' })}
          </Grid>
        )}
      {showContent &&
        !isloading &&
        currentLesson?.questions &&
        currentLesson.questions.length && (
          <>
            <Grid item xs={12} className="text-center">
              {`${currentLesson?.questions?.length ?? 0} ${t('questions', {
                ns: 'quizzes'
              })}`}
            </Grid>
            <Grid item xs={12} className="text-center pb-4">
              <span>
                {t('toPass', { ns: 'quizzes' })}{' '}
                <span style={percentageStyle}>{`${percentageToPass}%`}</span>{' '}
                {percentageToPass < 100 && t('orHigher', { ns: 'quizzes' })}{' '}
              </span>
            </Grid>
            <Grid item xs={12} className="text-center pb-5">
              <BasicButton
                onClick={startQuiz}
                color="primary"
                width="179px"
                height="45px"
              >
                {t('start', { ns: 'common' })}
              </BasicButton>
            </Grid>
          </>
        )}
    </Grid>
  );
};

const titleStyle: CSSProperties = {
  fontSize: '30px'
};

export default StartQuiz;
