import { Alert, Box, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as appActions } from '../../../actions/appState';
import { actionCreators as userProfileActions } from '../../../actions/userProfile';
import { UserProfileDto } from '../../../entities/UserProfile';

import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims } from '../../../core/constants';
import { ApplicationState } from '../../../store';
import FallbackLoader from '../../../components/FallbackLoader/FallbackLoader';
import { SimpleFormPage } from '../../../components/Pages';
import { IdentificationForm } from './IdentificationForm';
import { Redirect } from 'react-router-dom';
import { BasicButtonTypeEnum } from '../../../core/enums';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { IdentificationValidationSchema } from './IdentificationValidationSchema';

export const IdentificationSectionCreate = () => {
  const user = useCurrentUser();
  const userProfileState = useSelector(
    (state: ApplicationState) => state.userProfile
  );
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, formState, setValue } =
    useForm<UserProfileDto>({
      resolver: yupResolver(IdentificationValidationSchema)
    });
  const industryCategories = useSelector(
    (state: ApplicationState) => state.appState?.industryCategories
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  React.useEffect(() => {
    dispatch(appActions.getIndustryCategories());
  }, []);

  const onSubmit: SubmitHandler<UserProfileDto> = data => {
    const userProfileCreateDto: UserProfileDto = {
      ...data,
      email: user?.profile[UserClaims.UserEmail]! as string,
      userAccountId: user?.profile[UserClaims.UserId]! as string
    };
    dispatch(userProfileActions.createUserProfile(userProfileCreateDto));
  };

  if (isLoading) {
    return <FallbackLoader />;
  }

  if (!isLoading && industryCategories && !industryCategories.length) {
    return (
      <Stack spacing={2}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <Typography variant="blackTitle">Page not available</Typography>
        </Box>
      </Stack>
    );
  }

  if (userProfileState?.currentUserProfile) {
    return (
      <Redirect
        to={`/user/profile/${userProfileState.currentUserProfile.id}`}
      />
    );
  }

  if (!userProfileState || userProfileState?.errorMessage) {
    return (
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        component={Paper}
        sx={{ padding: '50px' }}
      >
        <Grid item>
          <Alert severity="error">
            Could not verify if you have a profile. Try again later.
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <SimpleFormPage title={"Let's get you set up"}>
      <IdentificationForm
        title="Create your Dirt World Profile"
        submitHandler={onSubmit}
        formState={formState}
        watch={watch}
        handleSubmit={handleSubmit}
        setValue={setValue}
        control={control}
        industryCategories={industryCategories}
        bottomActions={
          <BasicButton
            type={BasicButtonTypeEnum.Submit}
            color="primary"
            height={'auto'}
            width={'100%'}
          >
            Finish
          </BasicButton>
        }
      />
    </SimpleFormPage>
  );
};
