declare var window: any;

const config = {
  AUTHORITY: window.AppConfig ? window.AppConfig.IDENTITY_URL : '',
  AUTHORITY_API_URL: window.AppConfig
    ? window.AppConfig.IDENTITY_URL + '/api/'
    : '',
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  RESPONSE_TYPE: process.env.REACT_APP_RESPONSE_TYPE,
  REDIRECT_URI: window.AppConfig ? window.AppConfig.APP_URL + '/' : '',
  SILENT_REDIRECT_URI: window.AppConfig
    ? window.AppConfig.APP_URL + '/silentrenew'
    : '',
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  SCOPES: process.env.REACT_APP_SCOPES,
  COURSES_API_URL: window.AppConfig
    ? window.AppConfig.COURSE_API_URL + '/api/v1/'
    : '',
  STUDENT_API_URL: window.AppConfig
    ? window.AppConfig.STUDENT_API_URL + '/api/v1/'
    : '',
  ASSESSMENT_API_URL: window.AppConfig
    ? window.AppConfig.ASSESSMENT_API_URL + '/api/v1/'
    : '',
  LEADERS_API_URL: window.AppConfig
    ? window.AppConfig.LEADERS_API_URL + '/api/v1/'
    : '',
  LOGOUT_CLIENT_URL: window.AppConfig
    ? window.AppConfig.IDENTITY_URL + '/Identity/Account/LogoutClient'
    : '',
  USERPROFILE_API_URL: window.AppConfig
    ? window.AppConfig.PROFILE_API_URL + '/api/v1/'
    : '',
  IDENTITY_URL: window.AppConfig ? window.AppConfig.IDENTITY_URL : '',
  ENVIRONMENT: window.AppConfig ? window.AppConfig.ENVIRONMENT : '',
  BLOB_SAS_TOKEN: process.env.REACT_APP_BLOB_SAS_TOKEN,
  BLOB_COURSEIMAGES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_COURSEIMAGES,
  BLOB_LESSONIMAGES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_LESSONIMAGES,
  BLOB_LESSONSUBTITLES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_LESSONSUBTITLES,
  BLOB_LEARNINGPLANIMAGES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_LEARNINGPLANIMAGES,
  BLOB_LESSONDOCS_CONTAINER: process.env.REACT_APP_BLOB_CONTAINER_LESSONDOCS,
  BLOB_STORAGE_ACCOUNT_NAME: process.env.REACT_APP_BLOB_STORAGE_ACCOUNT_NAME,
  COURSES_BASE_URL: window.AppConfig ? window.AppConfig.COURSE_API_URL : '',
  BLOB_STORAGE_COLORCONTAINER_LIST_KEY:
    process.env.REACT_APP_BLOB_STORAGE_COLORCONTAINER_LIST_KEY,
  BLOB_STORAGE_BWCONTAINER_LIST_KEY:
    process.env.REACT_APP_BLOB_STORAGE_BWCONTAINER_LIST_KEY,
  BLOB_LESSONGALLERY_COLORCONTAINER:
    process.env.REACT_APP_BLOB_LESSONGALLERY_COLORCONTAINER,
  BLOB_LESSONGALLERY_BWCONTAINER:
    process.env.REACT_APP_BLOB_LESSONGALLERY_BWCONTAINER,
  DASHBOARD_SWITCH: window.AppConfig
    ? window.AppConfig.DASHBOARD_SWITCH === 'true'
    : false,
  BLOB_CONTAINER_PROFILEPICTURES: window.AppConfig
    ? window.AppConfig.BLOB_CONTAINER_PROFILEPICTURES
    : '',
  BLOB_CONTAINER_COMPANY_LOGOS: window.AppConfig.BLOB_CONTAINER_COMPANY_LOGOS,
  FF_PDF_RESUME: window.AppConfig.FF_PDF_RESUME === 'true',
  FF_MANAGE_ADMINS: window.AppConfig.FF_MANAGE_ADMINS === 'true'
};
export default config;
