import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useSignOut } from '../../hooks/useCurrentUser';
import UpdatePassword from './UpdatePassword';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const { signOut } = useSignOut();
  const { t } = useTranslation(['common', 'settings']);

  const HrStyle: CSSProperties = {
    height: '2px'
  };

  const handleSignOut = () => {
    signOut();
  };

  return (
    <PageContainer>
      <Row>
        <Col>
          <h3>{t('settings', {ns: 'settings'})}</h3>
        </Col>
      </Row>
      <hr style={HrStyle} />
      <Row className="pb-4">
        <Col sm={12} md={12} lg={6} xl={4}>
          <UpdatePassword />
        </Col>
      </Row>
      <hr style={HrStyle} />
      <Row>
        <Col className="pt-3">
          <BasicButton
            color="secondary"
            style={buttonStyle}
            onClick={handleSignOut}
          >
            <Typography style={fontButton}>{t('signOut', {ns: 'common'})}</Typography>
          </BasicButton>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Settings;
