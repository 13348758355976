import React, { useState, useEffect, CSSProperties } from 'react';
import { Modal, Row, Col, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import AssignEmployeesPanel from '../../components/AssignEmployeesPanel/AssignEmployeesPanel';
import { LessonAssignment } from '../../entities/Lesson';
import { ApplicationState } from '../../store';
import useCurrentUser from '../../hooks/useCurrentUser';
import { newLessonAssignment } from '../../reducers/LessonsReducer';
import { actionCreators as employeeActions } from '../../actions/employees';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { LearningPlanAssignByOptions } from '../../core/constants';
import { TransactionStatusEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';

export interface AssignLessonProps {
  onClose: () => void;
  onGoBack: (id: string) => void;
}

const AssignLesson: React.FC<AssignLessonProps> = ({ onClose, onGoBack }) => {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons?.currentLesson
  );
  const transactionStatus = useSelector(
    (state: ApplicationState) => state.lessons?.transactionStatus
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [lessonAssignment, setLessonAssignment] = useState<LessonAssignment>({
    ...newLessonAssignment
  });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const { t } = useTranslation(['lessons', 'common', 'assignments']);
  const lessonId = currentLesson?.id;
  const lessonEntryId = currentLesson?.lessonEntryId;

  useEffect(() => {
    if (currentLesson && !lessonAssignment.lessonId.length)
      setLessonAssignment({
        ...newLessonAssignment,
        lessonId: currentLesson.id,
        assignBy: LearningPlanAssignByOptions.EmployeesWorkloadBudget
      });
  }, [currentLesson]);

  useEffect(() => {
    dispatch(employeeActions.requestEmployeeGroups(companyId));
    dispatch(employeeActions.requestEmployees(companyId));
  }, []);

  useEffect(() => {
    setSaveInProgress(false);
    if (transactionStatus === TransactionStatusEnum.Failed) {
      const message = t('anExceptionOccurred', {ns: 'common'});
      setErrorMessage(message);
      alert(message);
    } else if (transactionStatus === TransactionStatusEnum.Successfull) {
      alert(t('savedSucessfully', {ns: 'common'}));
      setErrorMessage('');
      onClose();
    }

    if (transactionStatus !== TransactionStatusEnum.None)
      dispatch(lessonActions.resetTransactionStatus());

    return () => {
      dispatch(lessonActions.resetTransactionStatus());
    };
  }, [transactionStatus]);

  const handleAssign = () => {
    let message: string = '';

    if (!lessonAssignment.employeeIds.length)
      message = t('selectEmployee', {ns: 'common'});
    else if (
      !lessonAssignment.assignBy ||
      (lessonAssignment.assignBy === LearningPlanAssignByOptions.DueDate &&
        !lessonAssignment.dueDate)
    )
      message = t('requiredFieldsMustBeFilled', {ns: 'common'});

    if (message.length) {
      alert(message);
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    dispatch(lessonActions.assignLessonToEmployees(lessonAssignment));
  };

  return (
    <Modal
      dialogClassName="modal-dialog-scrollable"
      show
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ border: 'none' }}
    >
      <Modal.Header closeButton closeVariant="white" onHide={onClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('assignLesson', {ns: 'assignments'})} - {currentLesson?.title}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-danger">{errorMessage}</p>
        <AssignEmployeesPanel
          employees={employees ?? []}
          employeeGroups={employeeGroups ?? []}
          assignment={lessonAssignment}
          setAssignment={setLessonAssignment}
        />
      </Modal.Body>
      <Modal.Footer style={modalFooter}>
        {saveInProgress && (
          <Row>
            <Col>
              <div style={{ textAlign: 'center' }}>
                <Spinner animation="border" />
              </div>
            </Col>
          </Row>
        )}
        {!saveInProgress && (
          <BasicButton
            color="primary"
            width="160px"
            height="45px"
            onClick={handleAssign}
          >
            {t('assign', {ns: 'common'})}
          </BasicButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const modalFooter: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  border: 0
};

export default AssignLesson;
