import { FC } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer';
import { UserProfileDto } from '../../entities/UserProfile';
import FooterImage from '../../assets/footerPage.png';
import {
  TitilliumWebBold,
  TitilliumWebNormal,
  TitilliumWebSemiBold
} from '../../core/constants';
import { getMonthName } from '../../utils/stringUtils';
import NoPP from '../../assets/noPicture.png';

export interface PDFTemplateProps {
  userProfile: UserProfileDto;
}
Font.register({
  family: 'Titillium Web',
  fonts: [
    {
      src: TitilliumWebNormal
    },
    {
      src: TitilliumWebSemiBold,
      fontWeight: 600
    },
    {
      src: TitilliumWebBold,
      fontWeight: 'bold'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20
  },
  experienceContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 5
  },
  educationCertificationContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20
  },
  h1TagContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    marginLeft: 20
  },
  educationContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5
  },
  certificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 5
  },
  profilePicture: {
    width: 130,
    height: 130,
    borderRadius: 100,
    marginRight: 7
  },
  fullNameContainer: {
    display: 'flex',
    marginTop: 25
  },
  h1Tag: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 5,
    fontFamily: 'Titillium Web',
    color: '#262625'
  },
  h2Tag: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 5,
    fontFamily: 'Titillium Web',
    color: '#262625'
  },
  h3Tag: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 5,
    fontFamily: 'Titillium Web',
    color: '#262625'
  },
  titleTag: {
    fontSize: 12,
    fontFamily: 'Titillium Web',
    color: '#262625'
  },
  subTitleTag: {
    fontSize: 12,
    fontFamily: 'Titillium Web',
    color: '#262625B2'
  },
  normalTextTag: {
    fontSize: 10,
    fontFamily: 'Titillium Web',
    color: '#262625',
    marginTop: 5
  },
  separator: {
    fontSize: 12,
    fontFamily: 'Titillium Web',
    color: '#262625',
    marginLeft: 8,
    marginRight: 8
  },
  movieOverview: {
    fontSize: 10
  },
  informationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 12
  },
  countryAndEmailContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  footerPicture: {
    width: 118,
    height: 24
  },
  detailsFooter: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  breakStyle: { display: 'none' }
});

const PDFTemplate: FC<PDFTemplateProps> = ({ userProfile }) => {
  const renderEndDate = (endMonth: any, endYear: any) => {
    if (!endMonth && !endYear) {
      return 'Present';
    } else if (endMonth) {
      return `${getMonthName(parseInt(endMonth))} ${endYear}`;
    }
  };

  return (
    <Document>
      <Page style={styles.page} wrap>
        <View style={styles.mainInformationContainer}>
          {userProfile?.photoURL && (
            <Image
              style={styles.profilePicture}
              source={userProfile?.photoURL}
            />
          )}
          <View style={styles.fullNameContainer}>
            <Text style={styles.h1Tag}>
              {userProfile?.name} {userProfile?.lastName}
            </Text>
            <View style={styles.informationContainer}>
              {userProfile?.address === null ? (
                <Text style={styles.titleTag}>No information available</Text>
              ) : (
                <View>
                  <View style={styles.countryAndEmailContainer}>
                    <Text style={styles.titleTag}>
                      {userProfile?.address.state},{' '}
                      {userProfile?.address.country}
                    </Text>
                  </View>
                </View>
              )}
              <View style={styles.countryAndEmailContainer}>
                <Text style={styles.separator}>|</Text>
              </View>
              <View style={styles.countryAndEmailContainer}>
                <Text style={styles.titleTag}>{userProfile?.email}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.h1TagContainer}>
          <Text style={styles.h1Tag}>Experience</Text>
        </View>
        {userProfile?.workExperiences?.map(workExperience => (
          <View style={styles.experienceContainer} key={workExperience.id}>
            <Text style={styles.h2Tag}>{workExperience.jobTitle}</Text>
            <Text style={styles.h3Tag}>{workExperience.companyName}</Text>
            <Text style={styles.titleTag}>
              {`${getMonthName(parseInt(workExperience.startMonth))} ${
                workExperience.startYear
              } - ${renderEndDate(
                workExperience.endMonth,
                workExperience.endYear
              )}`}
            </Text>
            <Text style={styles.normalTextTag}>
              {workExperience.description}
            </Text>
          </View>
        ))}

        <View
          style={styles.educationCertificationContainer}
          break={(userProfile.workExperiences?.length ?? 0) >= 4}
        >
          <View style={styles.educationContainer}>
            <Text style={styles.h1Tag}>Education</Text>
            {userProfile?.educations?.map(education => (
              <View key={education.id}>
                <Text style={styles.h2Tag}>{education.school}</Text>
                <Text style={styles.h3Tag}>{education.fieldOfStudy}</Text>
                <Text style={styles.titleTag}>{education.graduationDate}</Text>
              </View>
            ))}
          </View>
          <View style={styles.certificationContainer}>
            <Text style={styles.h1Tag}>Certifications/Certificates</Text>
            {userProfile?.certifications?.map(certification => (
              <View key={certification.id}>
                <Text style={styles.h2Tag}>{certification.skillName}</Text>
                <Text style={styles.titleTag}>
                  {certification.dateAwarded} -{' '}
                  {certification.expirationDate &&
                  certification.expirationDate !== ''
                    ? certification.expirationDate
                    : 'Forever'}
                </Text>
              </View>
            ))}
          </View>
        </View>

        <View style={styles.detailsFooter} fixed>
          <Image style={styles.footerPicture} source={FooterImage} />
        </View>
      </Page>
    </Document>
  );
};

export default PDFTemplate;
