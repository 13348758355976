//courses
export const COURSES_SET_COURSES = 'COURSES_SET_COURSES';
export const COURSES_SET_EDITING = 'COURSES_SET_EDITING';
export const COURSES_SET_TAGS = 'COURSES_SET_TAGS';
export const COURSE_BY_ID = 'COURSE_BY_ID';
export const COURSES_SET_LESSON_NAMES = 'COURSES_SET_LESSON_NAMES';
export const COURSES_UPDATE_LESSON_ORDER = 'COURSES_UPDATE_LESSON_ORDER';
export const COURSES_UPDATE = 'COURSES_UPDATE';
export const COURSE_ERROR = 'COURSE_ERROR';
export const COURSE_RESET_ERROR = 'COURSE_RESET_ERROR';
export const COURSES_SET_COURSE_LIST = 'COURSES_SET_COURSE_LIST';
export const COURSES_UPDATE_TRANSACTION_STATUS =
  'COURSES_UPDATE_TRANSACTION_STATUS';
export const COURSES_RESET_TRANSACTION_STATUS =
  'COURSES_RESET_TRANSACTION_STATUS';

//lessons
export const LESSON_SET_LESSONS = 'LESSON_SET_LESSONS';
export const LESSON_SET_EDITING = 'LESSON_SET_EDITING';
export const LESSON_BY_ID = 'LESSON_BY_ID';
export const LESSON_SET_ALL_LESSONS = 'LESSON_SET_ALL_LESSONS';
export const LESSONS_SET_ASSESSMENT_QUESTIONS =
  'LESSONS_SET_ASSESSMENT_QUESTIONS';
export const LESSON_SET_POSITION_TRACKING = 'LESSON_SET_POSITION_TRACKING';
export const LESSON_SAVE_POSITION_TRACKING = 'LESSON_SAVE_POSITION_TRACKING';
export const LESSON_UPDATE_TRANSACTION_STATUS =
  'LESSON_UPDATE_TRANSACTION_STATUS';
export const LESSON_RESET_TRANSACTION_STATUS =
  'LESSON_RESET_TRANSACTION_STATUS';
export const LESSON_SET_EMPLOYEE_ASSIGNMENTS =
  'LESSON_SET_EMPLOYEE_ASSIGNMENTS';
export const LESSONS_SET_QUIZ_QUESTIONS = 'LESSONS_SET_QUIZ_QUESTIONS';

//tags
export const TAGS_SET_TAGS = 'TAGS_SET_TAGS';

//empployees
export const EMPLOYEE_UPDATE_PASSWORD = 'EMPLOYEE_UPDATE_PASSWORD';
export const RESET_UPDATE_PASSWORD = 'RESET_UPDATE_PASSWORD';
export const EMPLOYEE_SET_EMPLOYEES = 'EMPLOYEE_SET_EMPLOYEES';
export const EMPLOYEE_SET_EMPLOYEE_GROUPS = 'EMPLOYEE_SET_EMPLOYEE_GROUPS';
export const EMPLOYEE_SET_EMPLOYEES_WORKLOAD =
  'EMPLOYEE_SET_EMPLOYEES_WORKLOAD';
export const EMPLOYEE_UPDATE_TRANSACTION_STATUS =
  'EMPLOYEE_UPDATE_TRANSACTION_STATUS';
export const EMPLOYEE_RESET_TRANSACTION_STATUS =
  'EMPLOYEE_RESET_TRANSACTION_STATUS';
export const EMPLOYEE_SET_LEARNING_PROFILE = 'EMPLOYEE_SET_LEARNING_PROFILE';
export const EMPLOYEE_SET_ASSIGNMENTS_STATUS =
  'EMPLOYEE_SET_ASSIGNMENTS_STATUS';
export const EMPLOYEE_DATA_IS_LOADING = 'EMPLOYEE_DATA_IS_LOADING';

//learning plans
export const LEARNING_PLAN_NEW = 'LEARNING_PLAN_NEW';
export const LEARNING_PLAN_UPDATE = 'LEARNING_PLAN_UPDATE';
export const LEARNING_PLAN_SET_PLANS = 'LEARNING_PLAN_SET_PLANS';
export const LEARNING_PLAN_SAVE = 'LEARNING_PLAN_SAVE';
export const LEARNING_PLAN_UPDATE_TRANSACTION_STATUS =
  'LEARNING_PLAN_UPDATE_TRANSACTION_STATUS';
export const LEARNING_PLAN_RESET_TRANSACTION_STATUS =
  'LEARNING_PLAN_RESET_TRANSACTION_STATUS';
export const LEARNING_PLAN_SET_COURSES = 'LEARNING_PLAN_SET_COURSES';
export const LEARNING_PLAN_UPDATE_ASSIGNMENT =
  'LEARNING_PLAN_UPDATE_ASSIGNMENT';

//assessments
export const ASSESSMENT_SET_ASSESSMENT = 'ASSESSMENT_SET_ASSESSMENT';
export const ASSESSMENT_UPDATE_TRANSACTION_STATUS =
  'ASSESSMENT_UPDATE_TRANSACTION_STATUS';
export const ASSESSMENT_RESET_TRANSACTION_STATUS =
  'ASSESSMENT_RESET_TRANSACTION_STATUS';
export const ASSESSMENT_LIST_FAILED_QUIZ = 'ASSESSMENT_LIST_FAILED_QUIZ';
export const ASSESSMENT_MARK_QUIZ_AS_FAILED = 'ASSESSMENT_MARK_QUIZ_AS_FAILED';

//student
export const STUDENT_SET_IS_LOADING = 'STUDENT_SET_IS_LOADING';
export const STUDENT_SET_COURSE_COMPLETED_LESSONS =
  'STUDENT_SET_COURSE_COMPLETE_LESSONS';
export const STUDENT_MARK_LESSON_AS_COMPLETED =
  'STUDENT_MARK_LESSON_AS_COMPLETED';
export const STUDENT_SET_LEARNING_PLAY_LIST = 'STUDENT_SET_LEARNING_PLAY_LIST';
export const STUDENT_SET_ALL_LEARNING_PLAY_LIST =
  'STUDENT_SET_ALL_LEARNING_PLAY_LIST';
export const STUDENT_SET_LEARNING_PROFILE = 'STUDENT_SET_LEARNING_PROFILE';
export const STUDENT_UPDATE_TRANSACTION_STATUS =
  'STUDENT_UPDATE_TRANSACTION_STATUS';
export const STUDENT_RESET_TRANSACTION_STATUS =
  'STUDENT_RESET_TRANSACTION_STATUS';
export const STUDENT_WEEKLY_STATISTICS = 'STUDENT_WEEKLY_STATISTICS';

//user profile
export const USERPROFILE_SET_IS_LOADING = 'USERPROFILE_SET_IS_LOADING';
export const USERPROFILE_SET_CURRENT_USERPROFILE =
  'USERPROFILE_SET_CURRENT_USERPROFILE';
export const USERROLE_IS_LOADING = 'USERROLE_IS_LOADING';
export const USERROLE_SET_USERROLE = 'USERROLE_SET_USERROLE';

//assignments
export const STUDENT_SET_ASSIGNMENT = 'STUDENT_SET_ASSIGNMENT';
export const STUDENT_SET_COMPLETED_COURSES = 'STUDENT_SET_COMPLETED_COURSES';
export const STUDENT_SET_COMPLETED_LEARNING_PLANS =
  'STUDENT_SET_COMPLETED_LEARNING_PLANS';

// notifications
export const NOTIFICATIONS_SET_NOTIFICATIONS =
  'NOTIFICATIONS_SET_NOTIFICATIONS';
export const NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED =
  'NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED';
export const NOTIFICATIONS_DISMISS_NOTIFICATION =
  'NOTIFICATIONS_DISMISS_NOTIFICATION';

// companies
export const COMPANY_SET_COMPANIES = 'COMPANY_SET_COMPANIES';
export const COMPANY_IS_LOADING = 'COMPANY_IS_LOADING';
export const INDUSTRIES_SET_COMPANIES = 'INDUSTRIES_SET_COMPANIES';
export const INDUSTRY_IS_LOADING = 'INDUSTRY_IS_LOADING';
export const COMPANY_SET_COMPANY = 'COMPANY_SET_COMPANY';
export const COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE =
  'COMPANYPROFILE_SET_CURRENT_COMPANYPROFILE';
export const COMPANIES_GET_ADMINS ='COMPANIES_GET_ADMINS';
export const SET_ADMIN_TO_COMPANY_IS_LOADING = 'SET_ADMIN_TO_COMPANY_IS_LOADING';
export const COMPANIES_SET_ADMIN = 'COMPANIES_SET_ADMIN';
export const COMPANY_REMOVE_ADMIN = 'COMPANY_REMOVE_ADMIN';
export const COMPANY_GET_EMPLOYEE_BY_QUERY = 'COMPANY_GET_EMPLOYEE_BY_QUERY';
export const COMPANIES_IS_LOADING = 'COMPANIES_IS_LOADING';

// jobs
export const JOBLISTER_IS_LOADING = 'JOBLISTER_IS_LOADING';
export const JOBLISTER_SET_CATEGORIES = 'JOBLISTER_SET_CATEGORIES';
export const JOBLISTER_SAVE_POST_JOB = 'JOBLISTER_SAVE_POST_JOB';
export const JOBLISTER_SET_JOB_DETAIL = 'JOBLISTER_SET_JOB_DETAIL';
export const JOBLISTER_SET_JOB_DETAIL_PREVIEW =
  'JOBLISTER_SET_JOB_DETAIL_PREVIEW';
export const JOBLISTER_UPDATE_JOB_DETAIL = 'JOBLISTER_UPDATE_JOB_DETAIL';
export const JOBLISTER_SET_JOBS = 'JOBLISTER_SET_JOBS';
export const JOBLISTER_SET_JOB_TYPES = 'JOBLISTER_SET_JOB_TYPES'

// countries
export const COUNTRIES_IS_LOADING = 'COUNTRIES_IS_LOADING';
export const STATES_IS_LOADING = 'STATES_IS_LOADING';
export const CITIES_IS_LOADING = 'CITIES_IS_LOADING';
export const COUNTRIES_SET_COUNTRIES = 'COUNTRIES_SET_COUNTRIES';
export const STATES_SET_STATES = 'STATES_SET_STATES';
export const CITIES_SET_CITIES = 'CITIES_SET_CITIES';

//app
export const APP_SET_INDUSTRY_CATEGORIES = 'APP_SET_INDUSTRY_CATEGORIES';


//filters
export const SET_FILTERS = 'SET_FILTERS'