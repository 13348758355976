import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import DragDropList, {
  DraggableListItem
} from '../../components/DragDropList/DragDropList';
import {
  EntityTypeEnum,
  FormModeEnum,
  TransactionStatusEnum
} from '../../core/enums';
import { ApplicationState } from '../../store';
import { LearningPlan } from '../../entities/LearningPlan';
import { Course } from '../../entities/Course';
import ModalPage from '../../components/core/ModalPage/ModalPage';
import CreateLearningPlan from './CreateLearningPlan';
import SelectCourses from '../courses/SelectCourses';
import { actionCreators } from '../../actions/learningPlans';
import LearningPlanPageHeader from '../../components/LearningPlanPageHeader/LearningPlanPageHeader';
import { LibraryTypesEnum } from '../../core/enums';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import { Typography } from '@mui/material';
import ConfirmationModal from '../../components/core/ConfirmationModal/ConfirmationModal';
import { useTranslation } from 'react-i18next';

export interface LearningPlanDetailsProps {}

const LearningPlanDetails: React.FC<LearningPlanDetailsProps> = () => {
  const [learningPlan, setLearningPlan] = useState<LearningPlan>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openSelectModal, setOpenSelectModal] = useState<boolean>(false);
  const [displaySaveOrderButton, setDisplaySaveOrderButton] = useState<boolean>(
    false
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState<
    boolean
  >(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [list, setList] = React.useState<DraggableListItem[]>([]);
  const selectedCoursesRef = useRef<string[]>([]);
  const saveSourceRef = useRef<string>('');
  const currentLearningPlan = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlan
  );
  const learningPlanCourses = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlanCourses
  );
  const transactionSatus = useSelector(
    (state: ApplicationState) => state.learningPlans?.transactionStatus
  );
  const { t } = useTranslation(['common', 'learningPlans', 'courses']);

  const source = 'LearningPlanDetails';

  useEffect(() => {
    if (currentLearningPlan) setLearningPlan(currentLearningPlan);
    getLearningPlansCourses();

    return () => {
      dispatch(actionCreators.setLearningPlanCourses([]));
    };
  }, [currentLearningPlan, currentLearningPlan?.entries.length]);

  useEffect(() => {
    setList(mapCourses(learningPlanCourses!));
  }, [learningPlanCourses?.length]);

  useEffect(() => {
    if (transactionSatus === TransactionStatusEnum.Failed)
      alert(t('anExceptionOccurred', { ns: 'common' }));
    else if (
      transactionSatus === TransactionStatusEnum.Successfull &&
      saveSourceRef.current === source
    )
      setDisplaySaveOrderButton(false);
    else if (
      transactionSatus === TransactionStatusEnum.Successfull &&
      saveSourceRef.current === 'publish'
    )
      history.goBack();

    if (transactionSatus !== TransactionStatusEnum.None)
      dispatch(actionCreators.resetLearningPlanTransactionStatus());

    return () => {
      dispatch(actionCreators.resetLearningPlanTransactionStatus());
    };
  }, [transactionSatus]);

  const getLearningPlansCourses = () => {
    if (
      currentLearningPlan?.entries &&
      currentLearningPlan?.entries.some(e => e.courseId.length > 0)
    ) {
      const courses = currentLearningPlan?.entries.filter(
        e => e.courseId.length > 0
      );

      const courseIds = courses.map(course => course.courseId);
      const courseIdsStr = courseIds.join(',');
      dispatch(actionCreators.getLearningPlanCourses(courseIdsStr));
    } else if (learningPlanCourses?.length! > 0)
      dispatch(actionCreators.setLearningPlanCourses([]));
  };

  const handleBack = () => {
    history.goBack();
  };

  const mapCourses = (courses: Course[]) => {
    let res: DraggableListItem[] = [];
    let learningPlanCourses = currentLearningPlan?.entries.filter(
      e => e.courseId.length > 0
    );

    if (learningPlanCourses) {
      learningPlanCourses = learningPlanCourses.sort(
        (lp1, lp2) => lp1.order - lp2.order
      );
    }

    learningPlanCourses?.forEach(lpCourse => {
      const course = courses.find(course => course.id === lpCourse.courseId);

      if (course)
        res.push({
          id: course.id,
          title: course.title,
          videosCount: course.videosCount,
          flashCardsCount: course.flashCardsCount,
          quizesCount: course.quizesCount
        });
    });

    return res;
  };

  const getSelectedCoursesIds = (coursesIds: string[]) => {
    selectedCoursesRef.current = coursesIds;
  };

  const getItemsFromDDList = (items: DraggableListItem[]) => {
    if (learningPlan && learningPlan.entries) {
      const entries = [...learningPlan.entries];
      const orderedEntries = entries.map(entry => {
        return {
          ...entry,
          order: items.findIndex(item => item.id === entry.courseId) + 1
        };
      });

      const courseEntries = currentLearningPlan?.entries.filter(
        e => e.courseId.length > 0
      );
      let isDifferent: boolean = false;
      courseEntries?.forEach(entry => {
        if (
          !orderedEntries.find(
            orderedEntry =>
              orderedEntry.courseId === entry.courseId &&
              orderedEntry.order === entry.order
          )
        )
          isDifferent = true;
      });
      const learningPlanTemp = { ...learningPlan, entries: orderedEntries };
      setLearningPlan(learningPlanTemp);
      setDisplaySaveOrderButton(isDifferent);
    }
  };

  const handleDeleteCourse = (courseId: string) => {
    if (window.confirm(t('deleteCourseConfirmation', { ns: 'courses' }))) {
      saveSourceRef.current = source;
      dispatch(
        actionCreators.deleteLearningPlanCourses(learningPlan!.id, courseId)
      );
    }
  };

  const handleSaveCourseOrder = () => {
    saveLearningPlan(learningPlan!);
  };

  const saveCourses = () => {
    if (!selectedCoursesRef.current.length) {
      alert(t('noCoursesSelected', { ns: 'courses' }));
      return;
    }

    if (learningPlan) {
      const entries = [...learningPlan.entries];

      selectedCoursesRef.current.forEach(courseId => {
        entries!.push({
          order: 0,
          lessonId: '',
          courseId: courseId,
          assessmentId: ''
        });
      });

      const learningPlanTemp = { ...learningPlan, entries };
      saveLearningPlan(learningPlanTemp);
      setOpenSelectModal(false);
    }
  };

  const saveLearningPlan = (plan: LearningPlan) => {
    saveSourceRef.current = source;
    dispatch(actionCreators.saveLearningPlan(plan, ''));
  };

  const deleteLearningPlanAction = async () => {
    if (currentLearningPlan) {
      await dispatch(actionCreators.deleteLearningPlan(currentLearningPlan.id));
      setIsConfirmationModalOpen(false);
      history.push('/learning-plans');
    }
  };

  const deleteFullLearningPlanAction = async () => {
    if (currentLearningPlan) {
      await dispatch(
        actionCreators.deleteFullLearningPlan(currentLearningPlan.id)
      );
      setIsConfirmationModalOpen(false);
      history.push('/learning-plans');
    }
  };

  const getDeleteAllButton = () => {
    return (
      <BasicButton onClick={deleteFullLearningPlanAction} color="primary">
        {t('deleteAll', { ns: 'common' })}
      </BasicButton>
    );
  };

  const handleDelete = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleClose = () => {
    history.goBack();
  };

  const handlePublish = () => {
    if (learningPlan) {
      saveSourceRef.current = 'publish';
      dispatch(
        actionCreators.saveLearningPlan(
          {
            ...learningPlan,
            isDraft: false
          },
          ''
        )
      );
    }
  };

  const editLearningPlanStep1 = () => {
    setOpenEditModal(true);
  };

  const buttonsContainerStyle = {
    display: 'flex',
    flexDirection: 'row' as 'row',
    minHeight: '100px',
    paddingTop: '0'
  };

  if (!learningPlan) return <></>;

  return (
    <PageContainer>
      <ConfirmationModal
        show={isConfirmationModalOpen}
        showSave={true}
        showCancel={true}
        maxWidth="sm"
        saveText={t('deleteLP', { ns: 'learningPlans' })}
        cancelText={t('cancel', { ns: 'common' })}
        title={t('learningPlanDeletion', { ns: 'learningPlans' })}
        onSave={deleteLearningPlanAction}
        onCancel={() => setIsConfirmationModalOpen(false)}
        extraOptions={[getDeleteAllButton()]}
      >
        <p>
          {t('removeLPConfirmation', { ns: 'learningPlans' })}: "
          {currentLearningPlan?.name}
          "?
        </p>
      </ConfirmationModal>
      <LearningPlanPageHeader
        title={t('editLP', { ns: 'learningPlans' })}
        goBackLinkText={t('back', { ns: 'common' })}
        buttonText={
          learningPlan?.isDraft
            ? t('publish', { ns: 'common' })
            : t('close', { ns: 'common' })
        }
        handleGoBack={handleBack}
        handleButtonClick={() =>
          learningPlan?.isDraft ? handlePublish() : handleClose()
        }
        handleDelete={handleDelete}
      />
      <Row className="pt-3">
        <Col sm={12}>
          <DragDropList
            itemType={EntityTypeEnum.Course}
            items={list}
            deleteItem={handleDeleteCourse}
            getItems={getItemsFromDDList}
          >
            <div style={buttonsContainerStyle}>
              <BasicButton
                onClick={() => {
                  setOpenSelectModal(true);
                }}
                color="primary"
                style={buttonStyle}
              >
                <Typography sx={fontButton}>
                  {t('selectCourses', { ns: 'courses' })}
                </Typography>
              </BasicButton>
              <span className="ps-2" />
              {displaySaveOrderButton && (
                <BasicButton
                  onClick={handleSaveCourseOrder}
                  color="secondary"
                  width="175px"
                  height="45px"
                >
                  {t('saveCourseOrder', { ns: 'courses' })}
                </BasicButton>
              )}
            </div>
          </DragDropList>
        </Col>
      </Row>
      <Row>
        <Col className="pt-3">
          <BasicButton
            onClick={editLearningPlanStep1}
            color="secondary"
            width="200px"
            height="45px"
          >
            {t('editLPDetails', { ns: 'learningPlans' })}
          </BasicButton>
        </Col>
      </Row>
      <ModalPage
        title={t('editLP', { ns: 'learningPlans' })}
        onSave={() => {}}
        onCancel={() => setOpenEditModal(false)}
        show={openEditModal}
        showSave={false}
        showCancel={false}
        saveText={t('save', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        variant="lg"
        contentClassName="modal-body-auto-height"
        backdrop="static"
        keyboard={false}
      >
        <CreateLearningPlan
          formMode={FormModeEnum.Edit}
          showModal={setOpenEditModal}
        />
      </ModalPage>
      <ModalPage
        title={t('addCourses', { ns: 'courses' })}
        onSave={saveCourses}
        onCancel={() => setOpenSelectModal(false)}
        show={openSelectModal}
        showSave={true}
        showCancel={false}
        saveText={t('save', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        variant="lg"
        backdrop="static"
        keyboard={false}
      >
        <SelectCourses
          currentCourses={learningPlanCourses!}
          coursesLibraryType={LibraryTypesEnum.CompanyAndBuildwitt}
          getSelectedCoursesIds={getSelectedCoursesIds}
        />
      </ModalPage>
    </PageContainer>
  );
};

export default LearningPlanDetails;
