import React, { CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { DueLearningPlan } from '../../entities/LearningPlaylist';
import LearningPlanCard from '../../components/LearningPlanCard/LearningPlanCard';
import { LearningPlan } from '../../entities/LearningPlan';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { useTranslation } from 'react-i18next';
interface LearningPlanAssignmentsProps {
  dueLearningPlans: DueLearningPlan[];
}

const LearningPlanAssignments: React.FC<LearningPlanAssignmentsProps> = ({
  dueLearningPlans
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);

  const handleStartLearningPlan = (
    learningPlan: LearningPlan,
    dueDate: string,
    assignmentId: string
  ) => {
    dispatch(learningPlanActions.setCurrentLearningPlan(learningPlan));
    history.push(`/learning-plans/preview/${learningPlan.id}`, {
      dueDate,
      assignmentId
    });
  };

  return (
    <>
      {dueLearningPlans.map(dueItem => {
        const {
          learningPlan,
          isComplete,
          dueDateMoment,
          assignmentId
        } = dueItem;
        const { id } = learningPlan;
        const dueDate = dueDateMoment!.format('MM.DD.YY');

        return (
          <Grid item xs={1} md={12} lg={8} key={id}>
            <LearningPlanCard
              plan={learningPlan}
              showEdit={false}
              isComplete={isComplete ?? false}
              dueDate={dueDate}
            >
              <div>
                <BasicButton
                  onClick={() =>
                    handleStartLearningPlan(learningPlan, dueDate, assignmentId)
                  }
                  color={isComplete ? 'secondary' : 'primary'}
                  style={startButtonStyle}
                >
                  {isComplete
                    ? t('revisit', { ns: 'common' })
                    : t('start', { ns: 'common' })}
                </BasicButton>
              </div>
            </LearningPlanCard>
          </Grid>
        );
      })}
    </>
  );
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

export default LearningPlanAssignments;
