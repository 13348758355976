import * as yup from 'yup';

export const EducationValidationSchema = yup
  .object({
    school: yup.string().required('School is required.'),
    fieldOfStudy: yup.string(),
    degreeType: yup.number(),
    graduationDate: yup.string().required('Completion year is required.'),
    details: yup.string()
  })
  .required();
