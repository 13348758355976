import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { actionCreators as tagActions } from '../../actions/tags';
import { Lesson } from '../../entities/Lesson';
import { ApplicationState } from '../../store';
import LessonForm from '../../components/LessonForm/LessonForm';
import { LessonFormModel } from '../../entities/LessonFormModel';
import { useTranslation } from 'react-i18next';

const emptyLesson: Lesson = {
  id: '',
  title: '',
  description: '',
  order: 0,
  companyId: '',
  courseId: '',
  contentMedium: 0,
  videoUrl: '',
  videoId: '',
  thumbnailUrl: '',
  learningMaterialUrl: '',
  externalVideoUrl: '',
  lessonType: '',
  tags: [],
  subtitles: [],
  isVideoEncoding: false,
  isEncodingFailed: false
};
export interface CreateLessonProps {
  closeModal: () => void;
  createError: string;
  uploadVideoProgress: number;
  handleSaveLesson: (lessonModel: LessonFormModel, isEdit: boolean) => void;
}

const CreateLesson: React.FC<CreateLessonProps> = ({
  closeModal,
  createError,
  uploadVideoProgress,
  handleSaveLesson
}) => {
  const dispatch = useDispatch();
  const companyTags = useSelector(
    (state: ApplicationState) => state.tags?.tags
  );
  const { t } = useTranslation(['common']);

  React.useEffect(() => {
    if (!companyTags?.length) dispatch(tagActions.requestTags());
  }, []);

  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons!.currentLesson
  );

  return (
    <>
      <LessonForm
        lesson={currentLesson || emptyLesson}
        companyTags={companyTags || []}
        handleSaveLesson={handleSaveLesson}
        onSaveComplete={() => closeModal()}
        isEdit={false}
        showProgress={true}
        progressValue={uploadVideoProgress}
      ></LessonForm>
      {createError && <span style={{ color: 'red' }}>{createError}</span>}
    </>
  );
};

export default CreateLesson;
