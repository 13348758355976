import React, { CSSProperties } from 'react';
import { Option } from '../../entities/Assessment';
import redCross from '../../assets/red-cross.png';
import greenCheck from '../../assets/green-check.png';
import { styled } from '@mui/system';

import { AnswerStatus, Question2Props, renderFeedback } from './Types';
import { Col, Row } from 'react-bootstrap';
import FormLabel from '../core/FormLabel/FormLabel';
import { CorrectAnswerNotice } from './CorrectAnswerNotice';
import { useTranslation } from 'react-i18next';

export interface MultipleChoiceQuestion2Props extends Question2Props<Option> {
  imageUrl?: string;
  options: Option[];
}

const imgStyles: CSSProperties = {
  marginTop: '12px',
  marginBottom: '12px',
  maxWidth: '80%'
};

const renderStatusIcon = (
  answerStatus: AnswerStatus,
  option: Option,
  correctAnswer: Option,
  receivedAnswer?: Option
) => {
  if (
    answerStatus !== AnswerStatus.AnswerSubmitted ||
    receivedAnswer === undefined
  ) {
    return null;
  }

  if (receivedAnswer!.id === option.id) {
    const icon = correctAnswer.id === option.id ? greenCheck : redCross;
    return <img src={icon} alt="status" style={{ marginRight: '14px' }} />;
  }

  return null;
};

const MultipleChoiceQuestion2: React.FC<MultipleChoiceQuestion2Props> = ({
  questionText,
  showFeedback,
  receivedAnswer,
  correctAnswer,
  answerStatus,
  onAnswerReceived,
  imageUrl,
  options
}) => {
  const { t } = useTranslation(['assessments']);
  const hasValidImg = !!imageUrl;

  const onOptionSelected = (selectedOption: Option) => {
    onAnswerReceived(selectedOption);
  };

  return (
    <>
      <Row>
        <Col>
          <FormLabel>{t('selectCorrectAnswer', {ns: 'assessments'})}</FormLabel>
        </Col>
      </Row>
      {hasValidImg && (
        <Row>
          <Col className='mb-3'>
            <img style={imgStyles} src={imageUrl} />
          </Col>
        </Row>
      )}
      <Row>
        <Col className='mb-3'>
          <h4>{questionText}</h4>
        </Col>
      </Row>
      <Row>
        {options?.map((option: Option) => {
          return (
            <Col sm={6} className={`mb-4`} key={option.id}>
              <OptionWrapper
                option={option}
                answerStatus={answerStatus}
                correctAnswer={correctAnswer}
                receivedAnswer={receivedAnswer}
                onClick={() => onOptionSelected(option)}
                className="d-flex flex-row p-4 justify-content-start align-items-center"
              >
                {renderStatusIcon(
                  answerStatus,
                  option,
                  correctAnswer,
                  receivedAnswer
                )}
                <div>{option.optionText}</div>
              </OptionWrapper>
            </Col>
          );
        })}
      </Row>
      <CorrectAnswerNotice>
        {receivedAnswer !== undefined &&
          showFeedback &&
          renderFeedback<string>(
            correctAnswer.id,
            options!.find(option => option.isCorrect)!.optionText,
            t('correctAnswer', {ns: 'assessments'}),
            t('isTheCorrectAnswer', {ns: 'assessments'}),
            receivedAnswer.id
          )}
      </CorrectAnswerNotice>
    </>
  );
};

const escapeProps = [
  'option',
  'answerStatus',
  'correctAnswer',
  'receivedAnswer'
];

const OptionWrapper = styled('div', {
  shouldForwardProp: prop => !escapeProps.includes(prop as string)
})<{
  option: Option;
  answerStatus: AnswerStatus;
  correctAnswer: Option;
  receivedAnswer?: Option;
}>(props => {
  const { option, theme, answerStatus, correctAnswer, receivedAnswer } = props;
  let borderColor = theme.palette.grey[600];
  let backgroundColor = theme.palette.grey[600];
  let color = theme.palette.common.white;

  if (receivedAnswer !== undefined && option.id === receivedAnswer.id) {
    switch (answerStatus) {
      case AnswerStatus.NoAnswer:
        borderColor = theme.palette.grey[600];
        break;
      case AnswerStatus.AnsweredSelected:
        borderColor = 'orange';
        backgroundColor = '#FF9E00';
        color = theme.palette.grey[800];
        break;
      case AnswerStatus.AnswerSubmitted:
        borderColor =
          correctAnswer.id === receivedAnswer.id
            ? theme.palette.success.main
            : theme.palette.error.light;

        backgroundColor = theme.palette.grey[900];
        break;
    }
  }

  return {
    backgroundColor: backgroundColor,
    color: color,
    fontSize: '24px',
    cursor: !props.receivedAnswer ? 'pointer' : 'auto',
    border: `1px solid ${borderColor}`,
    borderRadius: '5px',
    height: '158px'
  };
});

export default MultipleChoiceQuestion2;
