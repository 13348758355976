import React, { CSSProperties, useState, useEffect } from 'react';
import { Grid, Typography, useMediaQuery, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { Page } from '../../components/Pages';
import PublicJobsList from './PublicJobsList';
import FiltersJobs from './FiltersJobs';
import jobsBanner from '../../assets/jobsBanner.png';
import { useTheme } from '@mui/material/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FiltersDTO } from '../../entities/Dto/FiltersDTO';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { Stack } from '@mui/material';
import { BasicButtonTypeEnum } from '../../core/enums';
import { JobsList } from '../../entities/JobsList';
import { actionCreators as jobsActions } from '../../actions/jobs';

const PublicJobsPost = () => {
  const dispatch = useDispatch();
  const { control, handleSubmit, watch, reset, formState, setValue } =
    useForm<FiltersDTO>();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [jobs, setJobs] = useState<JobsList[]>([]);

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const jobsList = useSelector(
    (state: ApplicationState) => state.jobs?.jobsList
  );

  const jobsFilters = useSelector(
    (state: ApplicationState) => state.jobs?.filters
  );

  const [applyFilter, setApplyFilter] = useState(true);

  const onSubmit: SubmitHandler<FiltersDTO> = data => {
    const params = Object.entries(data)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    dispatch(jobsActions.getJobs(params));
    dispatch(jobsActions.setJobsFilter(data));
    setApplyFilter(false);
  };

  const resetFilters = () => {
    dispatch(jobsActions.resetJobsFilter());
    dispatch(jobsActions.getJobs());
    reset({
      Keyword: '',
      Country: '',
      State: '',
      Category: '',
      Type: ''
    });
    setApplyFilter(true);
  };

  useEffect(() => {
    if (jobsList) {
      setJobs(jobsList);
    }
  }, [jobsList]);

  useEffect(() => {
    if (jobsFilters) {
      reset({
        Keyword: jobsFilters.Keyword,
        Country: jobsFilters.Country,
        State: jobsFilters.State,
        Category: jobsFilters.Category,
        Type: jobsFilters.Type
      });
    }
  }, [jobsFilters]);

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <Page>
      <div style={matches ? divBannerMobile : divBannerWeb}>
        <Grid container spacing={4} style={{ marginTop: '10vh' }}>
          <Grid item xs={12} md={12} sx={{ width: '100%' }}>
            <div
              style={
                matches ? containerMobileBannerStyle : containerBannerStyle
              }
            >
              <Typography
                gutterBottom
                style={matches ? textBannerMobileStyle : textBannerStyle}
              >
                A better future
              </Typography>
              <Typography
                gutterBottom
                style={matches ? textBannerMobileStyle : textBannerStyle}
              >
                in dirt starts right now
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        spacing={4}
        style={{ marginTop: matches ? '35vh' : '30vh' }}
      >
        <Grid item xs={12} md={4}>
          <FiltersJobs
            submitHandler={onSubmit}
            watch={watch}
            handleSubmit={handleSubmit}
            setValue={setValue}
            control={control}
            formState={formState}
            jobsLenght={jobs!.length}
            bottomActions={
              <Stack direction="row" sx={{ textAlign: 'center' }} spacing={2}>
                {applyFilter && (
                  <Button variant="text" type={BasicButtonTypeEnum.Submit}>
                    <Typography style={spanStyle}>Apply Filters</Typography>
                  </Button>
                )}

                {!applyFilter && (
                  <Button variant="text" onClick={resetFilters}>
                    <Typography style={spanStyle}>Reset Filters</Typography>
                  </Button>
                )}
              </Stack>
            }
          />
        </Grid>
        <Grid item xs={12} md={8} style={listJobsStyle}>
          {jobs!.map(job => (
            <PublicJobsList key={job.id} jobID={job.id} jobInfo={job} />
          ))}
        </Grid>
      </Grid>
    </Page>
  );
};

const listJobsStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%'
};

const containerBannerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10vh'
};

const containerMobileBannerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '2vh',
  marginTop: '5vh'
};

const textBannerStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '60px',
  lineHeight: '60px',
  color: '#FFFFFF'
};

const textBannerMobileStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '36px',
  lineHeight: '40px',
  color: '#FFFFFF'
};

const divBannerWeb: CSSProperties = {
  background: `url('${jobsBanner}'`,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '40vh'
};

const divBannerMobile: CSSProperties = {
  background: `url('${jobsBanner}'`,
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundPosition: '70% 50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '40vh'
};

const spanStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#242926',
  textTransform: 'capitalize'
};

export default PublicJobsPost;
