import axios from 'axios';
import { AppThunkAction } from '../store/index';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import config from './../config';
import { HttpStatusEnum, TransactionStatusEnum } from '../core/enums';
import { CompletedLessonInfo } from '../entities/CompletedLessonInfo';
import {
  AssignmentsPlaylistItems,
  EmployeePlayList,
  LearningPlaylistItems
} from '../entities/LearningPlaylist';
import {
  STUDENT_MARK_LESSON_AS_COMPLETED,
  STUDENT_SET_COURSE_COMPLETED_LESSONS,
  STUDENT_SET_IS_LOADING,
  STUDENT_SET_LEARNING_PLAY_LIST,
  STUDENT_SET_ALL_LEARNING_PLAY_LIST,
  STUDENT_UPDATE_TRANSACTION_STATUS,
  STUDENT_RESET_TRANSACTION_STATUS,
  STUDENT_SET_ASSIGNMENT,
  STUDENT_SET_COMPLETED_COURSES,
  STUDENT_SET_COMPLETED_LEARNING_PLANS,
  STUDENT_SET_LEARNING_PROFILE,
  STUDENT_WEEKLY_STATISTICS
} from './actionTypes';
import { CompletedCourseInfo } from '../entities/CompletedCourseInfo';
import { CompletedLearningPlanInfo } from '../entities/CompletedLearningPlanInfo';
import { ResultDTO } from './../entities/Dto/responseDTO';
import { LearningProfile } from '../entities/LearningProfile';
import { WeeklyStatistics } from '../entities/WeeklyStatistics';
import { WeeklyStatisticsDefault } from '../core/constants';

export const actionCreators = {
  getCompletedLessonsByCourseId: (
    courseId: string,
    revisionNumber: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      let completedLessons: CompletedLessonInfo[] = [];
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}lessonprogress/${courseId}/${revisionNumber}`
        );

        completedLessons = res.status === HttpStatusEnum.OK ? res.data : null;

        dispatch({
          type: STUDENT_SET_COURSE_COMPLETED_LESSONS,
          completedLessons: completedLessons
        });
      } catch (e) {
        throw e;
      }

      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: false
      });
    }
  },
  markLessonAsCompleted: (
    lessonId: string,
    courseId?: string,
    courseRevisionNumber?: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const completedLesson: CompletedLessonInfo = {
        completionDateUtc: new Date(),
        lessonId,
        courseId,
        courseRevisionNumber
      };

      let wasCourseCompleted = false;

      try {
        const res = await axios.post(
          `${config.STUDENT_API_URL!}lessonprogress/${lessonId}`,
          {
            courseId,
            courseRevisionNumber
          }
        );

        wasCourseCompleted = res.data;

        dispatch({
          type: STUDENT_MARK_LESSON_AS_COMPLETED,
          completedLesson
        });
      } catch (e) {
        throw e;
      }

      dispatch(appActions.setIsLoading(false));
      return wasCourseCompleted;
    }
  },
  getEmployeePlaylist: (
    employeeId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      let resStatus: number = 0;
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}employeeplaylist/${employeeId}/assignments`
        );

        resStatus = res.status;
        dispatch({
          type: STUDENT_SET_LEARNING_PLAY_LIST,
          learningPlaylistIems:
            resStatus === HttpStatusEnum.OK ? res.data : null
        });
      } catch (e) {
      } finally {
        dispatch({
          type: STUDENT_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  getAllAssignments: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      let resStatus: number = 0;
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}employeeplaylist/assignments/all`
        );
        resStatus = res.status;
        dispatch({
          type: STUDENT_SET_ASSIGNMENT,
          assignmentsItems: resStatus === HttpStatusEnum.OK ? res.data : null
        });
      } catch (e) {
      } finally {
        dispatch({
          type: STUDENT_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  getCompletedCoursesByLearningPlanId: (
    learningPlanId: string,
    revisionNumber: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      let resStatus: number = 0;
      let completedCourses: CompletedCourseInfo[] = [];
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}courseprogress/${learningPlanId}/${revisionNumber}`
        );
        resStatus = res.status;
        if (resStatus === HttpStatusEnum.OK) completedCourses = res.data;

        dispatch({
          type: STUDENT_SET_COMPLETED_COURSES,
          completedCourses: completedCourses
        });
      } catch (e) {
      } finally {
        dispatch({
          type: STUDENT_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  getAllEmployeePlaylist: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    const initialChunk = 30;

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}employeeplaylist/assignmentsPaginate/all?offset=0&limit=${initialChunk}`
        );

        dispatch({
          type: STUDENT_SET_ALL_LEARNING_PLAY_LIST,
          employeesPlaylists: res.status === HttpStatusEnum.OK ? res.data : null
        });

        if (res.data.itemTotal > initialChunk) {
          const rest = await axios.get(
            `${config.STUDENT_API_URL!}employeeplaylist/assignmentsPaginate/all?offset=${initialChunk}&limit=${
              res.data.itemTotal
            }`
          );

          const allPlayList = { ...res.data };
          allPlayList.result = [...allPlayList.result, ...rest.data.result];

          dispatch({
            type: STUDENT_SET_ALL_LEARNING_PLAY_LIST,
            employeesPlaylists:
              res.status === HttpStatusEnum.OK ? allPlayList : null
          });
        }
      } catch (e) {
      } finally {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  getCompletedLearningPlans: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      let completedLearningPlans: CompletedLearningPlanInfo[] = [];
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}learningplanprogress/all`
        );

        if (res.status === HttpStatusEnum.OK) completedLearningPlans = res.data;

        dispatch({
          type: STUDENT_SET_COMPLETED_LEARNING_PLANS,
          completedLearningPlans: completedLearningPlans
        });
      } catch (e) {
      } finally {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  createLearningProfile: (
    employeeId: string,
    companyId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));

      const employeeWorkloadDto: LearningProfile = {
        employeeId,
        companyId
      };

      try {
        const res = await axios.post(
          `${config.STUDENT_API_URL!}employeeLearningProfile`,
          {
            ...employeeWorkloadDto
          }
        );

        dispatch({
          type: STUDENT_SET_LEARNING_PROFILE,
          learningProfile: res.status === HttpStatusEnum.OK ? res.data : null
        });
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  getWeeklyStatistics: (
    startDate: string,
    endDate: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });
      let weeklyStatistics: WeeklyStatistics[] = [];
      let weeklyStatisticsTransform: WeeklyStatistics[] = [
        ...WeeklyStatisticsDefault
      ];
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL!}courseprogress/lessons/statistics?startDate=${startDate}&endDate=${endDate}`
        );
        if (res.status === HttpStatusEnum.OK) weeklyStatistics = res.data;

        // Transform
        if (weeklyStatistics.length > 0) {
          for (let statistic of weeklyStatistics) {
            let dayIndex = weeklyStatisticsTransform.findIndex(
              item => item.dayOfWeek === statistic.dayOfWeek
            );
            weeklyStatisticsTransform[dayIndex] = statistic;
          }
        }

        dispatch({
          type: STUDENT_WEEKLY_STATISTICS,
          weeklyStatistics: weeklyStatisticsTransform
        });
      } catch (e) {
        dispatch({
          type: STUDENT_WEEKLY_STATISTICS,
          weeklyStatistics: [...WeeklyStatisticsDefault]
        });
      } finally {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  setCompletedCourses: (completedCourses: CompletedCourseInfo[]) => ({
    type: STUDENT_SET_COMPLETED_COURSES,
    completedCourses: completedCourses
  }),
  resetTransactionStatus: () => ({
    type: STUDENT_RESET_TRANSACTION_STATUS
  })
};

export interface SetStudentLoading {
  type: 'STUDENT_SET_IS_LOADING';
  isLoading: boolean;
}

export interface GetCompletedLessonsByCourseId {
  type: 'STUDENT_SET_COURSE_COMPLETE_LESSONS';
  completedLessons: CompletedLessonInfo[];
}

export interface MarkLessonAsCompleted {
  type: 'STUDENT_MARK_LESSON_AS_COMPLETED';
  completedLesson: CompletedLessonInfo;
}

export interface SetEmployeePlaylist {
  type: 'STUDENT_SET_LEARNING_PLAY_LIST';
  learningPlaylistIems: LearningPlaylistItems;
}

export interface SetEmployeersPlaylists {
  type: 'STUDENT_SET_ALL_LEARNING_PLAY_LIST';
  employeesPlaylists: ResultDTO<EmployeePlayList[]>;
}

export interface UpdateStudentTransactionStatusAction {
  type: 'STUDENT_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetStudentTransactionStatusAction {
  type: 'STUDENT_RESET_TRANSACTION_STATUS';
}

export interface SetAssignmentPlaylist {
  type: 'STUDENT_SET_ASSIGNMENT';
  assignmentsItems: AssignmentsPlaylistItems[];
}

export interface GetStudentWeeklyStatistics {
  type: 'STUDENT_WEEKLY_STATISTICS';
  weeklyStatistics: WeeklyStatistics[];
}
export interface GetCompletedCourses {
  type: 'STUDENT_SET_COMPLETED_COURSES';
  completedCourses: CompletedCourseInfo[];
}

export interface GetCompletedLearningPlans {
  type: 'STUDENT_SET_COMPLETED_LEARNING_PLANS';
  completedLearningPlans: CompletedLearningPlanInfo[];
}

export interface SetLearningProfile {
  type: 'STUDENT_SET_LEARNING_PROFILE';
  learningProfile: LearningProfile;
}

export type KnownAction =
  | SetLoadingAction
  | GetCompletedLessonsByCourseId
  | SetStudentLoading
  | SetEmployeePlaylist
  | SetEmployeersPlaylists
  | UpdateStudentTransactionStatusAction
  | ResetStudentTransactionStatusAction
  | SetAssignmentPlaylist
  | GetCompletedCourses
  | GetCompletedLearningPlans
  | MarkLessonAsCompleted
  | SetLearningProfile
  | GetStudentWeeklyStatistics;
