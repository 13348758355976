import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { actionCreators } from '../../../actions/userProfile';
import { CertificationDto } from '../../../entities/UserProfile';

import { UserProfileDetailParams } from '../../../entities/Common';
import FallbackLoader from '../../../components/FallbackLoader/FallbackLoader';
import { ApplicationState } from '../../../store';
import { SimpleFormPage } from '../../../components/Pages';
import { CertificationForm } from './CertificationForm';
import { FormDualButtons } from '../FormDualButtons';
import { yupResolver } from '@hookform/resolvers/yup';
import { CertificationValidationSchema } from './CertificationValidationSchema';

export const CertificationSectionCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userProfileId } = useParams<UserProfileDetailParams>();

  const { control, handleSubmit, watch, setValue, formState } =
    useForm<CertificationDto>({
      resolver: yupResolver(CertificationValidationSchema)
    });
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const onSubmit: SubmitHandler<CertificationDto> = data => {
    dispatch(actionCreators.createCertification(data, userProfileId, history));
  };

  const goToProfile = () => {
    history.push(`/user/profile/${userProfileId}`);
  };

  if (isLoading) {
    return <FallbackLoader />;
  }

  return (
    <SimpleFormPage title={'Complete your profile'}>
      <CertificationForm
        title={'License / Certification'}
        submitHandler={onSubmit}
        watch={watch}
        setValue={setValue}
        formState={formState}
        bottomActions={
          <FormDualButtons
            cancelOnClick={goToProfile}
            submitButtonText="Save Changes"
          />
        }
        handleSubmit={handleSubmit}
        control={control}
      />
    </SimpleFormPage>
  );
};
