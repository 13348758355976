import React, { CSSProperties } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton
} from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import TrashIcon from '../Icons/TrashIcon';

export interface AccordionProps {
  title: string;
  onDelete: () => void;
  expanded: boolean;
  handleChange: (event: any, isExpanded: boolean) => void;
}

const trashIconStyle: CSSProperties = {
  background: '#999999',
  width: '20px',
  height: '20px',
  marginTop: '2px',
  marginRight: '6px'
};

const AccordionComponent: React.FC<AccordionProps> = ({
  title,
  children,
  onDelete,
  expanded,
  handleChange
}) => {
  const onDeleteButton = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <div>
      <Accordion sx={fontColor} expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon sx={fontColor} />}
          aria-controls={`Panel for ${title}`}
          id={title}
          sx={bgColor}
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
          <div style={divAccordion}>
            <Typography>{title}</Typography>
            <IconButton
              color="default"
              onClick={onDeleteButton}
              aria-label="Delete"
              style={trashIconStyle}
              size="small"
            >
              <TrashIcon viewBox="-9 -9 34 34" />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={bgColor}
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const divAccordion: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
};

const fontColor: CSSProperties = {
  color: '#FFFFFF'
};

const bgColor: CSSProperties = {
  backgroundColor: '#1B1C1C'
};

export default AccordionComponent;
