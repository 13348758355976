import React, { CSSProperties } from 'react';
import { Grid, Paper, Typography, Stack } from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import CheckIcon from '@mui/icons-material/Check';
import Stepper from './Stepper';
import { useHistory } from 'react-router';
import { ApplicationState } from '../../store';
import { useSelector } from 'react-redux';

const ConfirmationPage = (props: any) => {
  const history = useHistory();
  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const goToCompanyProfileDetails = () => {
    history.push(
      `/company/profile/${currentCompanyProfile?.id}`
    );
  };

  const renderInfo = props.location.state;

  return (
    <Stack spacing={2}>
      <Grid container spacing={2} style={{ paddingLeft: 80, paddingRight: 80 }}>
        <Grid item xs={2} md={2}></Grid>
        <Grid item xs={8} md={8}>
          <Paper
            sx={{
              p: 2,
              margin: 'auto',
              flexGrow: 1,
              backgroundColor: '#fff',
              height: '400px',
              borderRadius: '10px'
            }}
          >
            {renderInfo === 'deletePage' && (
              <div style={openJobsContainer}>
                <Grid item xs={12} md={12} style={{ width: '100%' }}></Grid>
                <Grid item xs={12} md={12} style={{ width: '100%' }}>
                  <div style={openJobsMiddleStyle}>
                    <CheckIcon style={iconCheckStyle} />
                    <Typography style={labelConfirmationStyle}>
                      [Job post] has been deleted.
                    </Typography>
                  </div>
                  <div style={buttonsConfirmationStyle}>
                    <BasicButton
                      onClick={goToCompanyProfileDetails}
                      color="primary"
                      style={{ height: '45px', width: '150px', marginLeft: 10 }}
                    >
                      <label style={labelButtonStyle}> Go to my profile</label>
                    </BasicButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} style={{ width: '100%' }}></Grid>
              </div>
            )}

            {renderInfo === 'duplicatePage' && (
              <div style={openJobsContainer}>
                <Grid item xs={12} md={12} style={{ width: '100%' }}></Grid>
                <Grid item xs={12} md={12} style={{ width: '100%' }}>
                  <div style={openJobsMiddleStyle}>
                    <CheckIcon style={iconCheckStyle} />
                    <Typography style={labelConfirmationStyle}>
                      Your BuildWitt Job has been duplicated!
                    </Typography>
                  </div>
                  <div style={buttonsConfirmationStyle}>
                    <BasicButton
                      onClick={goToCompanyProfileDetails}
                      color="secondary"
                      style={{
                        height: '45px',
                        width: '150px',
                        marginRight: 10
                      }}
                    >
                      <label style={{ ...labelButtonStyle, color: '#262625' }}>
                        Go to my profile
                      </label>
                    </BasicButton>
                    <BasicButton
                      onClick={() => {
                        history.push({
                          pathname: `/company/profile/jobs/post-job-create`
                        });
                      }}
                      color="primary"
                      style={{ height: '45px', width: '150px', marginLeft: 10 }}
                    >
                      <label style={labelButtonStyle}>Edit job post</label>
                    </BasicButton>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} style={{ width: '100%' }}></Grid>
              </div>
            )}
          </Paper>
        </Grid>
        <Grid item xs={2} md={2}></Grid>
      </Grid>
    </Stack>
  );
};

const openJobsContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%'
};

const labelConfirmationStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '40px',
  lineHeight: '61px',
  color: '#262625'
};

const openJobsMiddleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  padding: 10
};
const buttonsConfirmationStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  padding: 10
};

const labelButtonStyle: CSSProperties = {
  color: '#FFF',
  fontSize: '16px',
  marginTop: '6px',
  fontWeight: '700',
  lineHeight: '16px'
};

const iconCheckStyle: CSSProperties = {
  width: '86px',
  height: '71px',
  color: '#7A7C6F'
};

export default ConfirmationPage;
