import { Container, Paper } from '@mui/material';

interface PageProps extends React.PropsWithChildren<any> {}

export const Page = ({ children }: PageProps) => {
  return (
    <Container fixed sx={{ paddingBottom: 2 }}>
      {children}
    </Container>
  );
};
