import { Typography } from '@mui/material';
import React from 'react';

interface FormTitleProps {
  title: string;
}

export const FormTitle = ({ title }: FormTitleProps) => {
  return (
    <Typography
      variant="h4"
      sx={{ textAlign: 'center', color: '#242926', fontWeight: 700 }}
    >
      {title}
    </Typography>
  );
};
