import React, { CSSProperties } from 'react';
import { AccordionDetails, Typography, AccordionSummary } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import HorizontalLine from '../core/HorizontalLine/HorizontalLine';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export interface AccordionProperties {
  dueDate: string;
  expanded?: boolean;
  handleChange?: (event: any, isExpanded: boolean) => void;
  completeGroup?: boolean;
}

const BasicAccordion: React.FC<AccordionProperties> = ({
  dueDate,
  children,
  expanded,
  handleChange,
  completeGroup
}) => {
  const { t } = useTranslation(['assignments']);
  const StyleAccordion = styled(Accordion)<AccordionProps>(() => ({
    '&.MuiPaper-root': {
      backgroundColor: 'transparent !important',
      height: '50%',
      marginTop: '-25px'
    }
  }));

  var today = moment();
  var pastDueDate = moment(dueDate);

  return (
    <div>
      <HorizontalLine color={'#999999'} line={2} />
      <StyleAccordion
        sx={fontColor}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon sx={fontColor} />}
          aria-controls={`Panel for ${dueDate}`}
          id={dueDate}
          style={accordionSummaryStyle}
        >
          <div style={divAccordion}>
            {pastDueDate.isBefore(today, 'day') ? (
              <Typography style={typoStyle}>
                {completeGroup
                  ? t('dueOn', { ns: 'assignments' })
                  : t('overdue', { ns: 'assignments' })}{' '}
                <span style={completeGroup ? dueStyle : overdueStyle}>
                  {dueDate}
                </span>
              </Typography>
            ) : (
              <Typography style={typoStyle}>
                {t('dueOn', { ns: 'assignments' })}{' '}
                <span style={dueStyle}>{dueDate}</span>
              </Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          {children}
        </AccordionDetails>
      </StyleAccordion>
    </div>
  );
};

const divAccordion: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
};

const fontColor: CSSProperties = {
  color: '#FFFFFF'
};

const typoStyle: CSSProperties = {
  fontSize: '20px'
};

const dueStyle: CSSProperties = {
  color: '#FF9E00'
};

const overdueStyle: CSSProperties = {
  color: '#FF2A20'
};

const accordionSummaryStyle = {
  paddingLeft: '0px',
  paddingRight: '0px',
  marginTop: '-25px'
};

export default BasicAccordion;
