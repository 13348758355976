import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';
import React, { CSSProperties } from 'react';

export interface ThumbnailListViewProps {
  items: AzureBlobImageInfo[];
  onItemSelected: (item: any) => void;
  selectedImgUrl: string;
}

const imgContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  border: '3px solid black',
  width: '100%',
  minHeight: '100px',
  flexWrap: 'wrap',
  maxHeight: '250px',
  overflowY: 'scroll'
};

const imgItemStyle: CSSProperties = {
  borderRadius: '5px',
  width: '31%',
  margin: '5px',
  cursor: 'pointer'
};

const selectedItemStyle: CSSProperties = {
  borderColor: '#FF9E00',
  borderWidth: '5px',
  borderStyle: 'solid'
};

export const ThumbnailListView: React.FC<ThumbnailListViewProps> = ({
  items,
  onItemSelected,
  selectedImgUrl
}: ThumbnailListViewProps) => {
  const [thumbs, setThumbs] = React.useState<AzureBlobImageInfo[]>([]);
  const [
    selectedItem,
    setSelectedItem
  ] = React.useState<AzureBlobImageInfo | null>(null);

  React.useEffect(() => {
    setThumbs(items);
    var selItem = items.find(x => x.url === selectedImgUrl);
    if (selItem) {
      setSelectedItem(selItem);
    }
  }, [items]);

  const handleSelectItem = (item: AzureBlobImageInfo) => {
    setSelectedItem(item);
    if (onItemSelected) {
      onItemSelected(item);
    }
  };
  return (
    <div style={imgContainerStyle}>
      {thumbs.map(x => (
        <img
          src={x.url}
          onClick={() => handleSelectItem(x)}
          loading="eager"
          style={
            selectedItem?.name == x.name
              ? { ...imgItemStyle, ...selectedItemStyle }
              : imgItemStyle
          }
          height="100"
          alt="lib"
        />
      ))}
    </div>
  );
};
