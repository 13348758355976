import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';

export interface TextBoxProps {
  value: string;
  onChangeValue?: (value: any) => void;
  placeholderText?: string;
  id: string;
  name: string;
  width?: string;
  height?: string;
  isInvalid?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  isPassword?: boolean;
  isNumber?: boolean;
  disabled?: boolean;
  customStyle?: CSSProperties;
  type? :
    | 'filter'
    | 'transparent';
}
const TextBox: React.FC<TextBoxProps> = ({
  id,
  name,
  value,
  onChangeValue,
  placeholderText,
  width = '100%',
  height = '40px',
  isInvalid = false,
  onBlur = (e: React.FocusEvent<HTMLInputElement>) => {},
  maxLength = 90,
  isPassword = false,
  isNumber = false,
  disabled = false,
  customStyle = {},
  type,
}) => {
  React.useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);
  const [inputValue, setInputValue] = React.useState(value);
  const theme = useTheme();
  const preStyle = {
    backgroundColor: type === 'filter' ? '#FFF' : '#EDECE880',
    padding: '5px',
    color: theme.palette.primary.contrastText,
    borderRadius: '5px',
    outline: 'none',
    paddingLeft: '8px',
    fontFamily: theme.typography.fontFamily,
    height: height,
    borderColor: isInvalid ? theme.palette.error.main : '#D9D9D9',
    borderWidth: isInvalid ? 1 : 1,
    borderStyle: isInvalid ? 'solid' : 'solid',
    width: width
  };

  const inputStyle = {
    ...preStyle,
    ...customStyle
  };

  const isOnlyNumbersOrEmpty = (value: string) => {
    return new RegExp(/(^[0-9]*\.{0,1}[0-9]*$)|^0?\.?[0-9]*$/).test(value);
  };

  const shouldUpdateValue = (value: string) => {
    let result = true;
    result = isNumber ? isOnlyNumbersOrEmpty(value) : result;
    return result;
  };

  const handleChange = (e: any) => {
    if (shouldUpdateValue(e.target.value)) {
      setInputValue(e.target.value);
      if (onChangeValue) {
        onChangeValue(e.target.value);
      }
    }
  };

  return (
    <input
      type={isPassword ? 'password' : 'text'}
      width={width}
      height={height}
      id={id}
      name={name}
      value={inputValue}
      onChange={e => handleChange(e)}
      style={inputStyle}
      placeholder={placeholderText}
      onBlur={onBlur}
      maxLength={maxLength}
      disabled={disabled}
    />
  );
};

export default TextBox;
