import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import TextBox from '../../components/core/TextBox/TextBox';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { validPasswordRules } from '../../utils/validationsUtils';
import { actionCreators } from '../../actions/employees';
import { UpdatePasswordModel } from '../../entities/Employee';
import { ApplicationState } from '../../store';
import { TransactionStatusEnum, BasicButtonTypeEnum } from '../../core/enums';
import { Typography } from '@mui/material';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import { useTranslation } from 'react-i18next';

export interface UpdatePasswordProps {}

export interface ValidationFieldsState {
  currentPasswordMsg: string;
  newPasswordMsg: string;
}

const initUpdatePasswordState: UpdatePasswordModel = {
  currentPassword: '',
  newPassword: ''
};

const initValidationFieldsState: ValidationFieldsState = {
  currentPasswordMsg: '',
  newPasswordMsg: ''
};

const UpdatePassword: React.FC<UpdatePasswordProps> = () => {
  const [updatePassword, setUpdatePassword] = useState<UpdatePasswordModel>(
    initUpdatePasswordState
  );
  const [validationFields, setValidationFields] = useState<
    ValidationFieldsState
  >(initValidationFieldsState);
  const { currentPassword, newPassword } = updatePassword;
  const { currentPasswordMsg, newPasswordMsg } = validationFields;
  const passwordUpdated = useSelector(
    (state: ApplicationState) => state.employees?.passwordUpdated
  );
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'settings']);

  const validationMessages = {
    CURRENT_PASSWORD_MADATORY: t('CurrentPwdIsMandatory', {ns: 'settings'}),
    NEW_PASSWORD_MADATORY: t('newPwdIsMandatory', {ns: 'settings'}),
    PASSWORD_RULES: t('passwordRules', {ns: 'settings'}),
    CURRENT_AND_NEW_PASSWORD_MUST_BE_DIFFERENT: t('newPwsMustBeDifferent', {ns: 'settings'})
  };

  useEffect(() => {
    return () => {
      dispatch(actionCreators.resetUpdatePassword());
    };
  }, []);

  useEffect(() => {
    if (passwordUpdated === TransactionStatusEnum.Successfull)
      alert(t('pwdUpdated', {ns: 'settings'}));
    else if (passwordUpdated === TransactionStatusEnum.Failed)
      alert(t('pwdUpdateException', {ns: 'settings'}));
  }, [passwordUpdated]);

  const handleCurrentPwdChange = (value: string) => {
    setUpdatePassword({ ...updatePassword, currentPassword: value });

    if (!value.trim().length)
      setValidationFields({
        ...validationFields,
        currentPasswordMsg: validationMessages.CURRENT_PASSWORD_MADATORY
      });
    else if (value === newPassword)
      setValidationFields({
        ...validationFields,
        newPasswordMsg:
          validationMessages.CURRENT_AND_NEW_PASSWORD_MUST_BE_DIFFERENT
      });
    else setValidationFields({ ...validationFields, currentPasswordMsg: '' });
  };

  const handleNewPwdChange = (value: string) => {
    setUpdatePassword({ ...updatePassword, newPassword: value });

    if (!value.trim().length)
      setValidationFields({
        ...validationFields,
        newPasswordMsg: validationMessages.NEW_PASSWORD_MADATORY
      });
    else if (!validPasswordRules(value.trim()))
      setValidationFields({
        ...validationFields,
        newPasswordMsg: validationMessages.PASSWORD_RULES
      });
    else if (currentPassword === value)
      setValidationFields({
        ...validationFields,
        newPasswordMsg:
          validationMessages.CURRENT_AND_NEW_PASSWORD_MUST_BE_DIFFERENT
      });
    else setValidationFields({ ...validationFields, newPasswordMsg: '' });
  };

  const handleTextBoxBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value.trim().length) {
      e.target.id === 'currentPassword'
        ? setValidationFields({
            ...validationFields,
            currentPasswordMsg: validationMessages.CURRENT_PASSWORD_MADATORY
          })
        : setValidationFields({
            ...validationFields,
            newPasswordMsg: validationMessages.NEW_PASSWORD_MADATORY
          });
    }
  };

  const handleSavePassword = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      !currentPassword.length ||
      !newPassword.length ||
      currentPasswordMsg.length > 0 ||
      newPasswordMsg.length > 0
    )
      return;

    dispatch(actionCreators.updatePassword(updatePassword));
  };

  return (
    <form onSubmit={handleSavePassword}>
      <div className="d-flex flex-column">
        <h5>{t('password', {ns: 'settings'})}</h5>
        <FormLabel>{t('currentPassword', {ns: 'settings'})}</FormLabel>
        <TextBox
          id="currentPassword"
          name="currentPassword"
          width="100%"
          height="40px"
          isPassword={true}
          value={updatePassword.currentPassword}
          onChangeValue={handleCurrentPwdChange}
          onBlur={handleTextBoxBlur}
        />
        <p className="text-danger">{currentPasswordMsg}</p>
        <span className="pt-3" />
        <FormLabel>{t('newPassword', {ns: 'settings'})}</FormLabel>
        <TextBox
          id="newPassword"
          name="newPassword"
          width="100%"
          height="40px"
          isPassword={true}
          value={updatePassword.newPassword}
          onChangeValue={handleNewPwdChange}
          onBlur={handleTextBoxBlur}
        />
        <p className="text-danger">{newPasswordMsg}</p>
        <span className="pt-4" />
        <BasicButton
          color="primary"
          style={buttonStyle}
          type={BasicButtonTypeEnum.Submit}
        >
          <Typography style={fontButton}>{t('savePassword', {ns: 'settings'})}</Typography>
        </BasicButton>
      </div>
    </form>
  );
};

export default UpdatePassword;
