import React from 'react';
import ReactTags, { ReactTagsProps, Tag } from 'react-tag-autocomplete';
import './tagInput.css';

export interface TagEntity extends Tag {}
export interface TagProps extends ReactTagsProps {
    variant?: 'light' | 'dark' | undefined;
}

const TagInput : React.FC<TagProps> = ({
    variant = 'light',
    classNames, 
    ...props
} ) => {

    const customClass = {
        root: variant === 'light' ? 'react-tags' : 'react-tags-dark',
        rootFocused: 'is-focused',
        selected: 'react-tags__selected',
        selectedTag: 'react-tags__selected-tag',
        selectedTagName: 'react-tags__selected-tag-name',
        search: variant === 'light' ? 'react-tags__search' : 'react-tags__search-dark',
        searchWrapper: 'react-tags__search-wrapper',
        searchInput: 'react-tags__search-input',
        suggestions: 'react-tags__suggestions',
        suggestionActive: 'is-active',
        suggestionDisabled: 'is-disabled',
        suggestionPrefix: 'react-tags__suggestion-prefix'
      };      

    return (
        <ReactTags
            classNames={customClass} 
            { ...props } 
        />
    )
}


export default TagInput;