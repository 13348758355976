import { Grid } from '@mui/material';
import { FC } from 'react';

export interface StepperProps {
  firstStep?: boolean;
  secondStep?: boolean;
  thirdStep?: boolean;
}

const Stepper: FC<StepperProps> = ({
  firstStep = false,
  secondStep = false,
  thirdStep = false
}) => {
  return (
    <Grid container spacing={2} paddingY={2}>
      <Grid item xs={12} md={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Grid
            item
            xs={4}
            md={4}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start'
            }}
          >
            <div
              style={{
                backgroundColor: firstStep
                  ? '#CCFF00'
                  : 'rgba(237, 236, 232, 0.3)',
                borderRadius: '122px',
                width: '95%',
                height: '8px'
              }}
            ></div>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                backgroundColor: secondStep
                  ? '#CCFF00'
                  : 'rgba(237, 236, 232, 0.3)',
                borderRadius: '122px',
                width: '95%',
                height: '8px'
              }}
            ></div>
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end'
            }}
          >
            <div
              style={{
                backgroundColor: thirdStep
                  ? '#CCFF00'
                  : 'rgba(237, 236, 232, 0.3)',
                borderRadius: '122px',
                width: '95%',
                height: '8px'
              }}
            ></div>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default Stepper;
