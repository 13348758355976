import * as yup from 'yup';

export const CertificationValidationSchema = yup
  .object({
    skillName: yup.string().required('License/Certification is required.'),
    grantingInstitution: yup.string().required('Job Title is required.'),
    doesNotExpire: yup.boolean(),
    dateAwarded: yup.string().required('Issued year is required.'),
    expirationDate: yup
      .string()
      .when('doesNotExpire', (doesNotExpire, field) =>
        !doesNotExpire[0]
          ? field.required('Expiration year is required.')
          : field
      )
  })
  .required();
