import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import { FC } from 'react';

import BasicButton from '../BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

interface ConfirmationModalProps {
  children: any;
  title?: string;
  show: boolean;
  onSave?: () => void;
  onCancel: () => void;
  saveText?: string;
  cancelText?: string;
  showSave?: boolean;
  showCancel?: boolean;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  extraOptions?: React.ReactNode[];
  maxHeight?: string;
}

const iconStyles: SxProps<Theme> = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme => theme.palette.grey[500]
};

const contentContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  m: 'auto',
  width: 'fit-content'
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  children,
  title = '',
  show,
  onSave,
  onCancel,
  saveText = 'Save',
  cancelText = 'Close',
  showSave = true,
  showCancel = true,
  maxWidth = 'sm',
  extraOptions = [],
  maxHeight = '30vh'
}: ConfirmationModalProps) => {
  const { t } = useTranslation(['common']);

  const dialogStyles: SxProps = {
    m: 0,
    p: 2,
    '& 	.MuiDialog-container': {
      height: '100%',
      outline: '0px',
      display: 'flex',
      WebkitBoxPack: 'center',
      justifyContent: 'center',
      WebkitBoxAlign: 'center',
      alignItems: 'center'
    },
    '& 	.MuiDialog-paper': {
      borderStyle: 'solid',
      borderColor: 'gray',
      borderRadius: '14px',
      padding: '25px',
      margin: '32px',
      position: 'relative',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: maxHeight
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={maxWidth}
      open={show}
      onClose={onCancel}
      sx={dialogStyles}
    >
      <IconButton aria-label="close" onClick={onCancel} sx={iconStyles}>
        <CloseIcon />
      </IconButton>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText></DialogContentText>
        <Box component="p" sx={contentContainerStyles}>
          {children}
        </Box>
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <BasicButton onClick={onCancel} color="primary">
            {cancelText === 'Close' ? t('close', { ns: 'common' }) : cancelText}
          </BasicButton>
        )}
        {showSave && (
          <BasicButton onClick={onSave} color="primary">
            {saveText === 'Save' ? t('save', { ns: 'common' }) : saveText}
          </BasicButton>
        )}
        {extraOptions.map((component, index) => (
          <React.Fragment key={`extra-${index}`}>{component}</React.Fragment>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
