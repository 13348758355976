import React, { CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { Guid } from 'guid-typescript';
import { DueLesson } from '../../entities/LearningPlaylist';
import LessonCard from '../../components/LessonCard/LessonCard';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { Lesson } from '../../entities/Lesson';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';

interface LessonAssignmentsProps {
  dueLessons: DueLesson[];
}

const LessonAssignments: React.FC<LessonAssignmentsProps> = ({
  dueLessons
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const { t } = useTranslation(['common', 'assignments']);

  const handlePlayLesson = (lesson: Lesson) => {
    dispatch(lessonActions.setCurrentLessons([lesson]));
    logEvent.logPlayLessonEvent(lesson.id);

    history.push({
      pathname: `/lessons/play`,
      state: { lesson }
    });
  };

  const dueDateStyle: CSSProperties = {
    color: theme.palette.primary.main,
    paddingLeft: '0.3rem'
  };

  const overdueDateStyle: CSSProperties = {
    color: theme.palette.error.main,
    paddingLeft: '0.3rem'
  };

  var today = moment();

  return (
    <>
      {dueLessons.map(dueItem => {
        let { lesson, isComplete, dueDateMoment } = dueItem;
        let { id } = lesson;

        return (
          <Grid item xs={1} md={6} lg={4} key={id}>
            <LessonCard
              lesson={lesson}
              isComplete={isComplete ?? false}
              dueDate={dueDateMoment!.toDate()}
            >
              <div style={actionButtonsContainerStyle}>
                {isComplete == true ? (
                  <span />
                ) : (
                  <span>
                    {t('dueDate', { ns: 'assignments' })}:
                    <span
                      style={
                        !isComplete && dueDateMoment!.isBefore(today, 'day')
                          ? overdueDateStyle
                          : dueDateStyle
                      }
                    >
                      {dueDateMoment!.format('MM.DD.YY')}
                    </span>
                  </span>
                )}
                <BasicButton
                  onClick={() => {
                    if (isComplete) {
                      lesson = { ...lesson, isComplete: true };
                    }

                    handlePlayLesson(lesson);
                  }}
                  color={isComplete ? 'revisit' : 'primary'}
                  style={startButtonStyle}
                >
                  {isComplete
                    ? t('revisit', { ns: 'common' })
                    : t('start', { ns: 'common' })}
                </BasicButton>
              </div>
            </LessonCard>
          </Grid>
        );
      })}
    </>
  );
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

const actionButtonsContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export default LessonAssignments;
