import * as yup from 'yup';
import moment from 'moment';

export const workExperienceValidationSchema = yup
  .object({
    companyName: yup.string().required('Company name is required.'),
    jobTitle: yup.string().required('Job Title is required.'),
    isCurrentlyWorking: yup.boolean(),
    startMonth: yup.string().required('Start Month is required.'),
    startYear: yup.string().required('Start Year is required.'),
    endMonth: yup
      .string()
      .when('isCurrentlyWorking', (isCurrentlyWorking, field) =>
        !isCurrentlyWorking[0]
          ? field.required('End month is required.')
          : field
      ),
    endYear: yup
      .string()
      .when('isCurrentlyWorking', (isCurrentlyWorking, field) =>
        !isCurrentlyWorking[0]
          ? field.required('End year is required.').test(
              'isEndDateBiggerThanStartDate',
              () => 'End date should be bigger than start year',
              (value, context) => {
                let startMonth = context.parent.startMonth;
                let startYear = context.parent.startYear;
                let endMonth = context.parent.endMonth;
                let endYear = value;

                const startDateString = `${startYear}-${startMonth}-01`;
                const endDateString = `${endYear}-${endMonth}-01`;

                const startDate = moment(startDateString, 'YYYY-MM-DD');
                const endDate = moment(endDateString, 'YYYY-MM-DD');

                return endDate.isAfter(startDate);
              }
            )
          : field
      ),

    description: yup.string().required('Responsibilities are required.')
  })
  .required();
