import React, {useState} from 'react';
import { useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';


export interface TextDropdownListItem {
  text: string;
  value: any;
}

export interface TextDropdownListProps {
  onChange: (value: any) => void;
  items: TextDropdownListItem[];
  label: string;
}
const TextDropdownList: React.FC<TextDropdownListProps> = ({
  onChange,
  items,
  label,
  ...props
}) => {
  const [isToggle, setToggle] = useState<boolean>(false);
  const [currentOption, setCurrentOption] = useState<string>('');
  const theme = useTheme();
  const componentStyle: CSSProperties = {
    width: '90px',
    backgroundColor: 'transparent',
    borderStyle: 'none',
    color: theme.palette.primary.main,
    outline: 'none',
    position: 'absolute',
    top: '95px',
    right: '0px',
    zIndex: 2
  };

  const containerStyle: CSSProperties = {
    display: 'inline',    
  };

  const itemStyle: CSSProperties = {
    backgroundColor: theme.palette.grey['400'],
    textAlign: 'center'
  };

  const labelStyle: CSSProperties = {
    color: theme.palette.grey['500'],
    cursor: 'pointer',
    userSelect: 'none'
  };
  const spanSelect: CSSProperties = {
    color: theme.palette.primary.main,
  };
  
  const handleOption = (text: string) => {
    setCurrentOption(text);
    setToggle(false);
  }
  return (
    <div style={containerStyle}>
      <label onClick={()=>{isToggle ? setToggle(false) : setToggle(true)}} style={labelStyle}> {label} 
        <span style={spanSelect}> {currentOption === '' ? items[0].text : currentOption }   
        <FontAwesomeIcon 
            style={{margin: '0px 10px' }}
            icon={isToggle ? faAngleUp: faAngleDown} 
          />
        </span>
      </label>
      {isToggle &&  
      <select size={items.length}  className="select-text-dropdown" style={componentStyle} onChange={onChange} {...props}>
        {items.map(item => (
          <option onClick={()=>handleOption(item.text)} selected={currentOption == item.text ? true : false} className={'option-text-dropdown'} value={item.value} style={itemStyle}>
            {item.text}
          </option>
        ))}
      </select> }
    </div>
  );
};
export default TextDropdownList;
