import React, { CSSProperties, useEffect, useState, useMemo } from 'react';
import {
  Grid,
  FormControl,
  MenuItem,
  FormHelperText,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
  useMediaQuery
} from '@mui/material';
import {
  actionCreators as addressesActions,
  selectCityItems,
  selectCountryItems,
  selectStateItems
} from '../../actions/address';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import TextBox from '../../components/core/TextBox/TextBox';
import { DropdownItemNumber } from '../../entities/Common';
import { JobsTypeOptions } from '../../core/constants';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { Card } from '../../components/Card';
import { useTheme } from '@mui/material/styles';
import filterBG from '../../assets/filterBG.png';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { FiltersDTO } from '../../entities/Dto/FiltersDTO';

interface FiltersFormProps
  extends Pick<
    UseFormReturn<FiltersDTO>,
    'control' | 'watch' | 'handleSubmit' | 'formState' | 'setValue'
  > {
  submitHandler: SubmitHandler<FiltersDTO>;
  bottomActions: React.ReactNode;
  jobsLenght: number;
}

const FiltersJobs = ({
  control,
  watch,
  handleSubmit,
  submitHandler,
  setValue,
  formState,
  bottomActions,
  jobsLenght
}: FiltersFormProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const watchCountry = watch('Country');
  const jobsCategories = useSelector(
    (state: ApplicationState) => state.jobs?.jobsCategories
  );

  const isLoadingCountries = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCountries
  );
  const isLoadingStates = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingStates
  );

  const countryItems = useSelector(selectCountryItems);
  const stateItems = useSelector(selectStateItems);

  const [jobTypeOptions] = useState<DropdownItemNumber[]>(JobsTypeOptions);

  const jobCategoriesOptions = useMemo(() => {
    if (!!jobsCategories && !!jobsCategories.length) {
      return jobsCategories.map(jobCategory => ({
        label: jobCategory.name,
        value: jobCategory.name
      }));
    }
    return [];
  }, [jobsCategories]);

  useEffect(() => {
    if (watchCountry)
      dispatch(addressesActions.getStatesFromCountries(watchCountry));
  }, [watchCountry]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid container xs={12} spacing={2} direction="column">
        <Grid item>
          <FormLabel>Keyword</FormLabel>
          <Controller
            name="Keyword"
            control={control}
            defaultValue=""
            render={({ field: { name, onChange, value } }) => (
              <TextBox
                id={name}
                name={name}
                value={value ?? ''}
                onChangeValue={onChange}
                placeholderText="Try “Operator” or “Laborer”"
                width="100%"
                height="40px"
                type="filter"
              />
            )}
          />
        </Grid>
        <Grid item>
          <FormLabel>Country</FormLabel>
          <Controller
            name="Country"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <Autocomplete
                disablePortal
                options={countryItems ?? []}
                loading={isLoadingCountries}
                value={value}
                onChange={(_, newValue) => {
                  setValue('State', '');
                  formOnChange(newValue);
                }}
                placeholder="Country"
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingCountries && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <FormLabel>State</FormLabel>
          <Controller
            name="State"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange: formOnChange } }) => (
              <Autocomplete
                disablePortal
                options={stateItems ?? []}
                loading={isLoadingStates}
                value={value}
                onChange={(_, newValue) => {
                  formOnChange(newValue);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingStates && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item>
          <FormLabel>Job Category</FormLabel>
          <Controller
            name="Category"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                onChange={onChange}
                value={value ?? ''}
                displayEmpty
                defaultValue={''}
                labelId="Category"
              >
                <MenuItem disabled value={''}>
                  - select -
                </MenuItem>
                {jobCategoriesOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item>
          <FormLabel>Job Type</FormLabel>
          <Controller
            name="Type"
            control={control}
            defaultValue=""
            render={({ field: { value, onChange } }) => (
              <Select
                onChange={onChange}
                value={value ?? ''}
                displayEmpty
                labelId="Type"
                defaultValue={''}
              >
                <MenuItem disabled value={''}>
                  - select -
                </MenuItem>
                {jobTypeOptions.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item style={containerFilters}>
          <Typography style={spanStyle}>Showing [{jobsLenght}] jobs</Typography>
          <Typography style={spanStyle}>{bottomActions}</Typography>
        </Grid>
        <Grid item display={matches ? 'none' : 'block'}>
          <Card
            component={'a'}
            href={'https://dirtworld.com/dirt-101/workforce-survey'}
            target="_blank"
            rel="noreferrer"
            style={{
              margin: 'auto',
              flexGrow: 1,
              backgroundImage: `url(${filterBG})`,
              backgroundPosition: '50% 50%',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '310px',
              display: 'block'
            }}
            padding={'small'}
          >
            <div style={containerDWSummit}>
              <Grid item xs={12} md={12} sx={{ width: '100%' }}>
                <div style={dwSummitTopStyle}>
                  <Typography gutterBottom style={infoIStyle}>
                    2022 State of Training in the Dirt World
                  </Typography>
                  <Button variant="contained" style={buttonJobStyle}>
                    <label style={postJobLabelStyle}>Read the research</label>
                  </Button>
                </div>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

const containerDWSummit: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  marginTop: '10vh'
};

const dwSummitTopStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '90%',
  marginLeft: 20,
  marginTop: 20
};

const infoIStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '25px',
  lineHeight: '36px',
  color: '#FFFFFF',
  textAlign: 'center'
};

const buttonJobStyle: CSSProperties = {
  borderRadius: '25px',
  textTransform: 'none' as 'none',
  opacity: 1,
  border: '#CCFF00',
  backgroundColor: '#CCFF00',
  marginRight: '10px'
};

const postJobLabelStyle: CSSProperties = {
  color: '#262625',
  fontSize: '14px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '14px'
};

const containerFilters: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'space-between'
};

const spanStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#242926'
};

export default FiltersJobs;
