import React, {useEffect} from 'react';
import { useAuth } from 'react-oidc-context';

export const Mobile = () => {
  const auth = useAuth();
  
  useEffect(() => {
    if(auth.isAuthenticated && auth.user){
      const payload = {
        access_token: auth.user.access_token,
        access_token_expires: auth.user.expires_at,
        refresh_token: auth.user.refresh_token,
        claims: Object.keys(auth.user.profile).map(claimKey => ({
          key: claimKey,
          value: auth.user?.profile[claimKey]
        }))
      };
      const three = payload.claims[3];
      const five = payload.claims[5];
      const six = payload.claims[6];
      const seven = payload.claims[7];
      payload.claims[6] = three;
      payload.claims[3] = six;
      payload.claims[7] = five;
      payload.claims[5] = seven;

      if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify(payload));
      }
    }
  }, [auth.isAuthenticated])

  return <>Loading</>;
};
