import { Grid, Stack, Typography } from '@mui/material';
import { EducationDto } from '../../../entities/UserProfile';
import { getDegreeType } from '../../../utils/stringUtils';

export const EducationSectionItem = ({
  education
}: {
  education: EducationDto;
}) => {
  return (
    <Grid
      container
      justifyContent={'center'}
      alignItems={'center'}
      spacing={2}
      sx={{ padding: '15px' }}
    >
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="blackTitleMedium">{education.school}</Typography>
          <Typography variant="blackSubTitle">
            {getDegreeType(education.degreeType)}
          </Typography>
          <Typography variant="blackCaption">
            {education.graduationDate}
          </Typography>
          <Typography variant="blackCaption">
            {education.fieldOfStudy}
          </Typography>
          <Typography variant="blackCaption">{education.details}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
