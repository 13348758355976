import { Course } from '../../entities/Course';
import Panel, { PanelProps } from '../../components/core/Panel/Panel';
import TextBox, { TextBoxProps } from '../../components/core/TextBox/TextBox';
import TextArea, {
  TextAreaProps
} from '../../components/core/TextArea/TextArea';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import TextBoxWithButton from '../../components/core/TextBoxWithButton/TextBoxWithButton';
import FileInput from '../../components/core/FileInput/FileInput';
import React from 'react';
import TagInput, { TagEntity } from '../TagInput/TagInput';
import { Tag } from '../../entities/Tag';
import { Guid } from 'guid-typescript';
import { useTheme } from '@mui/material';
import BackgroundCoursePattern from '../../assets/BackgroundPattern.png';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { ThumbnailLibraryPanel } from '../../components/ThumbnailLibraryPanel/ThumbnailLibraryPanel';
import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';

const panelStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column' as 'column'
};

export interface CourseFormProps {
  onUpdateCourseInfo: (course: Course) => void;
  onUpdateThumbnail: (thumbnail: File) => void;
  course: Course;
  companyTags: Array<Tag>;
  onUpdateImageFromLib: (img: string) => void;
}

const CourseForm: React.FC<CourseFormProps> = ({
  onUpdateCourseInfo,
  onUpdateThumbnail,
  course,
  companyTags,
  onUpdateImageFromLib
}) => {
  const [courseFile, setCourseFile] = React.useState<any>(null);
  const [courseData, setCourseData] = React.useState<Course>(course);
  const [tags, setTags] = React.useState<Array<Tag>>([]);
  const [fileError, setFileError] = React.useState<boolean>(false);
  const [titleError, setTitleError] = React.useState<boolean>(false);
  const [descriptionError, setDescriptionError] = React.useState<boolean>(
    false
  );
  const [tagError, setTagError] = React.useState<boolean>(false);
  const theme = useTheme();
  const { t } = useTranslation(['courses', 'common']);
  const [imgUrl, setImgUrl] = React.useState<string>('');

  const labelStyle = {
    color: theme.palette.grey['100']
  };

  React.useEffect(() => {
    if (course) {
      setCourseData(course);
      setTags(course.tags);
      setImgUrl(course.thumbnailUrl);
    }
  }, [course]);

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg+xml'
    ];
    return validImageTypes.includes(fileType);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setCourseFile(file);
      onUpdateThumbnail(file);
      const fileType = file['type'];
      if (IsValidImageFormat(fileType)) {
        setFileError(false);
      } else {
        setFileError(true);
      }
    } else {
      setFileError(true);
    }
  };

  const handleChangeImage = (img: string) => {
    if (img) {
      setImgUrl(img);
      setCourseFile(null);
      onUpdateImageFromLib(img);
    }
  };

  const handleChangeDescription = (e: string) => {
    if (e) {
      setCourseData({
        ...courseData,
        tags: tags,
        description: e
      });

      setDescriptionError(false);
    } else {
      setDescriptionError(true);
    }
    onUpdateCourseInfo({
      ...courseData,
      tags: tags,
      description: e
    });
  };
  const handleChangeTitle = (e: string) => {
    if (e) {
      setTitleError(false);
      setCourseData({ ...courseData, tags: tags, title: e });
    } else {
      setTitleError(true);
    }
    onUpdateCourseInfo({ ...courseData, tags: tags, title: e });
  };

  const onDeleteTag = (index: number) => {
    const tagsArray = tags.slice(0);
    tagsArray.splice(index, 1);
    setTags(tagsArray);
    onUpdateCourseInfo({ ...courseData, tags: tagsArray });

    if (!tagsArray.length) setTagError(true);
  };

  const onAdditionTag = (newTag: TagEntity) => {
    if (!tags.find(tag => tag.name === newTag.name)) {
      const tagsArray = [
        ...tags,
        {
          id: !newTag.id ? Guid.create().toString() : (newTag.id as string),
          name: newTag.name
        }
      ];
      setTags(tagsArray);
      tagsArray.length > 1 ? setTagError(true) : setTagError(false);
      onUpdateCourseInfo({ ...courseData, tags: tagsArray });
    }
  };

  const onInputTag = (input: string) => {
    if (!input.length && !tags.length) {
      setTagError(true);
    } else if (tagError) {
      setTagError(false);
    }
  };

  const onBlurTag = () => {
    if (!tags.length) setTagError(true);
  };

  return (
    <>
      <FormLabel>
        {t('courseName', { ns: 'courses' })}{' '}
        <span className="text-danger">*</span>
      </FormLabel>
      <TextBox
        id="courseName"
        name="courseName"
        value={courseData.title}
        onChangeValue={handleChangeTitle}
        placeholderText={t('exampleNameHere', { ns: 'common' })}
        width="100%"
        height="30px"
      ></TextBox>
      {titleError && (
        <p className="text-danger">{t('nameIsRequired', { ns: 'common' })}</p>
      )}
      <br />
      <FormLabel>
        {t('courseDescription', { ns: 'courses' })}
        <span className="text-danger">*</span>
      </FormLabel>
      <TextArea
        id="courseDescription"
        name="courseDescription"
        value={courseData.description}
        onChangeValue={handleChangeDescription}
        placeholderText={t('writeDescriptionHere', { ns: 'common' })}
        width="100%"
        rows={5}
      ></TextArea>
      {descriptionError && (
        <p className="text-danger">
          {t('descriptionIsRequired', { ns: 'common' })}
        </p>
      )}
      <br />
      <FormLabel>
        {t('addCourseTags', { ns: 'courses' })}
        <span className="text-danger">*</span>{' '}
      </FormLabel>
      <TagInput
        tags={tags}
        suggestions={companyTags}
        onDelete={onDeleteTag}
        onAddition={onAdditionTag}
        onInput={onInputTag}
        onBlur={onBlurTag}
        allowNew={true}
        addOnBlur={true}
        placeholderText={t('writeTagHere', { ns: 'common' })}
      />
      {tagError && (
        <p className="text-danger">{t('oneTagRequired', { ns: 'common' })}</p>
      )}
      <br />
      <Row>
        <Col>
          <ThumbnailLibraryPanel
            onChange={handleChangeFile}
            thumbnailUrl={imgUrl}
            onItemSelected={(item: AzureBlobImageInfo) => {
              handleChangeImage(item.url);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CourseForm;
