import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators as courseActionCreators } from '../../actions/courses';
import { actionCreators as actionTags } from '../../actions/tags';
import { Course } from '../../entities/Course';
import { ApplicationState } from '../../store';
import CourseForm from '../../components/CourseForm/CourseForm';
import BackgroundCoursePattern from '../../assets/BackgroundCoursePattern.svg';
import Typography from '@mui/material/Typography';
import { buttonStyle, fontButton, rowReverse } from '../../utils/buttonStyles';
import { FormErrors } from '../../components/core/FormErrors/FormErrors';
import { useTranslation } from 'react-i18next';

export interface CreateCourseProps {}

const emptyCourse: Course = {
  id: '',
  title: '',
  description: '',
  thumbnailUrl: '',
  companyId: '',
  isDraft: true,
  tags: [],
  videosCount: 0,
  flashCardsCount: 0,
  quizesCount: 0,
  revisionNumber: 0
};

const CreateCourse: React.FC<CreateCourseProps> = () => {
  const [courseData, setCourseData] = React.useState<Course>(emptyCourse);
  const [courseFile, setCourseFile] = React.useState<any>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [invalidForm, setInvalidForm] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string[]>([]);
  const { t } = useTranslation(['common']);
  const [img, setImg] = React.useState<string>('');

  const companyTags = useSelector(
    (state: ApplicationState) => state.tags?.tags
  );

  React.useEffect(() => {
    if (!companyTags?.length) dispatch(actionTags.requestTags());
  }, []);

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg+xml'
    ];
    return validImageTypes.includes(fileType);
  };

  const handleSaveCourse = async () => {
    setInvalidForm(false);
    setErrors([]);
    try {
      if (
        courseData &&
        courseData.title &&
        courseData.description &&
        courseData.tags.length &&
        (courseFile === null && !img
          ? BackgroundCoursePattern
          : (courseFile && IsValidImageFormat(courseFile.type)) || img)
      ) {
        await dispatch(
          courseActionCreators.saveCourseAndEditLessons(
            courseData,
            courseFile,
            history,
            img
          )
        );
        await dispatch(actionTags.requestTags());
      } else {
        setInvalidForm(true);
        let errorsArray: string[] = [];

        if (!courseData.title) {
          errorsArray.push(t('titleRequired', { ns: 'common' }));
        }
        if (!courseData.description) {
          errorsArray.push(t('descriptionIsRequired', { ns: 'common' }));
        }
        if (!courseData.tags.length) {
          errorsArray.push(t('oneTagRequired', { ns: 'common' }));
        }

        if (courseFile && !IsValidImageFormat(courseFile.type)) {
          errorsArray.push(t('invalidImageFormat', { ns: 'common' }));
        }
        setErrors(errorsArray);
      }
    } catch (e) {
      setInvalidForm(true);
      setErrors([t('courseErrorMsg', { ns: 'common' })]);
    }
  };
  return (
    <>
      <Row>
        <Col className="d-flex flex-column">
          {invalidForm && <FormErrors errors={errors} />}
          <CourseForm
            course={emptyCourse}
            companyTags={companyTags || []}
            onUpdateThumbnail={setCourseFile}
            onUpdateCourseInfo={setCourseData}
            onUpdateImageFromLib={setImg}
          ></CourseForm>
        </Col>
      </Row>
      <Row style={rowReverse}>
        <BasicButton
          color="primary"
          style={buttonStyle}
          onClick={handleSaveCourse}
        >
          <Typography style={fontButton}>
            {t('continue', { ns: 'common' })}
          </Typography>
        </BasicButton>
      </Row>
    </>
  );
};

export default CreateCourse;
