import { AppThunkAction } from '../store';
import {
  JOBLISTER_IS_LOADING,
  JOBLISTER_SET_CATEGORIES,
  JOBLISTER_SET_JOB_DETAIL,
  JOBLISTER_SET_JOB_DETAIL_PREVIEW,
  JOBLISTER_SET_JOBS,
  JOBLISTER_SET_JOB_TYPES,
  SET_FILTERS
} from './actionTypes';
import axios from 'axios';
import config from '../config';
import { HttpStatusEnum } from '../core/enums';
import { JobsCategories } from '../entities/JobsCategories';
import { CreatePostJobDto } from '../entities/Dto/createJobPostDto';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { JobDetail } from '../entities/JobDetail';
import { History } from 'history';
import { JobsList } from '../entities/JobsList';
import { FiltersDTO } from '../entities/Dto/FiltersDTO';
import { JobTypes } from '../entities/JobTypes';
import { enqueueSnackbar } from 'notistack';

export interface SetJobsIsLoading {
  type: 'JOBLISTER_IS_LOADING';
  isLoading: boolean;
}

export interface RequestJobsCategoriesAction {
  type: 'JOBLISTER_SET_CATEGORIES';
  jobsCategories: JobsCategories[];
}

export interface SetJobDetail {
  type: 'JOBLISTER_SET_JOB_DETAIL';
  jobDetail: JobDetail;
}

export interface SetJobs {
  type: 'JOBLISTER_SET_JOBS';
  jobsList: JobsList[];
}

export interface SetFilters {
  type: 'SET_FILTERS';
  filters: FiltersDTO | null;
}

export interface SetJobPreviewDetail {
  type: 'JOBLISTER_SET_JOB_DETAIL_PREVIEW';
  jobDetailPreview: CreatePostJobDto | null;
}

export interface DeleteJobDetail {
  type: 'JOBLISTER_UPDATE_JOB_DETAIL';
  jobDetail: JobDetail;
}

export interface SetJobTypes {
  type: 'JOBLISTER_SET_JOB_TYPES';
  jobTypes: JobTypes[];
}

export const actionCreators = {
  getJobs:
    (filter?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: JOBLISTER_IS_LOADING,
          isLoading: true
        });

        let jobsList: JobsList[] = [];
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}joblister/postings?${filter}`
          );

          if (res.status === HttpStatusEnum.OK) jobsList = res.data;
        } catch (e) {
        } finally {
          dispatch({
            type: JOBLISTER_SET_JOBS,
            jobsList
          });

          dispatch({
            type: JOBLISTER_IS_LOADING,
            isLoading: false
          });
        }
      }
    },

  getJobsCategories:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: JOBLISTER_IS_LOADING,
          isLoading: true
        });

        let jobsCategories: JobsCategories[] = [];
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}joblister/categories`
          );

          if (res.status === HttpStatusEnum.OK) jobsCategories = res.data;
        } catch (e) {
        } finally {
          dispatch({
            type: JOBLISTER_SET_CATEGORIES,
            jobsCategories
          });

          dispatch({
            type: JOBLISTER_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  createJobPost:
    (
      jobPostData: CreatePostJobDto,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: JOBLISTER_IS_LOADING,
          isLoading: true
        });
        try {
          await axios.post(
            `${config.USERPROFILE_API_URL!}joblister/postings`,
            jobPostData
          );
          history.push({
            pathname: `/company/profile/jobs/post-job-confirmation`
          });
        } catch (e) {
          const message = 'Error while creating Job Post.';
          enqueueSnackbar(message, { variant: 'error' });
          history.push(`/company/profile/jobs/post-job-create`);
        } finally {
          dispatch({
            type: JOBLISTER_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  getJobDetail:
    (jobId: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}joblister/postings/${jobId}`
          );
          dispatch({
            type: JOBLISTER_SET_JOB_DETAIL,
            jobDetail: { ...res.data }
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  deleteJobPost:
    (jobPostID: string, history: History): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          await axios.delete(
            `${config.USERPROFILE_API_URL!}joblister/posting/${jobPostID}`,
            {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          );
          history.push({ 
            pathname: `/company/profile/jobs/confirmation`,
            state: 'deletePage'
         });
        } catch (e) {
          const message = 'Error while deleting Job Post.';
          enqueueSnackbar(message, { variant: 'error' });
          throw e;
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
    duplicateJobPost:
    (
      jobPostData: CreatePostJobDto,
      jobID: string,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: JOBLISTER_IS_LOADING,
          isLoading: true
        });
        try {
          await axios.post(
            `${config.USERPROFILE_API_URL!}joblister/postings`,
            jobPostData
          );
          history.push({ 
            pathname: `/company/profile/jobs/confirmation`,
            state: 'duplicatePage'
         });
        } catch (e) {
          history.push(`/company/profile/jobs/post-job-detail/${jobID}`);
          const message = 'Error while duplicating Job Post.';
          enqueueSnackbar(message, { variant: 'error' });
        } finally {
          dispatch({
            type: JOBLISTER_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
    updateJobPost:
    (
      jobPostData: CreatePostJobDto,
      jobPostId: string,
      history: History
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: JOBLISTER_IS_LOADING,
          isLoading: true
        });
        try {
          await axios.put(
            `${config.USERPROFILE_API_URL!}joblister/postings/${jobPostId}`,
            jobPostData
          );
          history.push({
            pathname: `/company/profile/jobs/post-job-detail/${jobPostId}`
          });
          const message = 'Job Post was updated.';
          enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
          history.push(`/company/profile/jobs/post-job-create`);
          const message = 'Error while updating Job Post.';
          enqueueSnackbar(message, { variant: 'error' });
        } finally {
          dispatch({
            type: JOBLISTER_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
    setJobDetailPreview:
    (jobDetail: CreatePostJobDto): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          dispatch({
            type: JOBLISTER_SET_JOB_DETAIL_PREVIEW,
            jobDetailPreview: jobDetail
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
    resetJobDetailPreview:
    (): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          dispatch({
            type: JOBLISTER_SET_JOB_DETAIL_PREVIEW,
            jobDetailPreview: null
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
    setJobsFilter:
    (filters: FiltersDTO): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          dispatch({
            type: SET_FILTERS,
            filters: filters
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
    resetJobsFilter:
    (): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          dispatch({
            type: SET_FILTERS,
            filters: null
          });
        } catch (e) {
          console.log('Error occurred', e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },

    getJobTypes:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: JOBLISTER_IS_LOADING,
          isLoading: true
        });

        let jobTypes: JobTypes[] = [];
        try {
          const res = await axios.get(
            `${config.USERPROFILE_API_URL!}joblister/jobtypes`
          );

          if (res.status === HttpStatusEnum.OK) jobTypes = res.data;
        } catch (e) {
        } finally {
          dispatch({
            type: JOBLISTER_SET_JOB_TYPES,
            jobTypes
          });

          dispatch({
            type: JOBLISTER_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
};
export type KnownAction =
  | SetLoadingAction
  | SetJobsIsLoading
  | RequestJobsCategoriesAction
  | SetJobDetail
  | SetJobPreviewDetail
  | SetJobs
  | SetFilters
  | SetJobTypes;
