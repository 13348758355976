import { CSSProperties } from 'react';

export const CompanyProfileLabelStyles: CSSProperties = {
  color: '#FFF',
  fontSize: '16px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '24px'
};

export const CompanyProfileButtonStyles: CSSProperties = {
  width: '225px',
  height: '44px'
};
