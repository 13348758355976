import { DropdownItem, DropdownItemNumber } from '../entities/Common';

export const shortenString = (str: string, length: number) => {
  if (str.length < length) return str;
  return str.substring(0, length - 1).concat('...');
};

export const truncateAzureUrl = (url: string) => {
  return url.substring(url.lastIndexOf('/'), url.length);
};

export const truncateLargeAzureUrl = (url: string) => {
  const newSlice = url.lastIndexOf('/');
  const firstPart = url.substring(newSlice, newSlice + 25);
  const secondPart = url.substring(url.length - 15, url.length);

  if (url.length > 120) {
    return firstPart + '...' + secondPart;
  } else {
    return url.substring(url.lastIndexOf('/'), url.length);
  }
};

export const lowerCaseFirstLetter = (text: string) => {
  return `${text.charAt(0).toLowerCase()}${text.slice(1)}`;
};

export const getDegreeType = (id: number) => {
  switch (id) {
    case 1:
      return 'University';
    case 2:
      return 'High School';
    default:
      return '';
  }
};

export const getIAmType = (id: number | undefined) => {
  switch (id) {
    case 1:
      return 'Looking to join the industry';
    case 2:
      return 'Business Leader';
    case 3:
      return 'Working in the industry';
    default:
      return '';
  }
};

export const getMonthName = (monthNumber: number) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'short' });
};
