import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';
import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material';
import FormLabel from '../core/FormLabel/FormLabel';
import {
  truncateAzureUrl,
  truncateLargeAzureUrl
} from '../../utils/stringUtils';

export interface InputFileUploadProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialText: string;
  thumbnailUrl: string;
}

const uploadContainerStyle: CSSProperties = {
  display: 'inline-block',
  padding: '10px 60px',
  cursor: 'pointer',
  margin: '20px',
  borderRadius: '20px'
};

const hideInputFileStyle: CSSProperties = { display: 'none' };

const inputContainerStyle: CSSProperties = {
  padding: '5% 10% 0 10%',
  textAlign: 'center'
};

const indicationStyle: CSSProperties = { paddingBottom: '30px' };

export const InputFileUpload: React.FC<InputFileUploadProps> = ({
  onChange,
  initialText
}: InputFileUploadProps) => {
  const [text, setText] = React.useState<string>(initialText);
  const handleFileChange = (e: any) => {
    let parts = e.target.value.split('\\');
    const fileName = parts[parts.length - 1];
    const extension = fileName.split('.').pop();
    const cutFileName =
      fileName.length > 30
        ? fileName.substring(0, 31).split('.')[0] + '...' + extension
        : fileName;
    setText(cutFileName);
    onChange(e);
  };

  const theme = useTheme();

  const primaryStyle: CSSProperties = {
    background: theme.palette.primary.main,
    color: theme.palette.common.black
  };

  let finalStyle = { ...uploadContainerStyle, ...primaryStyle };

  return (
    <div style={inputContainerStyle}>
      <p>{text}</p>
      <label style={finalStyle}>
        <input
          type="file"
          style={hideInputFileStyle}
          onChange={handleFileChange}
        />
        Upload an Image
      </label>
      <p style={indicationStyle}>
        Color photos work best for Learning Plans and Lessons. <br /> Black &
        White photos are recommended for Courses.
      </p>
    </div>
  );
};
