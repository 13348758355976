import { AppThunkAction } from '../store/index';
import {
  Course,
  CourseLessonName,
  ImportLessonDto,
  LessonOrder,
  ImportCourseDto,
  AssignCourseToEmployeeDto
} from '../entities/Course';
import { Tag } from '../entities/Tag';
import axios from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  COURSES_SET_COURSES,
  COURSES_SET_TAGS,
  COURSE_BY_ID,
  COURSES_SET_LESSON_NAMES,
  COURSES_UPDATE,
  COURSE_RESET_ERROR,
  COURSES_SET_COURSE_LIST,
  COURSES_UPDATE_TRANSACTION_STATUS,
  COURSES_RESET_TRANSACTION_STATUS
} from './actionTypes';
import config from './../config';
import { uploadCourseThumbToBlob } from '../services/blobStorage-service';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import { Guid } from 'guid-typescript';

export interface RequestCoursesAction {
  type: 'COURSES_SET_COURSES';
  courses: Course[];
}

export interface SetEditingCourseAction {
  type: 'COURSES_SET_EDITING';
  course: Course;
}

export interface UpdateCourseAction {
  type: 'COURSES_UPDATE';
  course: Course;
}

export interface RequestTagsAction {
  type: 'COURSES_SET_TAGS';
  tags: Tag[];
}

export interface RequestCourseByIdAction {
  type: 'COURSE_BY_ID';
  course: Course | null;
}

export interface RequestCourseLessonNamesAction {
  type: 'COURSES_SET_LESSON_NAMES';
  lessonNames: CourseLessonName[];
}

export interface UpdateCourseLessonsOrderAction {
  type: 'COURSES_UPDATE_LESSON_ORDER';
  lessonsOrder: LessonOrder[];
}

export interface SetErrorAction {
  type: 'COURSE_ERROR';
  error: string;
}

export interface ResetErrorAction {
  type: 'COURSE_RESET_ERROR';
}

export interface RequestCourseListAction {
  type: 'COURSES_SET_COURSE_LIST';
  courseList: Course[];
}

export interface UpdateCourseTransactionStatusAction {
  type: 'COURSES_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetCourseTransactionStatusAction {
  type: 'COURSES_RESET_TRANSACTION_STATUS';
}

export const actionCreators = {
  requestCourses: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    dispatch({
      type: COURSE_BY_ID,
      course: null
    });

    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL!}courses/all`);
      dispatch({
        type: COURSES_SET_COURSES,
        courses: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestAllCourses: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL!}courses/all`);
      dispatch({
        type: COURSES_SET_COURSE_LIST,
        courseList: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestBuildwittCourses: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();

    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}courses/buildwitt`
      );
      dispatch({
        type: COURSES_SET_COURSE_LIST,
        courseList: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  setCourseList: (courseList: Course[]) => ({
    type: COURSES_SET_COURSE_LIST,
    courseList
  }),
  filterCourses: (text: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState && appState.courses?.courses) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}courses/filter?criteria=${text}`
      );
      dispatch({
        type: COURSES_SET_COURSES,
        courses: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  requestCourseTags: (): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState && appState.courses?.tags) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL!}courses/tags`);

      dispatch({
        type: COURSES_SET_TAGS,
        tags: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  requestCourseById: (id: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState && Guid.isGuid(id)) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL!}courses/${id}`);
      dispatch({
        type: COURSE_BY_ID,
        course: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  requestCourseLessonNames: (id: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}courses/${id}/lesson/names`
      );
      dispatch({
        type: COURSES_SET_LESSON_NAMES,
        lessonNames: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  updateLessonsOrder: (
    courseId: string,
    lessonsOrder: LessonOrder[]
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      await axios.put(
        `${config.COURSES_API_URL!}courses/${courseId}/lesson/order`,
        lessonsOrder
      );
      dispatch(appActions.setIsLoading(false));
    }
  },

  saveCourseAndEditLessons: (
    courseDto: Course,
    file: File,
    history: any,
    imageUrl: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    let NewThumbnailUrl;
    if (appState) {
      dispatch({ type: COURSE_RESET_ERROR });
      dispatch(appActions.setIsLoading(true));

      try {
        if (file) {
          const blobUrl = await uploadCourseThumbToBlob(file);
          NewThumbnailUrl = blobUrl;
        }
        if (imageUrl) {
          NewThumbnailUrl = imageUrl;
        }

        let formData: FormData;
        formData = new FormData();
        formData.append(
          'courseJson',
          JSON.stringify({
            title: courseDto.title,
            description: courseDto.description,
            ThumbnailUrl: NewThumbnailUrl,
            companyId: courseDto.companyId,
            isDraft: courseDto.isDraft,
            Tags: courseDto.tags
          })
        );

        const res = await axios.post(
          `${config.COURSES_API_URL!}courses/`,
          formData
        );

        dispatch({
          type: COURSES_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            res.status === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });

        if (res.status === HttpStatusEnum.OK) {
          dispatch({
            type: COURSES_UPDATE,
            course: res.data
          });
        }

        history.push(`/courses/${res.data.id}/edit`);
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  editCourse: (
    courseDto: Course,
    imgUrl: string,
    file?: File
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      try {
        if (file) {
          const blobUrl = await uploadCourseThumbToBlob(file);
          courseDto.thumbnailUrl = blobUrl;
        } else if (imgUrl) {
          courseDto.thumbnailUrl = imgUrl;
        }

        const res = await axios.put(
          `${config.COURSES_API_URL!}courses/`,
          courseDto
        );

        dispatch({
          type: COURSE_BY_ID,
          course: res.data
        });
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  deleteCourse: (courseId: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      try {
        await axios.delete(
          `${config.COURSES_API_URL!}courses/?courseId=${courseId}`,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        );
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  deleteFullCourse: (courseId: string): AppThunkAction<KnownAction> => async (
    dispatch,
    getState
  ) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      try {
        await axios.delete(
          `${config.COURSES_API_URL!}courses/?courseId=${courseId}&isFull=true`,
          {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        );
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  importLessonsBatch: (
    id: string,
    selectedLessons: ImportLessonDto[]
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      await axios.put(
        `${config.COURSES_API_URL!}courses/${id}/import/lessons/batch`,
        {
          list: selectedLessons
        }
      );

      dispatch(appActions.setIsLoading(false));
    }
  },
  importCoursesBatch: (
    selectedCourses: ImportCourseDto[]
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      await axios.put(
        `${config.COURSES_API_URL!}courses/import/courses/batch`,
        {
          list: selectedCourses
        }
      );

      dispatch(appActions.setIsLoading(false));
    }
  },
  assignCourseToEmployees: (
    courseAssignment: AssignCourseToEmployeeDto
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      let httpStatus: number = 0;
      try {
        const res = await axios.post(
          `${config.STUDENT_API_URL!}assignments/assign/courses/employees/`,
          courseAssignment
        );
        httpStatus = res.status;
      } catch (e) {
      } finally {
        dispatch(appActions.setIsLoading(false));
        dispatch({
          type: COURSES_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            httpStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
      }
    }
  },

  requestCourseByIdAndRevisionNumber: (
    id: string,
    revisionNumber: number
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState && Guid.isGuid(id)) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(
        `${config.COURSES_API_URL!}courses/${id}/${revisionNumber}`
      );
      dispatch({
        type: COURSE_BY_ID,
        course: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  resetCourseTransactionStatus: () => ({
    type: COURSES_RESET_TRANSACTION_STATUS
  }),

  setCurrentCourse: (course: Course | null) => ({
    type: COURSE_BY_ID,
    course
  }),
  deleteLessonFromCourse: (
    courseId: string,
    lessonId: string
  ): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      try {
        await axios.put(
          `${config.COURSES_API_URL!}courses/${courseId}/remove/${lessonId}`,
          null
        );
      } catch (e) {
        throw e;
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  }
};

export type KnownAction =
  | RequestCoursesAction
  | UpdateCourseAction
  | SetLoadingAction
  | RequestTagsAction
  | RequestCourseByIdAction
  | SetEditingCourseAction
  | RequestCourseLessonNamesAction
  | UpdateCourseLessonsOrderAction
  | SetErrorAction
  | RequestCourseListAction
  | UpdateCourseTransactionStatusAction
  | ResetCourseTransactionStatusAction
  | ResetErrorAction;
