import React, { useRef, useEffect, useState, CSSProperties } from 'react';
import ReactPlayer from 'react-player';
import Duration from './Duration';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { Lesson } from '../../../entities/Lesson';
import VolumeUp from '../../../assets/volumeUp.svg';
import Play from '../../../assets/play.svg';
import Pause from '../../../assets/pause.svg';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators } from '../../../actions/lessons';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Subtitles } from '../../../entities/LessonFormModel';
import { LanguagesSubtitles } from '../../../core/constants';
import { TrackProps } from 'react-player/file';

interface VideoSectionProps {
  currentlesson: Lesson;
  onVideoEnded?: () => void;
  onLoaded: boolean;
}

const VideoSection: React.FC<VideoSectionProps> = ({
  currentlesson,
  onVideoEnded,
  onLoaded
}) => {
  const [state, setState] = useState({
    url: '',
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    volumeOpen: true,
    dropdownOpen: false,
    fullscreen: false,
    seeking: false,
    visible_button_refresh: false
  });
  const {
    pip,
    playing,
    controls,
    seeking,
    volumeOpen,
    volume,
    muted,
    loop,
    playbackRate,
    visible_button_refresh,
    played,
    duration,
    dropdownOpen
  } = state;
  const [valueProgress, setValueProgres] = useState<string>('');
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState(true);
  const [playedProgress, setPlayedProgress] = useState<number>(0);
  const playBackPosition = useSelector(
    (state: ApplicationState) => state.lessons?.currentVideoPosition
  );
  const isTracked = useSelector(
    (state: ApplicationState) => state.lessons?.isTracked
  );
  const dispatch = useDispatch();
  const playedProgressRef = useRef<number>(0);
  const { t } = useTranslation(['common']);
  const [videoTracks, setVideoTracks] = useState<TrackProps[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [langShow, setLangShow] = useState(false);

  useEffect(() => {
    setIsLoaded(onLoaded);
    dispatch(actionCreators.requestPositionTracking(currentlesson.videoId));
    if (
      currentlesson &&
      ((currentlesson.externalVideoUrl &&
        currentlesson.externalVideoUrl.trim().length) ||
        (currentlesson.videoUrl && currentlesson.videoUrl.trim().length))
    ) {
      const url =
        currentlesson.externalVideoUrl &&
        currentlesson.externalVideoUrl.trim().length
          ? currentlesson.externalVideoUrl
          : currentlesson.videoUrl;
      setVideoUrl(url);
    } else if (videoUrl.length) setVideoUrl('');
    loadVideoTracks(currentlesson.subtitles!!);
  }, [currentlesson]);

  useEffect(() => {
    playedProgressRef.current = playedProgress;
  }, [playedProgress]);

  useEffect(() => {
    return () => {
      dispatch(
        actionCreators.savePositionTracking(
          currentlesson.videoId,
          Math.floor(playedProgressRef.current)
        )
      );
      dispatch(actionCreators.resetPositionTracking());
    };
  }, [currentlesson]);

  const styleInputProgressbar = {
    appearance: 'none' as 'none',
    height: '15px',
    opacity: 1,
    outline: 'none',
    margin: '0 auto',
    width: '70%',
    backgroundSize: valueProgress,
    backgroundImage: `linear-gradient(orange, orange)`,
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      background: '#efefef'
    }
  };

  const player = useRef<ReactPlayer>(null);

  const handlePlayPause = () => {
    setState({ ...state, playing: !playing });
  };

  const loadVideoTracks = (subtitles: Subtitles[]) => {
    var tracks: TrackProps[] = [];
    var browserLanguage = getSelectedLanguageByBrowser();
    if (subtitles) {
      subtitles.forEach(s => {
        var srcLang = LanguagesSubtitles.find(x => x.label === s.language);
        var srcLangValue = srcLang ? srcLang.srcLang : '';
        tracks.push({
          kind: 'subtitles',
          src: s.url!!,
          srcLang: srcLangValue,
          label: s.language,
          default: s.language.toLowerCase() == browserLanguage.toLowerCase()
        });
      });
      setVideoTracks(tracks);
      var selectedLanguageLoaded = tracks.find(x => x.default);
      if (selectedLanguageLoaded) {
        setSelectedLanguage(selectedLanguageLoaded.label);
      }
    }
  };

  const getSelectedLanguageByBrowser = () => {
    var langItem = LanguagesSubtitles.find(x =>
      navigator.language.toLowerCase().startsWith(x.srcLang.toLowerCase())
    );
    return langItem ? langItem.label : '';
  };

  const handleVolumeChange = (e: React.ChangeEvent) => {
    const { target } = e;
    setState({
      ...state,
      volume: parseFloat((target as HTMLInputElement).value)
    });
    let value: string = (target as HTMLInputElement).value;
    let style: CSSStyleDeclaration = (target as HTMLInputElement).style;
    style.backgroundSize = parseFloat(value) * 100 + '% 100%';
  };

  const handleSetPlaybackRate = (e: React.MouseEvent) => {
    const { target } = e;
    setState({
      ...state,
      playbackRate: parseFloat((target as HTMLButtonElement).value),
      dropdownOpen: !dropdownOpen
    });
  };

  const handlePlay = () => {
    if (!playing) {
      setState({ ...state, playing: true });
    }
  };

  const handleEnablePIP = () => {
    setState({ ...state, pip: true });
  };

  const handleDisablePIP = () => {
    setState({ ...state, pip: false });
  };

  const handlePause = () => {
    setState({ ...state, playing: false });
  };

  const handleSeekMouseDown = (e: React.MouseEvent) => {
    const { target } = e;
    setState({ ...state, seeking: false });
    let playedPosition = played * 100;
    if (player?.current) {
      if (
        currentProgress > playedPosition &&
        currentProgress != playedPosition
      ) {
        player.current.seekTo(parseFloat((target as HTMLInputElement).value));
      }
    }
  };
  const handleSeekChange = (e: React.ChangeEvent) => {
    const { target } = e;
    let value: string = (target as HTMLInputElement).value;
    let style: CSSStyleDeclaration = (target as HTMLInputElement).style;
    setState({
      ...state,
      played: parseFloat(value)
    });
    style.backgroundSize = parseFloat(value) * 100 + '% 100%';
  };

  const handleSeekMouseUp = (e: React.MouseEvent) => {
    const { target } = e;
    setState({ ...state, seeking: false });
    let playedPosition = played * 100;
    if (player?.current) {
      if (
        currentProgress > playedPosition &&
        currentProgress != playedPosition
      ) {
        player.current.seekTo(parseFloat((target as HTMLInputElement).value));
      }
    }
  };

  const handleProgress = (stateIn: {
    played: number;
    playedSeconds: number;
  }) => {
    if (!seeking) {
      setState({ ...state, ...stateIn });
      let progress = stateIn.played * 100 + '% 100%';
      setValueProgres(progress);
      setCurrentProgress(stateIn.played * 100);
    }
    setPlayedProgress(stateIn.playedSeconds);
  };

  const handleEnded = () => {
    setPlayedProgress(0);
    dispatch(
      actionCreators.savePositionTracking(currentlesson.videoId, playedProgress)
    );
    setState({ ...state, playing: false });
    if (onVideoEnded) onVideoEnded();
  };

  const handleDuration = (duration: number) => {
    setState({ ...state, duration });
  };

  const toggleVolume = () => {
    setState({ ...state, volumeOpen: !volumeOpen });
  };

  const handleStart = () => {
    setState({ ...state, visible_button_refresh: true });
    if (player?.current) {
      player.current.seekTo(playBackPosition!);
    }
    setIsLoaded(false);
  };
  let youtubePath = videoUrl.includes('//www.youtube.com');

  const handleSwitchLanguage = (e: React.MouseEvent) => {
    const { target } = e;
    let selected = (target as HTMLButtonElement).value;
    setSelectedLanguage(selected);
    changeLanguage(selected);
    setLangShow(!langShow);
  };

  const changeLanguage = (languageLabel: string) => {
    var ytracks = document.querySelector('video')!!.textTracks;

    for (var i = 0, L = ytracks.length; i < L; i++) {
      if (ytracks[i].label.toLowerCase() === languageLabel.toLowerCase()) {
        ytracks[i].mode = 'showing';
      } else {
        ytracks[i].mode = 'hidden';
      }
    }
  };

  const videoConfigurations = (tracks: TrackProps[]) => ({
    file: {
      attributes: {
        crossOrigin: 'anonymous'
      },
      tracks: tracks
    }
  });

  return (
    <div className="App App-header">
      <>
        <div>
          {(videoUrl.length && playBackPosition != 0 && isTracked === true) ||
          (videoUrl.length && playBackPosition === 0 && isTracked === false) ? (
            <div className="Test" style={stylevideoSection}>
              <ReactPlayer
                width="100%"
                height="600px"
                url={videoUrl}
                ref={player}
                light=""
                pip={pip}
                playing={playing}
                controls={controls}
                loop={loop}
                playbackRate={playbackRate}
                volume={volume}
                muted={muted}
                onStart={handleStart}
                onPlay={handlePlay}
                onEnablePIP={handleEnablePIP}
                onDisablePIP={handleDisablePIP}
                onPause={handlePause}
                onBuffer={() => {}}
                onSeek={e => {}}
                onEnded={handleEnded}
                onError={e => e}
                onProgress={handleProgress}
                onDuration={handleDuration}
                onReady={() => handlePlayPause()}
                config={videoConfigurations(videoTracks)}
              />
              {isLoaded && !youtubePath && (
                <CircularProgress style={styleCirProgress} />
              )}
            </div>
          ) : (
            <div style={noVideoWrapper}>
              <h3>No Video Content</h3>
            </div>
          )}
          <div>
            <Row className="justify-content-center h-100 align-items-center">
              <Col>
                <div className="player-wrapper h-100 d-flex justify-content-center">
                  {visible_button_refresh && (
                    <div
                      style={videoController}
                      className="video-controller justify-content-between"
                    >
                      <div className=" pl-1 d-flex align-items-center ">
                        <button
                          style={buttonPlay}
                          type="button"
                          onClick={() => handlePlayPause()}
                          className="play-pause pr-2"
                        >
                          {playing ? (
                            <img width="30" src={Pause} />
                          ) : (
                            <img width="30" src={Play} />
                          )}
                        </button>
                      </div>
                      <div
                        style={styleVolume}
                        className={` d-flex align-items-center videoProgress ${
                          volumeOpen ? 'volume' : ''
                        }`}
                      >
                        <img
                          src={VolumeUp}
                          alt=""
                          onClick={() => toggleVolume()}
                        />
                        {volumeOpen && (
                          <input
                            style={inputVolume}
                            className="slider"
                            width="50px"
                            type="range"
                            min={0}
                            max={1}
                            step="any"
                            value={volume}
                            onChange={handleVolumeChange}
                          />
                        )}
                      </div>
                      <div
                        style={styleProgressbar}
                        className="d-flex align-items-center videoProgress"
                      >
                        <input
                          style={{ ...styleInputProgressbar }}
                          type="range"
                          className="slider"
                          min={0}
                          max={0.999999}
                          step="any"
                          value={played}
                          onMouseDown={handleSeekMouseDown}
                          onChange={handleSeekChange}
                          onMouseUp={handleSeekMouseUp}
                        />
                        <Duration
                          seconds={duration * (1 - played)}
                          className="time pl-2"
                        />
                      </div>
                      <div className="">
                        <Dropdown autoClose="inside" show={langShow}>
                          <Dropdown.Toggle
                            style={toggleRate}
                            onClick={() => setLangShow(!langShow)}
                            disabled={!videoTracks || videoTracks.length == 0}
                          >
                            {videoTracks &&
                            videoTracks.length > 0 &&
                            selectedLanguage
                              ? selectedLanguage
                              : 'No captions'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <button
                              style={styleBtnPlaybackRate}
                              onClick={handleSwitchLanguage}
                              value={'No captions'}
                            >
                              {'No captions'}
                            </button>
                            {videoTracks &&
                              videoTracks.map(t => (
                                <button
                                  style={styleBtnPlaybackRate}
                                  onClick={handleSwitchLanguage}
                                  value={t.label}
                                >
                                  {t.label}
                                </button>
                              ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="">
                        <Dropdown>
                          <Dropdown.Toggle style={toggleRate}>
                            {`${playbackRate}x`}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div>
                              <button
                                style={styleBtnPlaybackRate}
                                onClick={handleSetPlaybackRate}
                                value={0.5}
                              >
                                0.5x
                              </button>
                            </div>
                            <div>
                              <button
                                style={styleBtnPlaybackRate}
                                onClick={handleSetPlaybackRate}
                                value={1}
                              >
                                1x
                              </button>
                            </div>
                            <div>
                              <button
                                style={styleBtnPlaybackRate}
                                onClick={handleSetPlaybackRate}
                                value={1.25}
                              >
                                1.25x
                              </button>
                            </div>
                            <div>
                              <button
                                style={styleBtnPlaybackRate}
                                onClick={handleSetPlaybackRate}
                                value={1.5}
                              >
                                1.5x
                              </button>
                            </div>
                            <div>
                              <button
                                style={styleBtnPlaybackRate}
                                onClick={handleSetPlaybackRate}
                                value={2}
                              >
                                2x
                              </button>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col style={infoSectionBottom} md={12}>
                <h3>{currentlesson.title}</h3>
                <p>{currentlesson.description}</p>
              </Col>
            </Row>
          </div>
        </div>
      </>
    </div>
  );
};

const infoSectionBottom: CSSProperties = {
  justifyContent: 'space-between'
};

const stylevideoSection: CSSProperties = {
  position: 'relative'
};

const styleCirProgress: CSSProperties = {
  position: 'absolute',
  top: '45%',
  left: '45%',
  bottom: 0
};

const noVideoWrapper: CSSProperties = {
  height: '200px',
  color: 'orange'
};

const videoController: CSSProperties = {
  bottom: '0',
  width: '100%',
  margin: '0',
  display: 'flex'
};

const styleVolume: CSSProperties = {
  width: '15%'
};

const inputVolume: CSSProperties = {
  width: '100%'
};

const styleProgressbar: CSSProperties = {
  width: '60%'
};

const buttonPlay: CSSProperties = {
  background: 'none',
  border: 'none'
};

const styleBtnPlaybackRate: CSSProperties = {
  width: '100%',
  margin: '5px 0px',
  border: 'none'
};

const toggleRate: CSSProperties = {
  background: '#fa9e04',
  borderColor: 'fa9e04'
};

export default VideoSection;
