import React, { FC, useEffect } from 'react';
import TextArea from '../core/TextArea/TextArea';
import DropDownlist from '../core/DropDownList/DropDownList';
import { Col, Row, FormLabel } from 'react-bootstrap';
import { Question } from '../../entities/Assessment';
import { useTranslation } from 'react-i18next';

const responseOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];

interface TrueFalseQuestionDefinitionProps {
  question: Question;
  changeHandler: (prop: string, value: any) => void;
}

const TrueFalseQuestionDefinition: FC<TrueFalseQuestionDefinitionProps> = ({
  question,
  changeHandler
}) => {
  const { t } = useTranslation(['assessments', 'common']);

  return (
    <>
      <Row>
        <Col>
          <FormLabel>
            {t('question', {ns: 'assessments'})}<span className="text-danger">*</span>
          </FormLabel>
          <br />
          <TextArea
            name="questionText[]"
            id=""
            value={question.questionText}
            onChangeValue={value => changeHandler('questionText', value)}
            placeholderText={t('name', {ns: 'common'})}
            width="100%"
            rows={5}
          ></TextArea>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormLabel>
            {t('correctResponse', {ns: 'assessments'})}<span className="text-danger">*</span>
          </FormLabel>
          <br />
          <DropDownlist
            name="trueIsCorrectAnswer[]"
            id=""
            placeholder={t('selectOption', {ns: 'common'})}
            options={responseOptions}
            value={responseOptions.find(
              c => c.value === question.trueIsCorrectAnswer
            )}
            onChange={(newValue: any) => {
              changeHandler('trueIsCorrectAnswer', newValue.value as boolean);
            }}
          ></DropDownlist>
        </Col>
      </Row>
    </>
  );
};

export default TrueFalseQuestionDefinition;
