import React, { useEffect, CSSProperties, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { actionCreators } from '../../actions/courses';
import { actionCreators as actionLessons } from '../../actions/lessons';
import { actionCreators as actionStudent } from '../../actions/student';
import { actionCreators as actionAssesments } from '../../actions/assessments';
import { ApplicationState } from '../../store';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import LessonCard from '../../components/LessonCard/LessonCard';
import BackwardIcon from '../../assets/backward.svg';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import CourseInfoPanel from '../../components/CourseInfoPanel/CourseInfoPanel';
import { Typography } from '@mui/material';
import { CompletedLessonInfo } from '../../entities/CompletedLessonInfo';
import { Lesson } from '../../entities/Lesson';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';

export interface CoursePreviewProps {}

const fontButton: CSSProperties = {
  fontSize: '15px',
  color: '#0E0D0D'
};

const buttonStyle: CSSProperties = {
  width: '160px',
  height: '45px'
};

const CoursePreview: React.FC<CoursePreviewProps> = () => {
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const logEvent = useLogEvent();
  const [courseCompletedLessons, setCourseCompletedLessons] = useState<
    CompletedLessonInfo[]
  >([]);

  const course = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const completedLessons = useSelector(
    (state: ApplicationState) => state.student?.completedLessons
  );

  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const lessons = useSelector(
    (state: ApplicationState) => state.lessons?.lessons
  );

  const listFailedQuiz = useSelector(
    (state: ApplicationState) => state.assessments?.failedQuizzes
  );

  const { t } = useTranslation(['common', 'lessons']);

  const BackwardIconStyle: CSSProperties = {
    display: 'inline-block',
    width: 10,
    height: 18,
    cursor: 'pointer',
    backgroundImage: `url(${BackwardIcon})`
  };

  const ContainerStyle: CSSProperties = {
    color: '#999',
    backgroundColor: '#000',
    backgroundImage: `linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%), linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5) 2%, rgba(0, 0, 0, 0)), linear-gradient(-90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5) 2%, rgba(0, 0, 0, 0)), url('${course?.thumbnailUrl}')`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'luminosity'
  };

  const cardItemStyle = {
    paddingTop: '10px'
  };

  let quizAssesmentId: string[] = [];
  {
    lessons?.map(lesson => {
      if (lesson.lessonType === 'Quiz') {
        quizAssesmentId.push(lesson.assessmentId!);
      }
    });
  }

  useEffect(() => {
    if (params && params.id && params.revisionNumber) {
      dispatch(
        actionCreators.requestCourseByIdAndRevisionNumber(
          params.id,
          params.revisionNumber
        )
      );
      dispatch(
        actionLessons.requestCourseLessonsByRevision(
          params.id,
          params.revisionNumber
        )
      );
    }
  }, [params]);

  useEffect(() => {
    if (course) {
      dispatch(
        actionStudent.getCompletedLessonsByCourseId(
          course!.id,
          course!.revisionNumber
        )
      );
    }
  }, [course]);

  useEffect(() => {
      dispatch(
        actionAssesments.failedQuizzesResult(
          quizAssesmentId
        )
      );
    }, [lessons]);

  useEffect(() => {
    if (completedLessons) {
      setCourseCompletedLessons(completedLessons);
    }
  }, [completedLessons]);

  const backToCourses = () => {
    history.goBack();
  };

  const handlePlayLesson = (lesson: Lesson) => {
    logEvent.logPlayLessonEvent(lesson.id);
    history.push({
      pathname: `/courses/${course?.id}/revision/${course?.revisionNumber}/play`,
      state: { lesson }
    });
  };

  if (!course) return <></>;
  else
    return (
      <PageContainer style={ContainerStyle}>
        <div>
          <div className="d-flex flex-row">
            <div className="pt-2 pe-2">
              <i
                className="fas fa-sm fa-fw mr-2 text-gray-400"
                style={BackwardIconStyle}
                onClick={backToCourses}
              />
            </div>
            <div className="w-100">
              <Row>
                <Col xs={8}>
                  <h3>{course?.title}</h3>
                </Col>
                <Col className="text-right mr-3">
                  <BasicButton
                    color="primary"
                    onClick={() =>
                      history.push(
                        `/courses/${course.id}/revision/${course.revisionNumber}/preview`
                      )
                    }
                    style={buttonStyle}
                  >
                    <Typography sx={fontButton}>
                      {t('preview', { ns: 'common' })}
                    </Typography>
                  </BasicButton>
                </Col>
              </Row>
              <CourseInfoPanel
                course={course}
                totalLessonsCount={lessons?.length ?? 0}
                completedLessonsCount={courseCompletedLessons.length}
                isLoadingInfo={studentIsLoading}
              />
              <Row className="pt-5">
                <h4>{t('lessons', { ns: 'lessons' })}</h4>
              </Row>
              <Row className="pt-3">
                <Col className="d-flex align-content-between flex-wrap">
                  {lessons?.map(lesson => {
                    const isLessonComplete =
                      completedLessons?.some(
                        completeLesson => completeLesson.lessonId === lesson.id
                      ) ?? false;
                    return (
                      <Col
                        key={lesson.id}
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        style={cardItemStyle}
                      >
                        <div className="pt-2">
                          <LessonCard
                            lesson={lesson}
                            isComplete={isLessonComplete}
                            listFailedQuiz={listFailedQuiz!}
                          >
                            {!course.isDraft && (
                              <BasicButton
                                color="primary"
                                width="109px"
                                height="36.5px"
                                onClick={() => handlePlayLesson(lesson)}
                              >
                                {t('play', { ns: 'common' })}
                              </BasicButton>
                            )}
                          </LessonCard>
                        </div>
                      </Col>
                    );
                  })}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </PageContainer>
    );
};

export default CoursePreview;
