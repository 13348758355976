import React from 'react';
import StartQuiz from '../../Quizzes/StartQuiz';

const QuizSection: React.FC = () => {
  return (
    <div
      style={{
        height: '500px',
        margin: '10px 20px',
        backgroundColor: '#212121'
      }}
      className="rounded d-flex flex-column justify-content-center"
    >
      <StartQuiz />
    </div>
  );
};

export default QuizSection;
