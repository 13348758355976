import React, { CSSProperties, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import { actionCreators as studentActions } from '../../actions/student';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ApplicationState } from '../../store';
import { UserClaims } from '../../core/constants';
import AssignedLessons from './DailyLessons';
import { SxProps } from '@mui/system';
import AssignmentsByDueDate from './AssignmentsByDueDate';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as courseActions } from '../../actions/courses';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { useTranslation } from 'react-i18next';

export interface AssignmentsProps {}

const Assignments: React.FC<AssignmentsProps> = () => {
  const { t } = useTranslation(['assignments']);
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const learningPlayList = useSelector(
    (state: ApplicationState) => state.student?.learningPlayList
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const employeeId = user?.profile[UserClaims.EmployeeId] as string;

  useEffect(() => {
    if (employeeId) {
      dispatch(studentActions.getEmployeePlaylist(employeeId));
    }
  }, [employeeId]);

  useEffect(() => {
    dispatch(lessonActions.setCurrentLessons([]));
    dispatch(courseActions.setCurrentCourse(null));
    dispatch(learningPlanActions.setCurrentLearningPlan(null));
    dispatch(learningPlanActions.setLearningPlanCourses([]));
    dispatch(studentActions.setCompletedCourses([]));
  }, []);

  return (
    <>
      <PageContainer>
        <Grid container>
          <Grid sx={containerDailyLessonsStyle} item xs={12}>
            <Typography style={typoStyle}>
              {t('dailyLessons', { ns: 'assignments' })}
            </Typography>
            {!isLoading && learningPlayList ? (
              <AssignedLessons dailyLessons={learningPlayList?.dailyLessons} />
            ) : (
              <div style={progressContainerStyle}>
                <CircularProgress />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography style={assignmentTitleStyle}>
              {t('assignments', { ns: 'assignments' })}
            </Typography>
            {!isLoading && learningPlayList ? (
              <AssignmentsByDueDate learningPlanListItems={learningPlayList} />
            ) : (
              <div style={progressContainerStyle}>
                <CircularProgress />
              </div>
            )}
          </Grid>
        </Grid>
      </PageContainer>
    </>
  );
};

const typoStyle: CSSProperties = {
  fontSize: '30px',
  paddingBottom: '1rem'
};

const assignmentTitleStyle: CSSProperties = {
  ...typoStyle,
  paddingTop: '2rem'
};

const progressContainerStyle: CSSProperties = {
  textAlign: 'center'
};

const containerDailyLessonsStyle: SxProps = {
  paddingBottom: '1rem'
};

export default Assignments;
