import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import useCurrentUser from '../../hooks/useCurrentUser';
import { BuildWittName, UserClaims } from '../../core/constants';
import {
  Buildwitt_Value,
  Company_Value,
  TemporalNewId
} from '../../core/constants';
import BasicToggleButton from '../../components/core/BasicToggleButton/BasicToggleButton';
import { actionCreators } from '../../actions/courses';
import { ApplicationState } from '../../store';
import { Course } from '../../entities/Course';
import CourseCard from '../../components/CourseCard/CourseCard';
import SelectEntityButton from '../../components/SelectEntityButton/SelectEntityButton';
import { LibraryTypesEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';

export interface SelectCoursesProps {
  currentCourses?: Course[];
  coursesLibraryType: LibraryTypesEnum;
  getSelectedCoursesIds: (coursesIds: string[]) => void;
}

const SelectCourses: React.FC<SelectCoursesProps> = ({
  currentCourses = [],
  coursesLibraryType,
  getSelectedCoursesIds
}) => {
  const [CoursesSource, setCoursesSource] = useState(Company_Value);
  const [coursesToShow, setCoursesToShow] = useState<Course[]>([]);
  const [selectedCoursesIds, setSelectedCoursesIds] = useState<string[]>([]);
  const coursesInSource = useRef<Course[]>([]);
  const searchValue = useRef<string>('');
  const user = useCurrentUser();
  const courseList = useSelector(
    (state: ApplicationState) => state.courses?.courseList
  );
  const dispatch = useDispatch();
  const companyName = user?.profile[UserClaims.CompanyName];
  const companyId = user?.profile[UserClaims.CompanyId];
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId];
  const { t } = useTranslation(['common', 'courses']);

  const coursesSources = [
    {
      text: companyName,
      value: Company_Value
    },
    {
      text: BuildWittName,
      value: Buildwitt_Value
    }
  ];

  useEffect(() => {
    if (coursesLibraryType === LibraryTypesEnum.CompanyAndBuildwitt)
      dispatch(actionCreators.requestAllCourses());
    else if (coursesLibraryType === LibraryTypesEnum.Buildwitt)
      dispatch(actionCreators.requestBuildwittCourses());

    return () => {
      dispatch(actionCreators.setCourseList([]));
    };
  }, []);

  useEffect(() => {
    getSelectedCoursesIds(selectedCoursesIds);
  }, [selectedCoursesIds]);

  useEffect(() => {
    let filteredCourses = courseList
      ? [...courseList.filter(course => !course.isDraft)]
      : [];
    if (coursesLibraryType === LibraryTypesEnum.CompanyAndBuildwitt) {
      filteredCourses =
        filteredCourses?.filter(course => {
          const notInCourses = !currentCourses.some(
            currentCourse => currentCourse.id === course.id
          );

          const isInCompany =
            CoursesSource === Buildwitt_Value
              ? course.companyId === TemporalNewId ||
                course.companyId === bWCompanyId ||
                course.companyId === null
              : course.companyId === companyId;

          return notInCourses && isInCompany;
        }) || [];
    }

    coursesInSource.current = filteredCourses;

    if (searchValue.current.length) handleSearchChange(searchValue.current);
    else setCoursesToShow(filteredCourses);
  }, [CoursesSource, courseList]);

  const handleSearchChange = (value: string) => {
    value = value.toLowerCase();
    const filteredCourses = coursesInSource.current.filter(
      course =>
        course.title.toLowerCase().includes(value) ||
        course.description.toLowerCase().includes(value) ||
        course.tags.find(tag => tag.name.toLowerCase().includes(value))
    );

    searchValue.current = value;
    setCoursesToShow(filteredCourses);
  };

  const onChangeCourseSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCoursesIds(currentSelection => {
      if (e.target.checked) {
        return [...currentSelection, e.target.id];
      } else {
        return currentSelection.filter(courseId => courseId !== e.target.id);
      }
    });
  };

  const containerSelectButtonStyle = {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end'
  };

  return (
    <>
      <Row className="mb-3">
        <Col md={4}>
          <SearchBox
            id="searchCourses"
            name="searchCourses"
            placeholderText={t('search', { ns: 'common' })}
            width="190px"
            height="35px"
            value=""
            onChangeValue={handleSearchChange}
          />
        </Col>
        <Col className="text-right">
          {coursesLibraryType === LibraryTypesEnum.CompanyAndBuildwitt && (
            <ButtonGroup>
              {coursesSources.map(source => (
                <BasicToggleButton
                  key={`select-company-${source.value}`}
                  id={`select-company-${source.value}`}
                  type="radio"
                  name="coursesSource"
                  value={source.value}
                  checked={CoursesSource === source.value}
                  color={
                    CoursesSource === source.value ? 'primary' : 'secondary'
                  }
                  onChange={e => setCoursesSource(e.currentTarget.value)}
                >
                  {source.text as string}
                </BasicToggleButton>
              ))}
            </ButtonGroup>
          )}
        </Col>
      </Row>
      <Row className="card-container flex-fill">
        <Col className="d-flex flex-wrap p-0">
          {coursesToShow.map(course => (
            <Col className="ps-0 pb-2-half" md={12} lg={6} key={course.id}>
              <CourseCard
                course={course}
                width="100%"
                height="280px"
                showEdit={false}
              >
                <div style={containerSelectButtonStyle}>
                  <SelectEntityButton
                    entity={course}
                    isSelected={selectedCoursesIds.includes(course.id)}
                    onChangeSelection={onChangeCourseSelection}
                  />
                </div>
              </CourseCard>
            </Col>
          ))}

          {coursesToShow.length === 0 && (
            <Col className=" mb-3 p-0" xs={12}>
              {t('noCoursesAvailable', { ns: 'courses' })}{' '}
              {CoursesSource === Buildwitt_Value ? BuildWittName : companyName}
            </Col>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SelectCourses;
