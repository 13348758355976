import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/userProfile';

import {
  USERPROFILE_SET_IS_LOADING,
  USERPROFILE_SET_CURRENT_USERPROFILE,
  USERROLE_SET_USERROLE
} from '../actions/actionTypes';
import { UserProfileDto } from '../entities/UserProfile';

export interface UserProfileState {
  errorMessage: string;
  isLoading: boolean;
  userProfiles: UserProfileDto[];
  currentUserProfile: UserProfileDto | null;
  currentUserProfileNotFound: boolean | null;
  role: number;
}

const unloadedState: UserProfileState = {
  errorMessage: '',
  isLoading: false,
  userProfiles: [],
  currentUserProfile: null,
  currentUserProfileNotFound: null,
  role: 0
};

export const UserProfileReducer: Reducer<UserProfileState> = (
  state: UserProfileState | undefined,
  incomingAction: Action
): UserProfileState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case USERPROFILE_SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };

    case USERPROFILE_SET_CURRENT_USERPROFILE:
      return {
        ...state,
        currentUserProfile: action.profile,
        currentUserProfileNotFound: action.profileNotFound
      };
      case USERROLE_SET_USERROLE:
        return {
          ...state,
          role: action.role,
        };
    default:
      return state;
  }
};
