import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CertificationSectionItem } from './CertificationSectionItem';
import React, { CSSProperties } from 'react';
import { useHistory, useParams } from 'react-router';
import { UserProfileDetailParams } from '../../../entities/Common';
import { CertificationDto } from '../../../entities/UserProfile';
import { CertificationSectionEmpty } from './CertificationSectionEmpty';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { CardHeader } from '../../../components/Card/CardHeader';

export const CertificationSection = ({
  certifications
}: {
  certifications: CertificationDto[] | undefined;
}) => {
  const [accordionOpen, setAccordionOpen] = React.useState(true);
  const history = useHistory();
  const { userProfileId } = useParams<UserProfileDetailParams>();

  const certificationDetails = () => {
    history.push(`/user/profile/${userProfileId}/certification`);
  };

  if (!certifications || (certifications && certifications.length === 0)) {
    return <CertificationSectionEmpty />;
  }

  return (
    <Accordion expanded={accordionOpen}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ cursor: 'pointer' }}
            onClick={() => setAccordionOpen(!accordionOpen)}
          />
        }
        sx={{ cursor: 'unset !important' }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Stack direction={'row'} spacing={2}>
          <CardHeader title={'Licenses / Certifications'} />
          <BasicButton
            color="secondary"
            endIcon={
              <EditIcon
                style={{
                  color: '#000',
                  fontSize: '16px'
                }}
              />
            }
            onClick={certificationDetails}
            style={{ height: '30px', color: '#EDECE8', marginTop: '5px' }}
          >
            <label style={editLabelStyle}>Edit</label>
          </BasicButton>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {certifications?.map(certification => (
          <CertificationSectionItem
            certification={certification}
            key={certification.id}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const editLabelStyle: CSSProperties = {
  color: '#000',
  fontSize: '14px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '14px'
};
