import React, { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import BasicButton from '../core/BasicButton/BasicButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Grid,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { UserProfileDto } from '../../entities/UserProfile';
import { actionCreators as companyActions } from '../../actions/companies';
import Autocomplete from '@mui/material/Autocomplete';
import { ApplicationState } from '../../store';

export interface AssignModalProps {
  showModal: boolean,
  onClose: () => void,
  admins: UserProfileDto[],
  setAdmin: (id: string) => void;
  removeAdmin: (id: string) => void;
}

type EmployeeCombobox = {
  label: string,
  id: string,
};

const useStyles = makeStyles({
  autocompleteStyle: {
    width: 300,
    marginRight: 30, 
    borderRadius: 8,
  },
  table: {
    border: '1px solid #F6F6F4',
    width: '95%',
    marginInline: 20,
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: 50
  },
  tableCell: {
    borderBottom: '1px solid #F6F6F4',
  },
  tableHeader: {
    background: '#F6F6F4',
    borderRadius: '10px',
    fontSize: 20
  },
  headerText: {
    fontWeight: 'bold',
  },
});

const ManageAdminModal: React.FC<AssignModalProps> = ({
  showModal,
  setAdmin,
  removeAdmin,
  onClose,
  admins
}) => {
  const dispatch = useDispatch();
  const [adminToAdd, setAdminToAdd] = useState<EmployeeCombobox | null>(null);
  const adminsById = useMemo(() => admins?.map(admin => admin.id), [admins]);
  const employeesByQuery = useSelector(
    (state: ApplicationState) => state.companies?.employeesQueryResult
  );
  const theme = useTheme();
  const classes = useStyles();
  const defaultDialogWidth: DialogProps['maxWidth'] = 'lg';
  const iconStyle = {
    position: 'absolute' as 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
  }

  const handleQueryChange = (event:  React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target.value) {
      return
    }

    setTimeout(() => {
      dispatch(companyActions.getEmployeeByQuery(event.target.value));
    }, 1000)
  }

  const renderTable = () => (
    <Table aria-label="admins table" className={classes.table}>
      <TableHead className={classes.tableHeader}>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <Typography color="#4D4E49" fontWeight="bold">
              Employee
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography color="#4D4E49" fontWeight="bold">
              Contact Info
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}>
            <Typography color="#4D4E49" fontWeight="bold">
              Job Title
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {admins?.map((admin) => (
          <TableRow key={admin.id}>
            <TableCell className={classes.tableCell} component="th" scope="row">
            <img
              style={{marginRight: 5}}
              className={`img-profile rounded-circle 'my-3' }`}
              width="40"
              height="40"
              alt="Employee"
              src={ admin.photoURL
                      ? admin.photoURL 
                      : require('../../assets/user.png')}
            />
              {admin.name} {admin.lastName}
            </TableCell>
            <TableCell className={classes.tableCell}>{admin.email}</TableCell>
            <TableCell className={classes.tableCell}>
              <Box display="flex" justifyContent="space-between">
                <Typography>{admin.jobTitle}</Typography>
                <IconButton
                  aria-label="delete"
                  onClick={() => removeAdmin(admin.id)}
                >
                  <DeleteIcon fontSize="small" color="disabled"/>
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

    return(
      <Dialog sx={{padding: 10, height: 700}} open={showModal} onClose={onClose} maxWidth={defaultDialogWidth} fullWidth>
        <DialogTitle>
          <Typography color="#4D4E49" fontWeight="700" fontSize={36}>Manage Admins</Typography>
          <IconButton aria-label='close' onClick={onClose} sx={{...iconStyle}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box sx={{
          display: "flex",
          paddingInline: 1,
          width: 450,
          alignSelf: 'flex-end',
          alignItems: 'center',
          marginRight: 5,
          marginBottom: 2,
        }}>
          <Autocomplete
            freeSolo
            value={adminToAdd?.label}
            key={adminToAdd?.id}
            className={classes.autocompleteStyle}
            placeholder='Search by name or phone'
            options={employeesByQuery?.filter(item => !adminsById?.includes(item.id)).map(employee => ({
              label: `${employee.name} ${employee.lastName}`,
              id: employee.id,
              phoneNumber: employee.phoneNumber,
              email: employee.email
            })) ?? []}
            onChange={(_, value) => {
              // TODO: Fix any type to generic autocomplete onchange value type
             setAdminToAdd(value as any);
            }}
            filterOptions={(options, { inputValue }) => {
              return options.filter(option => {
                const nameMatch = option.label.toLowerCase().includes(inputValue.toLowerCase());
                const phoneMatch = option.phoneNumber && option.phoneNumber.includes(inputValue);
                const emailMatch = option.email && option.email.toLowerCase().includes(inputValue.toLowerCase());
  
                return nameMatch || phoneMatch || emailMatch;
              });
            }}
            renderInput={params => (
              <TextField
              placeholder='Search By Name or Phone'
              onChange={handleQueryChange}
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
          <BasicButton 
            width="200px"
            height="32px"
            color="primary"
            onClick={() => {
              setAdmin(adminToAdd?.id || '');
              setAdminToAdd(null);
            }}
            disabled={!adminToAdd}
          >
            Add
          </BasicButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {admins?.length ? renderTable() : <Typography color="4D4E49" fontSize={22} display="flex" justifyContent="center" paddingY={10} fontWeight="bold">There is no Admins added yet</Typography>}
          </Grid>
        </Grid>
      </Dialog>
    );
}

export default ManageAdminModal;
