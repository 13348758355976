import React, { CSSProperties, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { appPermissions, UserClaims } from '../../core/constants';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

import useCurrentUser from '../../hooks/useCurrentUser';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import { styled } from '@mui/material/styles';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import config from '../../config';

import { useTranslation } from 'react-i18next';
import LearningTable from './LearningTable';
import EmployeeTab from './EmployeeTab';

const Employees = () => {
  const theme = useTheme();
  const user = useCurrentUser();

  const [selectedTabValue, setSelectedValueTab] = useState<string>('employee');
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const { t } = useTranslation(['common', 'employees']);

  const handleChange = (event: any, newValue: any) => {
    setSelectedValueTab(newValue);
  };

  const StyleTab = styled(Tab)<TabProps>(() => ({
    '&.Mui-selected': {
      backgroundColor: 'transparent !important'
    }
  }));

  const handleNewEmployeeGroup = () => {
    window.open(`${config.AUTHORITY}/Employees?compid=${companyId}`);
  };

  return (
    <>
      <PageContainer>
        <Row>
          <Col xs={12} sm={12} md={12} lg={4}>
            <h3>{t('employeeManagement', { ns: 'employees' })}</h3>
          </Col>
          <Col xs={12} sm={12} md={12} lg={8}>
            <div style={buttonsContainerStyle}>
              <ProtectedComponent action={appPermissions.ADD_NEW_EMPLOYEE}>
                <BasicButton
                  color="primary"
                  style={buttonStyle}
                  onClick={handleNewEmployeeGroup}
                >
                  <Typography sx={fontButton}>
                    {t('addNewEmployee', { ns: 'employees' })}
                  </Typography>
                </BasicButton>
              </ProtectedComponent>
              <ProtectedComponent action={appPermissions.CREATE_GROUP}>
                <span className="ps-3" />
                <BasicButton
                  color="secondary"
                  style={buttonStyle}
                  onClick={handleNewEmployeeGroup}
                >
                  <Typography sx={fontButton}>
                    {t('createGroup', { ns: 'employees' })}
                  </Typography>
                </BasicButton>
              </ProtectedComponent>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Box sx={boxContainerStyle}>
              <TabContext value={selectedTabValue}>
                <Box sx={boxStyle}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <StyleTab
                      value="employee"
                      label={
                        <Typography
                          sx={{
                            ...fontTab,
                            color: `${
                              selectedTabValue === 'employee'
                                ? theme.palette.primary.main
                                : theme.palette.grey[100]
                            }`
                          }}
                        >
                          {t('profile', { ns: 'common' })}
                        </Typography>
                      }
                    />
                    <StyleTab
                      value="learning"
                      label={
                        <Typography
                          sx={{
                            ...fontTab,
                            color: `${
                              selectedTabValue === 'learning'
                                ? theme.palette.primary.main
                                : theme.palette.grey[100]
                            }`
                          }}
                        >
                          {t('learning', { ns: 'employees' })}
                        </Typography>
                      }
                    />
                  </TabList>
                </Box>
                <TabPanel value="employee" sx={tabPanelStyle}>
                  <HorizontalLine color={theme.palette.common.white} line={2} />
                  <EmployeeTab />
                </TabPanel>
                <TabPanel value="learning" sx={tabPanelStyle}>
                  <HorizontalLine color={theme.palette.common.white} line={2} />
                  <LearningTable />
                </TabPanel>
              </TabContext>
            </Box>
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};

const buttonsContainerStyle: CSSProperties = {
  textAlign: 'right',
  width: '100%',
  paddingTop: '20px',
  paddingBottom: '20px'
};

const boxContainerStyle: SxProps = {
  width: '100%',
  typography: 'body1'
};

const boxStyle: SxProps = {
  borderBottom: 1,
  borderColor: 'divider'
};

const tabPanelStyle: SxProps = {
  paddingLeft: '0',
  paddingRight: '0'
};

const fontTab: CSSProperties = {
  fontSize: '20px',
  textTransform: 'capitalize'
};

export default Employees;
