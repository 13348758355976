import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import BasicButton from '../../components/core/BasicButton/BasicButton';
import { actionCreators as appActions } from '../../actions/appState';
import { actionCreators as companyActions } from '../../actions/companies';
import { ApplicationState } from '../../store';
import { CompanyProfileDTO } from '../../entities/Dto/createCompanyDto';
import { SimpleFormPage } from '../../components/Pages';
import FallbackLoader from '../../components/FallbackLoader/FallbackLoader';
import { NotAvailablePage } from '../../components/NotAvailablePage/NotAvailablePage';
import { SubmitHandler, useForm } from 'react-hook-form';
import { BasicButtonTypeEnum } from '../../core/enums';
import { CompanyProfileForm } from './CompanyProfileForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyProfileValidationSchema } from './CompanyProfileValidationSchema';

export const CreateCompanyProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, watch, formState, setValue } =
    useForm<CompanyProfileDTO>({
      resolver: yupResolver(CompanyProfileValidationSchema)
    });
  const watchIsCompanyVerify = watch('isCompanyVerify');

  const currentCompanyProfile = useSelector(
    (state: ApplicationState) => state.companies?.currentCompanyProfile
  );

  const industryCategories = useSelector(
    (state: ApplicationState) => state.appState?.industryCategories
  );

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  React.useEffect(() => {
    dispatch(appActions.getIndustryCategories());
  }, []);

  if (isLoading) {
    return <FallbackLoader />;
  }
  if (!isLoading && industryCategories && !industryCategories.length) {
    return <NotAvailablePage />;
  }

  if (currentCompanyProfile) {
    return <Redirect to={`/profile/company/${currentCompanyProfile.id}`} />;
  }

  const onSubmit: SubmitHandler<CompanyProfileDTO> = data => {
    dispatch(companyActions.createDWCompany(data, history));
  };

  return (
    <SimpleFormPage title={"Let's get your Company set up"}>
      <CompanyProfileForm
        title="Create your Company Profile"
        submitHandler={onSubmit}
        watch={watch}
        formState={formState}
        handleSubmit={handleSubmit}
        setValue={setValue}
        control={control}
        industryCategories={industryCategories}
        bottomActions={
          <BasicButton
            type={BasicButtonTypeEnum.Submit}
            color="primary"
            sx={theme => ({
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            })}
            disable={!watchIsCompanyVerify}
          >
            Create a Company Profile
          </BasicButton>
        }
      />
    </SimpleFormPage>
  );
};
