import React from 'react';
import { useTranslation } from 'react-i18next';

const errorStyle = { color: 'red' };

export const FormErrors = ({ errors }: { errors: string[] }) => {
  const { t } = useTranslation(['common']);

  return (
    <span style={errorStyle}>
      {t('fillRequiredFields',{ns: 'common'})}:
      <ul>
        {errors.map(error => (
          <li>{error}</li>
        ))}
      </ul>
    </span>
  );
};
