import React, { useState, CSSProperties, FC, useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import FormLabel from '../FormLabel/FormLabel';
import { useTranslation } from 'react-i18next';

interface CounterProps {
  numberQuestions: number;
  setCorrectAnswers: (correctAnswers: number) => void;
  numberCorrectAnswers?: number | any;
  setMainCounter: (mainCounter: number) => void;
}

const Counter: FC<CounterProps> = ({
  numberQuestions,
  setCorrectAnswers,
  numberCorrectAnswers,
  setMainCounter
}) => {
  const [counter, setCounter] = useState<number>(0);
  const { t } = useTranslation(['assessments', 'common', 'quizzes']);

  useEffect(() => {
    setCounter(numberQuestions);
    setCorrectAnswers(numberQuestions);
    setMainCounter(numberQuestions);
    if (numberCorrectAnswers) {
      setCounter(numberCorrectAnswers);
      setCorrectAnswers(numberCorrectAnswers);
      setMainCounter(numberCorrectAnswers);
    }
    if (numberQuestions <= numberCorrectAnswers) {
      setCounter(numberQuestions);
      setCorrectAnswers(numberQuestions);
      setMainCounter(numberQuestions);
    }
  }, [numberQuestions]);

  //handleAdd
  const handleAdd = () => {
    setCounter(counter + 1);
    setCorrectAnswers(counter + 1);
    setMainCounter(counter + 1);
  };
  //handleSubtract
  const handleSubtract = () => {
    setCounter(counter - 1);
    setCorrectAnswers(counter - 1);
    setMainCounter(counter - 1);
  };
  return (
    <div>
      <div>
        <FormLabel>
        {t('responsedQuestionsNeededToPass', {ns: 'assessments'})}{' '}
          <span className="text-danger">*</span>
        </FormLabel>
      </div>
      <div>
        <Button
          variant="contained"
          style={leftBtn}
          onClick={handleSubtract}
          disabled={counter === 0}
          disableElevation
        >
          <Typography style={textBtn}>-</Typography>
        </Button>
        <Button
          variant="contained"
          disableElevation
          style={centerBtn}
          sx={borderCenterBtn}
        >
          <Typography style={textBtn}>{counter}</Typography>
        </Button>
        <Button
          variant="contained"
          style={rightBtn}
          onClick={handleAdd}
          disabled={counter >= numberQuestions}
          disableElevation
        >
          <Typography style={textBtn}>+</Typography>
        </Button>
        <span style={spnStyle}>
          {t('thereAreCurrently', {ns: 'common'})} {numberQuestions}{' '}
          {numberQuestions === 1 ? t('question', {ns: 'assessments'}).toLowerCase() : t('questions', {ns: 'quizzes'}).toLowerCase()} {' '} {t('inThisQuiz', {ns: 'quizzes'})}
        </span>
      </div>
      <br/>
      <div>
      {counter === 0 && numberQuestions !==0 && <p className="text-danger">{t('mustChooseCorrectAnswers', {ns: 'assessments'})}</p>}
      </div>
    </div>
  );
};

const leftBtn: CSSProperties = {
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
  background: '#F4F4F4',
  width: '65px',
  height: '43px'
};

const rightBtn: CSSProperties = {
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  background: '#F4F4F4',
  width: '65 px',
  height: '43px'
};

const centerBtn: CSSProperties = {
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  background: '#F4F4F4',
  width: '95px',
  height: '43px'
};

const borderCenterBtn: CSSProperties = {
  borderLeft: 1,
  borderRight: 1,
  borderColor: '#999999'
};

const textBtn: CSSProperties = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#0E0D0D'
};

const spnStyle: CSSProperties = {
  color: '#999999',
  lineHeight: '18px',
  fontSize: '15px',
  marginTop: '5px',
  marginLeft: '10px'
};

export default Counter;
