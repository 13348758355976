import React, { CSSProperties, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/system';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import CourseCardList from '../../components/CourseCardList/CourseCardList';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { actionCreators } from '../../actions/courses';
import { actionCreators as courseActions } from '../../actions/courses';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import { useHistory } from 'react-router-dom';
import { Course, ImportCourseDto } from '../../entities/Course';
import { appPermissions, BuildWittName } from '../../core/constants';
import ModalPage from '../../components/core/ModalPage/ModalPage';
import SelectCourses from './SelectCourses';
import { LibraryTypesEnum } from '../../core/enums';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims, Buildwitt_Value } from '../../core/constants';
import { Typography, Box } from '@mui/material';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import AssignCourse from './AssignCourse';
import BasicToggleButton from '../../components/core/BasicToggleButton/BasicToggleButton';
import CreateCourse from './CreateCourse';
import { TransactionStatusEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';

export interface CoursesProps {}

interface ProgressAndActionButtonsProps {
  course: Course;
  cardAuxiliarFunction?: (id?: string) => void;
}

const ProgressAndActionButtons: React.FC<ProgressAndActionButtonsProps> = ({
  course,
  cardAuxiliarFunction
}) => {
  const history = useHistory();
  const { t } = useTranslation(['common']);

  const handleAssign = (id: string) => {
    if (cardAuxiliarFunction) cardAuxiliarFunction(id);
  };

  const buttonSection: CSSProperties = {
    width: '100%',
    height: '20%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end',
    alignItems: 'end'
  };

  const boxStyle: CSSProperties = {
    marginLeft: '10px'
  };

  return (
    <div style={buttonSection}>
      {!course.isDraft && (
        <ProtectedComponent action={appPermissions.VIEW_DRAFT_COURSE}>
          <BasicButton
            width="90px"
            height="30px"
            color="secondary"
            onClick={() => handleAssign(course.id)}
          >
            {t('assign', {ns: 'common'})}
          </BasicButton>
        </ProtectedComponent>
      )}
      <Box style={boxStyle}>
        <BasicButton
          width="90px"
          height="30px"
          color="primary"
          onClick={() => {
            history.push(
              `/courses/${course.id}/revision/${course.revisionNumber}`
            );
          }}
        >
          {t('start', {ns: 'common'})}
        </BasicButton>
      </Box>
    </div>
  );
};

const toggleContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: {
    xs: 'flex-start',
    md: 'flex-end'
  },
  marginTop: '20px'
};

const Courses: React.FC<CoursesProps> = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const companyName = user?.profile[UserClaims.CompanyName] as string;
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId];
  const { t } = useTranslation(['common', 'courses']);

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const courses = useSelector(
    (state: ApplicationState) => state.courses?.courses
  );

  const [courseSource, setCourseSource] = React.useState<string>(companyId);
  const currentUserCanSeeDraftCourses =
    user?.profile['BuildWitt.User.Role'] !== 'Employee';
  const [draftCourses, setDraftCourses] = React.useState<Course[]>([]);
  const [publishedCourses, setPublishedCourses] = React.useState<Course[]>([]);
  const [filteredPublished, setFilteredPublished] = React.useState<Course[]>(
    []
  );
  const [searchText, setSearchText] = React.useState<string>('');
  const [openSelectModal, setOpenSelectModal] = React.useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = React.useState<boolean>(false);
  const [showAssignCourseModal, setShowAssignCourseModal] = React.useState<
    boolean
  >(false);
  const coursesToImportRef = useRef<string[]>([]);
  const refreshCoursesRef = useRef<boolean>(false);

  const courseSources = [
    {
      text: companyName,
      value: companyId
    },
    {
      text: BuildWittName,
      value: Buildwitt_Value
    }
  ];

  React.useEffect(() => {
    if (companyId) setCourseSource(companyId);
  }, [companyId]);

  React.useEffect(() => {
    if (!isLoading && refreshCoursesRef.current) {
      dispatch(actionCreators.requestCourses());
      refreshCoursesRef.current = false;
    }
  }, [isLoading]);

  React.useEffect(() => {
      dispatch(actionCreators.requestCourses());
      dispatch(actionCreators.requestCourseTags());
  }, [dispatch]);

  React.useEffect(() => {
    if (courses) setInitialCoursesfilter(courses);
  }, [courses]);

  React.useEffect(() => {
    if (searchText) {
      const filtered = filterCourses(searchText, courses!);
      setDraftCourses(getCoursesByStatus(filtered, true));
      const filteredPublished = getCoursesByStatus(filtered, false);
      setPublishedCourses(filteredPublished);
      setFilteredPublished(getFilteredCoursesBySource(filteredPublished));
    } else if (courses) setInitialCoursesfilter(courses);
  }, [searchText]);

  React.useEffect(() => {
    const filtered = getFilteredCoursesBySource(publishedCourses);
    const extraFiltered = filterCourses(searchText, filtered);
    setFilteredPublished(extraFiltered);
  }, [courseSource]);

  const setInitialCoursesfilter = (courses: Course[]) => {
    setDraftCourses(getCoursesByStatus(courses, true));
    const published = getCoursesByStatus(courses, false);
    setPublishedCourses(published);
    setFilteredPublished(getFilteredCoursesBySource(published));
  };

  const getFilteredCoursesBySource = (courses: Course[]) => {
    return courses.filter(course =>
      courseSource === Buildwitt_Value
        ? course.companyId === null || course.companyId === bWCompanyId
        : course.companyId === courseSource
    );
  };

  const getCoursesByStatus = (filteredCourses: Course[], isDraft: boolean) => {
    let courseList = filteredCourses.filter(
      course => course.isDraft === isDraft
    );
    if (isDraft)
      courseList = courseList.filter(course => course.companyId === companyId);

    return courseList;
  };

  const filterCourses = (criteria: string, vcourses: Course[]) => {
    let filtered: Course[] = [];
    vcourses.forEach(course => {
      const tags = course.tags.slice();
      if (
        course.title.toLowerCase().includes(criteria.toLowerCase()) ||
        course.description.toLowerCase().includes(criteria.toLowerCase()) ||
        tags.filter(tag =>
          tag.name.toLowerCase().includes(criteria.toLowerCase())
        ).length > 0
      ) {
        filtered.push(course);
      }
    });
    return filtered;
  };

  const getSelectedCoursesIds = (courseIds: string[]) => {
    coursesToImportRef.current = courseIds;
  };

  const importCourses = () => {
    if (!coursesToImportRef.current?.length) {
      alert(t('noCoursesSelected', {ns: 'courses'}));
      return;
    }

    const importCourses: ImportCourseDto[] = coursesToImportRef.current.map(
      courseId => {
        return { courseId, companyId };
      }
    );

    dispatch(actionCreators.importCoursesBatch(importCourses));
    coursesToImportRef.current = [];
    refreshCoursesRef.current = true;
    setOpenSelectModal(false);
  };

  const buttonsContainerStyle: CSSProperties = {
    textAlign: 'right',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '20px'
  };

  const searchBoxContainerStyle: CSSProperties = {
    textAlign: 'left',
    maxWidth: '350px',
    paddingTop: '20px',
    paddingBottom: '20px'
  };

  const handleOpenAssignModal = (id?: string) => {
    dispatch(courseActions.requestCourseById(id!));
    setShowAssignCourseModal(true);
  };

  const handleCloseAssignmodal = () => {
    dispatch(courseActions.setCurrentCourse(null));
    setShowAssignCourseModal(false);
  };

  return (
    <>
      <PageContainer>
        <Row>
          <h3>{t('courses', {ns: 'courses'})}</h3>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div style={searchBoxContainerStyle}>
              <SearchBox
                id="searchLesson"
                name="searchLesson"
                value=""
                onChangeValue={e => {
                  setSearchText(e);
                }}
                placeholderText={t('search', {ns: 'common'})}
                width="350px"
                height="35px"
                onSearchClick={e => {}}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div style={buttonsContainerStyle}>
              <ProtectedComponent action={appPermissions.CREATE_COURSE}>
                <BasicButton
                  color="primary"
                  onClick={() => setOpenCreateModal(true)}
                  style={buttonStyle}
                >
                  <Typography sx={fontButton}>{t('createCourse', {ns: 'courses'})}</Typography>
                </BasicButton>
              </ProtectedComponent>
              <ProtectedComponent action={appPermissions.IMPORT_COURSE}>
                <span className="ps-3" />
                <BasicButton
                  color="primary"
                  onClick={() => setOpenSelectModal(true)}
                  style={buttonStyle}
                >
                  <Typography sx={fontButton}>{t('importCourse', {ns: 'courses'})}</Typography>
                </BasicButton>
              </ProtectedComponent>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <ProtectedComponent action={appPermissions.VIEW_DRAFT_COURSE}>
              <>
                <h3 className="pb-2">{t('drafts', {ns: 'common'})}</h3>
                {!isLoading && (
                  <CourseCardList
                    courses={draftCourses || []}
                    cardComplement={ProgressAndActionButtons}
                    shouldShowDrafCourses={currentUserCanSeeDraftCourses}
                    cardAuxiliarFunction={handleOpenAssignModal}
                  />
                )}
              </>
            </ProtectedComponent>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div>
                  <h3 className="pt-5" style={{marginBottom: '20px'}}>{t('publishedCourses', {ns: 'courses'})}</h3>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div style={buttonsContainerStyle}>
                  <Grid
                    item
                    className="toggle-button"
                    sx={toggleContainerStyle}
                  >
                    {!isLoading &&
                      publishedCourses.length &&
                      courseSources.map(source => (
                        <BasicToggleButton
                          key={`switch-${source.value}`}
                          id={`switch-${source.value}`}
                          type="radio"
                          name="LPSource"
                          value={source.value}
                          checked={courseSource === source.value}
                          color={
                            courseSource === source.value
                              ? 'primary'
                              : 'secondary'
                          }
                          onChange={e => setCourseSource(e.currentTarget.value)}
                        >
                          {source.text}
                        </BasicToggleButton>
                      ))}
                  </Grid>
                </div>
              </Col>
            </Row>

            {!isLoading && (
              <CourseCardList
                courses={filteredPublished || []}
                shouldShowDrafCourses={currentUserCanSeeDraftCourses}
                cardComplement={ProgressAndActionButtons}
                cardAuxiliarFunction={handleOpenAssignModal}
              />
            )}
          </Col>
        </Row>
      </PageContainer>
      <ModalPage
        title={t('createCourse', {ns: 'courses'})}
        onSave={() => {}}
        onCancel={() => setOpenCreateModal(false)}
        show={openCreateModal}
        showSave={false}
        showCancel={false}
        saveText=""
        cancelText=""
        variant="lg"
        contentClassName="modal-body-auto-height"
        backdrop="static"
        keyboard={false}
      >
        <ProtectedComponent action={appPermissions.CREATE_COURSE}>
          <CreateCourse />
        </ProtectedComponent>
      </ModalPage>
      <ModalPage
        title={t('importCourse', {ns: 'courses'})}
        onSave={importCourses}
        onCancel={() => setOpenSelectModal(false)}
        show={openSelectModal}
        showSave={true}
        showCancel={false}
        saveText={t('import', {ns: 'common'})}
        cancelText={t('cancel', {ns: 'common'})}
        variant="lg"
        backdrop="static"
        keyboard={false}
      >
        <SelectCourses
          coursesLibraryType={LibraryTypesEnum.Buildwitt}
          getSelectedCoursesIds={getSelectedCoursesIds}
        />
      </ModalPage>
      {showAssignCourseModal && (
        <AssignCourse
          open={showAssignCourseModal}
          onClose={handleCloseAssignmodal}
        />
      )}
    </>
  );
};
export default Courses;
