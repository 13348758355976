import {
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { FormTitle } from '../../../components/Pages';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import TextBox from '../../../components/core/TextBox/TextBox';
import { MuiFileInput } from 'mui-file-input';
import React, { useEffect } from 'react';
import { UserProfileDto } from '../../../entities/UserProfile';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import {
  actionCreators as addressActions,
  selectCityItems,
  selectCountryItems,
  selectStateItems
} from '../../../actions/address';
import { IAmOptions } from '../../../core/constants';
import Autocomplete from '@mui/material/Autocomplete';
import { IndustryCategory } from '../../../entities/IndustryCategory';

interface IdentificationFormProps
  extends Pick<
    UseFormReturn<UserProfileDto>,
    'control' | 'watch' | 'handleSubmit' | 'formState' | 'setValue'
  > {
  title: string;
  submitHandler: SubmitHandler<UserProfileDto>;
  bottomActions: React.ReactNode;
  industryCategories?: IndustryCategory[];
}

export const IdentificationForm = ({
  title,
  control,
  watch,
  formState,
  setValue,
  handleSubmit,
  submitHandler,
  bottomActions,
  industryCategories = []
}: IdentificationFormProps) => {
  const dispatch = useDispatch();
  const watchCountry = watch('address.country');
  const watchState = watch('address.state');

  const isLoadingCountries = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCountries
  );
  const isLoadingStates = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingStates
  );
  const isLoadingCities = useSelector(
    (state: ApplicationState) => state.addresses?.isLoadingCities
  );

  const countryItems = useSelector(selectCountryItems);

  const stateItems = useSelector(selectStateItems);

  const cityItems = useSelector(selectCityItems);

  useEffect(() => {
    if (watchCountry)
      dispatch(addressActions.getStatesFromCountries(watchCountry));
  }, [watchCountry]);

  useEffect(() => {
    if (watchState && watchCountry)
      dispatch(addressActions.getCitiesFromStates(watchCountry, watchState));
  }, [watchState]);
  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormTitle title={title} />
        </Grid>
        {/*==========    ROW    =========*/}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.name}>
            <FormLabel>
              First Name <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>{formState.errors.name?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.lastName}>
            <FormLabel>
              Last Name <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.lastName?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/*==========    ROW    =========*/}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>I am</FormLabel>
            <Controller
              name="iAm"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select
                  onChange={onChange}
                  value={value || ''}
                  displayEmpty
                  defaultValue={0}
                >
                  <MenuItem disabled value={0}>
                    - select -
                  </MenuItem>
                  {IAmOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.industryCategories}>
            <FormLabel>
              Industry/Specialty
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="industryCategories"
              control={control}
              defaultValue={[]}
              render={({ field: { value, onChange: formOnChange } }) => (
                <Autocomplete
                  disablePortal
                  getOptionLabel={option => option.name}
                  options={industryCategories ?? []}
                  renderInput={params => <TextField {...params} />}
                  value={value}
                  onChange={(_, newValue) => {
                    formOnChange(newValue);
                  }}
                  multiple
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              )}
            />
            <FormHelperText>
              {formState.errors.industryCategories?.message}
            </FormHelperText>
          </FormControl>
        </Grid>

        {/*==========    ROW    =========*/}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>Country</FormLabel>
            <Controller
              name="address.country"
              control={control}
              defaultValue=""
              render={({ field: { value, onChange: formOnChange } }) => (
                <>
                  <Autocomplete
                    disablePortal
                    options={countryItems ?? []}
                    loading={isLoadingCountries}
                    value={value}
                    onChange={(_, newValue) => {
                      setValue('address.state', '');
                      setValue('address.city', '');
                      formOnChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoadingCountries && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>State</FormLabel>
            <Controller
              name="address.state"
              control={control}
              defaultValue=""
              render={({ field: { value, onChange: formOnChange } }) => (
                <>
                  <Autocomplete
                    disablePortal
                    options={stateItems ?? []}
                    loading={isLoadingStates}
                    value={value}
                    onChange={(_, newValue) => {
                      setValue('address.city', '');
                      formOnChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoadingStates && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </>
              )}
            />
          </FormControl>
        </Grid>

        {/*==========    ROW    =========*/}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <FormLabel>City</FormLabel>
            <Controller
              name="address.city"
              control={control}
              defaultValue=""
              render={({ field: { value, onChange: formOnChange } }) => (
                <>
                  <Autocomplete
                    disablePortal
                    options={cityItems ?? []}
                    loading={isLoadingCities}
                    value={value}
                    onChange={(_, newValue) => {
                      formOnChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {isLoadingCities && (
                                <CircularProgress color="inherit" size={20} />
                              )}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} />

        {/*==========    ROW    =========*/}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Role</FormLabel>
            <Controller
              name="jobTitle"
              control={control}
              defaultValue=""
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
          </FormControl>
        </Grid>

        {/*==========    ROW    =========*/}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Upload your Profile image</FormLabel>
            <Controller
              name="profileImage"
              control={control}
              render={({ field: { name, onChange, value }, fieldState }) => (
                <MuiFileInput
                  id={name}
                  value={value}
                  onChange={onChange}
                  helperText={fieldState.invalid ? 'File is invalid' : ''}
                  error={fieldState.invalid}
                />
              )}
            />
            <FormHelperText>
              <Typography variant="blackCaptionItalic">
                Image must be 400px by 400px, less than 1mb, png or jpeg.
              </Typography>
            </FormHelperText>
          </FormControl>
        </Grid>

        {/*==========    ROW    =========*/}
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {bottomActions}
        </Grid>
      </Grid>
    </form>
  );
};
