import React, { useState, useEffect, CSSProperties } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Course } from '../../entities/Course';
import { LearningPlan } from '../../entities/LearningPlan';
import AssignEmployeesPanel, { AssignEmployeesPanelProps } from '../../components/AssignEmployeesPanel/AssignEmployeesPanel';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

export interface AssignModalProps extends AssignEmployeesPanelProps {
    showModal: boolean,
    assignToEntity?: Course | LearningPlan | null,
    errorMessage: string,
    savingInProgress: boolean,
    onAssign: () => void,
    onClose: () => void
}

const CustomDialog = styled(Dialog)`
    & .MuiDialogActions-root {
        justify-content: center;
        padding: 0 24px 20px;
    }
`;

const AssignModal: React.FC<AssignModalProps> = ({
    showModal,
    assignToEntity,
    errorMessage,
    employees,
    employeeGroups,
    assignment,
    savingInProgress,
    setAssignment,
    onAssign,
    onClose
}) => {
    const theme = useTheme();
    const [title, setTitle] = useState<string>('');
    const defaultDialogWidth: DialogProps['maxWidth'] = 'lg';
    const { t } = useTranslation(['common', 'assignments']);

    useEffect(() => {
        let nameOrTitle: string = '';
        if (assignToEntity){
            const entity: any = assignToEntity;
            if (entity.title)
                nameOrTitle = entity.title;
            else if (entity.name)
                nameOrTitle = entity.name;

            setTitle(`${t('assign', {ns: 'common'})} ${nameOrTitle}`);
        }
    }, [assignToEntity]);

    const iconStyle = {
        position: 'absolute' as 'absolute',
        right: 8,
        top: 8,
        color: theme.palette.grey[500],
    }

    const titleStyle: CSSProperties = {
        color: theme.palette.grey[100],
        fontSize: '15px'
    };

    const mandatoryMarkStyle: CSSProperties = {
        color: 'red'
    };    

    return(
        <CustomDialog
            open={showModal}
            onClose={onClose}
            maxWidth={defaultDialogWidth}
            fullWidth={true}
        >
            <DialogTitle>
                {title}
                <IconButton
                    aria-label='close'
                    onClick={onClose}
                    sx={{...iconStyle}}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <p className="text-danger">{errorMessage}</p>
                <div className="pt-2 pb-3">
                    <span style={titleStyle}>
                    {t('selectEmployeesToAsign', {ns: 'assignments'})} <span style={mandatoryMarkStyle}>*</span>
                    </span>
                </div>
                <AssignEmployeesPanel 
                    employees={employees ?? []}
                    employeeGroups={employeeGroups ?? []}
                    assignment={assignment}
                    setAssignment={setAssignment}
                />
            </DialogContent>
            <DialogActions>
                {savingInProgress && (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </div>
                )}
                {!savingInProgress && (
                    <BasicButton
                            color="primary"
                            width='160px'
                            height='45px'
                            onClick={onAssign}
                        >
                            {t('assign', {ns: 'common'})}
                    </BasicButton>
                )}
            </DialogActions>
        </CustomDialog>
    );
}

export default AssignModal;
