import * as yup from 'yup';

export const JobFormValidationSchema = yup
  .object({
    jobTitle: yup.string().required('Job Title is required.'),
    jobCategory: yup.string().required('Job Category is required.'),
    jobType: yup.string().required('Job Type is required.'),
    compensation: yup.string().required('Pay Rate is required.'),
    jobLocationType: yup.string().required('Location place is required.'),
    postingAddress: yup.object({
      country: yup.string().required('Country is required.'),
      state: yup.string().required('State is required.'),
      postalCode: yup.string().required('Zipcode is required.'),
    }),
    description: yup.string().required('Job Description name is required.'),
    expiresAtUtc: yup.date().required('Length of Job Post is required.'),
  })
  .required();