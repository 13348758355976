import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { Controller, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { getFutureYears, getYears } from '../../../utils/dateUtils';
import { CertificationDto } from '../../../entities/UserProfile';
import React from 'react';
import { FormTitle } from '../../../components/Pages';
import TextBox from '../../../components/core/TextBox/TextBox';

interface CertificationFormProps
  extends Pick<
    UseFormReturn<CertificationDto>,
    'control' | 'handleSubmit' | 'formState' | 'setValue' | 'watch'
  > {
  submitHandler: SubmitHandler<CertificationDto>;
  title: string;
  bottomActions: React.ReactNode;
}

export const CertificationForm = ({
  control,
  formState,
  watch,
  setValue,
  title,
  handleSubmit,
  submitHandler,
  bottomActions
}: CertificationFormProps) => {
  const watchDoesNotExpire = watch('doesNotExpire');

  React.useEffect(() => {
    if (watchDoesNotExpire) {
      setValue('expirationDate', '', { shouldValidate: true });
    }
  }, [watchDoesNotExpire]);

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        columnSpacing={2}
        rowSpacing={5}
        sx={{ marginTop: 0 }}
      >
        <Grid item xs={12}>
          <FormTitle title={title} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.skillName}>
            <FormLabel>
              Licence / Certifications{' '}
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="skillName"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.skillName?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.grantingInstitution}>
            <FormLabel>
              Granting Institution{' '}
              <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="grantingInstitution"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
            <FormHelperText>
              {formState.errors.grantingInstitution?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth error={!!formState.errors.dateAwarded}>
            <FormLabel>
              Issued Year <Typography variant="redCaption">*</Typography>
            </FormLabel>
            <Controller
              name="dateAwarded"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <Select
                  id={name}
                  value={value || ''}
                  onChange={onChange}
                  labelId="dateAwarded"
                >
                  {getYears().map((year: number) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <FormHelperText>
              {formState.errors.dateAwarded?.message}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControl fullWidth error={!!formState.errors.expirationDate}>
              <FormLabel>
                Expiration Year <Typography variant="redCaption">*</Typography>
              </FormLabel>
              <Controller
                name="expirationDate"
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <Select
                    id={name}
                    value={value || ''}
                    onChange={onChange}
                    disabled={watchDoesNotExpire}
                    labelId="expirationDate"
                  >
                    {getFutureYears().map((year: number) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText>
                {formState.errors.expirationDate?.message}
              </FormHelperText>
            </FormControl>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
          <FormControlLabel
            control={
              <Controller
                name="doesNotExpire"
                control={control}
                defaultValue={false}
                render={({ field: { name, onChange, value } }) => (
                  <Checkbox name={name} checked={!!value} onChange={onChange} />
                )}
              />
            }
            label="Does not expire"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel>Additional Details</FormLabel>
            <Controller
              name="details"
              control={control}
              render={({ field: { name, onChange, value } }) => (
                <TextBox
                  id={name}
                  name={name}
                  value={value || ''}
                  onChangeValue={onChange}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          {bottomActions}
        </Grid>
      </Grid>
    </form>
  );
};
