import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/courses';
import {
  Course,
  CourseLessonName,
  AssignCourseToEmployeeDto
} from '../entities/Course';
import { Tag } from '../entities/Tag';
import {
  COURSES_SET_COURSES,
  COURSES_SET_EDITING,
  COURSES_SET_TAGS,
  COURSE_BY_ID,
  COURSES_SET_LESSON_NAMES,
  COURSE_ERROR,
  COURSE_RESET_ERROR,
  COURSES_SET_COURSE_LIST,
  COURSES_UPDATE_TRANSACTION_STATUS,
  COURSES_UPDATE,
  COURSES_RESET_TRANSACTION_STATUS
} from '../actions/actionTypes';
import { TransactionStatusEnum } from '../core/enums';
import { sortByKey } from '../utils/sorting';

export interface CoursesState {
  courses: Course[];
  courseList: Course[];
  currentCourse: Course | null;
  tags: Tag[];
  courseLessonNames: CourseLessonName[];
  error: string;
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

const unloadedState: CoursesState = {
  courses: [],
  courseList: [],
  currentCourse: null,
  tags: [],
  courseLessonNames: [],
  error: '',
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: ''
};

export const newAssignCourseToEmployeeDto: AssignCourseToEmployeeDto = {
  courseId: '',
  employeeIds: []
};

export const CoursesReducer: Reducer<CoursesState> = (
  state: CoursesState | undefined,
  incomingAction: Action
): CoursesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case COURSES_SET_COURSES:
      return {
        ...state,
        courses: action.courses.slice().sort(sortByKey<Course>('title'))
      };
    case COURSES_SET_EDITING:
      return { ...state, currentCourse: action.course };
    case COURSES_SET_TAGS:
      return { ...state, tags: action.tags };
    case COURSE_BY_ID:
      return { ...state, currentCourse: action.course };
    case COURSES_SET_LESSON_NAMES:
      return { ...state, courseLessonNames: action.lessonNames };
    case COURSE_ERROR:
      return { ...state, error: action.error };
    case COURSE_RESET_ERROR:
      return { ...state, error: '' };
    case COURSES_SET_COURSE_LIST:
      return { ...state, courseList: action.courseList };
    case COURSES_UPDATE:
      return { ...state, currentCourse: action.course };
    case COURSES_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case COURSES_RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: TransactionStatusEnum.None,
        errorMessage: ''
      };
    default:
      return state;
  }
};
