import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Route } from 'react-router';
import ProtectedRoute from '../components/core/ProtectedRoute/ProtectedRoute';
import { appPermissions } from '../core/constants';
import Courses from '../pages/courses/Courses';
import EditCourse from '../pages/courses/EditCourse';
import CreateCourse from '../pages/courses/CreateCourse';
import CoursePreview from '../pages/courses/CoursePreview';
import CourseDetails from '../pages/courses/CourseDetails';
import LessonPreview from '../pages/lessons/LessonPreview';

const CourseRoutes = () => {
  const match = useRouteMatch();
  const path = match?.path;

  return (
    <>
      <ProtectedRoute
        exact
        path={path}
        component={Courses}
        action={appPermissions.VIEW_COURSES}
      />
      <Route
        exact
        path={`${path}/:id/revision/:revisionNumber`}
        component={CoursePreview}
        action={appPermissions.VIEW_COURSE}
      />
      <Route
        exact
        path={`${path}/:id/revision/:revisionNumber/preview`}
        component={LessonPreview}
        action={appPermissions.PREVIEW_COURSE}
      />
      <Route
        exact
        path={`${path}/:id/revision/:revisionNumber/play`}
        component={LessonPreview}
        action={appPermissions.PLAY_COURSE}
      />
      <ProtectedRoute
        exact
        path={`${path}/:id/edit`}
        component={CourseDetails}
        action={appPermissions.VIEW_COURSE_DETAILS}
      />
    </>
  );
};

export default CourseRoutes;
